import React, { useMemo } from 'react';
import { Box, Paper, Typography, IconButton, Tooltip } from '@mui/material';
import { InfoOutlined, TrendingUp } from '@mui/icons-material';
import Plotly from 'plotly.js-basic-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';
import { SCHOOL_CATEGORIES } from '../../../utils/schoolCategorization';

const Plot = createPlotlyComponent(Plotly);

const STEADY_MARKET_SHARE = 1.4;
const STEADY_POPULATION = 2.0;

// Helper to format text with markdown-style bold
const formatText = (text) => {
  return text.split('**').map((part, index) => 
    index % 2 === 0 ? 
      part : 
      <strong key={index}>{part}</strong>
  );
};

const getAnalysisSummary = ({
  schoolName,
  marketShareChange,
  populationChange,
  schoolCategory,
  timeframeData
}) => {
  if (!schoolName || !schoolCategory) return '';
  
  // Handle new and newer schools differently
  if ([
    SCHOOL_CATEGORIES.NEW,
    SCHOOL_CATEGORIES.NEWER_INCREASING,
    SCHOOL_CATEGORIES.NEWER_DECREASING,
    SCHOOL_CATEGORIES.NEWER_STABLE
  ].includes(schoolCategory)) {
    const schoolStatus = schoolCategory === SCHOOL_CATEGORIES.NEW ? 
      "new school" : 
      "newer school";
    
    const gradeStatus = (() => {
      switch(schoolCategory) {
        case SCHOOL_CATEGORIES.NEWER_INCREASING:
          return "that has been **expanding grade levels**";
        case SCHOOL_CATEGORIES.NEWER_DECREASING:
          return "that has been **reducing grade levels**";
        case SCHOOL_CATEGORIES.NEWER_STABLE:
          return "with **stable grade offerings**";
        default:
          return timeframeData?.endLabel ? 
            `that opened in ${timeframeData.endLabel}` : 
            '';
      }
    })();

    return `${schoolName} is a **${schoolStatus}** ${gradeStatus}`;
  }

  // For established schools, use the original logic with grade level context
  let shareTrend;
  let popTrend;

  if (Math.abs(marketShareChange) < STEADY_MARKET_SHARE) {
    shareTrend = "maintained a **steady** market share";
  } else {
    shareTrend = marketShareChange > 0 
      ? `**gained ${Math.round(marketShareChange)}%** market share`
      : `**lost ${Math.round(Math.abs(marketShareChange))}%** market share`;
  }
  
  if (Math.abs(populationChange) < STEADY_POPULATION) {
    popTrend = "**stable** population";
  } else {
    popTrend = populationChange > 0
      ? `**${Math.round(populationChange)}% growth** in school-age population`
      : `**${Math.round(Math.abs(populationChange))}% decline** in school-age population`;
  }

  const gradeContext = (() => {
    switch(schoolCategory) {
      case SCHOOL_CATEGORIES.CONTINUOUS_INCREASING:
        return " while **expanding grade levels**";
      case SCHOOL_CATEGORIES.CONTINUOUS_DECREASING:
        return " while **reducing grade levels**";
      default:
        return "";
    }
  })();
  
  return `${schoolName} has ${shareTrend} in an area with ${popTrend}${gradeContext}`;
};

const CompXCommunityPlot = ({ 
  selectedSchool,
  marketShareData,
  currentVsPastChange,
  schoolCategory,
  timeframeData,
  hideHeader = false 
}) => {
  const { plotData, layout } = useMemo(() => {
    if (!selectedSchool?.name || !marketShareData) {
      return {
        plotData: [],
        layout: {}
      };
    }
  
    // Check if school is new based on category
    const isNewSchool = schoolCategory === SCHOOL_CATEGORIES.NEW;

    // Calculate ranges and padding
    const maxY = isNewSchool ? 5 : Math.max(Math.abs(marketShareData.change || 0) * 2, 5);
    const minY = isNewSchool ? -5 : -maxY;
    const maxX = Math.max(Math.abs(currentVsPastChange || 0) * 2, 5);
    const minX = -maxX;
    
    const xPadding = maxX * 0.3;
    const yPadding = maxY * 0.3;

    // Create trace for selected school
    const mainSchoolTrace = {
      x: [currentVsPastChange],
      y: [isNewSchool ? 0 : marketShareData?.change],
      name: selectedSchool.name,
      mode: 'markers+text',
      type: 'scatter',
      marker: {
        symbol: 'diamond',
        size: 13,
        color: '#1976d2',
        opacity: 1
      },
      text: [selectedSchool.name],
      textposition: 'bottom center',
      textfont: {
        size: 14,
        color: '#1976d2',
        family: 'Roboto, "Helvetica Neue", Arial, sans-serif',
        weight: 600
      },
      hovertemplate: isNewSchool ?
        '%{text}<br>' +
        'Area Population Change: %{x:,.0f}%<br>' +
        'Market Share Change: 0% (new school)' +
        '<extra></extra>' :
        '%{text}<br>' +
        'Area Population Change: %{x:,.0f}%<br>' +
        'Market Share Change: %{y:,.0f}%' +
        '<extra></extra>'
    };

    const layout = {
      height: 500, // Increased height for better visibility
      margin: { t: 20, r: 50, b: 80, l: 80 },
      showlegend: false,
      paper_bgcolor: 'rgba(0,0,0,0)',
      plot_bgcolor: 'rgba(0,0,0,0)',
      xaxis: {
        title: {
          text: '<b>Area Population Change (%)</b>',
          font: { 
            size: 11,
            family: '"Roboto"'
          },
          standoff: 40
        },
        zeroline: false,
        range: [minX - xPadding, maxX + xPadding],
        gridcolor: '#e0e0e0'
      },
      yaxis: {
        title: {
          text: '<b>Market Share Change (%)</b>',
          font: { 
            size: 11,
            family: '"Roboto"'
          }
        },
        zeroline: false,
        range: [minY - yPadding, maxY + yPadding],
        gridcolor: '#e0e0e0'
      },
      annotations: [
        // Quadrant labels
        {
          x: minX - xPadding/2,
          y: maxY + yPadding/2,
          text: '<b>Mixed Conditions</b><br>Bigger Piece of a Smaller Pie',
          showarrow: false,
          xanchor: 'left',
          yanchor: 'top',
          font: { 
            size: 16,
            family: 'Roboto, "Helvetica Neue", Arial, sans-serif'
          }
        },
        {
          x: maxX + xPadding/2,
          y: maxY + yPadding/2,
          text: '<b>Strong Conditions</b><br>Bigger Piece of a Growing Pie',
          showarrow: false,
          xanchor: 'right',
          yanchor: 'top',
          font: { 
            size: 16,
            family: 'Roboto, "Helvetica Neue", Arial, sans-serif'
          }
        },
        {
          x: minX - xPadding/2,
          y: minY - yPadding/2,
          text: '<b>Challenging Conditions</b><br>Shrinking Piece of a Smaller Pie',
          showarrow: false,
          xanchor: 'left',
          yanchor: 'bottom',
          font: { 
            size: 16,
            family: 'Roboto, "Helvetica Neue", Arial, sans-serif'
          }
        },
        {
          x: maxX + xPadding/2,
          y: minY - yPadding/2,
          text: '<b>Mixed Conditions</b><br>Smaller Piece of a Larger Pie',
          showarrow: false,
          xanchor: 'right',
          yanchor: 'bottom',
          font: { 
            size: 16,
            family: 'Roboto, "Helvetica Neue", Arial, sans-serif'
          }
        }
      ],
      shapes: [
        // Base quadrant backgrounds
        {
          type: 'rect',
          x0: STEADY_POPULATION,
          y0: STEADY_MARKET_SHARE,
          x1: maxX + xPadding,
          y1: maxY + yPadding,
          fillcolor: 'rgb(76, 175, 80)',
          opacity: 0.1,
          line: { width: 0 }
        },
        {
          type: 'rect',
          x0: minX - xPadding,
          y0: STEADY_MARKET_SHARE,
          x1: -STEADY_POPULATION,
          y1: maxY + yPadding,
          fillcolor: 'rgb(255, 152, 0)',
          opacity: 0.1,
          line: { width: 0 }
        },
        {
          type: 'rect',
          x0: minX - xPadding,
          y0: minY - yPadding,
          x1: -STEADY_POPULATION,
          y1: -STEADY_MARKET_SHARE,
          fillcolor: 'rgb(244, 67, 54)',
          opacity: 0.1,
          line: { width: 0 }
        },
        {
          type: 'rect',
          x0: STEADY_POPULATION,
          y0: minY - yPadding,
          x1: maxX + xPadding,
          y1: -STEADY_MARKET_SHARE,
          fillcolor: 'rgb(255, 152, 0)',
          opacity: 0.1,
          line: { width: 0 }
        },
        // Steady state bands
        {
          type: 'rect',
          x0: -STEADY_POPULATION,
          y0: minY - yPadding,
          x1: STEADY_POPULATION,
          y1: maxY + yPadding,
          fillcolor: 'rgb(158, 158, 158)',
          opacity: 0.1,
          line: { width: 0 }
        },
        {
          type: 'rect',
          x0: minX - xPadding,
          y0: -STEADY_MARKET_SHARE,
          x1: maxX + xPadding,
          y1: STEADY_MARKET_SHARE,
          fillcolor: 'rgb(158, 158, 158)',
          opacity: 0.1,
          line: { width: 0 }
        },
        // Crosshair lines
        {
          type: 'line',
          x0: minX - xPadding,
          y0: 0,
          x1: maxX + xPadding,
          y1: 0,
          line: { color: 'rgba(0,0,0,0.3)', width: 2, dash: 'dash' }
        },
        {
          type: 'line',
          x0: 0,
          y0: minY - yPadding,
          x1: 0,
          y1: maxY + yPadding,
          line: { color: 'rgba(0,0,0,0.3)', width: 2, dash: 'dash' }
        }
      ]
    };

    return {
      plotData: [mainSchoolTrace],
      layout
    };
  }, [selectedSchool?.name, currentVsPastChange, marketShareData, schoolCategory]);

  return (
    <Paper elevation={0} sx={{ 
      border: '1px solid',
      borderColor: 'divider',
      borderRadius: 1,
      width: '100%'
    }}>
      {!hideHeader && selectedSchool && (
        <Box sx={{ p: 2, pb: 0 }}>
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            mb: 1
          }}>
            <TrendingUp sx={{ color: 'primary.main', mr: 1 }} />
            <Typography variant="h6" sx={{ 
              flexGrow: 1, 
              fontWeight: 600,
              fontSize: '1.125rem'
            }}>
              School Market Share Compared with Community Changes
            </Typography>
            <Tooltip title="Analyze your school's market share changes relative to population changes in your area">
              <IconButton size="small">
                <InfoOutlined />
              </IconButton>
            </Tooltip>
          </Box>
          
          <Typography 
            variant="body2" 
            sx={{ 
              bgcolor: 'rgba(25, 118, 210, 0.1)',
              color: 'primary.main',
              p: 1,
              borderRadius: 1,
              fontWeight: 500,
              mb: 1
            }}
          >
            {formatText(getAnalysisSummary({
              schoolName: selectedSchool.name,
              marketShareChange: marketShareData?.change,
              populationChange: currentVsPastChange,
              schoolCategory,
              timeframeData
            }))}
          </Typography>
        </Box>
      )}

      {/* Full-width plot */}
      <Box sx={{ 
        width: '100%', 
        px: 2, 
        pb: 2,
        height: '525px'  // Add fixed height to maintain consistency
      }}>
        <Plot
          data={plotData}
          layout={{
            ...layout,
            margin: { t: 10, r: 50, b: 30, l: 50 },
            autosize: true  // Add this to ensure proper resizing
          }}
          config={{ 
            responsive: true,
            displayModeBar: false
          }}
          style={{ 
            width: '100%',
            height: '100%'  // Make plot fill container
          }}
          useResizeHandler={true}
        />
      </Box>
    </Paper>
  );
};

export default CompXCommunityPlot;