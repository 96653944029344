import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import NavigationBar from './NavigationBar';
import SiteFooter from './SiteFooter';
import EditIcon from '@mui/icons-material/Edit';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LaunchIcon from '@mui/icons-material/Launch';
import SettingsIcon from '@mui/icons-material/Settings';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import GroupsIcon from '@mui/icons-material/Groups';

const ProductPage = () => {
  const features = [
    {
      icon: <EditIcon sx={{ fontSize: 40, color: '#4F46E5' }} />,
      title: "Comprehensive",
      description: "Leverages a variety of proven data sources to deliver key local population and competitive data."
    },
    {
      icon: <LocationOnIcon sx={{ fontSize: 40, color: '#4F46E5' }} />,
      title: "Data-Driven",
      description: "Transforms complex data into clear, long-term insights."
    },
    {
      icon: <LaunchIcon sx={{ fontSize: 40, color: '#4F46E5' }} />,
      title: "Actionable",
      description: "Guides leaders to turn insights into impactful planning decisions."
    },
    {
      icon: <SettingsIcon sx={{ fontSize: 40, color: '#4F46E5' }} />,
      title: "Customizable",
      description: "Tailored insights designed to meet campus-specific needs based on your context."
    },
    {
      icon: <SwapVertIcon sx={{ fontSize: 40, color: '#4F46E5' }} />,
      title: "Scalable",
      description: "Adapts seamlessly across schools and organizations to allow for key comparisons."
    },
    {
      icon: <GroupsIcon sx={{ fontSize: 40, color: '#4F46E5' }} />,
      title: "Collaborative",
      description: "Empowers teams to make unified, data-driven decisions."
    }
  ];

  const valueProps = [
    {
      title: "Enrollment Planning & Strategy",
      image: "/images/planning_strategy.png",
      points: [
        "Build 5-year plans for growth and school transitions.",
        "Set and validate annual projections with data-driven insights and scenarios.",
        "Analyze competitors to identify risks and opportunities.",
        "Monitor evolving population and demographic trends in your area."
      ]
    },
    {
      title: "Finance",
      image: "/images/finance.png",
      points: [
        "Use enrollment projections to guide short and long-term budget planning.",
        "Leverage trend data to validate assumptions and guide informed financial planning discussions.",
        "Provide funders with a compelling narrative, supported by local insights and long-term projections."
      ]
    },
    {
      title: "Engagement & Marketing",
      image: "/images/engagement_marketing.png",
      points: [
        "Understand your community, including population and demographic changes.",
        "Incorporate area maps and demographic data into engagement strategies.",
        "Pinpoint underserved populations to foster focused outreach and equitable access.",
        "Leverage competitive insights to guide recruitment and canvassing plans."
      ]
    }
  ];

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <NavigationBar />

      {/* Hero Section */}
      <Box sx={{ bgcolor: 'grey.50', pt: 8, pb: 4 }}>
        <Container maxWidth="lg">
          <Box sx={{ textAlign: 'center', mb: 6 }}>
            <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 700 }}>
              School Explorer
            </Typography>
            <Typography variant="body1" sx={{ maxWidth: '800px', mx: 'auto', mb: 4 }}>
              Empowers education leaders with forward-looking enrollment projections by combining neighborhood demographics, competitive insights, and school-specific data. Seamlessly integrate trends and make real-time adjustments to support smarter, long-term strategic planning.
            </Typography>
            <Box
              component="img"
              src="/images/computer_projections.png"
              alt="School Explorer Dashboard"
              sx={{
                width: '100%',
                maxWidth: '900px',
                height: 'auto',
                mt: 4
              }}
            />
          </Box>
        </Container>
      </Box>

      {/* Features Section */}
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Typography
          variant="h4"
          component="h2"
          align="center"
          gutterBottom
          sx={{ fontWeight: 700, mb: 2 }}
        >
          How It Works: Your Advantage
        </Typography>
        <Typography
          variant="body1"
          align="center"
          sx={{ mb: 6, maxWidth: '700px', mx: 'auto' }}
        >
          Learn how Education Data Collective transforms information into impactful decisions.
        </Typography>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box sx={{ textAlign: 'center' }}>
                <Box sx={{ 
                  bgcolor: 'primary.50', 
                  width: 80, 
                  height: 80, 
                  borderRadius: '50%', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  mx: 'auto',
                  mb: 2
                }}>
                  {feature.icon}
                </Box>
                <Typography variant="h6" component="h3" gutterBottom sx={{ fontWeight: 700 }}>
                  {feature.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {feature.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Value Propositions Section */}
      <Box sx={{ bgcolor: 'grey.50', py: 8 }}>
        <Container maxWidth="lg">
          <Typography
            variant="h4"
            component="h2"
            align="center"
            gutterBottom
            sx={{ fontWeight: 700, mb: 2 }}
          >
            What It Provides: Value Across Roles
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ mb: 6, maxWidth: '800px', mx: 'auto' }}
          >
            Designed to empower leaders across departments, the tool provides insights and strategies to support enrollment, finance, and engagement efforts.
          </Typography>
          
          {valueProps.map((prop, index) => (
            <Grid 
              container 
              spacing={4} 
              key={index}
              sx={{ 
                mb: index !== valueProps.length - 1 ? 6 : 0,
                flexDirection: index % 2 === 0 ? 'row' : 'row-reverse'
              }}
              alignItems="center"
            >
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography variant="h5" component="h3" gutterBottom sx={{ fontWeight: 700 }}>
                    {prop.title}
                  </Typography>
                  <Box sx={{ 
                    bgcolor: 'primary.50', 
                    borderRadius: 2, 
                    p: 3,
                    mt: 2
                  }}>
                    {prop.points.map((point, idx) => (
                      <Typography 
                        key={idx} 
                        component="li" 
                        sx={{ 
                          mb: 1,
                          listStyle: 'none',
                          '&::before': {
                            content: '"•"',
                            color: 'primary.main',
                            fontWeight: 'bold',
                            display: 'inline-block',
                            width: '1em',
                            marginLeft: '-1em'
                          }
                        }}
                      >
                        {point}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  component="img"
                  src={prop.image}
                  alt={prop.title}
                  sx={{
                    width: '100%',
                    maxWidth: '300px',
                    height: 'auto',
                    display: 'block',
                    mx: 'auto'
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Container>
      </Box>

      <SiteFooter />
    </Box>
  );
};

export default ProductPage;