import React, { useEffect, useRef } from 'react';
import { 
  Grid, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Paper,
  Typography,
  Box,
  Divider
} from '@mui/material';
import { SchoolOutlined } from '@mui/icons-material';
import { formatSchoolName, capitalizeWord } from '../../utils/nameFormatter';
import { getSchoolGrades } from '../../utils/schoolUtils';

// Define grades in the order we want them displayed, excluding Pre-K
const GRADES = [
  'Kindergarten',
  '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'
];

const DRIVE_TIMES = [5, 10, 15];

const SharedFilters = ({
  schools,
  selectedSchool,
  governanceFilter,
  selectedGrades = [],
  selectedDriveTime,
  schoolTypeFilter,
  availableSchoolTypes,
  onSchoolChange,
  onGovernanceChange,
  onGradeChange,
  onDriveTimeChange,
  onSchoolTypeChange,
  onHasPreKChange,
  showSchoolType = false,
  compact = false,
  sticky = false
}) => {
  // Use ref to track if the initial school grades have been set
  const initialGradesSet = useRef(false);

  // Effect to set initial grades ONLY when school first changes
  useEffect(() => {
    if (selectedSchool?.grade_range && !initialGradesSet.current) {
      const schoolGrades = getSchoolGrades(selectedSchool.grade_range);
      const hasPreK = selectedSchool.grade_range.includes('Pre-Kindergarten');
      onHasPreKChange?.(hasPreK);
      onGradeChange({ target: { value: schoolGrades } });
      initialGradesSet.current = true;
    } else if (!selectedSchool) {
      initialGradesSet.current = false;
      onGradeChange({ target: { value: [] } });
      onHasPreKChange?.(false);
    }
  }, [selectedSchool, onGradeChange, onHasPreKChange]);

  const filterSize = sticky ? "small" : "medium";
  const filterHeight = sticky ? 40 : 56;

  const formatAddress = (school) => {
    if (!school) return '';
    
    const formattedStreetAddress = school.street_address
      .split(' ')
      .map(capitalizeWord)
      .join(' ');
  
    const formattedCity = school.city
      .split(' ')
      .map(capitalizeWord)
      .join(' ');
  
    return `${formattedStreetAddress}\n${formattedCity}, ${school.state} ${school.zip_code}`;
  };

  const renderFilters = () => (
    <Grid container spacing={2} wrap="nowrap" sx={{ overflowX: 'auto' }}>
      <Grid item xs="auto" style={{ minWidth: 200 }}>
        <FormControl fullWidth size={filterSize}>
          <InputLabel id="school-select-label">School</InputLabel>
          <Select
            labelId="school-select-label"
            id="school-select"
            value={selectedSchool?.ncessch || ''}
            label="School"
            onChange={e => {
              initialGradesSet.current = false;
              onSchoolChange(e);
            }}
            sx={{ height: filterHeight }}
          >
            {schools.map((school) => (
              <MenuItem key={school.ncessch} value={school.ncessch}>
                {formatSchoolName(school.name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
  
      {showSchoolType && (
        <Grid item xs="auto" style={{ minWidth: 150 }}>
          <FormControl fullWidth size={filterSize}>
            <InputLabel id="school-type-select-label">School Type</InputLabel>
            <Select
              labelId="school-type-select-label"
              id="school-type-select"
              value={schoolTypeFilter}
              label="School Type"
              onChange={onSchoolTypeChange}
              sx={{ height: filterHeight }}
            >
              {availableSchoolTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
  
      <Grid item xs="auto" style={{ minWidth: 150 }}>
        <FormControl fullWidth size={filterSize}>
          <InputLabel id="governance-select-label">Governance</InputLabel>
          <Select
            labelId="governance-select-label"
            id="governance-select"
            value={governanceFilter}
            label="Governance"
            onChange={onGovernanceChange}
            sx={{ height: filterHeight }}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Charter">Charter</MenuItem>
            <MenuItem value="District">District</MenuItem>
          </Select>
        </FormControl>
      </Grid>
  
      <Grid item xs="auto" style={{ minWidth: 150 }}>
        <FormControl fullWidth size={filterSize}>
          <InputLabel id="drive-time-select-label">Drive Time</InputLabel>
          <Select
            labelId="drive-time-select-label"
            id="drive-time-select"
            value={selectedDriveTime}
            label="Drive Time"
            onChange={onDriveTimeChange}
            sx={{ height: filterHeight }}
          >
            {DRIVE_TIMES.map((time) => (
              <MenuItem key={time} value={time}>
                {time} minutes
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
  
      <Grid item xs="auto" style={{ minWidth: 200 }}>
        <FormControl fullWidth size={filterSize}>
          <InputLabel id="grade-select-label">Grades</InputLabel>
          <Select
            labelId="grade-select-label"
            id="grade-select"
            multiple
            value={selectedGrades || []}
            onChange={(e) => {
              const sortedGrades = [...e.target.value].sort((a, b) => {
                if (a === 'Kindergarten') return -1;
                if (b === 'Kindergarten') return 1;
                return parseInt(a) - parseInt(b);
              });
              onGradeChange({ target: { value: sortedGrades } });
            }}
            input={<OutlinedInput label="Grades" />}
            renderValue={(selected) => [...selected].sort((a, b) => {
              if (a === 'Kindergarten') return -1;
              if (b === 'Kindergarten') return 1;
              return parseInt(a) - parseInt(b);
            }).join(', ')}
            sx={{ height: filterHeight }}
          >
            {GRADES.map((grade) => (
              <MenuItem key={grade} value={grade}>
                <Checkbox checked={selectedGrades?.indexOf(grade) > -1} />
                <ListItemText primary={grade} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );

  if (compact) {
    return renderFilters();
  }

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} md={3}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <SchoolOutlined sx={{ fontSize: 24, color: 'primary.main', mr: 1 }} />
            <Typography variant="h6">
              {selectedSchool ? formatSchoolName(selectedSchool.name) : 'Select a School'}
            </Typography>
          </Box>
          {selectedSchool && (
            <Typography variant="body2" color="text.secondary">
              {formatAddress(selectedSchool)}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md="auto">
          <Divider orientation="vertical" flexItem />
        </Grid>

        <Grid item xs={12} md={8}>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
            Filters
          </Typography>
          {renderFilters()}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SharedFilters;