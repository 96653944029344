import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
  Filler
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CrosshairPlugin } from 'chartjs-plugin-crosshair';
import {
  colorScheme,
  calculateEnrollmentForGrades,
  formatGradeForData
} from '../../../utils/projectionsUtils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend,
  Filler,
  CrosshairPlugin,
  ChartDataLabels
);

const ProjectXProjectionsChart = ({ 
  school, 
  enrollmentData, 
  selectedGrades, 
  timeframe, 
  view,
  targets,
  selectedScenarios,
  adjustedProjections,
  baselineProjections
}) => {
  // Cache last actual year and value
  const lastActualYear = useMemo(() => {
    if (!enrollmentData?.actual_enrollment) return null;
    return Object.keys(enrollmentData.actual_enrollment).sort().pop();
  }, [enrollmentData]);

  const lastActualValue = useMemo(() => {
    if (!lastActualYear || !enrollmentData?.actual_enrollment?.[lastActualYear]) return null;
    return calculateEnrollmentForGrades(
      enrollmentData.actual_enrollment[lastActualYear],
      selectedGrades,
      view
    );
  }, [lastActualYear, enrollmentData, selectedGrades, view]);

  // Pre-calculate chart options
  const options = useMemo(() => ({
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 750
    },
    plugins: {
      datalabels: {
        display: false,
        labels: {
          title: {
            display: false
          },
          value: {
            display: false
          }
        }
      },
      zoom: { 
        pan: {
          enabled: false
        },
        zoom: {
          wheel: {
            enabled: false
          },
          pinch: {
            enabled: false
          },
          mode: 'xy'
        }
      },
      legend: {
        position: 'top',
        labels: {
          filter: (item) => !['Baseline Range', '_hidden'].includes(item.text)
        }
      },
      tooltip: {
        enabled: true,
        mode: 'nearest',
        intersect: false,
        animation: {
          duration: 150
        },
        itemSort: (a, b) => {
          // Define the order priority
          const order = {
            'High Projection': 1,
            'Medium Projection': 2,
            'Low Projection': 3,
            'Actual': 4,
            'Target': 5,
            'Original Projection Range': 6
          };
          
          // Get the priority for each item, defaulting to 999 if not found
          const orderA = order[a.dataset.label] || 999;
          const orderB = order[b.dataset.label] || 999;
          
          // Sort by priority
          return orderA - orderB;
        },
        callbacks: {
          title: (context) => context[0].label,
          label: (context) => {
            // Skip baseline range and hidden datasets
            if (['Baseline Range', '_hidden'].includes(context.dataset.label)) {
              return null;
            }

            if (context.raw?.y === undefined || context.raw?.y === null) return null;
            
            const year = context.raw.x;
            if (year === lastActualYear && 
                !context.dataset.label.includes('Actual') && 
                context.dataset.label.includes('Projection')) {
              return null;
            }

            let label = context.dataset.label || '';
            if (label) label += ': ';
            
            // Add baseline comparison for adjusted projections
            if (context.dataset.label.includes('Projection') && adjustedProjections) {
              const currentValue = context.parsed.y;
              const scenarioType = context.dataset.label.includes('Low') ? 'min' : 
                                 context.dataset.label.includes('High') ? 'max' : 'median';
              const baselineValue = baselineProjections && calculateEnrollmentForGrades(
                baselineProjections[scenarioType][year],
                selectedGrades,
                view
              );
              
              if (baselineValue && currentValue !== baselineValue) {
                const diff = currentValue - baselineValue;
                return `${label}${currentValue.toLocaleString()} (${diff > 0 ? '+' : ''}${diff} from baseline)`;
              }
            }
            
            return `${label}${context.parsed.y.toLocaleString()}`;
          }
        }
      }
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Enrollment'
        },
        ticks: {
          display: true,
          callback: function(value) {
            return value.toLocaleString();
          }
        },
        grid: {
          display: true
        }
      },
      x: {
        title: {
          display: false
        },
        ticks: {
          display: true
        },
        grid: {
          display: true
        }
      }
    }
  }), [lastActualYear, adjustedProjections, baselineProjections, selectedGrades, view]);

  // Optimize chart data preparation
  const chartData = useMemo(() => {
    if (!enrollmentData) return null;
    
    // Create array of unique years
    const years = Array.from(new Set([
      ...Object.keys(enrollmentData.actual_enrollment || {}),
      ...Object.keys(enrollmentData.projections?.median || {})
    ])).sort();
    
    const datasets = [];

    // Add baseline range if we have adjustments
    if (baselineProjections && adjustedProjections) {
      const baselineLowData = years.map(year => {
        // Use baselineProjections directly, not the current projections
        const value = calculateEnrollmentForGrades(
          baselineProjections.min?.[year],
          selectedGrades,
          view
        );
        return {
          x: year,
          y: value || null
        };
      });
    
      const baselineHighData = years.map(year => {
        // Use baselineProjections directly, not the current projections
        const value = calculateEnrollmentForGrades(
          baselineProjections.max?.[year],
          selectedGrades,
          view
        );
        return {
          x: year,
          y: value || null
        };
      });
    
      // Only show baseline range if there are actual adjustments
      const hasAdjustments = years.some(year => {
        const baselineValue = calculateEnrollmentForGrades(baselineProjections.median?.[year], selectedGrades, view);
        const adjustedValue = calculateEnrollmentForGrades(adjustedProjections.median?.[year], selectedGrades, view);
        return baselineValue !== adjustedValue;
      });
    
      if (hasAdjustments) {
        datasets.push(
          {
            label: 'Original Projection Range',
            data: baselineLowData,
            backgroundColor: 'rgba(200, 200, 200, 0.3)',
            borderColor: 'rgba(200, 200, 200, 0.5)',
            borderDash: [5, 5],
            fill: '+1',
            pointRadius: 0,
            tension: 0.1,
            order: 0
          },
          {
            label: '_hidden',
            data: baselineHighData,
            backgroundColor: 'rgba(200, 200, 200, 0.3)',
            borderColor: 'rgba(200, 200, 200, 0.5)',
            borderDash: [5, 5],
            fill: false,
            pointRadius: 0,
            tension: 0.1,
            order: 0,
            showInLegend: false
          }
        );
      }
    }

    // Add actual enrollment data
    const actualData = years
      .filter(year => enrollmentData.actual_enrollment?.[year])
      .map(year => ({
        x: year,
        y: view === 'school-level' 
          ? calculateEnrollmentForGrades(enrollmentData.actual_enrollment[year], selectedGrades, view)
          : enrollmentData.actual_enrollment[year][formatGradeForData(selectedGrades[0])] || null
      }))
      .filter(point => point.y !== null);

    datasets.push({
      label: 'Actual',
      data: actualData,
      borderColor: colorScheme.actual.border,
      backgroundColor: colorScheme.actual.background,
      fill: false,
      tension: 0.1
    });

    // Add projections based on selected scenarios
    const projections = adjustedProjections || enrollmentData.projections;
    
    ['min', 'median', 'max'].forEach((projType) => {
      if (!selectedScenarios.includes(projType === 'min' ? 'low' : projType === 'max' ? 'high' : 'median')) {
        return;
      }
    
      const projectionData = years
        .filter(year => projections?.[projType]?.[year])
        .map(year => ({
          x: year,
          y: view === 'school-level'
            ? calculateEnrollmentForGrades(projections[projType][year], selectedGrades, view)
            : projections[projType][year][formatGradeForData(selectedGrades[0])] || null
        }))
        .filter(point => point.y !== null);
    
      // Add the last actual value as the first point for projections
      if (lastActualValue !== null && projectionData.length > 0) {
        projectionData.unshift({
          x: lastActualYear,
          y: lastActualValue
        });
      }
    
      const colorKey = projType === 'min' ? 'low' : projType === 'max' ? 'high' : 'median';
      datasets.push({
        label: `${projType === 'max' ? 'High' : projType === 'min' ? 'Low' : 'Medium'} Projection`,
        data: projectionData,
        borderColor: colorScheme[colorKey].border,
        backgroundColor: colorScheme[colorKey].background,
        fill: false,
        tension: 0.1
      });
    });

    // Add enrollment targets if available
    if (targets && Object.keys(targets).length > 0) {
      const targetData = years
        .map(year => ({
          x: year,
          y: view === 'school-level' ? targets[year] : targets[`${year}-${selectedGrades[0]}`]
        }))
        .filter(point => point.y !== null);

      if (targetData.length > 0) {
        datasets.push({
          label: 'Target',
          data: targetData,
          borderColor: colorScheme.target.border,
          backgroundColor: colorScheme.target.background,
          borderWidth: 2,
          borderDash: [5, 5],
          fill: false
        });
      }
    }

    return { datasets };
  }, [
    enrollmentData,
    selectedGrades,
    view,
    targets,
    selectedScenarios,
    adjustedProjections,
    baselineProjections,
    lastActualYear,
    lastActualValue
  ]);

  return (
    <div style={{ height: '400px', width: '100%' }}>
      {chartData && <Line data={chartData} options={options} />}
    </div>
  );
};

export default React.memo(ProjectXProjectionsChart);