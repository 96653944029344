import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getAnalytics, setUserId, logEvent } from 'firebase/analytics';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { Snackbar, Alert } from '@mui/material';
import customLogger from '../utils/customLogger';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children, onLogout }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const db = getFirestore();

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const initializeUserDocument = useCallback(async (user) => {
    if (!user) return;
    
    const userRef = doc(db, 'users', user.uid);
    
    try {
      customLogger(`Initializing user document for: ${user.email}`, 'info');
      const docSnap = await getDoc(userRef);
      
      if (!docSnap.exists()) {
        await setDoc(userRef, {
          email: user.email,
          createdAt: new Date(),
          consent: {
            analyticsAllowed: false,
            timestamp: Date.now(),
            version: '1.0',
            expiresAt: Date.now() + (365 * 24 * 60 * 60 * 1000),
          }
        });
        customLogger(`Created new user document for: ${user.email}`, 'info');
      } else if (!docSnap.data().consent) {
        await setDoc(userRef, {
          ...docSnap.data(),
          consent: {
            analyticsAllowed: false,
            timestamp: Date.now(),
            version: '1.0',
            expiresAt: Date.now() + (365 * 24 * 60 * 60 * 1000),
          }
        }, { merge: true });
        customLogger(`Added consent field to existing user: ${user.email}`, 'info');
      }
    } catch (error) {
      customLogger(`Error initializing user document: ${error.message}`, 'error');
      throw error;
    }
  }, [db]);

  const verifyTokenWithBackend = useCallback(async (user) => {
    try {
      customLogger(`Verifying token with backend for user: ${user.email}`, 'info');
      const idToken = await user.getIdToken();
      const response = await fetch(`${API_URL}/auth/verify-token`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idToken }),
      });

      if (!response.ok) {
        throw new Error(`Failed to verify token with backend: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      customLogger(`Token verified successfully for user: ${user.email}`, 'info');

      const mergedUser = {
        ...user,
        name: data.name,
        role: data.role,
        access_token: data.access_token,
      };

      customLogger(`User role received from backend: ${data.role}`, 'info');
      setCurrentUser(mergedUser);
      setLoading(false);
      return data.access_token;
    } catch (error) {
      customLogger(`Error verifying token: ${error.message}`, 'error');
      setCurrentUser(null);
      setLoading(false);
      throw error;
    }
  }, []);

  useEffect(() => {
    customLogger('Setting up auth state listener', 'info');
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        customLogger(`Auth state changed: User logged in (${user.email})`, 'info');
        try {
          await initializeUserDocument(user);
          await verifyTokenWithBackend(user);
        } catch (error) {
          customLogger(`Error during user initialization: ${error.message}`, 'error');
          setCurrentUser(null);
          setLoading(false);
        }
      } else {
        customLogger('Auth state changed: No user logged in', 'info');
        setCurrentUser(null);
        setLoading(false);
      }
    });
  
    return () => {
      customLogger('Cleaning up auth state listener', 'info');
      unsubscribe();
    };
  }, [verifyTokenWithBackend, initializeUserDocument]);

  const value = {
    currentUser,
    loading,
    setLoading,
    login: async (email, password) => {
      try {
        customLogger(`Attempting login for user: ${email}`, 'info');
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        customLogger(`Firebase login successful for user: ${email}`, 'info');

        // Track login in Analytics
        const analytics = getAnalytics();
        setUserId(analytics, userCredential.user.uid);
        logEvent(analytics, 'login', { method: 'email' });

        const access_token = await verifyTokenWithBackend(userCredential.user);
        return { access_token };
      } catch (error) {
        customLogger(`Login error for user ${email}: ${error.message}`, 'error');
        throw error;
      }
    },
    logout: async () => {
      try {
        customLogger('Attempting to log out user', 'info');
        await signOut(auth);
        customLogger('Firebase sign out successful', 'info');

        const response = await fetch(`${API_URL}/api/auth/logout`, {
          method: 'POST',
          credentials: 'include',
        });

        if (!response.ok) {
          customLogger(`Backend logout failed: ${response.status} ${response.statusText}`, 'warn');
        } else {
          customLogger('Backend logout successful', 'info');
        }

        setCurrentUser(null);
        
        // Show success message
        setSnackbar({
          open: true,
          message: 'Logged out successfully',
          severity: 'success'
        });

        // Small delay before navigation
        setTimeout(() => {
          if (onLogout) onLogout();
        }, 100);

      } catch (error) {
        customLogger(`Logout error: ${error.message}`, 'error');
        setSnackbar({
          open: true,
          message: 'Logout failed. Please try again.',
          severity: 'error'
        });
        throw error;
      }
    },
    setCurrentUser,
    verifyTokenWithBackend,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </AuthContext.Provider>
  );
}