import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography
} from '@mui/material';
import NavigationBar from './NavigationBar';
import SiteFooter from './SiteFooter';
import ExploreIcon from '@mui/icons-material/Explore';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SettingsIcon from '@mui/icons-material/Settings';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import GroupsIcon from '@mui/icons-material/Groups';

const AnimatedWord = () => {
  const words = ["Interactive", "Connected", "Data-Driven"];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentIndex((prev) => (prev + 1) % words.length);
        setIsAnimating(false);
      }, 500); // Half of the animation duration
    }, 3000); // Change word every 3 seconds

    return () => clearInterval(intervalId);
  }, [words.length]);

  return (
    <Box
      component="span"
      sx={{
        color: '#4F46E5',
        display: 'inline-block',
        transition: 'opacity 0.5s, transform 0.5s',
        opacity: isAnimating ? 0 : 1,
        transform: isAnimating ? 'translateY(20px)' : 'translateY(0)',
      }}
    >
      {words[currentIndex]}
    </Box>
  );
};

const HomePage = () => {

  const features = [
    {
      icon: <ExploreIcon sx={{ fontSize: 40, color: '#4F46E5' }} />,
      title: "Comprehensive",
      description: "Leverages a variety of proven data sources to deliver key local population and competitive data."
    },
    {
      icon: <DataUsageIcon sx={{ fontSize: 40, color: '#4F46E5' }} />,
      title: "Data-Driven",
      description: "Transforms complex data into clear, long-term insights."
    },
    {
      icon: <SettingsIcon sx={{ fontSize: 40, color: '#4F46E5' }} />,
      title: "Customizable",
      description: "Tailored insights designed to meet campus-specific needs based on your context."
    },
    {
      icon: <SwapVertIcon sx={{ fontSize: 40, color: '#4F46E5' }} />,
      title: "Scalable",
      description: "Adapts seamlessly across schools and organizations to allow for key comparisons."
    },
    {
      icon: <PlayArrowIcon sx={{ fontSize: 40, color: '#4F46E5' }} />,
      title: "Actionable",
      description: "Guides leaders to turn insights into impactful planning decisions."
    },
    {
      icon: <GroupsIcon sx={{ fontSize: 40, color: '#4F46E5' }} />,
      title: "Collaborative",
      description: "Empowers teams to make unified, data-driven decisions."
    }
  ];

  const tools = [
    {
      title: "Area Trends",
      description: "Understand local demographics and population shifts to anticipate enrollment opportunities and challenges.",
      image: "/images/area_trends.png"
    },
    {
      title: "Competitive Performance",
      description: "Analyze nearby schools to uncover strengths, weaknesses, and areas for differentiation in your local area.",
      image: "/images/competitive_performance.png"
    },
    {
      title: "Future Projections",
      description: "Leverage historical data to forecast enrollment patterns, helping you prepare for the future with confidence.",
      image: "/images/future_projections.png"
    },
    {
      title: "Risk Profiles",
      description: "Assess risk factors with clear visualizations, enabling proactive planning and resource allocation.",
      image: "/images/risk_profiles.png"
    }
  ];

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <NavigationBar />

      {/* Hero Section */}
      <Box sx={{ bgcolor: 'grey.50', py: 8 }}>
        <Container maxWidth="xl">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6} sx={{ pl: '24px' }}> {/* Added pl: '24px' to match navbar */}
              <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 700 }}>
                <AnimatedWord />{' '}
                solutions for long-term planning.
              </Typography>
              <Typography variant="body1" paragraph sx={{ mb: 4 }}>
                Connect your school, network, or district's data with local trends
                and competitive insights for smarter, sustainable planning.
              </Typography>
              <Button 
                variant="contained"
                size="large"
                href="/product"
                sx={{ 
                  bgcolor: '#4F46E5',
                  borderRadius: '24px',
                  px: 3,
                  py: 1.5,
                  fontWeight: 600,
                  textTransform: 'none',
                  '&:hover': {
                    bgcolor: '#4338CA',
                  }
                }}
              >
                Start Exploring
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box 
                sx={{ 
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                  overflow: 'visible'
                }}
              >
                <Box 
                  component="img"
                  src="/images/computer_projections.png"
                  alt="Projections Explorer"
                  sx={{ 
                    width: '150%', // Increased from 120%
                    maxWidth: 'none',
                    position: 'relative',
                    right: '-25%', // Pushed further right
                    top: '-10%', // Added some vertical offset
                    transform: 'scale(1.4)', // Made the image larger
                    transition: 'transform 0.3s ease-in-out',
                    '@media (max-width: 900px)': {
                      width: '100%',
                      right: 0,
                      top: 0,
                      transform: 'scale(1)'
                    }
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Features Section */}
      <Container maxWidth="lg" sx={{ py: 8, textAlign: 'center' }}>
        <Typography 
          variant="h4" 
          component="h2" 
          gutterBottom 
          sx={{ 
            fontWeight: 700,
            mb: 1
          }}
        >
          How It Works: Your Advantage
        </Typography>
        <Typography 
          variant="body1" 
          sx={{ 
            mb: 6,
            maxWidth: '700px',
            mx: 'auto'
          }}
        >
          Learn how Education Data Collective transforms information into
          impactful decisions.
        </Typography>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card 
                elevation={0} 
                sx={{ 
                  height: '100%', 
                  textAlign: 'center',
                  border: '1px solid rgba(0, 0, 0, 0.05)',
                  borderRadius: 2,
                  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)'
                  }
                }}
              >
                <CardContent>
                  <Box sx={{ mb: 2 }}>
                    {feature.icon}
                  </Box>
                  <Typography 
                    variant="h6" 
                    component="h3" 
                    gutterBottom 
                    sx={{ 
                      fontWeight: 700,
                      mb: 1
                    }}
                  >
                    {feature.title}
                  </Typography>
                  <Typography 
                    variant="body2"
                    sx={{
                      color: 'text.secondary'
                    }}
                  >
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Tools Section */}
      <Box sx={{ bgcolor: 'grey.50', py: 8 }}>
        <Container maxWidth="lg" sx={{ textAlign: 'center' }}>
          <Typography variant="h4" component="h2" gutterBottom sx={{ fontWeight: 800 }}>
            What It Delivers: Your Tools
          </Typography>
          <Typography variant="body1" sx={{ mb: 6, maxWidth: '700px', mx: 'auto' }}>
            Explore key trends, forecasts, and risk profiles to make smarter,
            informed decisions tailored to your institution's needs.
          </Typography>
          <Grid container spacing={4}>
            {tools.map((tool, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Card 
                  elevation={0} 
                  sx={{ 
                    height: '100%', 
                    textAlign: 'center',
                    backgroundColor: 'transparent',
                    border: 'none',
                    transition: 'transform 0.2s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                    },
                    '&:hover .tool-image': {
                      transform: 'scale(1.05)',
                    }
                  }}
                >
                  <CardContent sx={{ p: 3 }}>
                    <Box 
                      sx={{ 
                        mb: 3,
                        height: '200px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'hidden'
                      }}
                    >
                      <Box
                        component="img"
                        src={tool.image}
                        alt={tool.title}
                        className="tool-image"
                        sx={{ 
                          width: '200px',
                          height: 'auto',
                          transition: 'transform 0.3s ease-in-out',
                        }}
                      />
                    </Box>
                    <Typography 
                      variant="h5" 
                      component="h3" 
                      gutterBottom 
                      sx={{ 
                        fontWeight: 700,
                        color: '#111827',
                        mb: 2
                      }}
                    >
                      {tool.title}
                    </Typography>
                    <Typography 
                      variant="body1"
                      sx={{
                        color: 'text.secondary',
                        maxWidth: '300px',
                        mx: 'auto'
                      }}
                    >
                      {tool.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <SiteFooter />
    </Box>
  );
};

export default HomePage;