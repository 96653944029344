import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  CircularProgress,
  Tabs,
  Tab
} from '@mui/material';
import { School as SchoolIcon } from '@mui/icons-material';
import ProjectionsValidation from './validators/ProjectionsValidation';
import NearbySchoolsValidation from './validators/NearbySchoolsValidation';
import DemographicsValidation from './validators/DemographicsValidation';
import useSchoolStore from '../../../stores/schoolStore';

// TabPanel component for organizing validation tools
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`validation-tabpanel-${index}`}
      aria-labelledby={`validation-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const ValidationSuite = () => {
  const { 
    schools, 
    selectedSchool,
    selectSchool, 
    schoolsLoading, 
    error: storeError,
    initialize 
  } = useSchoolStore();

  const [activeTab, setActiveTab] = React.useState(0);

  // Initialize store on component mount
  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleSchoolChange = (event) => {
    const schoolId = event.target.value;
    const school = schools.find(s => s.ncessch === schoolId);
    if (school) {
      selectSchool(school);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (schoolsLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (storeError) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{storeError}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 'xl', mx: 'auto', p: 3 }}>
      <Paper sx={{ mb: 3, p: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
          <SchoolIcon color="primary" />
          <Typography variant="h5">
            Data Validation Tools
          </Typography>
        </Box>
        
        <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
          Review and validate various aspects of school data calculations, including enrollment projections
          and nearby schools analysis.
        </Typography>
        
        {/* School Selection */}
        <FormControl fullWidth sx={{ mb: 3 }}>
          <InputLabel>Select School</InputLabel>
          <Select
            value={selectedSchool?.ncessch || ''}
            onChange={handleSchoolChange}
            label="Select School"
          >
            {schools.map((school) => (
              <MenuItem key={school.ncessch} value={school.ncessch}>
                {school.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {!selectedSchool ? (
          <Alert severity="info">
            Please select a school to begin validation
          </Alert>
        ) : (
          <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
              <Tabs value={activeTab} onChange={handleTabChange} aria-label="validation tabs">
                <Tab label="Projections Validation" id="validation-tab-0" />
                <Tab label="Nearby Schools Validation" id="validation-tab-1" />
                <Tab label="Demographics Validation" id="validation-tab-2" />
              </Tabs>
            </Box>

            <TabPanel value={activeTab} index={0}>
              <ProjectionsValidation school={selectedSchool} />
            </TabPanel>
            
            <TabPanel value={activeTab} index={1}>
              <NearbySchoolsValidation school={selectedSchool} />
            </TabPanel>

            <TabPanel value={activeTab} index={2}>
              <DemographicsValidation school={selectedSchool} />
            </TabPanel>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default ValidationSuite;