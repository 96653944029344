import { Link } from '@mui/material';

export const THRESHOLDS = {
  MARKET_SHARE_SIGNIFICANT: 1.4,  // +/- 1.4% for market share changes
  POPULATION_SIGNIFICANT: 2.0,    // +/- 2.0% for population changes
  AREA_STEADY: 2.0               // +/- 1.0% for determining steady state
};

export const TREND_COLORS = {
  positive: 'rgb(76, 175, 80)',  // green
  negative: 'rgb(244, 67, 54)',  // red
  neutral: 'rgb(255, 152, 0)',   // orange
  steady: 'rgb(158, 158, 158)'   // grey
};

export const OVERALL_STATUS = {
  STRONG: 'Strong Conditions',
  CHALLENGING: 'Challenging Conditions',
  STEADY: 'Steady Conditions',
  MIXED: 'Mixed Conditions',
  NEW: 'New or Newer School'
};

export const createQuadrantDetails = () => ({
  'bigger-smaller': {
    title: 'Mixed Conditions: Bigger Piece of a Smaller Pie',
    description: 'Although market share has increased, area child population declines create a level of future enrollment risk if they continue.',
    action: <>Utilize the <b><Link href="/geographic-explorer">Geographic Explorer</Link></b> to better understand population shifts in your area, including by demographic group and consider using the <b><Link href="/projections-explorer">Projections Explorer</Link></b> to view future enrollment projection scenarios.</>,
    color: TREND_COLORS.neutral,
    lookFors: [
      'Rate of population decline',
      'Demographic shift patterns',
      'Market share growth sustainability'
    ],
    questions: [
      'What factors are driving population decline?',
      'How sustainable is the market share growth?',
      'What demographic groups are most affected?'
    ]
  },
  'strong': {
    title: 'Strong Conditions: Bigger Piece of a Growing Pie',
    description: 'Gaining market share in a growing area creates strong conditions for future enrollment.',
    action: 'Networks should maximize enrollment and could consider expansion or replication.',
    color: TREND_COLORS.positive,
    lookFors: [
      'Sustained population growth',
      'Market share expansion opportunities',
      'Capacity constraints'
    ],
    questions: [
      'Is there capacity for continued growth?',
      'What expansion opportunities exist?',
      'How can current success be replicated?'
    ]
  },
  'challenging': {
    title: 'Challenging Conditions: Shrinking Piece of a Smaller Pie',
    description: 'A combination of decreasing market share in a declining child population area creates greater future enrollment risk.',
    action: <>Explore the <b>Public School Enrollment</b> section below to understand which schools have gained market share and consider using the <b><Link href="/projections-explorer">Projections Explorer</Link></b> tab to view future enrollment projection scenarios.</>,
    color: TREND_COLORS.negative,
    lookFors: [
      'Competitive pressures',
      'Population decline factors',
      'Market share loss patterns'
    ],
    questions: [
      'What is driving market share losses?',
      'How can population trends be addressed?',
      'What immediate actions could improve position?'
    ]
  },
  'smaller-larger': {
    title: 'Mixed Conditions: Smaller Piece of a Larger Pie',
    description: 'Although market share has decreased, area growth helps reduce future enrollment risk, if it were to continue.',
    action: <>'Explore the <b>Public School Enrollment</b> section below for an in-depth understanding of which schools have gained market share.</>,
    color: TREND_COLORS.neutral,
    lookFors: [
      'Population growth patterns',
      'Competitive landscape changes',
      'Market share recovery opportunities'
    ],
    questions: [
      'Which competitors are gaining share?',
      'How can growth opportunities be captured?',
      'What competitive advantages can be leveraged?'
    ]
  },
  'steady-growing': {
    title: 'Steady Share in a Growing Area',
    description: 'Market shares are steady while the area has experienced growth, which creates a solid environment for future enrollment.',
    action: <>Explore the <b>Public School Enrollment</b> section below for a more in-depth understanding of area competition.</>,
    color: TREND_COLORS.steady,
    lookFors: [
      'Growth sustainability',
      'Market share maintenance factors',
      'Expansion opportunities'
    ],
    questions: [
      'Can market share be increased in this growth?',
      'What maintains current market position?',
      'Are there untapped growth opportunities?'
    ]
  },
  'steady-shrinking': {
    title: 'Steady Share in a Shrinking Area',
    description: 'Market shares are steady while the area has declining student populations, which creates risk for future enrollment.',
    action: <>Explore the <b>Public School Enrollment</b> section below for a more in-depth understanding of area competition and the <b><Link href="/geographic-explorer">Geographic Explorer</Link></b> for a better understanding of changing area populations, including demographic shifts.</>,
    color: TREND_COLORS.steady,
    lookFors: [
      'Population decline patterns',
      'Market position stability factors',
      'Risk mitigation opportunities'
    ],
    questions: [
      'How sustainable is current enrollment?',
      'What demographic shifts are occurring?',
      'How can risks be mitigated?'
    ]
  },
  'steady-all': {
    title: 'Steady Conditions',
    description: 'Both market share and area population changes are steady, creating a stable environment.',
    action: <>Monitor conditions and explore the <b>Public School Enrollment</b> section below for a more in-depth understanding of area competition.</>,
    color: TREND_COLORS.steady,
    lookFors: [
      'Stability factors',
      'Early change indicators',
      'Growth opportunities'
    ],
    questions: [
      'What maintains current stability?',
      'Are there growth opportunities?',
      'What could disrupt current stability?'
    ]
  }
});

// For backward compatibility and static access
export const QUADRANT_DETAILS = createQuadrantDetails();