// components/admin/FeedbackPanel.js
import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  CircularProgress,
  Alert,
} from "@mui/material";
import {
  BugReport,
  Lightbulb,
  Feedback,
  Assessment,
} from "@mui/icons-material";
import { formatDistance } from "date-fns";
import { feedbackService } from "../../services/feedbackService";
import { useAuth } from "../../contexts/AuthContext";
import { auth } from "../../firebase";

const FEEDBACK_TYPES = {
  bug: { label: "Bug Report", icon: BugReport, color: "error" },
  suggestion: { label: "Suggestion", icon: Lightbulb, color: "warning" },
  other: { label: "Other", icon: Feedback, color: "info" },
};

const FeedbackPanel = () => {
  const [feedback, setFeedback] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterType, setFilterType] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const { currentUser } = useAuth();

  // Check admin status using token
  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        if (currentUser) {
          const token = await auth.currentUser.getIdTokenResult();
          
          if (token.claims.role !== "admin") {
            setError("You must be an admin to view feedback");
            setLoading(false);
            return;
          }
          
          // If we get here, user is admin, fetch feedback
          fetchFeedback();
        } else {
          setError("No user logged in");
          setLoading(false);
        }
      } catch (err) {
        console.error("Error checking admin status:", err);
        setError("Error verifying admin permissions");
        setLoading(false);
      }
    };

    checkAdminStatus();
  }, [currentUser]);

  const fetchFeedback = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await feedbackService.getAllFeedback();
      console.log("Fetched feedback:", data); // Debug log
      setFeedback(data);
    } catch (err) {
      console.error("Error fetching feedback:", err);
      setError(
        "Unable to load feedback. Please ensure you have admin permissions."
      );
    } finally {
      setLoading(false);
    }
  };

  const filteredFeedback = feedback.filter(item => {
    const matchesType = filterType === "all" || item.type === filterType;
    const matchesSearch = 
      item.text.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.userEmail.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.tool.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.sectionTitle.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesType && matchesSearch;
  });

  // Calculate statistics
  const stats = {
    total: feedback.length,
    bugs: feedback.filter(f => f.type === "bug").length,
    suggestions: feedback.filter(f => f.type === "suggestion").length,
    other: feedback.filter(f => f.type === "other").length,
  };

  const StatCard = ({ title, value, icon: Icon, color = "primary" }) => (
    <Paper elevation={2} sx={{ p: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
        <Icon sx={{ color: `${color}.main` }} />
        <Typography variant="h6" color={`${color}.main`}>
          {title}
        </Typography>
      </Box>
      <Typography variant="h4">{value}</Typography>
    </Paper>
  );

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        {error}
      </Alert>
    );
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Feedback Management
      </Typography>

      {/* Statistics */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard 
            title="Total Feedback"
            value={stats.total}
            icon={Assessment}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard 
            title="Bug Reports"
            value={stats.bugs}
            icon={BugReport}
            color="error"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard 
            title="Suggestions"
            value={stats.suggestions}
            icon={Lightbulb}
            color="warning"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard 
            title="Other Feedback"
            value={stats.other}
            icon={Feedback}
            color="info"
          />
        </Grid>
      </Grid>

      {/* Filters */}
      <Box sx={{ mb: 3, display: "flex", gap: 2 }}>
        <TextField
          placeholder="Search feedback..."
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ flexGrow: 1 }}
        />
        <FormControl size="small" sx={{ minWidth: 200 }}>
          <InputLabel>Filter by Type</InputLabel>
          <Select
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
            label="Filter by Type"
          >
            <MenuItem value="all">All Types</MenuItem>
            {Object.entries(FEEDBACK_TYPES).map(([value, { label }]) => (
              <MenuItem key={value} value={value}>{label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Feedback Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Tool</TableCell>
              <TableCell>Section</TableCell>
              <TableCell>Feedback</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Submitted</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredFeedback.map((item) => {
              const typeConfig = FEEDBACK_TYPES[item.type];
              const Icon = typeConfig?.icon;
              
              return (
                <TableRow key={item.id}>
                  <TableCell>
                    <Chip
                      icon={Icon ? <Icon /> : undefined}
                      label={typeConfig?.label || item.type}
                      color={typeConfig?.color || "default"}
                      size="small"
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>{item.tool}</TableCell>
                  <TableCell>{item.sectionTitle}</TableCell>
                  <TableCell style={{ maxWidth: "300px", whiteSpace: "normal", wordWrap: "break-word" }}>
                    {item.text}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{item.userEmail}</Typography>
                  </TableCell>
                  <TableCell>
                    {formatDistance(new Date(item.createdAt), new Date(), { addSuffix: true })}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default FeedbackPanel;