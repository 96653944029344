import React from 'react';
import { Box, Typography, Paper, Button, } from '@mui/material';
import { MapsHomeWork, Launch } from '@mui/icons-material';

const PopulationAnalysis = ({ 
  selectedSchool,
  populationTotals,
  currentVsPastChange,
  selectedDriveTime,
  selectedGrades
}) => {
  if (!selectedSchool || !populationTotals) {
    return null;
  }

  // Create URL with school parameter
  const explorerUrl = `/geographic-explorer?school=${selectedSchool.ncessch}`;

  // Helper to determine trend text and color
  const getTrendInfo = () => {
    if (Math.abs(currentVsPastChange) < 5) {
      return {
        text: 'remained steady',
        color: 'text.primary'
      };
    }
    return currentVsPastChange > 0 
      ? { text: 'grown', color: 'success.main' }
      : { text: 'declined', color: 'error.main' };
  };

  const trendInfo = getTrendInfo();
  
  // Format the grade range for display
  const gradeRangeText = selectedGrades.length > 0 
    ? `grades ${selectedGrades[0]}-${selectedGrades[selectedGrades.length - 1]}`
    : 'all grades';

  return (
    <Box sx={{ width: '100%' }}>
      <Paper 
        elevation={0}
        sx={{
          mb: 2,
          py: 2,
          px: 3,
          backgroundColor: 'grey.50',
          color: 'text.primary',
          borderRadius: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2,
          position: 'relative',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            width: 6,
            backgroundColor: 'primary.main',
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8
          }
        }}
      >
        <Box sx={{ flex: 1 }}>
        <Typography variant="h8" sx={{ fontWeight: 200 }}>
            The school-age population for {gradeRangeText} within a {selectedDriveTime}-minute drive of <strong>{selectedSchool.name}</strong> has{' '}
            <strong style={{ color: trendInfo.color }}>{trendInfo.text}</strong>, 
            from <strong>{Math.round(populationTotals.past).toLocaleString()}</strong> children in 2020 
            to <strong>{Math.round(populationTotals.current).toLocaleString()}</strong> children today
            {Math.abs(currentVsPastChange) >= 5 && 
              ` (${currentVsPastChange > 0 ? '+' : ''}${Math.round(currentVsPastChange)}%)`}.
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          startIcon={<MapsHomeWork />}
          endIcon={<Launch />}
          href={explorerUrl}
          sx={{ 
            backgroundColor: 'primary.dark',
            color: 'white',
            '&:hover': {
              backgroundColor: 'primary.main'
            }
          }}
        >
          OPEN IN GEOGRAPHIC EXPLORER
        </Button>
      </Paper>
    </Box>
  );
};

export default PopulationAnalysis;