import React, { useMemo } from 'react';
import { Typography, Box, Divider, Paper, Grid } from '@mui/material';
import { TrendingUp, TrendingDown } from '@mui/icons-material';
import { formatSchoolName } from '../../../utils/nameFormatter';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip } from 'chart.js';
import { calculateEnrollment, getEarliestEnrollmentData, calculateFRLPercentage } from '../../../utils/schoolUtils';
import { SCHOOL_CATEGORIES } from '../../../utils/schoolCategorization';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const GeoXNearbyMapPopup = ({ school, schoolData, selectedGrades }) => {
  const enrollmentStats = useMemo(() => {
    if (!schoolData?.enrollment_by_grade?.current) {
      return { 
        current: 0, 
        fullEnrollment: 0,
        change: 0, 
        hasComparison: false,
        isNew: false 
      };
    }
  
    // Calculate filtered enrollment for display
    const gradesToUse = selectedGrades || [];
    const currentEnrollment = calculateEnrollment(
      schoolData.enrollment_by_grade.current,
      gradesToUse
    );
    
    const comparisonData = getEarliestEnrollmentData(schoolData);
    const comparisonEnrollment = comparisonData ? 
      calculateEnrollment(comparisonData, gradesToUse) : null;
  
    // Calculate full enrollment (including PreK) for FRL percentage
    const allGrades = ['Pre-Kindergarten', 'Kindergarten'];
    for (let i = 1; i <= 12; i++) {
      allGrades.push(`${i}`);
    }
    const fullCurrentEnrollment = calculateEnrollment(
      schoolData.enrollment_by_grade.current,
      allGrades
    ) || 0; // Add fallback
  
    const fullComparisonEnrollment = comparisonData ? 
      calculateEnrollment(comparisonData, allGrades) : null;
  
    const hasComparison = comparisonEnrollment !== null && comparisonEnrollment > 0;
    const percentChange = hasComparison ? 
      ((currentEnrollment - comparisonEnrollment) / comparisonEnrollment) * 100 : 0;
  
    return {
      current: currentEnrollment || 0,
      fullEnrollment: fullCurrentEnrollment,
      comparison: comparisonEnrollment || 0,
      fullComparison: fullComparisonEnrollment || 0,
      change: percentChange,
      hasComparison,
      isNew: schoolData.category === SCHOOL_CATEGORIES.NEW
    };
  }, [schoolData, selectedGrades]);

  // Use the utility function for FRL calculation
  const frlStats = useMemo(() => {
    const frl = calculateFRLPercentage(schoolData);
    return frl.percentage !== null ? frl.percentage : 0;
  }, [schoolData]);

  const raceColors = {
    'hispanic': 'rgba(255, 192, 203, 0.8)',
    'white': 'rgba(173, 216, 230, 0.8)',
    'black': 'rgba(255, 223, 186, 0.8)',
    'asian': 'rgba(176, 224, 230, 0.8)',
    'pacific_islander': 'rgba(221, 160, 221, 0.8)',
    'american_indian': 'rgba(255, 218, 185, 0.8)',
    'two_or_more_races': 'rgba(221, 221, 221, 0.8)'
  };

  const getRaceData = () => {
    if (!schoolData?.race_data?.current) return null;

    // Initialize aggregated totals
    const raceTotals = {
      american_indian: 0,
      asian: 0,
      black: 0,
      hispanic: 0,
      pacific_islander: 0,
      white: 0,
      two_or_more_races: 0
    };

    // Sum up race data across all grades
    Object.values(schoolData.race_data.current).forEach(gradeData => {
      Object.entries(gradeData).forEach(([race, count]) => {
        raceTotals[race] += count;
      });
    });

    const total = Object.values(raceTotals).reduce((sum, value) => sum + value, 0);
    if (total === 0) return null;

    const displayNames = {
      'american_indian': 'Native American',
      'asian': 'Asian',
      'black': 'Black',
      'hispanic': 'Hispanic',
      'pacific_islander': 'Pacific Islander',
      'white': 'White',
      'two_or_more_races': 'Two Or More Races'
    };

    const processedData = Object.entries(raceTotals)
      .map(([key, value]) => ({
        category: displayNames[key] || key,
        value: Math.round((value / total) * 100)
      }))
      .filter(item => item.value > 0)
      .sort((a, b) => b.value - a.value);

    return {
      labels: processedData.map(d => d.category),
      datasets: [{
        data: processedData.map(d => d.value),
        backgroundColor: processedData.map(d => 
          raceColors[
            Object.entries(displayNames)
              .find(([_, value]) => value === d.category)?.[0] || 
              d.category.toLowerCase()
          ] || 'rgba(200, 200, 200, 0.8)'
        ),
        borderWidth: 0,
        borderRadius: 4,
        barPercentage: 0.8,
        categoryPercentage: 0.9
      }]
    };
  };

  const chartOptions = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false
      }
    },
    scales: {
      x: {
        display: false,
        beginAtZero: true,
        max: 100,
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          callback: function(value, index) {
            return this.getLabelForValue(value);
          },
          font: {
            size: 11
          }
        }
      }
    }
  };

  const raceData = getRaceData();

  return (
    <Box sx={{ width: 300 }}>
      <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 0.5 }}>
        {formatSchoolName(school.name)}
      </Typography>
      <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.secondary' }}>
        {school.charter === 'Yes' ? 'Charter' : 'District'} School | {school.grade_range}
      </Typography>
      <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 1, fontStyle: 'italic' }}>
        Note: Enrollment reflects only grades that overlap with the selected school ({(selectedGrades || []).join(', ')}). 
        Total school enrollment including all grades is {(enrollmentStats.fullEnrollment || 0).toLocaleString()}.
      </Typography>
      <Divider sx={{ mb: 2 }} />
      
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={6}>
          <Paper elevation={3} sx={{ 
            p: 2, 
            textAlign: 'center', 
            height: 80, 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center' 
          }}>
            <Typography variant="h6">
              {enrollmentStats.current.toLocaleString()}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              23-24 Selected Grades
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper elevation={3} sx={{ 
            p: 2, 
            textAlign: 'center', 
            height: 80, 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center',
            bgcolor: enrollmentStats.isNew ? 'grey.50' :
                     enrollmentStats.change > 0 ? 'rgba(0, 200, 83, 0.08)' : 
                     enrollmentStats.change < 0 ? 'rgba(211, 47, 47, 0.08)' : 'grey.50'
          }}>
            {enrollmentStats.isNew ? (
              <Typography variant="subtitle2" color="text.secondary">
                New School
              </Typography>
            ) : enrollmentStats.hasComparison ? (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 0.5 }}>
                  {enrollmentStats.change > 0 ? (
                    <TrendingUp sx={{ color: 'success.main' }} fontSize="small" />
                  ) : enrollmentStats.change < 0 ? (
                    <TrendingDown sx={{ color: 'error.main' }} fontSize="small" />
                  ) : null}
                  <Typography variant="h6">
                    {enrollmentStats.change > 0 ? '+' : ''}{Math.round(enrollmentStats.change)}%
                  </Typography>
                </Box>
                <Typography variant="caption" color="text.secondary">Since 2019-20</Typography>
              </>
            ) : (
              <Typography variant="caption" color="text.secondary">No comparison data</Typography>
            )}
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper elevation={3} sx={{ 
            p: 2, 
            textAlign: 'center', 
            height: 80, 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center' 
          }}>
            <Typography variant="h6">
              {frlStats > 0 ? `${frlStats}%` : 'No Data'}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              FRL Students
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper elevation={3} sx={{ 
            p: 2, 
            textAlign: 'center', 
            height: 80, 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center' 
          }}>
            <Typography variant="h6">
              {schoolData?.teachers ? schoolData.teachers : 'No Data'}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              FTE Teachers
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <Divider sx={{ mb: 2 }} />
      <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 'bold' }}>
        2023-24 Demographics (%)
      </Typography>
      {raceData ? (
        <Box sx={{ height: 200, width: '100%' }}>
          <Bar 
            data={raceData} 
            options={chartOptions}
          />
        </Box>
      ) : (
        <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
          Demographic data not available for this school
        </Typography>
      )}
    </Box>
  );
};

export default GeoXNearbyMapPopup;