// Constants
export const GRADES = ['Kindergarten', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

export const colorScheme = {
  high: {
    background: 'rgba(144, 238, 144, 0.6)',
    border: 'rgba(144, 238, 144, 1)',
    light: 'rgba(144, 238, 144, 0.1)'
  },
  median: {
    background: 'rgba(255, 183, 77, 0.6)',
    border: 'rgba(255, 183, 77, 1)',
    light: 'rgba(255, 183, 77, 0.1)'
  },
  low: {
    background: 'rgba(255, 182, 193, 0.6)',
    border: 'rgba(255, 182, 193, 1)',
    light: 'rgba(255, 182, 193, 0.1)'
  },
  actual: {
    background: 'rgba(75, 192, 192, 0.6)',
    border: 'rgb(75, 192, 192)'
  },
  target: {
    background: 'rgba(0, 122, 255, 0.6)',
    border: 'rgb(0, 122, 255)',
    light: 'rgba(0, 122, 255, 0.1)'
  },
  range: {
    success: 'rgba(144, 238, 144, 0.1)',
    error: 'rgba(255, 182, 193, 0.1)'
  }
};

// Grade formatting utilities
export const formatGradeForDisplay = (grade) => {
  if (!grade) return '';
  if (grade === 'K' || grade === 'Kindergarten') return 'Kindergarten';
  if (grade.startsWith('Grade ')) return grade;
  return `Grade ${grade}`;
};

export const formatGradeForData = (grade) => {
  if (!grade) return '';
  if (grade === 'Kindergarten') return 'Kindergarten';
  const gradeString = typeof grade === 'string' ? grade : String(grade);
  return gradeString.startsWith('Grade ') ? gradeString : `Grade ${gradeString}`;
};

export const isPKGrade = (grade) => 
  ['PK', 'Pre-K', 'PreK', 'Pre-Kindergarten'].includes(grade);

// Enrollment calculation utilities
export const calculateEnrollmentForGrades = (yearData, selectedGrades, view) => {
  if (!yearData) return 0;
  if (view === 'school-level') {
    return Object.entries(yearData)
      .filter(([grade]) => !isPKGrade(grade))
      .reduce((sum, [_, val]) => sum + (val || 0), 0);
  }
  return selectedGrades.reduce((sum, grade) => {
    const dataGrade = formatGradeForData(grade);
    if (!isPKGrade(dataGrade)) {
      return sum + (yearData[dataGrade] || 0);
    }
    return sum;
  }, 0);
};

export const hasPKEnrollment = (enrollmentData) => {
  if (!enrollmentData?.actual_enrollment) return false;
  return Object.values(enrollmentData.actual_enrollment).some(yearData =>
    Object.keys(yearData).some(grade => isPKGrade(grade))
  );
};

// Target assessment utilities
export const isTargetInRange = (target, lowValue, highValue) => {
  if (!target) return null;
  if (target > highValue) return { inRange: false, position: 'above' };
  if (target < lowValue) return { inRange: false, position: 'below' };
  return { inRange: true, position: 'within' };
};

export const getProgressAssessment = (targetValue, originalValue, adjustedValue) => {
  if (!targetValue || !adjustedValue) return null;
  
  const gap = targetValue - adjustedValue;
  
  if (gap <= 0) {
    const excess = Math.abs(gap);
    return {
      severity: 'success',
      icon: 'TaskAltIcon',
      message: `Your selected strategies are projected to exceed your target by ${excess} students`,
      displayText: `${excess} over`,
      color: '#2e7d32'
    };
  }
  
  if (gap < (targetValue - originalValue)) {
    const improvement = (targetValue - originalValue) - gap;
    const percentProgress = Math.round((improvement / (targetValue - originalValue)) * 100);
    
    return {
      severity: 'warning',
      icon: 'TrendingUpIcon',
      message: `Your selected strategies close ${percentProgress}% of the gap to your target`,
      displayText: `${gap} needed`,
      color: '#f57c00'
    };
  }
  
  return {
    severity: 'error',
    icon: 'ErrorIcon',
    message: `You need ${gap} more students to reach your target. Consider additional strategies.`,
    displayText: `${gap} needed`,
    color: '#d32f2f'
  };
};

// Time filtering utilities
export const filterYearsByTimeframe = (years, timeframe, enrollmentData) => {
  if (!years.length) return [];
  
  const allYears = years.sort();
  
  if (timeframe === '1-year') {
    const firstProjectionYear = allYears.find(year =>
      enrollmentData.projections?.median?.[year] !== undefined
    );
    
    return allYears.filter(year => {
      const isProjectionYear = year === firstProjectionYear;
      const isActualYear = enrollmentData.actual_enrollment?.[year] !== undefined;
      return isActualYear || isProjectionYear;
    });
  } 
  
  if (timeframe === '3-year') {
    const projectionYears = allYears.filter(year => 
      enrollmentData.projections?.median?.[year] !== undefined
    );
    const cutoffYear = projectionYears[2];
    
    return allYears.filter(year => {
      const isActualYear = enrollmentData.actual_enrollment?.[year] !== undefined;
      return isActualYear || (year <= cutoffYear);
    });
  }
  
  return allYears;
};

// Format utilities
export const formatDifference = (original, adjusted) => {
  // Convert to numbers and handle null/undefined
  original = Number(original) || 0;
  adjusted = Number(adjusted) || 0;
  
  const diff = adjusted - original;
  
  return {
    value: adjusted.toLocaleString(),
    difference: diff,
    isPositive: diff > 0
  };
};