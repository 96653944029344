import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';import {
  Box,
  Typography,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  AlertTitle,
  CircularProgress,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  Tooltip,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip
} from '@mui/material';
import {
  PersonAdd,
  Group,
  Upload,
  Edit,
  Search,
  Cancel,
  Save,
  Download,
  Business,
  LocationOn,
  ArrowDropDown,
  LockOpen,
  Lock,
  Delete,
  Email
} from '@mui/icons-material';
import Papa from 'papaparse';
import { useSnackbar } from 'notistack';
import { adminService } from '../../services/adminService';

const NETWORKS = [
  'KIPP',
  'Achievement First',
  'Impact Public Schools',
  'Alliance for College-Ready Public Schools'
];

const DEPARTMENTS = [
  'Operations',
  'Engagement',
  'Strategy',
  'Finance',
  'Other'
];

const ROLES = [
  { value: 'admin', label: 'Admin', description: 'EDC Admin - Full access' },
  { value: 'EDC', label: 'EDC', description: 'EDC Staff - CSGF level access' },
  { value: 'funder', label: 'Funder', description: 'Funder access (e.g., CSGF)' },
  { value: 'network_leader', label: 'Network Leader', description: 'CMO network-wide access (e.g., KIPP' },
  { value: 'region_leader', label: 'Region Leader', description: 'Specific region access (e.g., KIPP DC)' }
];

const UserFormDocumentation = () => (
  <Paper sx={{ p: 3, mb: 3, backgroundColor: 'grey.50' }}>
    <Typography variant="h6" gutterBottom>User Management Documentation</Typography>
    <Typography variant="body2" component="div">
      <ul>
        <li><strong>Email:</strong> Authentication email (e.g., "dev_kipp_nc@edc-test.com")</li>
        <li><strong>Funder:</strong> Name of funder (e.g., "CSGF") - Not applicable for admin users</li>
        <li><strong>Network:</strong> Name of the CMO network (e.g., "KIPP") - Not applicable for admin and funder users</li>
        <li><strong>Region:</strong> Specific region within network (e.g., "KIPP NC") - Not applicable for admin, funder, and network_leader users</li>
        <li><strong>Department:</strong> User's department (e.g., "Operations", "Strategy")</li>
        <li><strong>Role:</strong> User access level
          <ul>
            {ROLES.map(role => (
              <li key={role.value}><strong>{role.label}:</strong> {role.description}</li>
            ))}
          </ul>
        </li>
        <li><strong>Name:</strong> User's full name (e.g., "Raja Ridgway")</li>
        <li><strong>States:</strong> Comma-separated list of state codes (e.g., "CT,NY,RI")</li>
      </ul>
    </Typography>
  </Paper>
);

const UserManagementPanel = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [mode, setMode] = useState('list');
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    funder: '',
    network: '',
    region: '',
    role: '',
    states: [],
    department: ''
  });
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc'
  });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedUserForAction, setSelectedUserForAction] = useState(null);
  const [suspendDialogOpen, setSuspendDialogOpen] = useState(false);
  const [invitingUser, setInvitingUser] = useState(null);
  const [testEmailLoading, setTestEmailLoading] = useState(false);


  const { enqueueSnackbar } = useSnackbar();

  // Environment indicator
  const environment = process.env.REACT_APP_ENVIRONMENT || 'development';

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setSelectedUserForAction(null);  // Clear the selected user when dialog is closed
  };

  useEffect(() => {
    if (currentUser?.role !== 'admin') {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const data = await adminService.listUsers();
      setUsers(data);
    } catch (err) {
      console.error('Error fetching users:', err); // Debug log
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    
    console.log('Submitting form data:', formData); // Add this line for debugging
    
    try {
      if (mode === 'edit') {
        await adminService.updateUser(selectedUser.uid, formData);
      } else {
        await adminService.createUser(formData);
      }
      await fetchUsers();
      setMode('list');
      resetForm();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleBulkUpload = async (file) => {
    setLoading(true);
    setError(null);
  
    Papa.parse(file, {
      header: true,
      complete: async (results) => {
        try {
          // Convert states from comma-separated string to array
          const processedData = results.data.map(row => ({
            ...row,
            states: row.states ? row.states.split(',').map(s => s.trim()) : []
          }));
          
          const response = await adminService.bulkCreateUsers(processedData);
          
          // Close the upload dialog
          setCsvUploadOpen(false);
          
          // Switch back to list view
          setMode('list');
          
          // Refresh the user list
          await fetchUsers();
          
          // Show success message with details
          enqueueSnackbar(
            `Successfully uploaded ${response.success_count} users${response.failed > 0 ? ` (${response.failed} failed)` : ''}`, 
            { variant: 'success' }
          );
          
          if (response.failed > 0) {
            // If there were failures, show warning with details
            enqueueSnackbar(
              'Some users failed to create. Check the console for details.', 
              { variant: 'warning' }
            );
            console.log('Failed users:', response.failed_users);
          }
        } catch (err) {
          setError(err.message);
          enqueueSnackbar(`Upload failed: ${err.message}`, { variant: 'error' });
        } finally {
          setLoading(false);
        }
      },
      error: (err) => {
        setError('Failed to parse CSV: ' + err.message);
        setLoading(false);
        enqueueSnackbar(`Failed to parse CSV: ${err.message}`, { variant: 'error' });
      }
    });
  };

  const handleSendInvite = async (user) => {
    try {
      setInvitingUser(user.uid);
      await adminService.sendUserInvite(user.uid);
      await fetchUsers();  // Refresh the user list
      enqueueSnackbar('Invitation sent successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error sending invite:', error);
      if (error.message.includes('Session expired')) {
        // Handle session expiration more gracefully
        enqueueSnackbar('Session expired. Please refresh the page.', { variant: 'error' });
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    } finally {
      setInvitingUser(null);
    }
  };

  const handleTestEmail = async () => {
    try {
      setTestEmailLoading(true);
      await adminService.testEmail();
      enqueueSnackbar('Test email sent successfully', { variant: 'success' });
    } catch (error) {
      console.error('Test email error:', error);
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setTestEmailLoading(false);
    }
  };

  const handleDeleteUser = async (uid, confirmationEmail) => {
    try {
      setDeleteDialogOpen(false);  // Close dialog immediately
      setSelectedUserForAction(null);  // Clear selection immediately
      
      await adminService.deleteUser(uid, confirmationEmail);
      enqueueSnackbar('User deleted successfully', { variant: 'success' });
      
      // Fetch users after everything else is done
      await fetchUsers();
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
      throw err;
    }
  };

  const downloadTemplate = () => {
    const headers = ['email', 'name', 'funder', 'network', 'region', 'role', 'department', 'states'];
    const csv = Papa.unparse({
      fields: headers,
      data: []
    });
    
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'user_template.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const resetForm = () => {
    setFormData({
      email: '',
      name: '',
      funder: '',
      network: '',
      region: '',
      role: '',
      states: [],
      department: ''
    });
    setSelectedUser(null);
  };

  const getBannerColor = () => {
    switch(environment) {
      case 'production':
        return 'error.main';
      case 'staging':
        return 'warning.main';
      default:
        return 'success.main';
    }
  };

  const StatsCard = ({ title, value, icon: Icon }) => (
    <Paper elevation={2} sx={{ p: 2, textAlign: 'center' }}>
      <Icon sx={{ fontSize: 40, color: 'primary.main', mb: 1 }} />
      <Typography variant="h4" sx={{ mb: 1 }}>{value}</Typography>
      <Typography variant="body2" color="text.secondary">{title}</Typography>
    </Paper>
  );

  const sortedUsers = React.useMemo(() => {
    let sortableUsers = [...users];
    if (sortConfig.key !== null) {
      sortableUsers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableUsers;
  }, [users, sortConfig]);

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };
  
  const SuspendConfirmationDialog = () => (
    <Dialog open={suspendDialogOpen} onClose={() => setSuspendDialogOpen(false)}>
      <DialogTitle>
        {selectedUserForAction?.disabled ? 'Reactivate' : 'Suspend'} User
      </DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to {selectedUserForAction?.disabled ? 'reactivate' : 'suspend'} user{' '}
          <strong>{selectedUserForAction?.email}</strong>?
        </Typography>
        {!selectedUserForAction?.disabled && (
          <Typography variant="body2" color="warning.main" sx={{ mt: 1 }}>
            The user will be unable to log in until reactivated.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setSuspendDialogOpen(false)}>Cancel</Button>
        <Button
          color={selectedUserForAction?.disabled ? 'success' : 'warning'}
          onClick={async () => {
            try {
              if (selectedUserForAction?.disabled) {
                await adminService.reactivateUser(selectedUserForAction.uid);
              } else {
                await adminService.suspendUser(selectedUserForAction.uid);
              }
              await fetchUsers();
              setSuspendDialogOpen(false);
              setSelectedUserForAction(null);
            } catch (err) {
              setError(err.message);
            }
          }}
        >
          {selectedUserForAction?.disabled ? 'Reactivate' : 'Suspend'} User
        </Button>
      </DialogActions>
    </Dialog>
  );

  const DeleteConfirmationDialog = ({ 
    open, 
    onClose, 
    selectedUser, 
    onConfirmDelete 
  }) => {
    const [confirmationEmail, setConfirmationEmail] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
  
    // Clear email when dialog closes
    useEffect(() => {
      if (!open) {
        setConfirmationEmail('');
        setIsDeleting(false);
      }
    }, [open]);
  
    const handleDelete = async () => {
      if (!selectedUser) return;
      
      try {
        setIsDeleting(true);
        await onConfirmDelete(selectedUser.uid, confirmationEmail);
        // Don't call onClose here - let it be handled by the parent after successful deletion
      } catch (err) {
        console.error('Delete failed:', err);
        enqueueSnackbar(`Failed to delete user: ${err.message}`, { variant: 'error' });
      } finally {
        setIsDeleting(false);
      }
    };
  
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle sx={{ color: 'error.main' }}>Delete User</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            This action cannot be undone. The user will lose all access and data.
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            To confirm deletion, please type the user's email:
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              {selectedUser?.email}
            </Box>
          </Typography>
          <TextField
            fullWidth
            value={confirmationEmail}
            onChange={(e) => setConfirmationEmail(e.target.value)}
            label="Confirm Email"
            error={confirmationEmail !== '' && confirmationEmail !== selectedUser?.email}
            helperText={confirmationEmail !== '' && confirmationEmail !== selectedUser?.email 
              ? "Email doesn't match" 
              : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button 
            color="error"
            disabled={confirmationEmail !== selectedUser?.email || isDeleting}
            onClick={handleDelete}
          >
            {isDeleting ? 'Deleting...' : 'Delete User'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderForm = () => (
    <Box>
      <UserFormDocumentation />
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              value={formData.email}
              onChange={e => setFormData({...formData, email: e.target.value})}
              disabled={mode === 'edit'}
              required
              helperText="Authentication email (e.g., dev_kipp_nc@edc-test.com)"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Name"
              value={formData.name}
              onChange={e => setFormData({...formData, name: e.target.value})}
              required
              helperText="User's full name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Role</InputLabel>
              <Select
                value={formData.role}
                onChange={e => setFormData({...formData, role: e.target.value})}
                label="Role"
                required
              >
                {ROLES.map(role => (
                  <MenuItem key={role.value} value={role.value}>
                    {role.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* Only show funder field if role isn't admin */}
          {formData.role !== 'admin' && (
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Funder"
                value={formData.funder}
                onChange={e => setFormData({...formData, funder: e.target.value})}
                helperText="Name of funder (e.g., CSGF)"
              />
            </Grid>
          )}
          {/* Only show network field if role isn't admin or funder */}
          {!['admin', 'funder'].includes(formData.role) && (
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Network</InputLabel>
                <Select
                  value={formData.network}
                  onChange={e => setFormData({...formData, network: e.target.value})}
                  label="Network"
                  required
                >
                  {NETWORKS.map(network => (
                    <MenuItem key={network} value={network}>{network}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {/* Only show region field if role is region_leader */}
          {formData.role === 'region_leader' && (
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Region"
                value={formData.region}
                onChange={e => setFormData({...formData, region: e.target.value})}
                required
                helperText="Specific region within network (e.g., KIPP NC)"
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>States</InputLabel>
              <Select
                multiple
                value={formData.states || []}
                onChange={e => setFormData({...formData, states: e.target.value})}
                label="States"
                required
              >
                {["CA", 
                  "CO", 
                  "CT", 
                  "DC", 
                  "NC", 
                  "NY", 
                  "RI", 
                  "TN", 
                  "TX", 
                  "WA"].map(state => (
                  <MenuItem key={state} value={state}>{state}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Department</InputLabel>
              <Select
                value={formData.department}
                onChange={e => setFormData({...formData, department: e.target.value})}
                label="Department"
                required
              >
                {DEPARTMENTS.map(dept => (
                  <MenuItem key={dept} value={dept}>{dept}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
  
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button
            variant="outlined"
            onClick={() => {
              setMode('list');
              resetForm();
            }}
            startIcon={<Cancel />}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            startIcon={<Save />}
          >
            {loading ? 'Saving...' : (mode === 'edit' ? 'Save Changes' : 'Create User')}
          </Button>
        </Box>
      </Box>
    </Box>
  );

  const renderUserList = () => (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ mb: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
        <TextField
          placeholder="Search users..."
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: <Search sx={{ color: 'action.active', mr: 1 }} />
          }}
          sx={{ flexGrow: 1 }}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
        <TableHead>
          <TableRow>
            {[
              { id: 'status', label: 'Status' },
              { id: 'name', label: 'Name' },
              { id: 'email', label: 'Email' },
              { id: 'network', label: 'Network' },
              { id: 'region', label: 'Region' },
              { id: 'funder', label: 'Funder' },
              { id: 'states', label: 'States' },
              { id: 'role', label: 'Role' },
              { id: 'department', label: 'Department' },
              { id: 'inviteStatus', label: 'Invite Status' },
              { id: 'actions', label: 'Actions' }
            ].map((column) => (
              <TableCell 
                key={column.id}
                onClick={() => column.id !== 'actions' && requestSort(column.id)}
                sx={{ 
                  fontWeight: 'bold',
                  cursor: column.id !== 'actions' ? 'pointer' : 'default',
                  '&:hover': column.id !== 'actions' ? {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                  } : {},
                  userSelect: 'none'
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {column.label}
                  {sortConfig.key === column.id && (
                    <ArrowDropDown 
                      sx={{ 
                        transform: sortConfig.direction === 'desc' ? 'rotate(180deg)' : 'none',
                        transition: 'transform 150ms ease'
                      }} 
                    />
                  )}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedUsers
            .filter(user => 
              Object.values(user).some(value => 
                String(value).toLowerCase().includes(searchQuery.toLowerCase())
              )
            )
            .map(user => (
              <TableRow 
                key={user.uid}
                sx={{
                  backgroundColor: user.disabled ? 'rgba(0, 0, 0, 0.04)' : 'inherit',
                  '& > td': {
                    color: user.disabled ? 'text.disabled' : 'inherit'
                  }
                }}
              >
                <TableCell>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    gap: 1
                  }}>
                    {user.disabled ? (
                      <Chip
                        size="small"
                        label="Suspended"
                        color="error"
                        variant="outlined"
                        icon={<Lock fontSize="small" />}
                      />
                    ) : (
                      <Chip
                        size="small"
                        label="Active"
                        color="success"
                        variant="outlined"
                        icon={<LockOpen fontSize="small" />}
                      />
                    )}
                  </Box>
                </TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.network}</TableCell>
                <TableCell>{user.region}</TableCell>
                <TableCell>{user.funder}</TableCell>
                <TableCell>{Array.isArray(user.states) ? user.states.join(', ') : user.states}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>{user.department || user.customClaims?.department || '-'}</TableCell>
                <TableCell>
                  {user.lastInviteSent ? (
                    <Tooltip title={new Date(user.lastInviteSent).toLocaleString()}>
                      <Chip
                        size="small"
                        label="Invite Sent"
                        color="success"
                        variant="outlined"
                      />
                    </Tooltip>
                  ) : (
                    <Chip
                      size="small"
                      label="Not Invited"
                      color="default"
                      variant="outlined"
                    />
                  )}
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Tooltip title="Send Invite">
                      <IconButton
                        onClick={() => handleSendInvite(user)}
                        disabled={invitingUser === user.uid}
                        color="primary"
                      >
                        <Email />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit User">
                      <IconButton
                        onClick={() => {
                          setSelectedUser(user);
                          setFormData(user);
                          setMode('edit');
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={user.disabled ? 'Reactivate User' : 'Suspend User'}>
                      <IconButton
                        onClick={() => {
                          setSelectedUserForAction(user);
                          setSuspendDialogOpen(true);
                        }}
                        color={user.disabled ? 'success' : 'warning'}
                      >
                        {user.disabled ? <LockOpen /> : <Lock />}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete User">
                      <IconButton
                        onClick={() => {
                          setSelectedUserForAction(user);
                          setDeleteDialogOpen(true);
                        }}
                        color="error"
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  return (
    <Container maxWidth="xl">
      {/* Environment Banner */}
      <Box sx={{ 
        bgcolor: getBannerColor(),
        color: 'white',
        p: 1,
        mb: 2,
        textAlign: 'center',
        borderRadius: 1
      }}>
        {environment.toUpperCase()} ENVIRONMENT
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      )}

    {/* Stats Cards */}
    <Grid container spacing={3} sx={{ mb: 4 }}>
      <Grid item xs={12} sm={6} md={3}>
        <StatsCard 
          title="Total Users" 
          value={users.length} 
          icon={Group}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <StatsCard 
          title="Admins" 
          value={users.filter(u => u.role === 'admin').length}
          icon={PersonAdd}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <StatsCard 
          title="Networks" 
          value={new Set(users.map(u => u.network)).size}
          icon={Business}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <StatsCard 
          title="Regions" 
          value={new Set(users.map(u => u.region)).size}
          icon={LocationOn}
        />
      </Grid>
    </Grid>

      {/* Header with Mode Selection */}
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" component="h1">
          User Management
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Button
            variant="outlined"
            onClick={handleTestEmail}
            disabled={testEmailLoading}
            startIcon={<Email />}
            size="small"
          >
            Test Email
          </Button>
          <ToggleButtonGroup
            value={mode}
            exclusive
            onChange={(e, newMode) => newMode && setMode(newMode)}
          >
            <ToggleButton value="list">
              <Tooltip title="List Users">
                <Group />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="create">
              <Tooltip title="Add User">
                <PersonAdd />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="bulk" onClick={() => setCsvUploadOpen(true)}>
              <Tooltip title="Bulk Upload">
                <Upload />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>

      {loading && mode === 'list' ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {mode === 'list' && renderUserList()}
          {(mode === 'create' || mode === 'edit') && renderForm()}
        </>
      )}

      {/* CSV Upload Dialog */}
      <Dialog open={csvUploadOpen} onClose={() => setCsvUploadOpen(false)}>
        <DialogTitle>Bulk Upload Users</DialogTitle>
        <DialogContent>
          <Box sx={{ p: 3, textAlign: 'center' }}>
            <input
              type="file"
              accept=".csv"
              onChange={(e) => {
                if (e.target.files?.[0]) {
                  handleBulkUpload(e.target.files[0]);
                }
              }}
              style={{ display: 'none' }}
              id="csv-upload"
            />
            <label htmlFor="csv-upload">
              <Button
                variant="outlined"
                component="span"
                startIcon={<Upload />}
                sx={{ mb: 2 }}
              >
                Select CSV File
              </Button>
            </label>
            <Typography variant="body2" color="text.secondary">
              or drag and drop your CSV file here
            </Typography>
            <Button
              onClick={downloadTemplate}
              startIcon={<Download />}
              sx={{ mt: 2 }}
            >
              Download CSV Template
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCsvUploadOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <DeleteConfirmationDialog 
          open={deleteDialogOpen}
          onClose={handleCloseDeleteDialog}  // Use the new handler
          selectedUser={selectedUserForAction}
          onConfirmDelete={handleDeleteUser}
        />
      <SuspendConfirmationDialog />
    </Container>
  );
};

export default UserManagementPanel;