// services/feedbackService.js
import { collection, addDoc, getDocs, query, where, orderBy } from "firebase/firestore";
import { db } from "../firebase";

export const feedbackService = {
  // Submit new feedback
  async submitFeedback(feedbackData) {
    try {
      const docRef = await addDoc(collection(db, "feedback"), {
        ...feedbackData,
        createdAt: new Date().toISOString(),
        status: "new",
      });
      
      return docRef.id;
    } catch (error) {
      console.error("Error submitting feedback:", error);
      throw error;
    }
  },

  // Get all feedback (for admin)
  async getAllFeedback() {
    try {
      const q = query(
        collection(db, "feedback"),
        orderBy("createdAt", "desc"),
      );
      
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error("Error fetching feedback:", error);
      throw error;
    }
  },

  // Get feedback by user
  async getUserFeedback(userId) {
    try {
      const q = query(
        collection(db, "feedback"),
        where("userId", "==", userId),
        orderBy("createdAt", "desc"),
      );
      
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error("Error fetching user feedback:", error);
      throw error;
    }
  },
};