import React, { useState, useEffect, useMemo } from 'react';
import { 
  Typography, 
  Box, 
  Paper, 
  Grid, 
  Card, 
  CardContent, 
  Divider, 
  Container, 
  TextField,
  InputAdornment
} from '@mui/material';
import { 
  AssessmentOutlined,
  MapOutlined, 
  AutoGraphOutlined, 
  MapsHomeWork, 
  DescriptionOutlined, 
  DonutSmallOutlined,
  Search as SearchIcon
} from '@mui/icons-material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, useMap, Popup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import L from 'leaflet';
import { apiFetch } from '../../utils/api';
import { formatSchoolName } from '../../utils/nameFormatter';
import 'leaflet/dist/leaflet.css';

// Custom icon for map markers
const markerIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

// Map bounds fitting component
const MapBoundsFitter = ({ schools }) => {
  const map = useMap();
  
  useEffect(() => {
    if (schools.length > 0) {
      const bounds = L.latLngBounds(schools.map(school => [school.latitude, school.longitude]));
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [schools, map]);
  
  return null;
};

const featureCards = [
  {
    title: 'School Summary',
    description: 'Get a comprehensive overview of key metrics and performance indicators for any selected school.',
    icon: <AssessmentOutlined fontSize="large" />,
    link: '/school-summary'
  },
  {
    title: 'Network Map',
    description: 'View all schools within the network with enrollment and grade-range information. Filter by city and state.',
    icon: <MapOutlined fontSize="large" />,
    link: '/map'
  },
  {
    title: 'Geographic Explorer',
    description: 'Understand local context with population and enrollment trends. View nearby schools within various drive times.',
    icon: <MapsHomeWork fontSize="large" />,
    link: '/geographic-explorer'
  },
  {
    title: 'Competitive Explorer',
    description: 'Analyze market share for schools within a selected radius. Compare enrollment data and demographic information.',
    icon: <DonutSmallOutlined fontSize="large" />,
    link: '/competitive-explorer'
  },
  {
    title: 'Projections Explorer',
    description: 'Explore historical and projected enrollment data for the next five years. View by grade level and add custom data.',
    icon: <AutoGraphOutlined fontSize="large" />,
    link: '/projections-explorer'
  },
  {
    title: 'Data Reports',
    description: 'Download detailed reports, including Esri demographic data for selected schools.',
    icon: <DescriptionOutlined fontSize="large" />,
    link: '/data-reports'
  }
];

// Add this CSS to your styles
const mapStyles = `
  .custom-popup .leaflet-popup-content-wrapper {
    background: white;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    border: 2px solid #ff4444;
  }
  .custom-popup .leaflet-popup-tip-container {
    display: none;
  }
  .custom-popup .leaflet-popup-content {
    margin: 8px;
    width: auto !important;
  }
`;

const Home = ({ user }) => {
  useEffect(() => {
    // Add custom styles to head
    const style = document.createElement('style');
    style.textContent = mapStyles;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);
  const displayName = user?.displayName || user?.email || 'Guest';
  const [schools, setSchools] = useState([]);
  const [error, setError] = useState(null);
  const [totalEnrollment, setTotalEnrollment] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const data = await apiFetch('/schools');
        const processedData = data.map(school => ({
          ...school,
          enrollment: typeof school.enrollment === 'string' ? 
            parseInt(school.enrollment.replace(/[,.]/g, ''), 10) || 0 : 
            school.enrollment || 0
        }));
        
        setSchools(processedData);
        const total = processedData.reduce((sum, school) => sum + school.enrollment, 0);
        setTotalEnrollment(total);
      } catch (error) {
        console.error('Error fetching school data:', error);
        setError('Failed to load school data');
      }
    };
    fetchSchools();
  }, []);

  const filteredSchools = useMemo(() => {
    if (!searchTerm) return [];
    const search = searchTerm.toLowerCase();
    return schools.filter(school => 
      school.name.toLowerCase().includes(search) ||
      school.city.toLowerCase().includes(search) ||
      school.state.toLowerCase().includes(search)
    ).slice(0, 50);
  }, [schools, searchTerm]);

  const handleSchoolClick = (school) => {
    navigate(`/school-summary`, { state: { selectedSchool: school } });
  };

  const groupedSchools = useMemo(() => {
    const grouped = {};
    schools.forEach(school => {
      const key = `${school.latitude},${school.longitude}`;
      if (!grouped[key]) grouped[key] = [];
      grouped[key].push(school);
    });
    return grouped;
  }, [schools]);

  if (error) return <div>Error: {error}</div>;

  return (
    <Container maxWidth="xl" sx={{ py: 2 }}>
      <Grid container spacing={2}>
        {/* Top Section */}
        <Grid item xs={12} md={4}>
          <Paper 
            elevation={3} 
            sx={{ 
              p: 2,
              height: '100%',
              background: 'linear-gradient(145deg, #ffffff 0%, #f5f5f5 100%)',
              borderRadius: 2
            }}
          >
            <Box>
              <Typography 
                variant="h4" 
                component="h1"
                sx={{ 
                  fontWeight: 600,
                  background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                  backgroundClip: 'text',
                  textFillColor: 'transparent',
                  mb: 1
                }}
              >
                Welcome back,
              </Typography>
              <Typography 
                variant="h5" 
                component="div"
                sx={{ 
                  fontWeight: 700,
                  color: 'primary.main',
                  mb: 1
                }}
              >
                {displayName}
              </Typography>
              
              <Grid container spacing={2} sx={{ mt: 0.5, mb: 1.5 }}>
                <Grid item xs={6}>
                  <Paper elevation={1} sx={{ p: 1.5, textAlign: 'center' }}>
                    <Typography variant="h5" color="primary">{schools.length}</Typography>
                    <Typography variant="body2">Schools</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper elevation={1} sx={{ p: 1.5, textAlign: 'center' }}>
                    <Typography variant="h5" color="primary">
                      {totalEnrollment.toLocaleString()}
                    </Typography>
                    <Typography variant="body2">Students</Typography>
                  </Paper>
                </Grid>
              </Grid>

              {/* School Search Section */}
              <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
                Start with a school:
              </Typography>

              {/* School Search */}
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search schools..."
                size="small"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
              <Box sx={{
                maxHeight: '200px',
                overflowY: 'auto',
                border: searchTerm ? '1px solid' : 'none',
                borderColor: 'divider',
                borderRadius: 1,
                mt: 1,
                '&:empty': {
                  display: 'none'
                }
              }}>
                {filteredSchools.map((school) => (
                  <Box
                    key={school.ncessch}
                    onClick={() => handleSchoolClick(school)}
                    sx={{
                      p: 1,
                      cursor: 'pointer',
                      '&:hover': {
                        bgcolor: 'action.hover'
                      },
                      '&:not(:last-child)': {
                        borderBottom: '1px solid',
                        borderColor: 'divider'
                      }
                    }}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {formatSchoolName(school.name)}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {school.city}, {school.state} • {school.enrollment.toLocaleString()} students
                    </Typography>
                  </Box>
                ))}
                {searchTerm && filteredSchools.length === 0 && (
                  <Box sx={{ p: 1, textAlign: 'center' }}>
                    <Typography variant="caption" color="text.secondary">
                      No schools found matching "{searchTerm}"
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Paper>
        </Grid>

        {/* Map Section */}
        <Grid item xs={12} md={8}>
          <Paper 
            elevation={3} 
            sx={{ 
              height: '350px',
              borderRadius: 2,
              overflow: 'hidden'
            }}
          >
            <MapContainer 
              style={{ height: "100%", width: "100%" }}
            >
              <TileLayer
                url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
                attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>'
              />
              <MapBoundsFitter schools={schools} />
              <MarkerClusterGroup
                chunkedLoading
                spiderfyOnMaxZoom={true}
                zoomToBoundsOnClick={true}
                maxClusterRadius={40}
                disableClusteringAtZoom={12}
              >
                {Object.entries(groupedSchools).map(([key, schoolsAtLocation], index) => {
                  const position = [schoolsAtLocation[0].latitude, schoolsAtLocation[0].longitude];
                  return (
                    <Marker 
                      key={index}
                      position={position}
                      icon={markerIcon}
                    >
                      <Popup
                        closeButton={false}
                        autoPan={false}
                        permanent={true}
                        className="custom-popup"
                      >
                        <div style={{
                          padding: '2px',
                          width: '150px',
                        }}>
                          {schoolsAtLocation.map((school, idx) => (
                            <div key={idx}>
                              <div
                                onClick={() => handleSchoolClick(school)}
                                style={{
                                  cursor: 'pointer',
                                  padding: '3px 4px',
                                  fontSize: '13px',
                                  color: '#2196F3',
                                  wordWrap: 'break-word',
                                  lineHeight: 1.2,
                                  margin: '2px 0',
                                  textAlign: 'center',
                                  borderRadius: '3px',
                                }}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.backgroundColor = '#f0f7ff';
                                  e.currentTarget.style.color = '#1565c0';
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.backgroundColor = 'transparent';
                                  e.currentTarget.style.color = '#2196F3';
                                }}
                              >
                                {formatSchoolName(school.name)}
                              </div>
                              {idx < schoolsAtLocation.length - 1 && (
                                <div style={{
                                  height: '1px',
                                  background: '#e0e0e0',
                                  margin: '2px 10px'
                                }} />
                              )}
                            </div>
                          ))}
                        </div>
                      </Popup>
                    </Marker>
                  );
                })}
              </MarkerClusterGroup>
            </MapContainer>
          </Paper>
        </Grid>

        {/* Divider */}
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }}>
            <Typography variant="subtitle1" color="text.secondary">
              Available Features
            </Typography>
          </Divider>
        </Grid>

        {/* Feature Cards */}
        {featureCards.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card 
              component={RouterLink} 
              to={card.link} 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                textDecoration: 'none',
                color: 'inherit',
                background: 'linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%)',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: (theme) => `0 12px 24px ${theme.palette.primary.light}25`,
                  '& .MuiSvgIcon-root': {
                    transform: 'scale(1.1)',
                    color: 'primary.main'
                  }
                }
              }}
            >
              <CardContent sx={{ p: 2 }}>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    mb: 1,
                    '& .MuiSvgIcon-root': {
                      fontSize: '2rem',
                      color: 'text.secondary',
                      transition: 'all 0.3s ease-in-out'
                    }
                  }}
                >
                  {card.icon}
                </Box>
                <Typography 
                  variant="h6" 
                  component="h2" 
                  align="center"
                  sx={{ 
                    fontWeight: 600,
                    mb: 1,
                    fontSize: '1.1rem'
                  }}
                >
                  {card.title}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  align="center"
                  sx={{ fontSize: '0.875rem' }}
                >
                  {card.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Home;