import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { schoolsApi } from '../utils/apiService';
import { formatSchoolName } from '../utils/nameFormatter';
import { categorizeSchools } from '../utils/schoolCategorization';

const processEnrollmentData = (data) => {
  return data.map(school => {
    return {
      ncessch: school.ncessch,
      ...school,
      // Maintain the nested structure for race_data
      race_data: {
        current: school.race_data?.current || {},
        comparison: school.race_data?.comparison || {}
      },
      // Handle other fields as before
      enrollment_by_grade: {
        current: school.enrollment_by_grade?.current || {},
        comparison: school.enrollment_by_grade?.comparison || {}
      }
    };
  });
};

const validateMarketShareData = (data) => {
  if (!data?.drive_times) {
    console.warn('Missing drive_times in market share data');
    return false;
  }

  // Check if at least one drive time has valid polygon data
  return Object.values(data.drive_times).some(timeData => {
    if (!timeData?.drive_time_polygon) return false;
    
    try {
      const polygonData = typeof timeData.drive_time_polygon === 'string' 
        ? JSON.parse(timeData.drive_time_polygon) 
        : timeData.drive_time_polygon;
      
      return polygonData?.rings?.length > 0 || 
             (Array.isArray(polygonData) && polygonData.length > 0);
    } catch (error) {
      console.error('Invalid polygon data:', error);
      return false;
    }
  });
};


const useSchoolStore = create(
  devtools((set, get) => ({
    // Data states
    schools: [],
    selectedSchool: null,
    esriData: null,
    nearbySchools: {},
    rawNearbySchools: {}, // New state for unfiltered schools
    enrollmentProjections: null,
    schoolsEnrollmentData: [],
    
    // Loading states
    schoolsLoading: false,
    contentLoading: false,
    
    // Error state
    error: null,

    // Action to fetch all schools
    fetchSchools: async () => {
      try {
        set({ schoolsLoading: true });
        const schools = await schoolsApi.getAllSchools();
        
        // Deduplicate schools by ncessch
        const uniqueSchools = Array.from(
          new Map(schools.map(school => [
            school.ncessch, 
            {...school, name: formatSchoolName(school.name)}
          ])).values()
        ).sort((a, b) => a.name.localeCompare(b.name));
    
        set({ schools: uniqueSchools, schoolsLoading: false });
      } catch (error) {
        console.error('Error fetching schools:', error);
        set({ error: error.message, schoolsLoading: false });
      }
    },
    
    // Action to set selected school and fetch its data
    selectSchool: async (school) => {
      set({ selectedSchool: school, contentLoading: true });
      
      if (school) {
          try {
              const [marketShareData, nearbySchoolsResponse, projectionsData] = await Promise.all([
                  schoolsApi.getMarketShareData(school.ncessch),
                  schoolsApi.getAllNearbySchools(school.ncessch),
                  schoolsApi.getEnrollmentProjections(school.ncessch)
              ]);
  
              console.log('Nearby schools response:', {
                  current: {
                      5: (nearbySchoolsResponse.current?.[5] || []).length,
                      10: (nearbySchoolsResponse.current?.[10] || []).length,
                      15: (nearbySchoolsResponse.current?.[15] || []).length
                  },
                  comparison: {
                      5: (nearbySchoolsResponse.comparison?.[5] || []).length,
                      10: (nearbySchoolsResponse.comparison?.[10] || []).length,
                      15: (nearbySchoolsResponse.comparison?.[15] || []).length
                  }
              });
    
          // Validate market share data
          if (!validateMarketShareData(marketShareData)) {
            console.error('Invalid or missing polygon data for school:', school.ncessch);
            // Set a default empty structure that won't break the map
            marketShareData.drive_times = {
              10: { drive_time_polygon: [[]] }
            };
          }
    
          // Get all school IDs for enrollment data, including from grade data
          const schoolIds = new Set([
            school.ncessch,
            ...Object.values(nearbySchoolsResponse.current || {})
              .flatMap(schools => schools.map(s => s.ncessch)),
            ...Object.values(nearbySchoolsResponse.comparison || {})
              .flatMap(schools => schools.map(s => s.ncessch)),
            ...Object.keys(nearbySchoolsResponse.gradeData || {})
          ]);
          
          // Fetch and process enrollment data
          const enrollmentData = await schoolsApi.getSchoolsEnrollmentData(
            Array.from(schoolIds),
            school.ncessch
          );
      
          // Process the enrollment data while preserving the race data structure
          const processedData = processEnrollmentData(enrollmentData);
          
          // Categorize schools with grade data consideration
          const categorizedEnrollmentData = categorizeSchools(
            processedData, 
            school.ncessch, 
            nearbySchoolsResponse.gradeData
          );
          
          set({
            esriData: marketShareData,
            nearbySchools: nearbySchoolsResponse,
            rawNearbySchools: nearbySchoolsResponse, // Store unfiltered data
            enrollmentProjections: projectionsData,
            schoolsEnrollmentData: categorizedEnrollmentData,
            contentLoading: false,
            error: null
          });
        } catch (error) {
          console.error('Error fetching school data:', error);
          set({ 
            error: error.message, 
            contentLoading: false 
          });
        }
      }
    },

    // Action to clear school data
    clearSchoolData: () => {
      set({
        selectedSchool: null,
        esriData: null,
        nearbySchools: {},
        rawNearbySchools: {}, // Clear raw data too
        enrollmentProjections: null,
        schoolsEnrollmentData: [],
        error: null
      });
    },

    // Initialize store
    initialize: async () => {
      try {
        await get().fetchSchools();
      } catch (error) {
        console.error('Error initializing store:', error);
      }
    }
  }))
);

export default useSchoolStore;