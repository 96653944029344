// shared_components/templates/headlinesTemplate.js
import React from 'react';
import { Box, Typography, Grid, Card } from '@mui/material';

const HeadlineCard = ({ 
  title, 
  content, 
  color = 'text.primary',
  elevation = 0,
  backgroundColor = 'background.paper',
  sx = {}
}) => (
  <Card 
    elevation={elevation}
    sx={{ 
      p: 2.5,
      backgroundColor,
      ...(sx.border === undefined && {
        border: '1px solid',
        borderColor: 'divider',
      }),
      borderRadius: 2,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      ...sx
    }}
  >
    <Typography 
      variant="h6" 
      sx={{ 
        fontWeight: 600,
        color: color,
        mb: 1.5,
        fontSize: '1.1rem'
      }}
    >
      {title}
    </Typography>
    <Typography 
      variant="body2" 
      sx={{ 
        color: 'text.secondary',
        lineHeight: 1.6,
        fontSize: '0.875rem',
        flex: '1 0 auto',  // Allow content to grow but not shrink
        whiteSpace: 'pre-wrap',  // Add this to handle line breaks
        '& .note': {  // Add styling for note class
          display: 'block',
          marginTop: 2,
          fontSize: '0.75rem',
          fontStyle: 'italic'
        }
      }}
    >
      {content}
    </Typography>
  </Card>
);

const HeadlinesTemplate = ({ 
  headlines,
  spacing = 3,
  columnSizes = { xs: 12, sm: 6, md: 4 },
  cardProps = {}
}) => {
  // Calculate items per row based on number of headlines
  const itemsPerRow = headlines.length;  // Changed this to be dynamic
  
  return (
    <Box sx={{ width: '100%' }}>
      <Grid 
        container 
        spacing={spacing} 
        sx={{
          '& .MuiGrid-item': {
            display: 'flex',
            md: {  // Changed from [headlines.length === 3 ? 'md' : 'lg'] to just 'md'
              flexBasis: `${100 / itemsPerRow}%`,
              maxWidth: `${100 / itemsPerRow}%`,
            }
          }
        }}
      >
        {headlines.map((headline, index) => (
          <Grid 
            item 
            xs={12}
            sm={6}
            md={12 / itemsPerRow}  // Changed to be dynamic based on number of headlines
            key={index}
          >
            <HeadlineCard 
              {...headline} 
              {...(typeof cardProps === 'function' ? cardProps(index) : cardProps)} 
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HeadlinesTemplate;