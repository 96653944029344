// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics, isSupported } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);

// Initialize Analytics with fallback
let analytics = null;

const initializeAnalytics = async () => {
  try {
    const analyticsSupported = await isSupported();
    if (analyticsSupported) {
      analytics = getAnalytics(app);
      console.log('Analytics initialized successfully');
    } else {
      console.warn('Analytics not supported in this environment - trying fallback mode');
      // Fallback to basic event tracking if needed
      analytics = {
        logEvent: (...args) => console.log('Analytics Event (Fallback):', ...args)
      };
    }
  } catch (error) {
    console.error('Error initializing analytics:', error);
    // Provide fallback analytics object
    analytics = {
      logEvent: (...args) => console.log('Analytics Event (Error Fallback):', ...args)
    };
  }
};

// Initialize analytics immediately
initializeAnalytics();

export { app, auth, analytics, db, functions };