import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
  Box,
  IconButton
} from '@mui/material';
import {
  Delete as DeleteIcon
} from '@mui/icons-material';
import { schoolsApi } from '../../utils/apiService';

const GRADE_ORDER = [
'Kindergarten', 
  'Grade 1', 'Grade 2', 'Grade 3', 'Grade 4', 'Grade 5', 'Grade 6', 
  'Grade 7', 'Grade 8', 'Grade 9', 'Grade 10', 'Grade 11', 'Grade 12'
];

const ProjectXEnrollmentEditor = ({ 
  open, 
  onClose, 
  school, 
  onSuccess, 
  existingData = null, 
  isEdit = false 
}) => {
  const [enrollmentData, setEnrollmentData] = useState({});
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [additionalGrades, setAdditionalGrades] = useState([]);

  // Load existing data when editing
  useEffect(() => {
    if (isEdit && existingData) {
      // Convert the existing data into the format expected by the form
      const formattedData = {};
      const additional = [];
      
      Object.entries(existingData.grades || {}).forEach(([grade, enrollment]) => {
        if (school.grades?.includes(grade)) {
          formattedData[grade] = enrollment === -1 ? 'no_longer_offered' : enrollment;
        } else {
          additional.push({
            grade,
            enrollment: enrollment === -1 ? 'no_longer_offered' : enrollment
          });
        }
      });
      
      setEnrollmentData(formattedData);
      setAdditionalGrades(additional);
    }
  }, [isEdit, existingData, school]);

  const enrollmentOptions = [
    { value: 'no_longer_offered', label: 'No longer offered' },
    ...Array.from({ length: 999 }, (_, i) => ({ value: i + 1, label: i + 1 }))
  ];

  const handleGradeChange = (grade) => (event) => {
    const { value } = event.target;
    setEnrollmentData(prev => ({
      ...prev,
      [grade]: value
    }));
    validateGrade(grade, value);
  };

  const validateGrade = (grade, value) => {
    if (value === '') {
      setValidationErrors(prev => ({
        ...prev,
        [grade]: 'Enrollment is required for all grades.'
      }));
    } else if (value !== 'no_longer_offered' && parseInt(value, 10) > 999) {
      setValidationErrors(prev => ({
        ...prev,
        [grade]: 'Enrollment exceeds 999 students. Please verify.'
      }));
    } else {
      setValidationErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[grade];
        return newErrors;
      });
    }
  };

  const handleAddGrade = () => {
    if (additionalGrades.length < 3) {
      setAdditionalGrades(prev => [...prev, { grade: '', enrollment: '' }]);
    }
  };

  const handleRemoveGrade = (index) => {
    setAdditionalGrades(prev => prev.filter((_, i) => i !== index));
  };

  const handleAdditionalGradeChange = (index, field) => (event) => {
    const { value } = event.target;
    setAdditionalGrades(prev => prev.map((grade, i) => 
      i === index ? { ...grade, [field]: value } : grade
    ));

    if (field === 'enrollment') {
      validateGrade(`additional-${index}`, value);
    }
  };

  const handleSubmit = async () => {
    if (Object.keys(validationErrors).length > 0) return;
   
    setLoading(true);
    try {
      const grades = { ...enrollmentData };
      additionalGrades.forEach(({ grade, enrollment }) => {
        if (grade && enrollment) {
          grades[grade] = enrollment;
        }
      });
   
      const submissionData = {
        schoolId: school.ncessch,
        schoolName: school.name,
        year: '2024-2025',
        grades: Object.entries(grades).reduce((acc, [grade, enrollment]) => {
          if (enrollment !== '') {
            acc[grade] = enrollment === 'no_longer_offered' ? -1 : parseInt(enrollment, 10);
          }
          return acc;
        }, {})
      };
   
      await schoolsApi.submitEnrollmentData(submissionData);
      onSuccess();
      onClose();
    } catch (error) {
      console.error('Error submitting enrollment data:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderGradeInputs = () => {
    const sortedGrades = school?.grades?.sort((a, b) => 
      GRADE_ORDER.indexOf(a) - GRADE_ORDER.indexOf(b)
    ) || [];

    return (
      <>
        {sortedGrades.map((grade) => (
          <Grid item xs={12} sm={6} key={grade}>
            <FormControl fullWidth error={!!validationErrors[grade]}>
              <InputLabel>{grade}</InputLabel>
              <Select
                value={enrollmentData[grade] || ''}
                onChange={handleGradeChange(grade)}
                label={grade}
                required
              >
                {enrollmentOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {validationErrors[grade] && (
                <Typography variant="caption" color="error">
                  {validationErrors[grade]}
                </Typography>
              )}
            </FormControl>
          </Grid>
        ))}
        {additionalGrades.map((grade, index) => (
          <Grid item xs={12} container spacing={2} key={`additional-${index}`}>
            <Grid item xs={5}>
              <FormControl fullWidth>
                <InputLabel>Additional Grade</InputLabel>
                <Select
                  value={grade.grade}
                  onChange={handleAdditionalGradeChange(index, 'grade')}
                  label="Additional Grade"
                >
                  {GRADE_ORDER.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <FormControl fullWidth error={!!validationErrors[`additional-${index}`]}>
                <InputLabel>Enrollment</InputLabel>
                <Select
                  value={grade.enrollment}
                  onChange={handleAdditionalGradeChange(index, 'enrollment')}
                  label="Enrollment"
                >
                  {enrollmentOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={() => handleRemoveGrade(index)} size="small">
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">
            {isEdit ? 'Edit' : 'Enter'} 2024-2025 Enrollment Data
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {isEdit ? 'Update' : 'Enter'} enrollment data for {school?.name}
            </Typography>
          </Grid>
          {renderGradeInputs()}
          {additionalGrades.length < 3 && (
            <Grid item xs={12}>
              <Button onClick={handleAddGrade} variant="outlined">
                Add Grade
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleSubmit} 
          variant="contained" 
          disabled={loading || Object.keys(validationErrors).length > 0}
        >
          {loading ? <CircularProgress size={24} /> : isEdit ? 'Update' : 'Submit'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectXEnrollmentEditor;