import React, { useMemo } from 'react';
import { Box, Typography, Link } from '@mui/material';
import HeadlinesTemplate from '../../shared_components/templates/headlinesTemplate';
import { 
  calculateEnrollment, 
  filterAgeData,
  calculateMarketShareStats,
  calculateTotalChildren,
  getSchoolTimeframe
} from '../../../utils/schoolUtils';
import { SCHOOL_CATEGORIES } from '../../../utils/schoolCategorization';
import CompXCommunityPlot from './CompXCommunityPlot';
import { OVERALL_STATUS, TREND_COLORS, THRESHOLDS } from '../../../constants/competitiveExplorer';


// Helper to format text with markdown-style bold and color
const formatText = (text, defaultColor = 'inherit') => {
  // First split by possible note sections
  const parts = text.split(/(\n\n<span class="note">.*?<\/span>)/);
  
  return parts.map((part, partIndex) => {
    // Check if this is a note section
    if (part.startsWith('\n\n<span class="note">')) {
      // Extract the note text (remove the span tags)
      const noteText = part.replace('\n\n<span class="note">', '').replace('</span>', '');
      return (
        <span 
          key={`note-${partIndex}`} 
          style={{
            display: 'block',
            marginTop: '16px',
            fontSize: '0.75rem',
            fontStyle: 'italic',
            color: 'rgba(0, 0, 0, 0.6)'
          }}
        >
          {noteText.split('**').map((boldPart, index) => {
            if (index % 2 === 0) return boldPart;
            return <strong key={`${partIndex}-${index}`}>{boldPart}</strong>;
          })}
        </span>
      );
    }

    // Handle regular text with bold sections
    return part.split('**').map((boldPart, index) => {
      if (index % 2 === 0) return boldPart;
      
      let color = defaultColor;
      const lowerPart = boldPart.toLowerCase();
      if (['growing', 'increased', 'increase', 'up', 'growth'].some(word => lowerPart.includes(word))) {
        color = TREND_COLORS.positive;
      } else if (['declining', 'decreased', 'decrease', 'down', 'declines'].some(word => lowerPart.includes(word))) {
        color = TREND_COLORS.negative;
      } else if (['steady', 'unchanged', 'remained'].some(word => lowerPart.includes(word))) {
        color = TREND_COLORS.neutral;
      }
      
      return <strong key={`${partIndex}-${index}`} style={{ color }}>{boldPart}</strong>;
    });
  });
};

const getOverallStatus = ({
  schoolCategory,
  marketShareChange,
  populationChange,
  isNewSchool  // Add this parameter
}) => {
  // Check if school is new or newer
  if (isNewSchool || [
    SCHOOL_CATEGORIES.NEW,
    SCHOOL_CATEGORIES.NEWER_INCREASING,
    SCHOOL_CATEGORIES.NEWER_DECREASING,
    SCHOOL_CATEGORIES.NEWER_STABLE
  ].includes(schoolCategory)) {
    return OVERALL_STATUS.NEW;
  }

  // Check if conditions are strong
  if (marketShareChange >= THRESHOLDS.MARKET_SHARE_SIGNIFICANT && 
      populationChange >= THRESHOLDS.POPULATION_SIGNIFICANT) {
    return OVERALL_STATUS.STRONG;
  }

  // Check if conditions are challenging
  if (marketShareChange <= -THRESHOLDS.MARKET_SHARE_SIGNIFICANT && 
      populationChange <= -THRESHOLDS.POPULATION_SIGNIFICANT) {
    return OVERALL_STATUS.CHALLENGING;
  }

  // Check if conditions are steady
  if (Math.abs(marketShareChange) < THRESHOLDS.MARKET_SHARE_SIGNIFICANT && 
      Math.abs(populationChange) < THRESHOLDS.POPULATION_SIGNIFICANT) {
    return OVERALL_STATUS.STEADY;
  }

  // If none of the above, conditions are mixed
  return OVERALL_STATUS.MIXED;
};

const getAreaTrendText = (areaTrend) => `in a **${areaTrend}** student population area`;

const getMainHeadline = ({
  schoolCategory,
  schoolName,
  shareTrend,
  areaTrend,
  timeframe,
  startGrades,
  endGrades
}) => {
  // Helper to format grade change note
  const getGradeChangeNote = () => {
    if (!startGrades || !endGrades || startGrades === endGrades) return '';
    return `\n\n<span class="note">The school offered **${startGrades}** in ${timeframe.startLabel}, and ${
      schoolCategory === SCHOOL_CATEGORIES.CONTINUOUS_INCREASING || 
      schoolCategory === SCHOOL_CATEGORIES.NEWER_INCREASING ? 
      'has expanded to' : 'now offers'} **${endGrades}** in ${timeframe.endLabel}.</span>`;
  };

  // For new schools, use the category to determine text
  if (schoolCategory === SCHOOL_CATEGORIES.NEW) {
    return {
      title: "New School",
      content: `${schoolName} is **new** (opened in ${timeframe.endLabel}) ${getAreaTrendText(areaTrend)}.`
    };
  }

  switch (schoolCategory) {
    case SCHOOL_CATEGORIES.CONTINUOUS_STABLE:
      return {
        title: "Continuous Operation",
        content: `${schoolName} has a **${shareTrend}** market share ${getAreaTrendText(areaTrend)}.`
      };

    case SCHOOL_CATEGORIES.CONTINUOUS_INCREASING:
      return {
        title: "Growing Grade Levels",
        content: `${schoolName} has a **${shareTrend}** market share ${getAreaTrendText(areaTrend)}${getGradeChangeNote()}`
      };

    case SCHOOL_CATEGORIES.CONTINUOUS_DECREASING:
      return {
        title: "Reducing Grade Levels",
        content: `${schoolName} has a **${shareTrend}** market share ${getAreaTrendText(areaTrend)}${getGradeChangeNote()}`
      };

    case SCHOOL_CATEGORIES.NEWER_INCREASING:
      return {
        title: "Newer and Growing",
        content: `${schoolName} is **newer and growing** ${getAreaTrendText(areaTrend)}${getGradeChangeNote()}`
      };

    case SCHOOL_CATEGORIES.NEWER_DECREASING:
      return {
        title: "Newer with Reducing Grades",
        content: `${schoolName} is **newer** ${getAreaTrendText(areaTrend)}${getGradeChangeNote()}`
      };

    case SCHOOL_CATEGORIES.NEWER_STABLE:
      return {
        title: "Newer School",
        content: `${schoolName} is **newer and stable** ${getAreaTrendText(areaTrend)}, maintaining **${endGrades}** since ${timeframe.startLabel}.`
      };

    default:
      return {
        title: "School Overview",
        content: `${schoolName} has a **${shareTrend}** market share ${getAreaTrendText(areaTrend)}.`
      };
  }
};

const CompXCommunityHeadlines = ({ 
  selectedSchool,
  filteredSchools, 
  selectedGrades,  
  esriData,
  selectedDriveTime,
  schoolsEnrollmentData,
}) => {
  const selectedSchoolData = schoolsEnrollmentData.find(
    d => d.ncessch === selectedSchool.ncessch
  );
  const timeframe = getSchoolTimeframe(selectedSchoolData);

  // Calculate population and market share data
  const {
    communityData,
    currentVsPastChange,
    marketShareData
  } = useMemo(() => {
    const communityData = esriData?.drive_times?.[selectedDriveTime];
    
    if (!communityData) {
      return {
        communityData: null,
        populationTotals: { past: 0, current: 0 },
        currentVsPastChange: 0,
        marketShareData: { current: 0, past: 0 }
      };
    }

    // Your existing calculations...
    const filteredAgeData = filterAgeData(communityData.ages['4_17'], selectedGrades);
    
    const totals = {
      past: calculateTotalChildren(filteredAgeData['2020']),
      current: calculateTotalChildren(filteredAgeData.current),
    };

    const populationChange = ((totals.current - totals.past) / totals.past) * 100;

    const enrollmentData = schoolsEnrollmentData.find(d => d.ncessch === selectedSchool.ncessch);

    const marketShareStats = calculateMarketShareStats({
      school: selectedSchool,
      enrollmentData,
      selectedGrades,
      esriData,
      selectedDriveTime
    });

    return {
      communityData,
      populationTotals: totals,
      currentVsPastChange: populationChange,
      marketShareData: marketShareStats.population
    };
  }, [esriData, selectedDriveTime, selectedGrades, selectedSchool, schoolsEnrollmentData]);

  // Helper functions
  const getAreaTrend = (change) => {
    if (Math.abs(change) < THRESHOLDS.POPULATION_SIGNIFICANT) return 'steady';
    return change > 0 ? 'growing' : 'declining';
  };

  const getShareTrend = (change) => {
    if (Math.abs(change) < THRESHOLDS.MARKET_SHARE_SIGNIFICANT) return 'steady';
    return change > 0 ? 'growing' : 'declining';
  };  

  // Generate headlines data
  const headlines = useMemo(() => {
    if (!communityData) return [];
  
    const areaTrend = getAreaTrend(currentVsPastChange);
    const shareTrend = getShareTrend(marketShareData.change);
    
    const selectedSchoolData = schoolsEnrollmentData.find(
      d => d.ncessch === selectedSchool.ncessch
    );
    
    const timeframe = getSchoolTimeframe(selectedSchoolData);
    const currentEnrollment = calculateEnrollment(
      selectedSchoolData?.enrollment_by_grade?.current || {},
      selectedGrades
    );
  
    const mainHeadline = getMainHeadline({
      schoolCategory: selectedSchoolData?.category,
      schoolName: selectedSchool.name,
      shareTrend,
      areaTrend,
      timeframe,
      startGrades: timeframe.startGrades,
      endGrades: timeframe.endGrades
    });
  
    const overallStatus = getOverallStatus({
      schoolCategory: selectedSchoolData?.category,
      marketShareChange: marketShareData.change,
      populationChange: currentVsPastChange
    });
  
    const getHeadlineColor = (status) => {
      switch (status) {
        case OVERALL_STATUS.STRONG:
          return 'success.main';
        case OVERALL_STATUS.CHALLENGING:
          return 'error.main';
        case OVERALL_STATUS.NEW:
          return 'info.main';
        case OVERALL_STATUS.STEADY:
          return 'info.main';
        default:
          return 'warning.main'; // for MIXED conditions
      }
    };
  
    return [
      {
        title: `Overall Headline: ${overallStatus}`,
        content: mainHeadline.content,
        color: getHeadlineColor(overallStatus),
        sx: {
          borderColor: 'grey.800',
          borderWidth: 2,
          backgroundColor: 'grey.50'
        }
      },
      {
        title: `${areaTrend === 'steady' ? 'Steady' : currentVsPastChange >= 0 ? 'Growing' : 'Shrinking'} Area`,
        content: `The area student population has ${
          Math.abs(currentVsPastChange) < THRESHOLDS.AREA_STEADY ? 
          '**remained steady**' : 
          `**${currentVsPastChange >= 0 ? 'increased' : 'decreased'}** by ${Math.abs(currentVsPastChange).toFixed(0)}%`
        } since 2020.`,
        color: currentVsPastChange >= 0 ? 'success.main' : 'error.main'
      },
      {
        title: 'Current Enrollment and Share',
        content: `This school enrolls **${currentEnrollment.toLocaleString()}** students, representing **${marketShareData.current.toFixed(0)}%** of area children${
          marketShareData.past > 0 ? 
          `, **${Math.abs(marketShareData.change) < THRESHOLDS.MARKET_SHARE_SIGNIFICANT ? 'steady' : marketShareData.change > 0 ? 'up' : 'down'}** from ${marketShareData.past.toFixed(0)}% in 2020` : 
          ''
        }.`,
        color: 'info.main'
      }
    ];
  }, [
    communityData, 
    currentVsPastChange, 
    marketShareData, 
    selectedSchool, 
    schoolsEnrollmentData, 
    selectedGrades
  ]);

  return (
    <Box sx={{ 
      pt: 0.5,
      px: 2, 
      pb: 2,
      width: '100%'
    }}>
      <HeadlinesTemplate 
        headlines={headlines.map(headline => ({
          ...headline,
          content: formatText(headline.content)
        }))}
        spacing={3}
        columnSizes={{ xs: 12, sm: 4 }}
        cardProps={(index) => ({ 
          elevation: 0,
          sx: { 
            backgroundColor: 'transparent',
            borderWidth: index === 0 ? 2 : 1,
            borderStyle: 'solid',
            borderColor: index === 0 ? 'grey.800' : 'divider',
            ...headlines[index]?.sx
          }
        })}
      />
      <Box sx={{ mt: 3, width: '100%' }}>
        <CompXCommunityPlot
          selectedSchool={selectedSchool}
          marketShareData={marketShareData}
          currentVsPastChange={currentVsPastChange}
          schoolCategory={selectedSchoolData?.category}
          timeframeData={timeframe}
        />
      </Box>
      
      {/* Sources Footer moved here */}
      <Box sx={{ 
        mt: 2,
        display: 'flex',
        justifyContent: 'center'
      }}>
        <Typography variant="caption" color="text.secondary">
          Sources: {' '}
          <Link 
            href="https://doc.arcgis.com/en/esri-demographics/latest/reference/methodologies.htm" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            Esri
          </Link>
          {' & '}
          <Link 
            href="https://nces.ed.gov/ccd" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            NCES 2019-20 through 2023-24
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default CompXCommunityHeadlines;