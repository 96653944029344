import L from 'leaflet';
import { calculateEnrollment, getEarliestEnrollmentData } from '../utils/schoolUtils';

/**
 * Custom icon creator for both map types
 */
export const customIcon = ({ color, isSelected = false, isCharter = false, opacity = 1 }) => {
  const size = isSelected ? 40 : 30;
  
  const locationPath = "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z";
  const schoolPath = "M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3L1 9l11 6 9-4.91V17h2V9L12 3z";

  const iconSvg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24" style="opacity: ${opacity}">
      <path fill="${color}" d="${isCharter ? schoolPath : locationPath}"/>
    </svg>
  `;

  return L.divIcon({
    html: iconSvg,
    className: `custom-icon ${isCharter ? 'SchoolOutlined' : 'LocationOnOutlined'}`,
    iconSize: [size, size],
    iconAnchor: [size/2, size],
    popupAnchor: [0, -size/2],
  });
};

/**
 * Calculate enrollment change for a school
 */
const calculateEnrollmentChange = (schoolData) => {
  if (!schoolData?.enrollment_by_grade?.current) {
    return { percentChange: 0, hasData: false };
  }

  // Use all grades for consistent calculation
  const allGrades = ['Kindergarten'];
  for (let i = 1; i <= 12; i++) {
    allGrades.push(`${i}`);
  }

  const currentEnrollment = calculateEnrollment(
    schoolData.enrollment_by_grade.current,
    allGrades
  );
  
  // Get earliest available data for comparison
  const comparisonData = getEarliestEnrollmentData(schoolData);
  const comparisonEnrollment = comparisonData ? 
    calculateEnrollment(comparisonData, allGrades) : null;

  const hasComparison = comparisonEnrollment !== null && comparisonEnrollment > 0;
  const percentChange = hasComparison ? 
    ((currentEnrollment - comparisonEnrollment) / comparisonEnrollment) * 100 : 0;

  return {
    percentChange,
    hasData: hasComparison && currentEnrollment > 0,
    currentEnrollment,
    comparisonEnrollment
  };
};

/**
 * Get color based on map mode and school data
 */
export const getSchoolColor = (school, schoolsEnrollmentData, mode, marketShares = {}) => {
  if (mode === 'market-share') {
    const marketShareData = marketShares[school.ncessch]?.marketData;
    
    // If no market share data, return gray instead of red
    if (!marketShareData) return '#808080';
    
    // Use current market share (stored as decimal)
    const currentShare = marketShareData.current || 0;
    return getMarketShareColor(currentShare);
  }
  
  // Rest of the function remains the same...
  const schoolData = schoolsEnrollmentData?.find(s => s.ncessch === school.ncessch);
  if (!schoolData) return '#808080';

  const { percentChange, hasData } = calculateEnrollmentChange(schoolData);
  
  if (!hasData) return '#808080';

  if (percentChange > 0) return '#008000';
  if (percentChange < 0) return '#FF0000';
  return '#808080';
};

/**
 * Color logic for market share mode
 */
export const getMarketShareColor = (marketShare) => {
  if (marketShare === null || marketShare === undefined) return '#808080';
  
  // Convert marketShare to a percentage (0-100)
  const value = marketShare * 100;
  
  // Create a more sensitive spectrum for lower values
  // Using a logarithmic-like scale to better differentiate small values
  if (value === 0) return '#ff0000';  // Pure red for 0%
  
  if (value <= 3) {
    // Interpolate between red and orange for very small values (0-3%)
    const ratio = value / 3;
    const r = 255;
    const g = Math.round(128 * ratio);
    return `rgb(${r}, ${g}, 0)`;
  } else if (value <= 10) {
    // Interpolate between orange and yellow (3-10%)
    const ratio = (value - 3) / 7;
    const r = 255;
    const g = Math.round(128 + (127 * ratio));
    return `rgb(${r}, ${g}, 0)`;
  } else if (value <= 25) {
    // Interpolate between yellow and light green (10-25%)
    const ratio = (value - 10) / 15;
    const r = Math.round(255 * (1 - ratio));
    const g = 255;
    return `rgb(${r}, ${g}, 0)`;
  } else {
    // Interpolate to deep green for larger values (>25%)
    const ratio = Math.min((value - 25) / 25, 1);
    const g = 255;
    const r = Math.round(255 * (1 - ratio));
    return `rgb(${r}, ${g}, 0)`;
  }
};

export const driveTimeColors = {
  5: '#4338ca',  // Darker blue
  10: '#6366f1', // Medium blue
  15: '#818cf8'  // Lighter blue
};

export const colorScales = {
  'market-share': [
    { color: 'linear-gradient(to right, #ff0000, #ff8000, #ffff00, #80ff00, #008000)', 
      label: 'Market Share (Low to High)' }
  ],
  'geographic': [
    { color: '#008000', label: 'Enrollment Growth' },
    { color: '#FF0000', label: 'Enrollment Decline' },
    { color: '#808080', label: 'No Change/Data' }
  ]
};