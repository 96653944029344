import React, { useState } from 'react';
import { 
  Box, 
  Tabs, 
  Tab, 
  Container, 
  Typography, 
  Paper,
} from '@mui/material';
import {
  Group as GroupIcon,
  Analytics as AnalyticsIcon,
  DataObject as DataIcon,
  Feedback as FeedbackIcon
} from '@mui/icons-material';

// Components
import UserManagementPanel from './UserManagementPanel';  
import ValidationSuite from './validation/ValidationSuite';
import AnalyticsPanel from './AnalyticsPanel';
import FeedbackPanel from './FeedbackPanel';
//import AdminCheck from "./AdminCheck";

// TabPanel component
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`admin-tabpanel-${index}`}
      aria-labelledby={`admin-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState(0);
  
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Container maxWidth="xl">
      <Paper elevation={0} sx={{ mb: 3, p: 3, bgcolor: 'background.default' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Admin Dashboard
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Manage users, validate data, monitor analytics, and review feedback.
        </Typography>
      </Paper>

      <Paper sx={{ width: '100%', mb: 2 }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="admin dashboard tabs"
          variant="fullWidth"
        >
          <Tab 
            icon={<GroupIcon />} 
            label="User Management" 
            id="admin-tab-0"
          />
          <Tab 
            icon={<DataIcon />} 
            label="Data Validation" 
            id="admin-tab-1"
          />
          <Tab 
            icon={<AnalyticsIcon />} 
            label="Analytics" 
            id="admin-tab-2"
          />
          <Tab 
            icon={<FeedbackIcon />} 
            label="Feedback" 
            id="admin-tab-3"
          />
        </Tabs>
      </Paper>

      <TabPanel value={activeTab} index={0}>
        <UserManagementPanel />
      </TabPanel>
      
      <TabPanel value={activeTab} index={1}>
        <ValidationSuite />
      </TabPanel>
      
      <TabPanel value={activeTab} index={2}>
        <AnalyticsPanel />
      </TabPanel>

      <TabPanel value={activeTab} index={3}>
        <FeedbackPanel />
      </TabPanel>
    </Container>
  );
};

export default AdminDashboard;