import React, { useState, useEffect } from 'react';
import { 
  Alert,
  AlertTitle,
  Button,
  Box,
  Typography
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

// Higher-order component to handle IndexedDB errors
const withIndexedDBErrorHandler = (WrappedComponent) => {
  return function WithIndexedDBErrorHandler(props) {
    const [hasError, setHasError] = useState(false);
    
    useEffect(() => {
      // Listen for IndexedDB errors
      const handleError = (event) => {
        if (event.target instanceof IDBDatabase || 
            event.target instanceof IDBRequest ||
            event.target instanceof IDBTransaction) {
          setHasError(true);
        }
      };

      // Set up error listeners
      window.addEventListener('error', handleError);
      
      // Periodically check IndexedDB connection
      const checkConnection = async () => {
        try {
          const request = indexedDB.open('connection-test', 1);
          request.onerror = () => setHasError(true);
          request.onsuccess = () => {
            const db = request.result;
            db.close();
            indexedDB.deleteDatabase('connection-test');
          };
        } catch (error) {
          setHasError(true);
        }
      };

      const interval = setInterval(checkConnection, 60000); // Check every minute

      return () => {
        window.removeEventListener('error', handleError);
        clearInterval(interval);
      };
    }, []);

    const handleRefresh = () => {
      setHasError(false);
      window.location.reload();
    };

    if (hasError) {
      return (
        <Box sx={{ p: 3 }}>
          <Alert 
            severity="error"
            sx={{
              '& .MuiAlert-message': {
                width: '100%'
              }
            }}
          >
            <AlertTitle sx={{ fontWeight: 600 }}>
              Connection Error
            </AlertTitle>
            <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
              The application has lost its database connection. This can happen after long periods of inactivity.
            </Typography>
            <Button
              variant="outlined"
              color="error"
              startIcon={<RefreshIcon />}
              onClick={handleRefresh}
              sx={{ mt: 1 }}
            >
              Refresh Page
            </Button>
          </Alert>
        </Box>
      );
    }

    return <WrappedComponent {...props} />;
  };
};

export default withIndexedDBErrorHandler;