import React from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  IconButton,
  Tooltip
} from '@mui/material';
import { 
  UnfoldLess, 
  UnfoldMore 
} from '@mui/icons-material';

const PageLayout = ({ 
  title,
  description,
  children,
  expandedSections,
  onExpandAll,
  onCollapseAll,
  showExpand = false,
}) => {
  const isAllExpanded = expandedSections && 
    Object.values(expandedSections).every(value => value === true);

  return (
    <Container 
      maxWidth={false} 
      disableGutters
      sx={{ 
        mt: 0, 
        mb: 4,
        pl: 3,
        pr: '10px',
        maxWidth: '100%',
        boxSizing: 'border-box',
        overflow: 'visible', 
        position: 'relative'
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 2,
        pt: 2
      }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
        
        {showExpand && (
          <Tooltip 
            title={isAllExpanded ? "Collapse all sections" : "Expand all sections"}
          >
            <IconButton 
              onClick={isAllExpanded ? onCollapseAll : onExpandAll}
              size="large"
              sx={{ 
                color: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.light',
                  color: 'primary.dark',
                }
              }}
            >
              {isAllExpanded ? <UnfoldLess /> : <UnfoldMore />}
            </IconButton>
          </Tooltip>
        )}
      </Box>
      
      {description && (
        <Typography variant="body1" sx={{ mb: 4, color: 'text.secondary' }}>
          {description}
        </Typography>
      )}

      {children}
    </Container>
  );
};

export default PageLayout;