// src/hooks/usePrivacyConsent.js
import { useState, useEffect, useCallback } from 'react';
import consentStore from '../stores/consentStore';
import { useAuth } from '../contexts/AuthContext';

const CONSENT_CHECK_INTERVAL = 5 * 60 * 1000;

export const usePrivacyConsent = () => {
  const { currentUser } = useAuth();
  const [analyticsAllowed, setAnalyticsAllowed] = useState(false);
  const [showBanner, setShowBanner] = useState(consentStore.shouldShowBanner());
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const checkGPCSignal = useCallback(() => {
    return navigator.globalPrivacyControl !== undefined ? 
      navigator.globalPrivacyControl : false;
  }, []);

  const handleConsentChoice = useCallback(async (consentData) => {
    try {
      const updatedConsent = await consentStore.updateConsent(
        currentUser?.uid,
        consentData
      );
      
      setAnalyticsAllowed(updatedConsent.analyticsAllowed);
      setShowBanner(false);
      setSnackbarMessage(updatedConsent.analyticsAllowed ? 
        'Analytics cookies accepted. Thank you for helping us improve our service.' :
        'Analytics cookies declined. Only essential cookies will be used.'
      );
    } catch (error) {
      console.error('Error updating consent:', error);
      setSnackbarMessage('Error saving privacy settings. Please try again.');
    }
  }, [currentUser]);

  useEffect(() => {
    const initializeConsent = async () => {
      try {
        const storedConsent = await consentStore.initializeConsent(currentUser?.uid);
        const gpcEnabled = checkGPCSignal();

        if (gpcEnabled) {
          await handleConsentChoice({
            analyticsAllowed: false,
            gpcEnforced: true
          });
          setShowBanner(false);
        } else if (storedConsent) {
          setAnalyticsAllowed(storedConsent.analyticsAllowed);
          setShowBanner(false);
        } else {
          setAnalyticsAllowed(false);
          setShowBanner(true);
        }
      } catch (error) {
        console.error('Error initializing consent:', error);
        setShowBanner(true);
      }
    };

    initializeConsent();

    const intervalId = setInterval(() => {
      const gpcEnabled = checkGPCSignal();
      if (gpcEnabled && analyticsAllowed) {
        handleConsentChoice({
          analyticsAllowed: false,
          gpcEnforced: true
        });
      }
    }, CONSENT_CHECK_INTERVAL);

    return () => clearInterval(intervalId);
  }, [currentUser, checkGPCSignal, handleConsentChoice, analyticsAllowed]);

  return {
    analyticsAllowed,
    showBanner,
    snackbarMessage,
    setSnackbarMessage,
    applyConsent: handleConsentChoice,
    setShowBanner
  };
};