import React, { useState, useEffect } from 'react';
import { schoolsApi } from '../../../../utils/apiService';
import {
  Card,
  CardContent,
  Typography,
  Tabs,
  Tab,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  CircularProgress,
  Alert,
  Chip,
  Grid,
  Tooltip,
  IconButton
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { formatGradeForDisplay } from '../../../../utils/projectionsUtils';
import useSchoolStore from '../../../../stores/schoolStore';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`projections-tabpanel-${index}`}
      aria-labelledby={`projections-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const getCategoryDescription = (category) => {
    const descriptions = {
      CONTINUOUS_STABLE: "Data available in 2019-20 and the grades with data have not changed in 2023-24.",
      CONTINUOUS_INCREASING: "Data available in 2019-20 with more grades in 2023-24 than 2019-20.",
      CONTINUOUS_DECREASING: "Data available in 2019-20, with fewer grades in 2023-24 than 2019-20.",
      NEW: "Open with only data in 2023-24.",
      NEWER_INCREASING: "Data becomes available between 2020-21 and 2022-23 with increasing number of grades over time.",
      NEWER_DECREASING: "Data becomes available between 2020-21 and 2022-23 with decreasing number of grades over time.",
      NEWER_STABLE: "Data becomes available between 2020-21 and 2022-23 with stable number of grades over time."
    };
    return descriptions[category] || "Category description not available";
  };

const ProjectionsValidation = ({ school }) => {
  const [enrollmentData, setEnrollmentData] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedGrades, setSelectedGrades] = useState([]);
  
  const schoolData = useSchoolStore(state => 
    state.schoolsEnrollmentData.find(s => s.ncessch === school.ncessch)
  );
  console.log('School Store Data:', {
    schoolNcessch: school.ncessch,
    schoolsEnrollmentData: useSchoolStore(state => state.schoolsEnrollmentData),
    foundSchoolData: schoolData
  });

  // Helper functions for grade data processing
  const getCurrentGrades = () => {
    if (!enrollmentData?.actual_enrollment) return [];
    
    // Get the most recent year from the data
    const years = Object.keys(enrollmentData.actual_enrollment).sort();
    const mostRecentYear = years[years.length - 1];
    
    if (!mostRecentYear) return [];
    
    return Object.entries(enrollmentData.actual_enrollment[mostRecentYear])
      .filter(([_, value]) => value > 0)
      .map(([grade]) => grade)
      .sort((a, b) => {
        if (a === 'Kindergarten') return -1;
        if (b === 'Kindergarten') return 1;
        return parseInt(a) - parseInt(b);
      });
  };

  const getAvailableSurvivalRates = (grade) => {
    const rates = [];
    if (enrollmentData?.survivalRates?.oneYear?.[grade]) rates.push('One Year');
    if (enrollmentData?.survivalRates?.threeYear?.[grade]) rates.push('Three Year');
    if (enrollmentData?.survivalRates?.fiveYear?.[grade]) rates.push('Five Year');
    return rates;
  };

  const handleGradeSelection = (grade) => {
    setSelectedGrades(prev => {
      if (prev.includes(grade)) {
        return prev.filter(g => g !== grade);
      } else {
        return [...prev, grade].sort((a, b) => {
          if (a === 'Kindergarten') return -1;
          if (b === 'Kindergarten') return 1;
          return parseInt(a) - parseInt(b);
        });
      }
    });
  };

  useEffect(() => {
    const fetchProjectionsData = async () => {
      try {
        setLoading(true);
        const data = await schoolsApi.getEnrollmentProjections(school.ncessch);
        setEnrollmentData(data);
        setError(null);
      } catch (err) {
        console.error('Error fetching projections:', err);
        setError(err.message || 'Failed to fetch projections data');
      } finally {
        setLoading(false);
      }
    };

    if (school?.ncessch) {
      fetchProjectionsData();
    }
  }, [school]);

  const renderHistoricalData = () => {
    if (!enrollmentData?.actual_enrollment) return null;

    const years = Object.keys(enrollmentData.actual_enrollment).sort();
    const allGrades = new Set();
    
    // Collect all grades
    years.forEach(year => {
      Object.entries(enrollmentData.actual_enrollment[year]).forEach(([grade, value]) => {
        if (value !== -1) {
          allGrades.add(grade);
        }
      });
    });

    // Filter and sort grades
    const grades = Array.from(allGrades)
      .filter(grade => selectedGrades.length === 0 || selectedGrades.includes(grade))
      .sort((a, b) => {
        if (a === 'Kindergarten') return -1;
        if (b === 'Kindergarten') return 1;
        return parseInt(a) - parseInt(b);
      });

    return (
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell 
                sx={{ 
                  fontWeight: 'bold', 
                  backgroundColor: 'primary.main', 
                  color: 'primary.contrastText',
                  minWidth: '120px'
                }}
              >
                Grade
              </TableCell>
              {years.map(year => (
                <TableCell 
                  key={year}
                  align="right"
                  sx={{ 
                    fontWeight: 'bold', 
                    backgroundColor: 'primary.main', 
                    color: 'primary.contrastText',
                    minWidth: '100px'
                  }}
                >
                  {year}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {grades.map(grade => (
              <TableRow 
                key={grade} 
                hover
                sx={{ 
                  bgcolor: selectedGrades.includes(grade) ? 'primary.light' : 'inherit'
                }}
                onClick={() => handleGradeSelection(grade)}
              >
                <TableCell 
                  component="th" 
                  scope="row" 
                  sx={{ fontWeight: 'bold', position: 'sticky', left: 0 }}
                >
                  {formatGradeForDisplay(grade)}
                </TableCell>
                {years.map(year => {
                  const value = enrollmentData.actual_enrollment[year][grade];
                  return (
                    <TableCell key={`${grade}-${year}`} align="right">
                      {value === -1 ? 'N/A' : value?.toLocaleString() || '-'}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
            <TableRow 
              sx={{ 
                bgcolor: 'grey.100',
                '& td, & th': {
                  fontWeight: 'bold',
                }
              }}
            >
              <TableCell>Total</TableCell>
              {years.map(year => {
                const yearData = enrollmentData.actual_enrollment[year];
                const total = Object.entries(yearData)
                  .filter(([grade, value]) => 
                    value !== -1 && 
                    (selectedGrades.length === 0 || selectedGrades.includes(grade))
                  )
                  .reduce((sum, [_, value]) => sum + (value || 0), 0);
                return (
                  <TableCell key={year} align="right">
                    {total.toLocaleString()}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderSurvivalRates = () => {
    if (!enrollmentData?.survivalRates || !enrollmentData?.forecastSurvivalRates) {
      return (
        <Alert severity="warning">
          No survival rates data available for this school
        </Alert>
      );
    }

    const grades = Object.keys(enrollmentData.survivalRates.oneYear || {})
      .filter(grade => selectedGrades.length === 0 || selectedGrades.includes(grade))
      .sort((a, b) => {
        if (a === 'Kindergarten') return -1;
        if (b === 'Kindergarten') return 1;
        return parseInt(a) - parseInt(b);
      });

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {/* Historical Survival Rates */}
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom color="primary">
              Historical Survival Rates
            </Typography>
            <Typography variant="body2" color="text.secondary" paragraph>
              Shows the calculated survival rates between grades based on historical data.
              One-year rates use the most recent transition, three-year rates use the average of the last three years,
              and five-year rates use the average of the last five years.
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'grey.100' }}>Grade</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: 'grey.100' }}>One Year</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: 'grey.100' }}>Three Year</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: 'grey.100' }}>Five Year</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {grades.map(grade => {
                    const isEntryGrade = grade === enrollmentData.entryGrade;
                    return (
                      <TableRow 
                        key={grade} 
                        hover
                        sx={{ bgcolor: selectedGrades.includes(grade) ? 'primary.light' : 'inherit' }}
                        onClick={() => handleGradeSelection(grade)}
                      >
                        <TableCell 
                          component="th" 
                          scope="row" 
                          sx={{ fontWeight: 'bold' }}
                        >
                          {formatGradeForDisplay(grade)}
                          {isEntryGrade && ' (Entry Grade)'}
                        </TableCell>
                        <TableCell align="right">
                          {isEntryGrade ? '-' : 
                            (enrollmentData.survivalRates.oneYear?.[grade]?.toFixed(3) || '-')}
                        </TableCell>
                        <TableCell align="right">
                          {isEntryGrade ? '-' : 
                            (enrollmentData.survivalRates.threeYear?.[grade]?.toFixed(3) || '-')}
                        </TableCell>
                        <TableCell align="right">
                          {isEntryGrade ? '-' : 
                            (enrollmentData.survivalRates.fiveYear?.[grade]?.toFixed(3) || '-')}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow sx={{ bgcolor: 'grey.100' }}>
                    <TableCell sx={{ fontWeight: 'bold' }}>Average</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                      {(grades
                        .map(grade => enrollmentData.forecastSurvivalRates[grade]?.min || 0)
                        .reduce((sum, rate) => sum + rate, 0) / grades.length).toFixed(3)}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                      {(grades
                        .map(grade => enrollmentData.forecastSurvivalRates[grade]?.median || 0)
                        .reduce((sum, rate) => sum + rate, 0) / grades.length).toFixed(3)}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                      {(grades
                        .map(grade => enrollmentData.forecastSurvivalRates[grade]?.max || 0)
                        .reduce((sum, rate) => sum + rate, 0) / grades.length).toFixed(3)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>

        {/* Forecast Survival Rates */}
        <Card>
        <CardContent>
            <Typography variant="h6" gutterBottom color="primary">
            Forecast Survival Rates
            </Typography>
            <Typography variant="body2" color="text.secondary" paragraph>
            Shows the survival rates that will be used for generating projections, based on historical patterns.
            These rates represent the expected range of grade-to-grade transitions.
            </Typography>
            <TableContainer>
            <Table size="small">
                <TableHead>
                <TableRow>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'grey.100' }}>Grade</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: 'grey.100' }}>Min</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: 'grey.100' }}>Median</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: 'grey.100' }}>Max</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {grades.map(grade => {
                    const isEntryGrade = grade === enrollmentData.entryGrade;
                    const forecastRates = enrollmentData.forecastSurvivalRates?.[grade] || {};
                    
                    return (
                    <TableRow 
                        key={grade}
                        hover
                        sx={{ bgcolor: selectedGrades.includes(grade) ? 'primary.light' : 'inherit' }}
                        onClick={() => handleGradeSelection(grade)}
                    >
                        <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                        {formatGradeForDisplay(grade)}
                        {isEntryGrade && ' (Entry Grade)'}
                        </TableCell>
                        <TableCell align="right">
                        {isEntryGrade ? '-' : (forecastRates.min?.toFixed(3) || '-')}
                        </TableCell>
                        <TableCell align="right">
                        {isEntryGrade ? '-' : (forecastRates.median?.toFixed(3) || '-')}
                        </TableCell>
                        <TableCell align="right">
                        {isEntryGrade ? '-' : (forecastRates.max?.toFixed(3) || '-')}
                        </TableCell>
                    </TableRow>
                    );
                })}
                <TableRow sx={{ bgcolor: 'grey.100' }}>
                    <TableCell sx={{ fontWeight: 'bold' }}>Average</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {(grades
                        .filter(grade => grade !== enrollmentData.entryGrade)
                        .map(grade => enrollmentData.forecastSurvivalRates?.[grade]?.min || 0)
                        .reduce((sum, rate) => sum + rate, 0) / 
                        (grades.length - (enrollmentData.entryGrade ? 1 : 0))).toFixed(3)}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {(grades
                        .filter(grade => grade !== enrollmentData.entryGrade)
                        .map(grade => enrollmentData.forecastSurvivalRates?.[grade]?.median || 0)
                        .reduce((sum, rate) => sum + rate, 0) / 
                        (grades.length - (enrollmentData.entryGrade ? 1 : 0))).toFixed(3)}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {(grades
                        .filter(grade => grade !== enrollmentData.entryGrade)
                        .map(grade => enrollmentData.forecastSurvivalRates?.[grade]?.max || 0)
                        .reduce((sum, rate) => sum + rate, 0) / 
                        (grades.length - (enrollmentData.entryGrade ? 1 : 0))).toFixed(3)}
                    </TableCell>
                </TableRow>
                </TableBody>
            </Table>
            </TableContainer>
        </CardContent>
        </Card>

        {/* Entry Grade Estimates */}
        {enrollmentData.entryGrade && (
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom color="primary">
                Entry Grade Estimates ({formatGradeForDisplay(enrollmentData.entryGrade)})
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                Shows the estimated enrollment range for the entry grade, based on historical patterns.
                These estimates are used as the starting point for projections.
              </Typography>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'grey.100' }}>Metric</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'grey.100' }}>Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>Low Estimate</TableCell>
                      <TableCell>{enrollmentData.entryGradeEstimates?.low}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>Median Estimate</TableCell>
                      <TableCell>{enrollmentData.entryGradeEstimates?.median}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>High Estimate</TableCell>
                      <TableCell>{enrollmentData.entryGradeEstimates?.high}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        )}
      </Box>
    );
  };

  const renderProjections = () => {
    if (!enrollmentData?.projections) return null;

    const years = Object.keys(enrollmentData.projections.median).sort();
    const grades = Object.keys(enrollmentData.projections.median[years[0]] || {})
      .filter(grade => selectedGrades.length === 0 || selectedGrades.includes(grade))
      .sort((a, b) => {
        if (a === 'Kindergarten') return -1;
        if (b === 'Kindergarten') return 1;
        return parseInt(a) - parseInt(b);
      });

    const scenarios = ['outer_min', 'min', 'median', 'max', 'outer_max'];

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {years.map(year => (
          <Card key={year}>
            <CardContent>
              <Typography variant="h6" gutterBottom color="primary">
                {year} Projections
              </Typography>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'grey.100' }}>
                        Grade
                      </TableCell>
                      {scenarios.map(scenario => (
                        <TableCell 
                          key={scenario}
                          align="right"
                          sx={{ fontWeight: 'bold', backgroundColor: 'grey.100' }}
                        >
                          {scenario.replace(/_/g, ' ').toUpperCase()}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {grades.map(grade => (
                      <TableRow 
                        key={grade} 
                        hover
                        sx={{ bgcolor: selectedGrades.includes(grade) ? 'primary.light' : 'inherit' }}
                        onClick={() => handleGradeSelection(grade)}
                      >
                        <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                          {formatGradeForDisplay(grade)}
                        </TableCell>
                        {scenarios.map(scenario => (
                          <TableCell key={`${grade}-${scenario}`} align="right">
                            {enrollmentData.projections[scenario]?.[year]?.[grade]?.toLocaleString() || '-'}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                    <TableRow sx={{ bgcolor: 'grey.100' }}>
                      <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
                      {scenarios.map(scenario => {
                        const total = grades.reduce((sum, grade) => 
                          sum + (enrollmentData.projections[scenario]?.[year]?.[grade] || 0), 0);
                        return (
                          <TableCell key={`total-${scenario}`} align="right" sx={{ fontWeight: 'bold' }}>
                            {total.toLocaleString()}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        ))}
      </Box>
    );
  };

  const renderSchoolOverview = () => {
    if (!enrollmentData || !school) return null;
  
    // Get years of data
    const years = Object.keys(enrollmentData.actual_enrollment || {}).sort();
    const mostRecentYear = years[years.length - 1];
    const currentGrades = getCurrentGrades();
  
    // Get data availability for each grade
    const gradeDataAvailability = {};
    currentGrades.forEach(grade => {
      const yearsWithData = years.filter(year => 
        enrollmentData.actual_enrollment[year][grade] > 0
      );
      gradeDataAvailability[grade] = yearsWithData;
    });
  
    // Calculate survival rate availability for each grade
    const survivalRateTypes = {};
    currentGrades.forEach(grade => {
      const rates = getAvailableSurvivalRates(grade);
      survivalRateTypes[grade] = rates;
    });
  
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom color="primary">
              School Overview
            </Typography>
            
            {/* Basic Info */}
            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary">School Name</Typography>
                <Typography paragraph>{school.name}</Typography>
  
                <Typography variant="subtitle2" color="text.secondary">
                  Current Grades ({mostRecentYear})
                </Typography>
                <Typography paragraph>
                  {currentGrades.map(grade => formatGradeForDisplay(grade)).join(', ')}
                </Typography>
  
                <Typography variant="subtitle2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                    School Category
                    {schoolData?.category && (
                        <Tooltip title={getCategoryDescription(schoolData.category)} arrow>
                        <IconButton size="small" sx={{ ml: 0.5 }}>
                            <InfoIcon fontSize="small" />
                        </IconButton>
                        </Tooltip>
                    )}
                    </Typography>
                    <Typography paragraph>
                    {schoolData?.category?.split('_').map(word => 
                        word.charAt(0) + word.slice(1).toLowerCase()
                    ).join(' ') || 'Unknown'}
                    </Typography>
              </Grid>
  
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary">Data Overview</Typography>
                <Typography paragraph>
                  {years.length} years of historical data ({years[0]} to {years[years.length - 1]})
                </Typography>
              </Grid>
            </Grid>
  
            {/* Data Availability Table */}
            <Typography variant="subtitle1" gutterBottom color="primary" sx={{ mt: 2 }}>
              Grade-Level Data Availability
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Grade</TableCell>
                    <TableCell>Years of Data</TableCell>
                    <TableCell>Available Survival Rates</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentGrades.map(grade => (
                    <TableRow key={grade}>
                      <TableCell>{formatGradeForDisplay(grade)}</TableCell>
                      <TableCell>{gradeDataAvailability[grade].length} years</TableCell>
                      <TableCell>
                        {survivalRateTypes[grade].length > 0 
                          ? survivalRateTypes[grade].join(', ')
                          : 'None (Entry Grade)'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
  
        {/* Enrollment Summary */}
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom color="primary">
              Historical and Projected (Median) Enrollment
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell 
                      sx={{ 
                        fontWeight: 'bold',
                        backgroundColor: 'primary.main',
                        color: 'primary.contrastText'
                      }}
                    >
                      Grade
                    </TableCell>
                    {/* Historical Years */}
                    {years.map(year => (
                      <TableCell 
                        key={year}
                        align="right"
                        sx={{ 
                          fontWeight: 'bold',
                          backgroundColor: 'primary.main',
                          color: 'primary.contrastText'
                        }}
                      >
                        {year}
                      </TableCell>
                    ))}
                    {/* Projected Years */}
                    {Object.keys(enrollmentData.projections?.median || {}).sort().map(year => (
                      <TableCell 
                        key={year}
                        align="right"
                        sx={{ 
                          fontWeight: 'bold',
                          backgroundColor: 'grey.300'
                        }}
                      >
                        {year} (Proj.)
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentGrades.map(grade => (
                    <TableRow key={grade}>
                      <TableCell sx={{ fontWeight: 'bold' }}>
                        {formatGradeForDisplay(grade)}
                      </TableCell>
                      {/* Historical Values */}
                      {years.map(year => (
                        <TableCell key={year} align="right">
                          {enrollmentData.actual_enrollment[year][grade] > 0 
                            ? enrollmentData.actual_enrollment[year][grade].toLocaleString()
                            : '-'}
                        </TableCell>
                      ))}
                      {/* Projected Values */}
                      {Object.keys(enrollmentData.projections?.median || {}).sort().map(year => (
                        <TableCell key={year} align="right">
                          {(enrollmentData.projections.median[year][grade] || '-').toLocaleString()}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Box>
    );
  };

  const renderCalculations = () => {
    if (!enrollmentData) return null;

    const grades = (selectedGrades.length > 0 ? selectedGrades : getCurrentGrades())
      .sort((a, b) => {
        if (a === 'Kindergarten') return -1;
        if (b === 'Kindergarten') return 1;
        return parseInt(a) - parseInt(b);
      });

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {/* Entry Grade Calculation */}
        {grades.includes(enrollmentData.entryGrade) && (
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom color="primary">
                Entry Grade Calculation Method
              </Typography>
              <Typography variant="body2" paragraph>
                Entry Grade ({formatGradeForDisplay(enrollmentData.entryGrade)}) enrollment is estimated using:
              </Typography>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Metric</TableCell>
                      <TableCell align="right">Value</TableCell>
                      <TableCell>Calculation Method</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Historical Minimum</TableCell>
                      <TableCell align="right">{enrollmentData.entryGradeEstimates?.low}</TableCell>
                      <TableCell>Lowest historical enrollment</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Historical Maximum</TableCell>
                      <TableCell align="right">{enrollmentData.entryGradeEstimates?.high}</TableCell>
                      <TableCell>Highest historical enrollment</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Historical Median</TableCell>
                      <TableCell align="right">{enrollmentData.entryGradeEstimates?.median}</TableCell>
                      <TableCell>Median of all historical values</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        )}

        {/* Grade-by-Grade Calculations */}
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom color="primary">
              Grade-by-Grade Calculation Method
            </Typography>
            
            {grades.map(grade => {
              if (grade === enrollmentData.entryGrade) return null;
              
              const rates = {
                oneYear: enrollmentData.survivalRates?.oneYear?.[grade],
                threeYear: enrollmentData.survivalRates?.threeYear?.[grade],
                fiveYear: enrollmentData.survivalRates?.fiveYear?.[grade]
              };

              const forecastRates = enrollmentData.forecastSurvivalRates?.[grade] || {};

              return (
                <Box key={grade} sx={{ mb: 4 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    {formatGradeForDisplay(grade)}
                  </Typography>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Rate Type</TableCell>
                          <TableCell align="right">Value</TableCell>
                          <TableCell>Used In</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.entries(rates).map(([type, value]) => (
                          <TableRow key={type}>
                            <TableCell>{type.replace(/([A-Z])/g, ' $1')}</TableCell>
                            <TableCell align="right">{value?.toFixed(3) || '-'}</TableCell>
                            <TableCell>Historical calculation</TableCell>
                          </TableRow>
                        ))}
                        <TableRow sx={{ bgcolor: 'grey.50' }}>
                          <TableCell colSpan={3}>Resulting Forecast Rates:</TableCell>
                        </TableRow>
                        {Object.entries(forecastRates)
                          .filter(([key]) => ['min', 'median', 'max'].includes(key))
                          .map(([type, value]) => (
                            <TableRow key={type}>
                              <TableCell>{type.toUpperCase()}</TableCell>
                              <TableCell align="right">{value?.toFixed(3) || '-'}</TableCell>
                              <TableCell>Used in projections</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              );
            })}
          </CardContent>
        </Card>
      </Box>
    );
  };

  const renderAnalysis = () => {
    if (!enrollmentData) return null;

    const grades = selectedGrades.length > 0 ? selectedGrades : getCurrentGrades();

    // Find historical min/max values for each grade
    const gradeStats = {};
    Object.entries(enrollmentData.actual_enrollment || {}).forEach(([year, yearData]) => {
      Object.entries(yearData).forEach(([grade, value]) => {
        if (grades.includes(grade) && value !== -1) {
          if (!gradeStats[grade]) {
            gradeStats[grade] = { min: Infinity, max: -Infinity, values: [] };
          }
          gradeStats[grade].values.push(value);
          gradeStats[grade].min = Math.min(gradeStats[grade].min, value);
          gradeStats[grade].max = Math.max(gradeStats[grade].max, value);
        }
      });
    });

    // Find projections outside historical ranges
    const anomalies = [];
    Object.entries(enrollmentData.projections?.median || {}).forEach(([year, yearData]) => {
      Object.entries(yearData).forEach(([grade, value]) => {
        if (grades.includes(grade)) {
          const stats = gradeStats[grade];
          if (stats && (value < stats.min || value > stats.max)) {
            anomalies.push({
              year,
              grade,
              value,
              historicalMin: stats.min,
              historicalMax: stats.max,
              type: value < stats.min ? 'low' : 'high'
            });
          }
        }
      });
    });

    // Calculate grade-to-grade transitions
    const transitions = [];
    Object.entries(enrollmentData.survivalRates?.oneYear || {}).forEach(([grade, rate]) => {
      if (grades.includes(grade) && (rate < 0.8 || rate > 1.2)) {
        transitions.push({
          grade,
          rate,
          type: rate < 0.8 ? 'low' : 'high'
        });
      }
    });

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {/* Projection Anomalies */}
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom color="primary">
              Projection Anomalies
            </Typography>
            {anomalies.length > 0 ? (
              anomalies.map((anomaly, index) => (
                <Alert 
                  key={index}
                  severity={anomaly.type === 'low' ? 'warning' : 'info'}
                  sx={{ mb: 1 }}
                >
                  {formatGradeForDisplay(anomaly.grade)} in {anomaly.year}: Projected {anomaly.value} students 
                  is {anomaly.type === 'low' ? 'below' : 'above'} historical range 
                  ({anomaly.historicalMin} - {anomaly.historicalMax})
                </Alert>
              ))
            ) : (
              <Alert severity="success">
                All projections fall within historical ranges
              </Alert>
            )}
          </CardContent>
        </Card>

        {/* Unusual Grade Transitions */}
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom color="primary">
                Outlier Survival Rates
                </Typography>
                <Typography variant="body2" color="text.secondary" paragraph>
                Flags any one-year grade-to-grade survival rates that fall below 80% or exceed 120% of the previous year's enrollment.
                </Typography>
                {transitions.length > 0 ? (
                transitions.map((transition, index) => (
                    <Alert 
                    key={index}
                    severity={transition.type === 'low' ? 'warning' : 'info'}
                    sx={{ mb: 1 }}
                    >
                    {formatGradeForDisplay(transition.grade)}: {(transition.rate * 100).toFixed(1)}% survival rate is 
                    {transition.type === 'low' ? ' unusually low' : ' unusually high'}
                    </Alert>
                ))
                ) : (
                <Alert severity="success">
                    All grade-to-grade transitions appear normal (between 80% and 120%)
                </Alert>
                )}
            </CardContent>
            </Card>


        {/* Stability Analysis */}
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom color="primary">
                Grade Level Stability
                </Typography>
                <Typography variant="body2" color="text.secondary" paragraph>
                Measures enrollment stability over time for each grade. Grades are flagged for high variability when their 
                coefficient of variation (standard deviation / mean * 100) exceeds 15%.
                </Typography>
                <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Grade</TableCell>
                    <TableCell align="right">Min</TableCell>
                    <TableCell align="right">Max</TableCell>
                    <TableCell align="right">Average</TableCell>
                    <TableCell align="right">Variation</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(gradeStats)
                    .filter(([grade]) => grades.includes(grade))
                    .map(([grade, stats]) => {
                      const mean = stats.values.reduce((a, b) => a + b, 0) / stats.values.length;
                      const variance = Math.sqrt(
                        stats.values.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0) / stats.values.length
                      );
                      const variationCoeff = (variance / mean) * 100;

                      return (
                        <TableRow 
                          key={grade}
                          hover
                          sx={{ bgcolor: selectedGrades.includes(grade) ? 'primary.light' : 'inherit' }}
                          onClick={() => handleGradeSelection(grade)}
                        >
                          <TableCell>{formatGradeForDisplay(grade)}</TableCell>
                          <TableCell align="right">{stats.min}</TableCell>
                          <TableCell align="right">{stats.max}</TableCell>
                          <TableCell align="right">{mean.toFixed(1)}</TableCell>
                          <TableCell align="right">{variationCoeff.toFixed(1)}%</TableCell>
                          <TableCell>
                            {variationCoeff > 15 ? (
                                <Chip 
                                label="High Variability" 
                                color="warning" 
                                size="small"
                                />
                            ) : (
                                <Chip 
                                label="Stable" 
                                color="success" 
                                size="small"
                                />
                            )}
                            </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Box>
    );
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 2 }}>
        Error loading projections data: {error}
      </Alert>
    );
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom color="primary">
          {school.name} - Enrollment Analysis
        </Typography>
        
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
            aria-label="enrollment data tabs"
            >
            <Tab label="Overview" />
            <Tab label="Historical Data" />
            <Tab label="Survival Rates" />
            <Tab label="Projections" />
            <Tab label="Calculations" />
            <Tab label="Analysis" />
            </Tabs>
        </Box>

        <TabPanel value={activeTab} index={0}>
            {renderSchoolOverview()}
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
            {renderHistoricalData()}
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
            {renderSurvivalRates()}
            </TabPanel>
            <TabPanel value={activeTab} index={3}>
            {renderProjections()}
            </TabPanel>
            <TabPanel value={activeTab} index={4}>
            {renderCalculations()}
            </TabPanel>
            <TabPanel value={activeTab} index={5}>
            {renderAnalysis()}
            </TabPanel>
      </CardContent>
    </Card>
  );
};

export default ProjectionsValidation;