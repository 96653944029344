import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia
} from '@mui/material';
import NavigationBar from './NavigationBar';
import SiteFooter from './SiteFooter';
import SchoolIcon from '@mui/icons-material/School';
import GroupsIcon from '@mui/icons-material/Groups';
import InsightsIcon from '@mui/icons-material/Insights';

function AboutUs() {
  const features = [
    {
      icon: <SchoolIcon sx={{ fontSize: 40, color: '#4F46E5' }} />,
      title: "Built by Education Leaders,\nfor Education Leaders",
      description: "Our founders drew from their own leadership experiences to create a tool that addresses the real challenges schools face."
    },
    {
      icon: <GroupsIcon sx={{ fontSize: 40, color: '#4F46E5' }} />,
      title: "Collaboratively Designed\nwith School Networks",
      description: "Partnering with active school leaders, we built a tool rooted in real-world needs and designed to drive practical solutions."
    },
    {
      icon: <InsightsIcon sx={{ fontSize: 40, color: '#4F46E5' }} />,
      title: "Empowering Smarter,\nSustainable Decisions",
      description: "By combining data-driven tools with intuitive design, EDC helps leaders confidently plan for growth and adapt to change."
    }
  ];

  const teamMembers = [
    {
      name: "Brian Eschbacher",
      title: "Co-Founder & CEO",
      image: "/images/eschbacher_headshot.png",
      bio: "Exploring the intersection of demography and school choice has been the core focus for Brian Eschbacher since entering education in 2011. Building upon six years of private sector management consulting experience, Brian joined Denver Public Schools (DPS) and served as Executive Director of Enrollment Planning & School Choice for seven years, when DPS was the fastest growing urban district in the country. Brian launched Eschbacher Consulting in 2018 and has created enrollment landscape and portfolio analyses in 27 cities for a variety of educational stakeholders to guide their future planning efforts.",
      stats: [
        { value: "15", label: "years", description: "working with school systems to support long-term planning goals" },
        { value: "27", label: "cities", description: "across the country to identify broader trends" }
      ]
    },
    {
      name: "Dr. Raja Ridgway",
      title: "Co-Founder & CTO",
      image: "/images/ridgway_headshot.jpg",
      bio: "Dr. Raja Ridgway has 16 years of experience in education, with the last five years specializing in data science and engineering. As CTO, he develops full-stack applications that help school systems and education organizations make data-driven decisions. His work includes building predictive enrollment models that integrate historical enrollment, demographic trends, and academic performance, as well as designing responsive web applications that make complex data accessible to education leaders. Raja has worked with enrollment, academic, behavior, staff, finance, HR, and operations data to support schools, funders, and adjacent organizations in long-term planning.",
      stats: [
        { value: "20", label: "schools", description: "supported with predictive models and data applications" },
        { value: "7+", label: "education data domains", description: "integrated into full-stack applications for strategic planning" }
      ]
    }
  ];

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <NavigationBar />

      {/* Mission Section */}
      <Container maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
        <Typography variant="h2" gutterBottom align="center" sx={{ fontWeight: 800 }}>
          Meet the Collective
        </Typography>
        <Typography variant="body1" sx={{ mb: 6 }}>
          The Education Data Collective was sparked by conversations with school and organizational leaders, hearing their challenges about long-term planning in a time of rapid population and competitive changes. Since 2011, enrollment planning has been the primary focus of our founders and is becoming increasingly important to education leaders because of the evolving landscape.
        </Typography>

        {/* Features */}
        <Grid container spacing={4} sx={{ mb: 8 }}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card 
                elevation={0} 
                sx={{ 
                  height: '100%',
                  backgroundColor: 'rgb(236, 235, 250)',
                  border: '1px solid rgba(0, 0, 0, 0.05)',
                  borderRadius: 2,
                  p: 2,
                  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)'
                  }
                }}
              >
                <CardContent sx={{ textAlign: 'center' }}>
                  <Box sx={{ mb: 2 }}>
                    {feature.icon}
                  </Box>
                  <Typography variant="h6" gutterBottom sx={{ 
                    fontWeight: 700,
                    whiteSpace: 'pre-line',
                    textAlign: 'center'
                  }}>
                    {feature.title}
                  </Typography>
                  <Typography variant="body2">
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Team Members */}
        <Typography variant="h3" gutterBottom sx={{ fontWeight: 800, mb: 4 }}>
          Meet Your Partners
        </Typography>
        {teamMembers.map((member, index) => (
          <Box key={index} sx={{ mb: 8 }}>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={4}>
                <CardMedia
                  component="img"
                  image={member.image}
                  alt={member.name}
                  sx={{ 
                    borderRadius: '50%',
                    width: '100%',
                    maxWidth: 400,
                    height: 'auto',
                    aspectRatio: '1/1',
                    objectFit: 'cover',
                    objectPosition: 'center 20%'
                  }}
                />
                <Typography variant="h4" sx={{ mt: 2, fontWeight: 700, textAlign: 'center' }}>
                  {member.name}
                </Typography>
                <Typography variant="h6" sx={{ textAlign: 'center', color: 'text.secondary' }}>
                  {member.title}
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Card elevation={0} sx={{ bgcolor: 'grey.50', mb: 4 }}>
                  <CardContent>
                    <Typography variant="body1">
                      {member.bio}
                    </Typography>
                  </CardContent>
                </Card>
                <Grid container spacing={4}>
                  {member.stats.map((stat, statIndex) => (
                    <Grid item xs={12} md={6} key={statIndex}>
                      <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="h2" sx={{ color: '#4F46E5', fontWeight: 700 }}>
                          {stat.value}
                        </Typography>
                        <Typography variant="h5" sx={{ color: '#4F46E5', mb: 1 }}>
                          {stat.label}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {stat.description}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Container>

      <SiteFooter />
    </Box>
  );
}

export default AboutUs;