import React, { useEffect, useRef } from 'react';
import { useInsight } from '../../contexts/InsightContext';

const SectionVisibilityTracker = ({ sectionId, isExpanded }) => {
  const sectionRef = useRef(null);
  const { setToolAndSection, currentTool } = useInsight();
  
  useEffect(() => {
    if (!isExpanded) return;

    const currentRef = sectionRef.current; // Store ref in a variable inside effect

    const observer = new IntersectionObserver(
      ([entry]) => {
        // Store the intersection ratio for this section
        if (currentRef) {
          currentRef.visibilityRatio = entry.intersectionRatio;
        }
        
        // Find all sections being tracked
        const allSections = document.querySelectorAll('[data-section-tracker]');
        let maxVisibility = 0;
        let mostVisibleSection = null;

        allSections.forEach(section => {
          if (section.visibilityRatio > maxVisibility) {
            maxVisibility = section.visibilityRatio;
            mostVisibleSection = section.dataset.sectionId;
          }
        });

        // Update insight content if this is the most visible section
        if (mostVisibleSection === sectionId) {
          setToolAndSection(currentTool, sectionId);
        }
      },
      {
        threshold: Array.from({ length: 20 }, (_, i) => i / 20), // Create thresholds at 5% intervals
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) { // Use the stored ref value
        observer.unobserve(currentRef);
      }
    };
  }, [sectionId, isExpanded, setToolAndSection, currentTool]);

  return (
    <div 
      ref={sectionRef}
      data-section-tracker
      data-section-id={sectionId}
      style={{ position: 'relative' }}
    />
  );
};

export default SectionVisibilityTracker;