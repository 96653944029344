import React, { useState, useEffect, Suspense, useMemo, useCallback, useRef } from 'react';
import { 
  Skeleton, Box
} from '@mui/material';
import {
  MapsHomeWork,
  AutoGraphOutlined,
  DescriptionOutlined,
  Explore,
  PeopleOutlined,
  GroupsOutlined,
  CompareArrowsOutlined
} from '@mui/icons-material';

// Shared Components
import PageLayout from '../shared_components/pageLayout';
import LoadingErrorWrapper from '../shared_components/loadingErrorWrapper';
import AccordionSection from '../shared_components/accordionSection';
import AccordionContainer from '../shared_components/accordionContainer';
import StickyFilters from '../shared_components/stickyFilters';
import InitialSchoolSelector from '../shared_components/initialSchoolSelector';
import NowWhatSection from '../shared_components/templates/nowWhatTemplate';
import CompXNearbyMapPopup from './nearby/CompXNearbyMapPopup';

// Custom Hook
import { useAccordionState } from '../../hooks/useAccordionState';

// Contexts
import { useInsight } from '../../contexts/InsightContext';

// Utils
import { filterSchools, getAvailableSchoolTypes, hasGradeOverlap } from '../../utils/schoolUtils';
import { formatSchoolName } from '../../utils/nameFormatter';

// Stores
import useSchoolStore from '../../stores/schoolStore';

// Components
import CompXCommunityHeadlines from './community/CompXCommunityHeadlines';

// Lazy load components
const CompXNearbyHeadlines = React.lazy(() => import('./nearby/CompXNearbyHeadlines'));
const CompXEnrollmentHeadlines = React.lazy(() => import('./enrollment/CompXEnrollmentHeadlines'));


function CompetitiveExplorer() {
  // Use school store directly
  const {
    schools,
    selectedSchool,
    esriData,
    nearbySchools,
    schoolsEnrollmentData,
    error,
    schoolsLoading,
    contentLoading
  } = useSchoolStore();

  // Context declarations
  const { setToolAndSection } = useInsight();
  const sectionRefs = useRef({});
  const observerRef = useRef(null);
  const prevNearbyProps = useRef({});
  const initialSchoolLoadRef = useRef(false);

  // State declarations
  const [governanceFilter, setGovernanceFilter] = useState('All');
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [selectedDriveTime, setSelectedDriveTime] = useState(10);
  const [schoolTypeFilter, setSchoolTypeFilter] = useState('All');
  const [availableSchoolTypes, setAvailableSchoolTypes] = useState(['All']);

  // Handle URL parameters after schools are loaded
  useEffect(() => {
    if (schools.length > 0 && !selectedSchool && !initialSchoolLoadRef.current) {
      const params = new URLSearchParams(window.location.search);
      const schoolId = params.get('school');
      
      if (schoolId) {
        const school = schools.find(s => s.ncessch === schoolId);
        if (school) {
          useSchoolStore.getState().selectSchool({
            ...school,
            name: formatSchoolName(school.name)
          });
        }
        initialSchoolLoadRef.current = true;
      }
    }
  }, [schools, selectedSchool]);

  // Initialize schools on mount
  useEffect(() => {
    if (schools.length === 0) {
      useSchoolStore.getState().fetchSchools();
    }
  }, [schools.length]);

  // Update tool and section in context
  useEffect(() => {
    // On mount, set the tool with null section to show default content
    setToolAndSection('competitive-explorer', null); 
    
    // On unmount, only clear if we're actually unmounting (not just switching tools)
    return () => {
      // Get all tool components that might be mounted
      const geoExplorerExists = document.querySelector('[data-tool="geographic-explorer"]');
      const compExplorerExists = document.querySelector('[data-tool="competitive-explorer"]');
      
      // Only clear if no tool components are present
      if (!geoExplorerExists && !compExplorerExists) {
        setToolAndSection(null, null);
      }
    };
  }, [setToolAndSection]);

  useEffect(() => {
    observerRef.current = new IntersectionObserver((entries) => {
      let maxVisibility = 0;
      let mostVisible = null;
  
      entries.forEach(entry => {
        const sectionId = entry.target.getAttribute('data-section-id');
        if (entry.intersectionRatio > maxVisibility) {
          maxVisibility = entry.intersectionRatio;
          mostVisible = sectionId;
        }
      });
  
      if (mostVisible && maxVisibility > 0.5) {
        setToolAndSection('competitive-explorer', mostVisible);
      }
    }, {
      threshold: [0, 0.5, 1],
      rootMargin: '0px 0px -10% 0px'
    });
  
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [setToolAndSection]);

  const getSectionRef = useCallback((sectionId) => (element) => {
    if (element) {
      sectionRefs.current[sectionId] = element;
      if (observerRef.current) {
        observerRef.current.observe(element);
      }
    }
  }, []);

  // Use accordion state hook
  const {
    expandedSections,
    handleExpandedChange,
    handleExpandAll,
    handleCollapseAll,
  } = useAccordionState({
    communityHeadlines: false,
    enrollmentTrends: false,
    nearby: false,
    nowWhat: false
  });

  // Modified accordion change handler
  const handleSectionChange = useCallback((sectionId) => (event, isExpanded) => {
    handleExpandedChange(sectionId)(event, isExpanded);
    if (isExpanded) {
      setToolAndSection('competitive-explorer', sectionId);
    } else if (!Object.values(expandedSections).some(expanded => expanded)) {
      setToolAndSection('competitive-explorer', null);  // Show default content when all sections closed
    }
  }, [handleExpandedChange, expandedSections, setToolAndSection]);


  // Initialize schools on mount
  useEffect(() => {
    if (schools.length === 0) {
      useSchoolStore.getState().fetchSchools();
    }
  }, [schools.length]);

  // Update available school types
  useEffect(() => {
    if (schoolsEnrollmentData.length > 0) {
      const types = getAvailableSchoolTypes(schoolsEnrollmentData);
      setAvailableSchoolTypes(types);
    }
  }, [schoolsEnrollmentData]);

  useEffect(() => {
    const hasExpandedSection = Object.values(expandedSections).some(isExpanded => isExpanded);
    if (!hasExpandedSection) {
      setToolAndSection('competitive-explorer', null);  // Keep tool, clear section
    }
  }, [expandedSections, setToolAndSection]);

  const filteredSchools = useMemo(() => {
    if (!selectedSchool || !nearbySchools) {
      return [];
    }
  
    // Don't filter if no grades selected yet
    if (!selectedGrades || selectedGrades.length === 0) {
      console.log('No grades selected yet, skipping filtering');
      return [];
    }
    
    // Get schools for selected drive time
    const schoolsForSelectedDriveTime = nearbySchools?.current?.[selectedDriveTime] || [];
    
    // Create array with selected school and nearby schools
    const allSchools = [
      { 
        ...selectedSchool, 
        drive_time: 0 
      }, 
      ...schoolsForSelectedDriveTime.map(school => ({
        ...school,
        drive_time: selectedDriveTime
      }))
    ];
  
    // Debug logging
    console.log('Before filterSchools:', allSchools.map(s => ({
      name: s.name,
      gradeRange: s.grade_range,
      selectedGrades,
      overlap: hasGradeOverlap(s.grade_range, selectedGrades.join(', '))
    })));
  
    const filtered = filterSchools(
      allSchools,
      {
        selectedGrades,
        governanceFilter,
        schoolTypeFilter
      },
      schoolsEnrollmentData
    );
  
    // Debug logging
    console.log('After filterSchools:', filtered.map(s => s.name));
  
    return filtered;
  }, [
    selectedSchool,
    nearbySchools,
    selectedDriveTime,
    selectedGrades,
    governanceFilter,
    schoolTypeFilter,
    schoolsEnrollmentData
  ]);

  useEffect(() => {
    const changedProps = {};
    const currentProps = {
      selectedSchool,
      filteredSchools,
      schoolsEnrollmentData,
      selectedGrades,
      selectedDriveTime,
      governanceFilter,
      schoolTypeFilter,
      nearbySchools,
      esriData
    };

    Object.entries(currentProps).forEach(([key, value]) => {
      if (prevNearbyProps.current[key] !== value) {
        changedProps[key] = key;
      }
    });

    prevNearbyProps.current = currentProps;
  }, [
    selectedSchool,
    filteredSchools,
    schoolsEnrollmentData,
    selectedGrades,
    selectedDriveTime,
    governanceFilter,
    schoolTypeFilter,
    nearbySchools,
    esriData
  ]);

  const handleSchoolChange = (event) => {
    const schoolId = event.target.value;
    const school = schools.find(s => s.ncessch === schoolId);
    if (school) {
      useSchoolStore.getState().selectSchool({
        ...school,
        name: formatSchoolName(school.name)
      });
    }
  };

  const handleGradeChange = (event) => {
    const value = event.target.value;
    setSelectedGrades(typeof value === 'string' ? value.split(',') : value);
  };

  const handleGovernanceChange = (event) => {
    setGovernanceFilter(event.target.value);
  };

  const handleDriveTimeChange = (event) => {
    setSelectedDriveTime(Number(event.target.value));
  };

  const handleSchoolTypeChange = (event) => {
    setSchoolTypeFilter(event.target.value);
  };

  // Define actions for Now What section
  const actions = [
    {
      icon: MapsHomeWork,
      title: 'Geographic Explorer',
      description: 'Explore demographic and enrollment trends in your community',
      path: '/geographic-explorer'
    },
    {
      icon: AutoGraphOutlined,
      title: 'Projections Explorer',
      description: 'Explore historical and projected enrollment data for the next five years',
      path: '/projections-explorer'
    },
    {
      icon: DescriptionOutlined,
      title: 'Data Reports',
      description: 'Download detailed reports, including Esri demographic data for selected schools',
      path: '/data-reports'
    }
  ];

  const nearbyHeadlinesProps = useMemo(() => ({
    selectedSchool,
    filteredSchools,
    schoolsEnrollmentData,
    selectedGrades,
    governanceFilter,
    schoolTypeFilter,
    nearbySchools,
    selectedDriveTime,
    esriData,
    visibleDriveTimes: [selectedDriveTime]
  }), [
    selectedSchool,
    filteredSchools,
    schoolsEnrollmentData,
    selectedGrades,
    governanceFilter,
    schoolTypeFilter,
    nearbySchools,
    selectedDriveTime,
    esriData
  ]);

  return (
    <PageLayout
      data-tool="competitive-explorer"
      title="Competitive Explorer"
      description={selectedSchool
        ? "Analyze market share data for the selected school. Use the filters to refine your analysis."
        : "Use this tool to analyze market share by students who reside and attend schools in your area. Start by selecting a school."
      }
    >
      <LoadingErrorWrapper
        loading={schoolsLoading}
        error={error}
        initialLoading={true}
      >
        {!selectedSchool ? (
          <InitialSchoolSelector
            schools={schools}
            selectedSchool={selectedSchool}
            onSchoolChange={handleSchoolChange}
          />
        ) : (
          <LoadingErrorWrapper
            loading={contentLoading}
            error={error}
            initialLoading={false}
            sx={{ 
              position: 'relative',
              height: '100%',
              overflow: 'visible'
            }}
          >
            <Box sx={{ position: 'relative' }}> 
              <StickyFilters
                schools={schools}
                selectedSchool={selectedSchool}
                governanceFilter={governanceFilter}
                selectedGrades={selectedGrades}
                selectedDriveTime={selectedDriveTime}
                schoolTypeFilter={schoolTypeFilter}
                availableSchoolTypes={availableSchoolTypes}
                onSchoolChange={handleSchoolChange}
                onGovernanceChange={handleGovernanceChange}
                onGradeChange={handleGradeChange}
                onDriveTimeChange={handleDriveTimeChange}
                onSchoolTypeChange={handleSchoolTypeChange}
                expandedSections={expandedSections}
                onExpandAll={handleExpandAll}
                onCollapseAll={handleCollapseAll}
                showSchoolType={true}
              />
    
              <AccordionContainer 
                expandedSections={expandedSections}
                onSectionChange={handleSectionChange}
              >
                <AccordionSection
                  id="communityHeadlines"
                  title="Your Community"
                  summary={`Share of estimated school-age children population within ${selectedDriveTime} minutes of ${selectedSchool?.name || 'the selected school'}`}
                  icon={PeopleOutlined}
                >
                  <div 
                    ref={getSectionRef("communityHeadlines")} 
                    data-section-id="communityHeadlines"
                  >
                    <Suspense fallback={<Skeleton variant="rectangular" height={200} />}>
                      <CompXCommunityHeadlines
                        selectedSchool={selectedSchool}
                        filteredSchools={filteredSchools}
                        selectedDriveTime={selectedDriveTime}
                        schoolsEnrollmentData={schoolsEnrollmentData}
                        selectedGrades={selectedGrades}
                        esriData={esriData}
                      />
                    </Suspense>
                  </div>
                </AccordionSection>
    
                <AccordionSection
                  id="enrollmentTrends"
                  title="Enrollment & Demographic Trends"
                  summary={`Share of students enrolled in public schools within ${selectedDriveTime} minutes of ${selectedSchool?.name || 'the selected school'}`}
                  icon={GroupsOutlined}
                >
                  <div 
                    ref={getSectionRef("enrollmentTrends")} 
                    data-section-id="enrollmentTrends"
                  >
                    <Suspense fallback={<Skeleton variant="rectangular" height={200} />}>
                      <CompXEnrollmentHeadlines
                        selectedSchool={selectedSchool}
                        filteredSchools={filteredSchools}
                        selectedGrades={selectedGrades}
                        schoolsEnrollmentData={schoolsEnrollmentData}
                        esriData={esriData}
                        selectedDriveTime={selectedDriveTime}
                        nearbySchools={nearbySchools} 
                        governanceFilter={governanceFilter}
                      />
                    </Suspense>
                  </div>
                </AccordionSection>
    
                <AccordionSection
                  id="nearby"
                  title="Nearby Schools Comparison"
                  summary={`Area school market share and changes in market share within ${selectedDriveTime} minutes of ${selectedSchool?.name || 'the selected school'}`}
                  icon={CompareArrowsOutlined}
                >
                  <div 
                    ref={getSectionRef("nearby")} 
                    data-section-id="nearby"
                  >
                    <Suspense fallback={<Skeleton variant="rectangular" height={500} />}>
                    <CompXNearbyHeadlines
                        {...nearbyHeadlinesProps}
                        PopupComponent={CompXNearbyMapPopup}
                      />
                    </Suspense>
                  </div>
                </AccordionSection>
    
                <AccordionSection
                  id="nowWhat"
                  title="Now What?"
                  summary="Continue your analysis with these related tools"
                  icon={Explore}
                >
                  <div 
                    ref={getSectionRef("nowWhat")} 
                    data-section-id="nowWhat"
                  >
                    <Suspense fallback={<Skeleton variant="rectangular" height={200} />}>
                      <NowWhatSection 
                        sectionIcon={Explore}
                        actions={actions}
                      />
                    </Suspense>
                  </div>
                </AccordionSection>
              </AccordionContainer>
              </Box>
          </LoadingErrorWrapper>
        )}
      </LoadingErrorWrapper>
    </PageLayout>
  );
}

export default CompetitiveExplorer;