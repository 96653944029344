import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Alert,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Chip,
  Grid,
  IconButton,
  Collapse,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import _ from 'lodash';
import { formatGradeList, hasGradeOverlap } from '../../../../utils/schoolUtils';
import { schoolsApi } from '../../../../utils/apiService';
import useSchoolStore from '../../../../stores/schoolStore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import SchoolIcon from '@mui/icons-material/School';
import TimerIcon from '@mui/icons-material/Timer';
import MarketShareAnalysis from './MarketShareAnalysis';

// Helper function to get friendly category name
const getCategoryDisplay = (category) => {
  const displays = {
    CONTINUOUS_STABLE: 'Continuous (Stable)',
    CONTINUOUS_INCREASING: 'Continuous (Growing)',
    CONTINUOUS_DECREASING: 'Continuous (Declining)',
    NEW: 'New School',
    NEWER_INCREASING: 'Newer (Growing)',
    NEWER_DECREASING: 'Newer (Declining)',
    NEWER_STABLE: 'Newer (Stable)',
    CLOSED: 'Closed',
    NEW_OR_NEWER: 'New/Newer School'
  };
  return displays[category] || 'Unknown';
};

// Enhanced table row component with expandable details
const EnhancedTableRow = ({ school, isSelected, showDetails }) => {
  const [open, setOpen] = useState(false);
  
  const getEnrollmentChange = () => {
    if (!school.enrollmentChange) return null;
    const change = parseFloat(school.enrollmentChange);
    if (isNaN(change)) return null;
    
    return {
      value: change,
      color: change > 5 ? 'success' : change < -5 ? 'error' : 'warning',
      icon: change > 5 ? <TrendingUpIcon /> : change < -5 ? <TrendingDownIcon /> : <CompareArrowsIcon />
    };
  };

  const enrollmentChange = getEnrollmentChange();

  return (
    <>
      <TableRow
        hover
        selected={isSelected}
        sx={isSelected ? { 
          backgroundColor: (theme) => theme.palette.action.selected,
          '&:hover': {
            backgroundColor: (theme) => theme.palette.action.selected
          }
        } : {}}
      >
        <TableCell padding="checkbox">
          {showDetails && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{school.name}</TableCell>
        <TableCell>
          <Chip 
            size="small" 
            label={school.charter}
            color={school.charter === 'Charter' ? 'primary' : 'default'}
            variant="outlined"
          />
        </TableCell>
        <TableCell>{school.category}</TableCell>
        <TableCell>{school.currentGrades}</TableCell>
        <TableCell>{school.comparisonGrades}</TableCell>
        <TableCell align="right">{school.currentEnrollment.toLocaleString()}</TableCell>
        <TableCell align="right">{school.comparisonEnrollment.toLocaleString()}</TableCell>
        <TableCell align="right">
          {enrollmentChange && (
            <Chip
              icon={enrollmentChange.icon}
              label={`${enrollmentChange.value.toFixed(1)}%`}
              color={enrollmentChange.color}
              size="small"
            />
          )}
        </TableCell>
        <TableCell align="right">{school.frlPercent}</TableCell>
        <TableCell align="right">{school.frlCount}</TableCell>
      </TableRow>
      {showDetails && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Detailed Metrics
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography color="text.secondary" gutterBottom>
                          Enrollment Trends
                        </Typography>
                        <List dense>
                          <ListItem>
                            <ListItemText 
                              primary="Grade Change"
                              secondary={`From ${school.comparisonGrades} to ${school.currentGrades}`}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText 
                              primary="Enrollment Change"
                              secondary={`${school.enrollmentChange}% (${school.comparisonEnrollment} → ${school.currentEnrollment})`}
                            />
                          </ListItem>
                        </List>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography color="text.secondary" gutterBottom>
                          Student Demographics
                        </Typography>
                        <List dense>
                          <ListItem>
                            <ListItemText 
                              primary="FRL Percentage"
                              secondary={`${school.frlPercent}% (${school.frlCount} students)`}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText 
                              primary="Student/Teacher Ratio"
                              secondary={school.studentTeacherRatio}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText 
                              primary="Teachers"
                              secondary={school.teachers}
                            />
                          </ListItem>
                        </List>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const NearbySchoolsValidation = ({ school }) => {
  const [validationData, setValidationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('asc');
  const [driveTime, setDriveTime] = useState(10);

  const schoolsEnrollmentData = useSchoolStore(state => state.schoolsEnrollmentData);
  const contentLoading = useSchoolStore(state => state.contentLoading);
  const storeError = useSchoolStore(state => state.error);

  const handleDriveTimeChange = (event, newDriveTime) => {
    if (newDriveTime !== null) {
      setDriveTime(newDriveTime);
    }
  };

  const processSchoolData = React.useCallback((schoolData, selectedGrades) => {
    if (!schoolData) return null;  
    
    // Helper to calculate enrollment for overlapping grades
    const calculateOverlappingEnrollment = (enrollmentByGrade) => {
      return Object.entries(enrollmentByGrade || {})
        .filter(([grade]) => {
          if (grade === 'Pre-Kindergarten') return false;
          const formattedGrade = formatGradeList([grade]);
          return hasGradeOverlap(formattedGrade, selectedGrades);
        })
        .reduce((sum, [_, count]) => sum + (Number(count) || 0), 0);
    };
  
    // Calculate enrollments using overlapping grades
    const currentEnrollment = calculateOverlappingEnrollment(
      schoolData.enrollment_by_grade?.current
    );
    
    const comparisonEnrollment = calculateOverlappingEnrollment(
      schoolData.enrollment_by_grade?.comparison
    );
  
    // Get current and comparison grades that have enrollment > 0
    const currentGrades = Object.entries(schoolData.enrollment_by_grade?.current || {})
      .filter(([_, count]) => count > 0)
      .map(([grade]) => grade);
  
    const comparisonGrades = Object.entries(schoolData.enrollment_by_grade?.comparison || {})
      .filter(([_, count]) => count > 0)
      .map(([grade]) => grade);
  
    // Calculate enrollment change only based on overlapping grades
    const enrollmentChange = comparisonEnrollment > 0 
      ? ((currentEnrollment - comparisonEnrollment) / comparisonEnrollment) * 100 
      : null;
  
    // Rest of FRL and other calculations remain the same
    const totalEnrollment = Object.entries(schoolData.enrollment_by_grade?.current || {})
      .reduce((sum, [_, count]) => sum + (Number(count) || 0), 0);
      
    const studentTeacherRatio = schoolData.teachers && schoolData.teachers > 0 
      ? (currentEnrollment / schoolData.teachers).toFixed(1) 
      : 'N/A';
  
    const frlData = schoolData.frl_data;
    const frlCount = frlData?.current?.count;
    
    const frlPercent = frlCount && totalEnrollment > 0
      ? ((frlCount / totalEnrollment) * 100).toFixed(1)
      : 'N/A';
  
    return {
      ncessch: schoolData.ncessch,
      name: schoolData.name,
      category: getCategoryDisplay(schoolData.category),
      charter: schoolData.charter === 'Yes' ? 'Charter' : 'District',
      currentGrades: formatGradeList(currentGrades),
      comparisonGrades: formatGradeList(comparisonGrades),
      currentEnrollment,
      comparisonEnrollment,
      enrollmentChange,
      frlPercent,
      frlCount: frlCount || 'N/A',
      teachers: schoolData.teachers || 'N/A',
      studentTeacherRatio,
      // Add a property to help with debugging
      overlappingGrades: Object.keys(schoolData.enrollment_by_grade?.current || {})
        .filter(grade => {
          if (grade === 'Pre-Kindergarten') return false;
          const formattedGrade = formatGradeList([grade]);
          return hasGradeOverlap(formattedGrade, selectedGrades);
        })
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        const [currentData, comparisonData] = await Promise.all([
          schoolsApi.getNearbySchools(school.ncessch, driveTime, 'current'),
          schoolsApi.getNearbySchools(school.ncessch, driveTime, 'comparison')
        ]);

        // Get selected school data and grades first
        const selectedSchoolData = schoolsEnrollmentData.find(d => d.ncessch === school.ncessch);
        const selectedGrades = Object.entries(selectedSchoolData?.enrollment_by_grade?.current || {})
          .filter(([_, count]) => count > 0)
          .map(([grade]) => grade);
        const formattedSelectedGrades = formatGradeList(selectedGrades);

        const processedData = {
          selectedSchool: processSchoolData(
            selectedSchoolData,
            formattedSelectedGrades
          ),
          currentNearby: currentData.map(s => processSchoolData(
            schoolsEnrollmentData.find(d => d.ncessch === s.ncessch),
            formattedSelectedGrades
          )).filter(Boolean),
          comparisonNearby: comparisonData.map(s => processSchoolData(
            schoolsEnrollmentData.find(d => d.ncessch === s.ncessch),
            formattedSelectedGrades
          )).filter(Boolean)
        };

        setValidationData(processedData);
      } catch (err) {
        console.error('Error fetching nearby schools data:', err);
        setError(err.message || 'Failed to fetch nearby schools data');
      } finally {
        setLoading(false);
      }
    };

    if (school?.ncessch && schoolsEnrollmentData?.length > 0) {
      fetchData();
    }
  }, [school, schoolsEnrollmentData, driveTime, processSchoolData]);

  

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  if (loading || contentLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error || storeError) {
    return <Alert severity="error">{error || storeError}</Alert>;
  }

  if (!validationData) {
    return <Alert severity="info">No validation data available</Alert>;
  }

  const { selectedSchool, currentNearby, comparisonNearby } = validationData;

  if (!selectedSchool) {
    return <Alert severity="error">Selected school data not available</Alert>;
  }

  const validCurrentNearby = (currentNearby || []).filter(school => school !== null);
  const validComparisonNearby = (comparisonNearby || []).filter(school => school !== null);

  const currentSchoolIds = new Set(validCurrentNearby.map(s => s.ncessch));
  const comparisonSchoolIds = new Set(validComparisonNearby.map(s => s.ncessch));

  const newSchools = validCurrentNearby
    .filter(s => !comparisonSchoolIds.has(s.ncessch))
    .filter(s => 
      s && s.currentGrades && selectedSchool.currentGrades && 
      hasGradeOverlap(s.currentGrades, selectedSchool.currentGrades)
    );

  const removedSchools = validComparisonNearby
    .filter(s => !currentSchoolIds.has(s.ncessch))
    .filter(s => 
      s && s.comparisonGrades && selectedSchool.comparisonGrades && 
      hasGradeOverlap(s.comparisonGrades, selectedSchool.comparisonGrades)
    );

  const otherSchools = _.orderBy(
    validCurrentNearby
      .filter(s => s.ncessch !== selectedSchool.ncessch)
      .filter(s => hasGradeOverlap(s.currentGrades, selectedSchool.currentGrades)),
    [orderBy],
    [order]
  );

  const sortedSchools = [selectedSchool, ...otherSchools];

  const columns = [
    { id: 'expand', label: '', numeric: false, sortable: false },
    { id: 'name', label: 'School Name', numeric: false },
    { id: 'charter', label: 'Governance', numeric: false },
    { id: 'category', label: 'Category', numeric: false },
    { id: 'currentGrades', label: 'Current Grades', numeric: false },
    { id: 'comparisonGrades', label: 'Comparison Grades', numeric: false },
    { id: 'currentEnrollment', label: 'Current Enrollment', numeric: true },
    { id: 'comparisonEnrollment', label: 'Comparison Enrollment', numeric: true },
    { id: 'enrollmentChange', label: 'Change %', numeric: true },
    { id: 'frlPercent', label: 'FRL %', numeric: true },
    { id: 'frlCount', label: 'FRL Count', numeric: true }
  ];

  return (
    <Box>
      {/* Drive Time Selection */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
            <TimerIcon color="primary" />
            <Typography variant="subtitle1">Drive Time Selection</Typography>
            <ToggleButtonGroup
              value={driveTime}
              exclusive
              onChange={handleDriveTimeChange}
              aria-label="drive time"
              size="small"
            >
              <ToggleButton value={5} aria-label="5 minutes">
                5 min
              </ToggleButton>
              <ToggleButton value={10} aria-label="10 minutes">
                10 min
              </ToggleButton>
              <ToggleButton value={15} aria-label="15 minutes">
                15 min
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </CardContent>
      </Card>

      {/* Market Share Analysis */}
      {validationData && (
        <MarketShareAnalysis
          selectedSchool={selectedSchool}
          schoolsEnrollmentData={schoolsEnrollmentData}
          sortedSchools={sortedSchools}
        />
      )}

      {/* Summary Statistics Card */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
            <SchoolIcon color="primary" />
            <Typography variant="h6">
              {driveTime}-Minute Drive Time Summary Statistics
            </Typography>
          </Box>
          
          <Grid container spacing={3}>
            {/* Key Metrics */}
            <Grid item xs={12} md={4}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="subtitle2" color="primary" gutterBottom>
                    Key Metrics
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText 
                        primary={`Total Current Nearby Schools: ${validCurrentNearby.length}`}
                        secondary="Schools within designated radius"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText 
                        primary={`Current Grade Overlap: ${validCurrentNearby.filter(s => 
                          s && s.currentGrades && selectedSchool.currentGrades && 
                          hasGradeOverlap(s.currentGrades, selectedSchool.currentGrades)
                        ).length}`}
                        secondary="Schools serving similar grades"
                      />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
  
            {/* Change Analysis */}
            <Grid item xs={12} md={4}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="subtitle2" color="primary" gutterBottom>
                    Change Analysis
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText 
                        primary={`New Schools: ${newSchools.length}`}
                        secondary="Added since comparison period"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText 
                        primary={`Removed Schools: ${removedSchools.length}`}
                        secondary="No longer in proximity"
                      />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
  
            {/* Enrollment Analysis */}
            <Grid item xs={12} md={4}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="subtitle2" color="primary" gutterBottom>
                    Enrollment Analysis
                  </Typography>
                  <List dense>
                  {(() => {
                    // First get all schools with grade overlap
                    const nearbySchools = otherSchools.filter(s => 
                        hasGradeOverlap(s.currentGrades, selectedSchool.currentGrades)
                    );

                    // Calculate total enrollments for nearby schools (excluding selected)
                    const nearbyCurrentTotal = nearbySchools.reduce((sum, s) => sum + s.currentEnrollment, 0);
                    const nearbyComparisonTotal = nearbySchools.reduce((sum, s) => sum + s.comparisonEnrollment, 0);
                    const nearbyEnrollmentChange = nearbyComparisonTotal > 0
                        ? ((nearbyCurrentTotal - nearbyComparisonTotal) / nearbyComparisonTotal * 100).toFixed(1)
                        : 'N/A';

                    // Calculate total enrollments for area (including selected)
                    const allSchools = [selectedSchool, ...nearbySchools];
                    const areaCurrentTotal = allSchools.reduce((sum, s) => sum + s.currentEnrollment, 0);
                    const areaComparisonTotal = allSchools.reduce((sum, s) => sum + s.comparisonEnrollment, 0);
                    const areaEnrollmentChange = areaComparisonTotal > 0
                        ? ((areaCurrentTotal - areaComparisonTotal) / areaComparisonTotal * 100).toFixed(1)
                        : 'N/A';

                    // Calculate rank (including schools with valid enrollment change)
                    const schoolsWithChange = allSchools.filter(s => s.enrollmentChange !== null);
                    const sortedSchools = [...schoolsWithChange].sort((a, b) => b.enrollmentChange - a.enrollmentChange);
                    const selectedSchoolRank = sortedSchools.findIndex(s => s.ncessch === selectedSchool.ncessch) + 1;
                    
                    return (
                        <>
                        <ListItem>
                            <ListItemText 
                            primary={`Nearby Schools Change: ${nearbyEnrollmentChange}%`}
                            secondary={`Enrollment change based on total student count (${nearbyCurrentTotal.toLocaleString()} vs ${nearbyComparisonTotal.toLocaleString()})`}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText 
                            primary={`Area Change: ${areaEnrollmentChange}%`}
                            secondary={`Enrollment change including selected school (${areaCurrentTotal.toLocaleString()} vs ${areaComparisonTotal.toLocaleString()})`}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText 
                            primary={`Enrollment Change Rank: ${selectedSchoolRank} of ${schoolsWithChange.length}`}
                            secondary={`Out of ${nearbySchools.length + 1} schools with grade overlap (${schoolsWithChange.length} have valid change data)`}
                            />
                        </ListItem>
                        </>
                    );
                    })()}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
  
          {/* School Changes Section - Two Columns */}
          {(newSchools.length > 0 || removedSchools.length > 0) && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="subtitle1" gutterBottom>
                Detailed Changes in Nearby Schools:
              </Typography>
              
              <Grid container spacing={2}>
                {/* New Schools Column */}
                <Grid item xs={12} md={6}>
                  {newSchools.length > 0 && (
                    <Card variant="outlined" sx={{ bgcolor: 'rgba(76, 175, 80, 0.08)', height: '100%' }}>
                      <CardContent>
                        <Typography variant="subtitle2" color="success.dark" gutterBottom>
                          New Schools with Grade Overlap ({newSchools.length}):
                        </Typography>
                        <List dense>
                          {newSchools.map(school => (
                            <ListItem key={school.ncessch}>
                              <ListItemText
                                primary={
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <span>{school.name}</span>
                                    <Typography variant="caption" color="text.secondary">
                                      (NCESSCH: {school.ncessch})
                                    </Typography>
                                    <Chip 
                                      size="small" 
                                      label={school.charter}
                                      color={school.charter === 'Charter' ? 'primary' : 'default'}
                                      variant="outlined"
                                    />
                                  </Box>
                                }
                                secondary={`Grades: ${school.currentGrades} | Category: ${school.category}`}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </CardContent>
                    </Card>
                  )}
                </Grid>
  
                {/* Removed Schools Column */}
                <Grid item xs={12} md={6}>
                  {removedSchools.length > 0 && (
                    <Card variant="outlined" sx={{ bgcolor: 'rgba(244, 67, 54, 0.08)', height: '100%' }}>
                      <CardContent>
                        <Typography variant="subtitle2" color="error.dark" gutterBottom>
                          Schools No Longer in Range ({removedSchools.length}):
                        </Typography>
                        <List dense>
                          {removedSchools.map(school => (
                            <ListItem key={school.ncessch}>
                              <ListItemText
                                primary={
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <span>{school.name}</span>
                                    <Typography variant="caption" color="text.secondary">
                                      (NCESSCH: {school.ncessch})
                                    </Typography>
                                    <Chip 
                                      size="small" 
                                      label={school.charter}
                                      color={school.charter === 'Charter' ? 'primary' : 'default'}
                                      variant="outlined"
                                    />
                                  </Box>
                                }
                                secondary={`Previous Grades: ${school.comparisonGrades} | Category: ${school.category}`}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </CardContent>
                    </Card>
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
        </CardContent>
      </Card>
  
      {/* Main Data Table */}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.numeric ? 'right' : 'left'}
                  sortDirection={orderBy === column.id ? order : false}
                >
                  {column.sortable !== false ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={() => handleRequestSort(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedSchools.map((school) => (
              <EnhancedTableRow 
                key={school.ncessch}
                school={school}
                isSelected={school.ncessch === selectedSchool.ncessch}
                showDetails={true}
              />
            ))}
            {/* Totals Row */}
            <TableRow sx={{ 
              backgroundColor: (theme) => theme.palette.grey[50],
              '& > td': { fontWeight: 'bold' }
            }}>
              <TableCell />
              <TableCell>Totals ({sortedSchools.length} schools)</TableCell>
              <TableCell colSpan={4} /> {/* Skip governance, category, and grades columns */}
              <TableCell align="right">
                {sortedSchools.reduce((sum, s) => sum + s.currentEnrollment, 0).toLocaleString()}
              </TableCell>
              <TableCell align="right">
                {sortedSchools.reduce((sum, s) => sum + s.comparisonEnrollment, 0).toLocaleString()}
              </TableCell>
              <TableCell align="right">
                {(() => {
                  const totalCurrent = sortedSchools.reduce((sum, s) => sum + s.currentEnrollment, 0);
                  const totalComparison = sortedSchools.reduce((sum, s) => sum + s.comparisonEnrollment, 0);
                  const change = ((totalCurrent - totalComparison) / totalComparison * 100).toFixed(1);
                  return (
                    <Chip
                      icon={change > 5 ? <TrendingUpIcon /> : change < -5 ? <TrendingDownIcon /> : <CompareArrowsIcon />}
                      label={`${change}%`}
                      color={change > 5 ? 'success' : change < -5 ? 'error' : 'warning'}
                      size="small"
                    />
                  );
                })()}
              </TableCell>
              <TableCell align="right">
                {(() => {
                  const validFRL = sortedSchools.filter(s => !isNaN(parseFloat(s.frlPercent)));
                  if (validFRL.length === 0) return 'N/A';
                  const avgFRL = validFRL.reduce((sum, s) => sum + parseFloat(s.frlPercent), 0) / validFRL.length;
                  return `${avgFRL.toFixed(1)}`;
                })()}
              </TableCell>
              <TableCell align="right">
                {sortedSchools.reduce((sum, s) => sum + (s.frlCount === 'N/A' ? 0 : parseInt(s.frlCount)), 0).toLocaleString()}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default NearbySchoolsValidation;