import React, { useMemo } from 'react';
import { Groups2Outlined } from '@mui/icons-material';
import LineChartTemplate from '../../shared_components/templates/lineChartTemplate';
import {  
  getTrendColor, 
  getColorWithOpacity,
  getTrendBackgroundWithOpacity 
} from '../../../utils/schoolUtils';

const GeoXCommunityTrends = ({ 
  populationTotals,
  totalChange,
  selectedDriveTime,
  height = 400,
  cardProps = {}
}) => {
  const trendColor = useMemo(() => getTrendColor(totalChange), [totalChange]);

  const populationChanges = useMemo(() => {
    const shortTermChange = ((populationTotals.current - populationTotals.past) / populationTotals.past) * 100;
    const longTermChange = ((populationTotals.future - populationTotals.current) / populationTotals.current) * 100;
    return {
      shortTerm: shortTermChange,
      longTerm: longTermChange
    };
  }, [populationTotals]);

  const getAnalysisSummary = useMemo(() => {
    const shortTerm = populationChanges.shortTerm;
    const longTerm = populationChanges.longTerm;

    const shortTermTrend = shortTerm >= 0 ? 'grown' : 'declined';
    const longTermTrend = longTerm >= 0 ? 'continue growing' : 'decline';

    return `School-age population has ${shortTermTrend} by ${Math.abs(shortTerm).toFixed(0)}% since 2020 and is projected to ${longTermTrend} by ${Math.abs(longTerm).toFixed(0)}% through 2029`;
  }, [populationChanges]);

  const chartData = {
    labels: ['2020', '2024', '2029'],
    datasets: [{
      label: 'School-Age Population',
      data: [populationTotals.past, populationTotals.current, populationTotals.future],
      borderColor: trendColor,
      backgroundColor: getColorWithOpacity(trendColor, 0.1),
      tension: 0.3,
      pointRadius: 4,
      pointBackgroundColor: trendColor,
      fill: true
    }]
  };

  const minDataValue = Math.min(
    populationTotals.past,
    populationTotals.current,
    populationTotals.future
  );

  return (
    <LineChartTemplate
      icon={Groups2Outlined}
      title="Population Trends"
      analysis={getAnalysisSummary}
      tooltip="Projected changes in school-age population"
      chartData={chartData}
      chartOptions={{
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {
              label: (context) => `Population: ${context.raw.toLocaleString()}`
            }
          },
          datalabels: {
            color: trendColor,
            align: 'top',
            formatter: (value, context) => {
              if (context.dataIndex === 0) return '';
              const prevValue = context.dataset.data[context.dataIndex - 1];
              const change = ((value - prevValue) / prevValue) * 100;
              return `${change >= 0 ? '+' : ''}${change.toFixed(0)}%`;
            },
            display: (context) => context.dataIndex > 0,
            textAlign: 'center'
          }
        },
        scales: {
          y: {
            min: minDataValue / 2,
            ticks: {
              callback: (value) => value.toLocaleString()
            }
          }
        }
      }}
      analysisBackgroundColor={getTrendBackgroundWithOpacity(trendColor, 0.1)}
      analysisTextColor={trendColor}
      height={height}
      cardProps={cardProps}
    />
  );
};

export default GeoXCommunityTrends;