import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { getAnalytics, logEvent } from 'firebase/analytics';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
} from '@mui/material';

const AnalyticsPanel = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [analyticsData, setAnalyticsData] = useState({
    totalUsers: 0,
    activeUsers: 0,
    userDetails: []
  });
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      try {
        if (!currentUser?.access_token) {
          throw new Error('No authenticated user or access token');
        }

        // Log analytics panel view
        const analytics = getAnalytics();
        logEvent(analytics, 'analytics_panel_view', {
          user_id: currentUser.uid,
          timestamp: new Date().toISOString()
        });

        // Fetch from your backend
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/analytics/overview`, {
          headers: {
            'Authorization': `Bearer ${currentUser.access_token}`,
            'Content-Type': 'application/json',
          },
          credentials: 'include'
        });

        if (!response.ok) {
          const text = await response.text();
          console.error('Failed to fetch analytics:', response.status, text);
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        
        // Validate and transform the data
        const transformedData = {
          totalUsers: data.totalUsers || 0,
          activeUsers: data.activeUsers || 0,
          userDetails: data.userDetails?.map(user => ({
            ...user,
            loginCount: user.loginCount || 0,
            avgSessionTime: user.avgSessionTime || 0,
            lastLogin: user.lastLogin || null
          })) || []
        };

        setAnalyticsData(transformedData);
        setError(null);
      } catch (error) {
        console.error('Error in fetchAnalyticsData:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAnalyticsData();
    // Set up periodic refresh (every 5 minutes)
    const refreshInterval = setInterval(fetchAnalyticsData, 5 * 60 * 1000);

    return () => clearInterval(refreshInterval);
  }, [currentUser]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        Error loading analytics data: {error}
      </Alert>
    );
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Analytics Overview
      </Typography>
      
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Total Users
              </Typography>
              <Typography variant="h4">
                {analyticsData.totalUsers.toLocaleString()}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Last updated: {new Date().toLocaleTimeString()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Active Users (Last 7 Days)
              </Typography>
              <Typography variant="h4">
                {analyticsData.activeUsers.toLocaleString()}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Updated every 5 minutes
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
        User Activity Details
      </Typography>
      
      <TableContainer component={Paper}>
        <Table>
        <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Last Login</TableCell>
              <TableCell align="right">Login Count</TableCell>
              <TableCell align="right">Avg. Session Time</TableCell>
              <TableCell align="right">Analytics Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              {analyticsData.userDetails.length > 0 ? (
                analyticsData.userDetails.map((user) => (
                  <TableRow key={user.id || user.email}>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      {user.lastLogin ? new Date(user.lastLogin).toLocaleDateString() : 'Never'}
                    </TableCell>
                    <TableCell align="right">{user.loginCount}</TableCell>
                    <TableCell align="right">
                      {user.avgSessionTime ? `${Math.round(user.avgSessionTime / 60)} mins` : 'N/A'}
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body2"
                        color={user.analyticsAllowed ? "success.main" : "text.secondary"}
                      >
                        {user.analyticsAllowed ? "Enabled" : "Disabled"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No user activity data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AnalyticsPanel;