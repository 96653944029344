import { useState } from 'react';
import { apiFetch } from './api';

/**
 * School API endpoints
 */
export const schoolsApi = {
  // Keep all existing methods unchanged
  async getAllSchools() {
    return apiFetch('/schools');
  },

  async getMarketShareData(schoolId) {
    try {
      const response = await apiFetch(`/school/${schoolId}/esri-market-share`);
      console.log('ESRI Market Share Response:', {
        hasDriveTimes: !!response.drive_times,
        driveTimes: Object.keys(response.drive_times || {}),
        samplePolygon: response.drive_times?.[5]?.drive_time_polygon ? 'present' : 'missing'
      });
      return response;
    } catch (error) {
      console.error('Error fetching market share data:', error);
      throw error;
    }
  },

  getEnrollmentProjections: async (schoolId) => {
    try {
      return await apiFetch(`/school/${schoolId}/enrollment-projections`);
    } catch (error) {
      console.error('Enrollment projections error:', {
        status: error.status,
        message: error.message,
        response: error.response
      });

      if (error.status === 401 || error.status === 403) {
        throw new Error('You do not have permission to view this school\'s projections');
      } else if (error.status === 404) {
        throw new Error('School projections not found');
      } else if (typeof error.response === 'string' && error.response.includes('<!DOCTYPE')) {
        throw new Error('Authentication error. Please try logging in again.');
      } else {
        throw new Error(error.message || 'Failed to fetch enrollment projections');
      }
    }
  },

  submitEnrollmentData: async (data) => {
    return await apiFetch('/submit-enrollment-data', {
      method: 'POST',
      body: JSON.stringify(data)
    });
  },

  updateProjections: async (ncessch) => {
    return await apiFetch('/update-projections', {
      method: 'POST',
      body: JSON.stringify({ ncessch })
    });
  },

  submitEnrollmentTargets: async (data) => {
    try {
      return await apiFetch('/submit-enrollment-targets', {
        method: 'POST',
        body: JSON.stringify(data)
      });
    } catch (error) {
      console.error('Submit enrollment targets error:', {
        status: error.status,
        message: error.message,
        response: error.response
      });

      if (error.status === 401 || error.status === 403) {
        throw new Error('You do not have permission to submit enrollment targets for this school');
      } else if (error.status === 404) {
        throw new Error('School not found');
      } else if (typeof error.response === 'string' && error.response.includes('<!DOCTYPE')) {
        throw new Error('Authentication error. Please try logging in again.');
      } else {
        throw new Error(error.message || 'Failed to submit enrollment targets');
      }
    }
  },

  getEnrollmentTargets: async (schoolId) => {
    try {
      return await apiFetch(`/school/${schoolId}/enrollment-targets`);
    } catch (error) {
      console.error('Get enrollment targets error:', {
        status: error.status,
        message: error.message,
        response: error.response
      });

      if (error.status === 401 || error.status === 403) {
        throw new Error('You do not have permission to view this school\'s enrollment targets');
      } else if (error.status === 404) {
        throw new Error('School targets not found');
      } else if (typeof error.response === 'string' && error.response.includes('<!DOCTYPE')) {
        throw new Error('Authentication error. Please try logging in again.');
      } else {
        throw new Error(error.message || 'Failed to fetch enrollment targets');
      }
    }
  },

  // Enhanced nearby schools methods
  async getNearbySchools(schoolId, driveTime, type = 'current') {
    try {
        console.log(`Fetching ${type} nearby schools for ${schoolId} at ${driveTime} minutes`);
        const response = await apiFetch(`/school/${schoolId}/nearby-schools/${driveTime}?type=${type}`);
        console.log(`Got ${response.length || 0} nearby schools`);
        return response; 
    } catch (error) {
        console.error('Error fetching nearby schools:', error);
        return [];  // Return empty array on error to avoid undefined
    }
},

  async getAllNearbySchools(schoolId) {
    try {
      const results = {
        current: {},
        comparison: {},
        gradeData: {} // New field for grade information
      };
      
      // Fetch all drive times in parallel for better performance
      const driveTimes = [5, 10, 15];
      const [currentResponses, comparisonResponses] = await Promise.all([
        Promise.all(driveTimes.map(time => 
          this.getNearbySchools(schoolId, time, 'current')
        )),
        Promise.all(driveTimes.map(time => 
          this.getNearbySchools(schoolId, time, 'comparison')
        ))
      ]);

      // Process responses
      driveTimes.forEach((time, index) => {
        // Store schools
        results.current[time] = currentResponses[index];
        results.comparison[time] = comparisonResponses[index];

        // Store grade data if available
        if (currentResponses[index].gradeData) {
          results.gradeData = {
            ...results.gradeData,
            ...currentResponses[index].gradeData
          };
        }
        if (comparisonResponses[index].gradeData) {
          results.gradeData = {
            ...results.gradeData,
            ...comparisonResponses[index].gradeData
          };
        }
      });
      
      console.log('Nearby schools response:', results);
      return results;
    } catch (error) {
      console.error('Error fetching nearby schools:', error);
      throw error;
    }
  },

  async getSchoolsEnrollmentData(schoolIds, selectedSchoolId) {
    console.log('API call - getSchoolsEnrollmentData:', {
      requestedSchools: schoolIds,
      selectedSchool: selectedSchoolId
    });
    
    try {
      const data = await apiFetch('/schools-enrollment-by-grade', {
        method: 'POST',
        body: JSON.stringify({ 
          ncesschList: schoolIds,
          selectedSchool: selectedSchoolId
        })
      });
      
      console.log('API response - getSchoolsEnrollmentData:', {
        schoolsRequested: schoolIds.length,
        schoolsReceived: data.length,
        selectedSchoolIncluded: selectedSchoolId ? data.some(d => d.ncessch === selectedSchoolId) : false
      });
      
      return data;
    } catch (error) {
      console.error('Error fetching enrollment data:', error);
      throw error;
    }
  },
};

/**
 * Hook for managing API calls with loading and error states
 */
export function useApiCall(apiCall, onSuccess, onError, showLoading = true) {
  const [loading, setLoading] = useState(showLoading);
  const [error, setError] = useState(null);

  const execute = async (...args) => {
    try {
      setLoading(true);
      setError(null);

      const result = await apiCall(...args);
      
      if (!result || (typeof result === 'string' && result.includes('<!DOCTYPE'))) {
        throw new Error('Invalid response received');
      }

      if (onSuccess) onSuccess(result);
      return result;
    } catch (err) {
      console.error('API call failed:', {
        error: err,
        status: err.status,
        message: err.message
      });

      let errorMessage = err.message;
      
      if (err.status === 401 || 
          err.message.includes('unauthorized') || 
          err.message.includes('<!DOCTYPE')) {
        errorMessage = 'Your session has expired. Please log in again.';
      }

      setError(errorMessage);
      if (onError) onError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { execute, loading, error };
}

export { apiFetch };