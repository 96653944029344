import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { 
  ThemeProvider, 
  createTheme, 
  CssBaseline, 
  Box,
  CircularProgress,
} from '@mui/material';
import Sidebar from './components/internal_layout/Sidebar';
import ManagePreferences from './components/internal_layout/ManagePreferences';
import InsightSidebar from './components/internal_layout/InsightSidebar';
import { InsightProvider } from './contexts/InsightContext';
import Footer from './components/internal_layout/Footer';
import Home from './components/home/Home';
import Map from './components/Map';
import Login from './components/external_site/Login';
import ResetPassword from './components/external_site/ResetPassword';
import ProtectedRoute from './components/ProtectedRoute';
import AddressDataVisualization from './components/AddressDataVisualization';
import DriveTimePage from './components/DriveTimePage';
import SchoolSummary from './components/school_summary/SchoolSummary';
import GeographicExplorer from './components/geographic_explorer/GeographicExplorer';
import CompetitiveExplorer from './components/competitive_explorer/CompetitiveExplorer';
import DataReportsPage from './components/DataReportsPage';
import ProjectionsExplorer from './components/projections_explorer/ProjectionsExplorer';
import TermsOfService from './components/external_site/TermsOfService'; 
import PrivacyNotice from './components/external_site/PrivacyNotice';
import HomePage from './components/external_site/HomePage';
import AboutUs from './components/external_site/AboutUs';
import Contact from './components/external_site/Contact';
import ProductPage from './components/external_site/ProductPage';
import AdminDashboard from './components/admin/AdminDashboard';
import SessionTracker from './components/admin/SessionTracker';
import PrivacyConsentManager from './components/external_site/PrivacyConsentManager';
import { onAuthStateChanged } from 'firebase/auth';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { auth } from './firebase';
import useSchoolStore from './stores/schoolStore';
import { getAnalytics, setUserId, logEvent } from 'firebase/analytics';
import { SnackbarProvider } from 'notistack';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4338ca',
      light: '#6366f1',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    grey: {
      200: '#f3f4f6',
    },
  },
});

function AppContent() {
  const location = useLocation();
  const { currentUser, setCurrentUser, verifyTokenWithBackend, loading: authLoading, setLoading } = useAuth();
  const userRef = useRef(null);
  
  useEffect(() => {
    const analytics = getAnalytics();
    let sessionStartTime = Date.now();
    
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Set the user ID for all future events
        setUserId(analytics, user.uid);
        
        // Log session start with user ID
        logEvent(analytics, 'session_start', {
          user_id: user.uid,
          email: user.email,
          engagement_time_msec: 0,
        });

        // Track user engagement
        logEvent(analytics, 'user_engagement', {
          user_id: user.uid,
          engagement_time_msec: 0
        });
        
        verifyTokenWithBackend(user);
      } else {
        if (userRef.current) {
          logEvent(analytics, 'session_end', {
            user_id: userRef.current.uid,
            engagement_time_msec: Date.now() - sessionStartTime,
            session_duration: Date.now() - sessionStartTime
          });
        }
        setCurrentUser(null);
        if (setLoading) {
          setLoading(false);
        }
      }
      userRef.current = user;
    });

    return () => {
      // Clean up by logging session end if component unmounts
      if (userRef.current) {
        logEvent(analytics, 'session_end', {
          user_id: userRef.current.uid,
          engagement_time_msec: Date.now() - sessionStartTime,
          session_duration: Date.now() - sessionStartTime
        });
      }
      unsubscribe();
    };
  }, [verifyTokenWithBackend, setCurrentUser, setLoading]);

  useEffect(() => {
    const initializeStore = async () => {
      if (!authLoading && currentUser) {
        try {
          await useSchoolStore.getState().initialize();
        } catch (error) {
          console.error('Failed to initialize store:', error);
        }
      } else if (!authLoading && !currentUser) {
        useSchoolStore.getState().clearSchoolData();
      }
    };

    initializeStore();
  }, [currentUser, authLoading]);

  const handleSidebarToggle = () => {};

  if (authLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const publicPages = ['/', '/about', '/login', '/reset-password', '/terms-of-service', '/privacy-notice', '/product', '/contact'];
  const isPublicPage = publicPages.includes(location.pathname);

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <Box 
        component="main" 
        sx={{ 
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          pl: isPublicPage ? 0 : '70px', // Space for closed nav sidebar
          pr: isPublicPage ? 0 : '60px', // Space for closed insight sidebar
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        <Box sx={{ 
          flexGrow: 1,
          width: '100%',
          boxSizing: 'border-box',
          overflow: 'visible',
          position: 'relative',
        }}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-notice" element={<PrivacyNotice />} />
            <Route path="/product" element={<ProductPage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/map" element={<ProtectedRoute user={currentUser}><Map /></ProtectedRoute>} />
            <Route path="/dashboard" element={<ProtectedRoute user={currentUser}><Home user={currentUser} /></ProtectedRoute>} />
            <Route path="/address-visualization" element={<ProtectedRoute user={currentUser}><AddressDataVisualization /></ProtectedRoute>} />
            <Route path="/school-summary" element={<SchoolSummary />} />
            <Route path="/projections-explorer" element={<ProtectedRoute user={currentUser}><ProjectionsExplorer /></ProtectedRoute>} />
            <Route path="/geographic-explorer" element={<ProtectedRoute user={currentUser}><GeographicExplorer /></ProtectedRoute>} />
            <Route path="/competitive-explorer" element={<ProtectedRoute user={currentUser}><CompetitiveExplorer /></ProtectedRoute>} />
            <Route path="/data-reports" element={<ProtectedRoute user={currentUser}><DataReportsPage /></ProtectedRoute>} />
            <Route path="/data-reports/drive-time" element={<ProtectedRoute user={currentUser}><DriveTimePage /></ProtectedRoute>} />
            <Route path="/admin" element={<ProtectedRoute allowedRoles={['admin']}><AdminDashboard /></ProtectedRoute>} />
            <Route path="/preferences" element={<ManagePreferences />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Box>
        {!isPublicPage && <Footer />}
      </Box>

      {!isPublicPage && (
        <>
          {/* Navigation Sidebar - fixed position */}
          <Box sx={{ position: 'fixed', left: 0, top: 0, height: '100vh', zIndex: 1200 }}>
            <Sidebar 
              user={currentUser}
              onToggle={handleSidebarToggle}
            />
          </Box>

          {/* Insight Sidebar - fixed position */}
          <Box sx={{ position: 'fixed', right: 0, top: 0, height: '100vh', zIndex: 1200 }}>
            <InsightSidebar />
          </Box>
        </>
      )}
    </Box>
  );
}

function App() {
  const handleLogoutNavigation = () => {
    // This function will be called by AuthContext after successful logout
    window.location.href = '/login';
  };

  return (
    <AuthProvider onLogout={handleLogoutNavigation}>
      <InsightProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            autoHideDuration={5000}
          >
            <Router>
              <PrivacyConsentManager />
              <SessionTracker />
              <AppContent />
            </Router>
          </SnackbarProvider>
        </ThemeProvider>
      </InsightProvider>
    </AuthProvider>
  );
}

export default App;