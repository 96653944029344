// AccordionContentWrapper.js
import React from 'react';
import SectionVisibilityTracker from './sectionVisibilityTracker';

const AccordionContentWrapper = ({ children, sectionId, isExpanded }) => {
  return (
    <div className="relative">
      <SectionVisibilityTracker 
        sectionId={sectionId} 
        isExpanded={isExpanded} 
      />
      {children}
    </div>
  );
};

export default AccordionContentWrapper; 