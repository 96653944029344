import React from 'react';
import { Typography, Box, Divider, Paper } from '@mui/material';
import { formatSchoolName } from '../../../utils/nameFormatter';
import { TrendingUp, TrendingDown } from '@mui/icons-material';
import { THRESHOLDS, TREND_COLORS } from '../../../constants/competitiveExplorer';
import { SCHOOL_CATEGORIES } from '../../../utils/schoolCategorization';

const roundMarketShare = (value) => Math.round(value * 1000) / 10;

const getTrendIcon = (change, schoolCategory) => {
  if (schoolCategory === SCHOOL_CATEGORIES.NEW) return null;
  const changePercent = change * 100;
  if (Math.abs(changePercent) <= THRESHOLDS.MARKET_SHARE_SIGNIFICANT) return null;
  return changePercent > 0 ? 
    <TrendingUp sx={{ fontSize: 16, color: TREND_COLORS.positive }} /> : 
    <TrendingDown sx={{ fontSize: 16, color: TREND_COLORS.negative }} />;
};

const getChangeColor = (change) => {
  const changePercent = change * 100;
  if (changePercent > THRESHOLDS.MARKET_SHARE_SIGNIFICANT) {
    return '#e8f5e9'; // Light green
  }
  if (changePercent < -THRESHOLDS.MARKET_SHARE_SIGNIFICANT) {
    return '#ffebee'; // Light red
  }
  return '#f8f9fa'; // Default grey
};

const CompXNearbyMapPopup = React.memo(({ 
  school, 
  marketShareData, 
  enrollmentData,
  selectedGrades
}) => {
  const currentMarketShare = Number(marketShareData?.['2023-24'] ?? 0);
  const pastMarketShare = Number(marketShareData?.['2019-20'] ?? 0);
  const changeInMarketShare = currentMarketShare - pastMarketShare;
  const schoolCategory = enrollmentData?.category;

  const currentSchoolEnrollment = marketShareData?.enrollments?.['2023-24']?.school;
  const currentTotalEnrollment = marketShareData?.enrollments?.['2023-24']?.total;
  const marketShareRank = marketShareData?.rank;

  // Debug log to check the rank value
  console.log('Market Share Data:', marketShareData);
  console.log('Rank:', marketShareRank);

  return (
    <Box sx={{ width: 300 }}>
      <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 0.5 }}>
        {formatSchoolName(school.name)}
      </Typography>
      <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.secondary' }}>
        {school.charter === 'Yes' ? 'Charter' : 'District'} School | {school.grade_range}
      </Typography>
      <Divider sx={{ mb: 2 }} />

      <Typography variant="body2" color="text.secondary" sx={{ 
        mb: 2,
        fontStyle: 'italic',
        fontSize: '0.75rem'
      }}>
        Note: Enrollment reflects only grades that overlap with selected school ({selectedGrades.join(', ')}).
      </Typography>

      {/* Full-width market share box */}
      <Paper
        elevation={0}
        sx={{
          p: 2,
          width: '100%',
          bgcolor: '#f8f9fa',
          border: '1px solid',
          borderColor: 'divider',
          mb: 2
        }}
      >
        <Typography variant="h4" sx={{ 
          fontWeight: 'bold',
          fontSize: '1.75rem',
          mb: 0.5,
          textAlign: 'center'
        }}>
          {roundMarketShare(currentMarketShare)}%
        </Typography>
        <Typography variant="body2" color="text.secondary" align="center">
          Market Share
        </Typography>
        {currentSchoolEnrollment && currentTotalEnrollment && (
          <Typography variant="body2" color="text.secondary" align="center">
            {currentSchoolEnrollment.toLocaleString()} of {currentTotalEnrollment.toLocaleString()} students
          </Typography>
        )}
      </Paper>

      {/* Change and Rank side by side */}
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Paper
          elevation={0}
          sx={{
            p: 2,
            flex: 1,
            bgcolor: getChangeColor(changeInMarketShare),
            border: '1px solid',
            borderColor: 'divider'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 0.5, justifyContent: 'center' }}>
            {getTrendIcon(changeInMarketShare, schoolCategory)}
            <Typography variant="h4" sx={{ 
              fontWeight: 'bold',
              fontSize: '1.75rem'
            }}>
              {changeInMarketShare > 0 ? '+' : ''}{roundMarketShare(changeInMarketShare)}%
            </Typography>
          </Box>
          <Typography variant="body2" color="text.secondary" align="center">
            Since 2019-20
          </Typography>
        </Paper>

        <Paper
          elevation={0}
          sx={{
            p: 2,
            flex: 1,
            bgcolor: '#f8f9fa',
            border: '1px solid',
            borderColor: 'divider'
          }}
        >
          <Typography variant="h4" sx={{ 
            fontWeight: 'bold',
            fontSize: '1.75rem',
            textAlign: 'center',
            mb: 0.5
          }}>
            {marketShareRank ? `#${marketShareRank}` : '-'}
          </Typography>
          <Typography variant="body2" color="text.secondary" align="center">
            Market Share Rank
          </Typography>
        </Paper>
      </Box>
    </Box>
  );
});

CompXNearbyMapPopup.displayName = 'CompXNearbyMapPopup';

export default CompXNearbyMapPopup;