import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { 
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  CssBaseline,
  CircularProgress
} from "@mui/material";
import { styled, keyframes } from "@mui/material/styles";
import { useAuth } from '../../contexts/AuthContext';
import customLogger from '../../utils/customLogger';
import getFirebaseErrorMessage from '../../utils/firebaseErrorMessages';

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const PageWrapper = styled(Box)({
  minHeight: '100vh',
  width: '100vw',
  backgroundColor: '#F9FAFB',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
});

const LogoWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '-50px',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '100px',
  height: '100px',
  '& .logo-content': {
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    borderRadius: '50%',
    padding: '4px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    animation: `${fadeInUp} 0.8s ease-out`,
  },
  '& .logo': {
    width: '100%',
    height: '100%',
    backgroundImage: 'url("/images/edc_logo_circle.png")',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  }
}));

const StyledContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
});

const StyledSignInContainer = styled(Paper)({
  backgroundColor: '#ffffff',
  border: '1px solid rgba(0, 0, 0, 0.05)',
  borderRadius: '8px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.05)',
  maxWidth: '600px',
  width: '100%',
  padding: '40px',
  paddingTop: '80px',
  textAlign: 'center',
  position: 'relative',
  marginTop: '60px',
});

const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  width: '100%',
  marginTop: '16px',
});

const InputLabel = styled(Typography)({
  textAlign: 'left',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '14px',
  fontWeight: 500,
  color: '#111827',
  marginBottom: '6px',
});

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    '& fieldset': {
      borderColor: '#E5E7EB',
    },
    '&:hover fieldset': {
      borderColor: '#E5E7EB',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6366F1',
    },
  },
  '& .MuiInputBase-input': {
    padding: '14px 16px',
    fontSize: '14px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&::placeholder': {
      color: '#9CA3AF',
    }
  },
});

const StyledButton = styled(Button)({
  backgroundColor: '#4F46E5',
  color: '#ffffff',
  fontSize: '16px',
  fontWeight: 600,
  padding: '14px',
  borderRadius: '32px',
  textTransform: 'none',
  boxShadow: '0px 4px 6px rgba(79, 70, 229, 0.3)',
  marginTop: '12px',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  '&:hover': {
    backgroundColor: '#4338CA',
    boxShadow: '0px 4px 8px rgba(79, 70, 229, 0.4)',
  },
});

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isLoading) return;
    setError('');
    setIsLoading(true);
    try {
      customLogger(`Login attempt initiated for email: ${email}`, 'info');
      const { access_token } = await login(email, password);
      customLogger(`Received access token: ${access_token.substring(0, 10)}...`, 'info');
      localStorage.setItem('access_token', access_token);
      customLogger(`Access token stored in localStorage`, 'info');
      customLogger(`User logged in successfully: ${email}`, 'info');
      navigate('/dashboard');
    } catch (error) {
      const errorMessage = getFirebaseErrorMessage(error.code) || error.message;
      customLogger(`Login failed for ${email}: ${errorMessage}`, 'error');
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageWrapper>
      <CssBaseline />
      <StyledContainer maxWidth="md">
        <StyledSignInContainer elevation={0}>
          <LogoWrapper>
            <div className="logo-content">
              <div className="logo" aria-label="EDC Logo" />
            </div>
          </LogoWrapper>

          <Typography
            component="h1"
            variant="h4"
            sx={{
              fontSize: '28px',
              fontWeight: 600,
              color: '#111827',
              marginBottom: '32px',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            }}
          >
            Begin Exploring
          </Typography>

          <StyledForm onSubmit={handleSubmit}>
            <Box>
              <InputLabel>Email</InputLabel>
              <StyledTextField
                required
                fullWidth
                id="email"
                name="email"
                placeholder="Email"
                type="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
              />
            </Box>

            <Box>
              <InputLabel>Password</InputLabel>
              <StyledTextField
                required
                fullWidth
                name="password"
                placeholder="Password"
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
              />
            </Box>

            {error && (
              <Typography 
                color="error" 
                align="center" 
                sx={{ mt: 2 }}
              >
                {error}
              </Typography>
            )}

            <StyledButton
              type="submit"
              fullWidth
              variant="contained"
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Sign In'}
            </StyledButton>

            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: '12px',
              alignItems: 'center',
              mt: 1
            }}>
              <Link 
                to="/reset-password" 
                style={{ textDecoration: 'none' }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: '#4F46E5',
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  Forgot Password?
                </Typography>
              </Link>

              <Typography
                variant="body2"
                sx={{
                  fontSize: '14px',
                  color: '#4B5563',
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  '& a': {
                    color: '#4F46E5',
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  },
                }}
              >
                By clicking "Sign In", you agree to our{' '}
                <Link 
                  to="/terms-of-service" 
                  style={{ 
                    color: '#4F46E5', 
                    textDecoration: 'none' 
                  }}
                >
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link 
                  to="/privacy-notice" 
                  style={{ 
                    color: '#4F46E5', 
                    textDecoration: 'none' 
                  }}
                >
                  Privacy Notice
                </Link>.
              </Typography>
            </Box>
          </StyledForm>
        </StyledSignInContainer>
      </StyledContainer>
    </PageWrapper>
  );
};

export default Login;