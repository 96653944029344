import React, { useState, useEffect, useMemo } from 'react';
import {
  Drawer,
  IconButton,
  Typography,
  Box,
  useTheme,
  Alert,
  AlertTitle,
  keyframes,
  Fade
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
} from '@mui/icons-material';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import TipsAndUpdatesRoundedIcon from '@mui/icons-material/TipsAndUpdatesRounded';
import { useInsight } from '../../contexts/InsightContext';

const pulse = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  50% {
    transform: translateX(-5px);
    opacity: 0.7;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const InsightSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const { getCurrentContent, currentTool, currentSection, insightContent } = useInsight();

  // Memoize these derived values
  const defaultContent = useMemo(() => 
    currentTool ? insightContent[currentTool]?.default : null,
    [currentTool, insightContent]
  );  

  const content = useMemo(() => {
    return getCurrentContent();
  }, [getCurrentContent]);  

  const drawerWidth = isOpen ? 320 : 48;

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log('Dependencies changed:', {
        currentTool,
        currentSection,
        getCurrentContentRef: getCurrentContent, // see if this function reference is changing
        contentValue: getCurrentContent(),
        insightContentRef: insightContent, // see if this object reference is changing
      });
    }
  }, [currentTool, currentSection, getCurrentContent, insightContent]);

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: isOpen ? '#F5F3FF' : '#6B46C1',
          borderLeft: `1px solid ${isOpen ? '#6B46C1' : '#6B46C1'}`,
          overflowX: 'hidden',
          transition: theme.transitions.create(['width', 'background-color'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          boxShadow: '-4px 0 10px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <Box 
        onClick={() => !isOpen && setIsOpen(true)}
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          height: '100%',
          cursor: !isOpen ? 'pointer' : 'default',
          '&:hover': {
            backgroundColor: !isOpen ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
          },
          transition: 'background-color 0.2s ease',
        }}>
        <Box sx={{ 
          display: 'flex',
          alignItems: 'center',
          borderBottom: `1px solid ${isOpen ? theme.palette.divider : '#8B5CF6'}`,
          minHeight: 64,
        }}>
          {!isOpen ? (
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              py: 1,
            }}>
              <TipsAndUpdatesRoundedIcon sx={{ color: 'white' }} />
              <Typography
                sx={{
                  writingMode: 'vertical-lr',
                  transform: 'rotate(180deg)',
                  textAlign: 'center',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  my: 2,
                  color: 'white',
                }}
              >
                Insights & Actions
              </Typography>
              <ChevronLeft sx={{ 
                color: 'white',
                animation: `${pulse} 1.5s ease-in-out infinite`,
              }} />
            </Box>
          ) : (
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              width: '100%',
              px: 2,
            }}>
              <TipsAndUpdatesRoundedIcon sx={{ color: 'primary.main', mr: 1 }} />
              <Typography sx={{ 
                fontSize: '1rem',
                fontWeight: 500,
                color: theme.palette.text.primary,
              }}>
                Insights & Actions
              </Typography>
              <IconButton 
                onClick={() => setIsOpen(false)}
                sx={{ ml: 'auto' }}
              >
                <ChevronRight />
              </IconButton>
            </Box>
          )}
        </Box>
  
        {isOpen && (
          <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
            {!currentTool ? (
              <Alert severity="info" sx={{ m: 2 }}>
                <AlertTitle>No Tool Selected</AlertTitle>
                Select a tool to view relevant insights and guidance
              </Alert>
            ) : !content && defaultContent ? (
              <Box sx={{ p: 2 }}>
                <Box sx={{ mb: 4 }}>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mb: 2,
                    gap: 1.5
                  }}>
                    <TipsAndUpdatesRoundedIcon 
                      sx={{ 
                        color: 'primary.main',
                        fontSize: '1.25rem'
                      }} 
                    />
                    <Typography 
                      sx={{ 
                        fontSize: '0.875rem',
                        fontWeight: 600,
                        letterSpacing: '0.01em',
                        color: 'text.primary'
                      }}
                    >
                      Purpose
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: '0.875rem',
                      color: 'text.secondary',
                      mb: 2,
                      lineHeight: 1.5,
                    }}
                  >
                    {defaultContent.purpose}
                  </Typography>
                </Box>
  
                <Box sx={{ mb: 4 }}>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mb: 2,
                    gap: 1.5
                  }}>
                    <PlaylistAddCheckRoundedIcon 
                      sx={{ 
                        color: 'primary.main',
                        fontSize: '1.25rem'
                      }} 
                    />
                    <Typography 
                      sx={{ 
                        fontSize: '0.875rem',
                        fontWeight: 600,
                        letterSpacing: '0.01em',
                        color: 'text.primary'
                      }}
                    >
                      Action
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: '0.875rem',
                      color: 'text.secondary',
                      mb: 2,
                      lineHeight: 1.5,
                    }}
                  >
                    {defaultContent.action}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Fade in={true} timeout={300}>
                <Box sx={{ py: 2 }}>
                  {content?.explanations?.length > 0 && (
                    <Box sx={{ mb: 4 }}>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        mb: 2,
                        px: 3,
                        gap: 1.5
                      }}>
                        <QuestionAnswerOutlinedIcon 
                          sx={{ 
                            color: 'primary.main',
                            fontSize: '1.25rem'
                          }} 
                        />
                        <Typography 
                          sx={{ 
                            fontSize: '0.875rem',
                            fontWeight: 600,
                            letterSpacing: '0.01em',
                            color: 'text.primary'
                          }}
                        >
                          Key Explanations
                        </Typography>
                      </Box>
                      <Box sx={{ px: 3 }}>
                        {content.explanations.map((item, i) => (
                          <Typography
                            key={i}
                            sx={{
                              fontSize: '0.875rem',
                              color: 'text.secondary',
                              mb: 1.5,
                              lineHeight: 1.5,
                            }}
                          >
                            {item}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  )}
  
                  {content?.actions?.length > 0 && (
                    <Box sx={{ mb: 4 }}>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        mb: 2,
                        px: 3,
                        gap: 1.5
                      }}>
                        <PlaylistAddCheckRoundedIcon 
                          sx={{ 
                            color: 'primary.main',
                            fontSize: '1.25rem'
                          }} 
                        />
                        <Typography 
                          sx={{ 
                            fontSize: '0.875rem',
                            fontWeight: 600,
                            letterSpacing: '0.01em',
                            color: 'text.primary'
                          }}
                        >
                          Recommended Actions
                        </Typography>
                      </Box>
                      <Box sx={{ px: 3 }}>
                        {content.actions.map((item, i) => (
                          <Typography
                            key={i}
                            sx={{
                              fontSize: '0.875rem',
                              color: 'text.secondary',
                              mb: 1.5,
                              lineHeight: 1.5,
                            }}
                          >
                            {item}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  )}
  
                  {content?.lookFors?.length > 0 && (
                    <Box sx={{ mb: 4 }}>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        mb: 2,
                        px: 3,
                        gap: 1.5
                      }}>
                        <QueryStatsRoundedIcon 
                          sx={{ 
                            color: 'primary.main',
                            fontSize: '1.25rem'
                          }} 
                        />
                        <Typography 
                          sx={{ 
                            fontSize: '0.875rem',
                            fontWeight: 600,
                            letterSpacing: '0.01em',
                            color: 'text.primary'
                          }}
                        >
                          What to Look For
                        </Typography>
                      </Box>
                      <Box sx={{ px: 3 }}>
                        {content.lookFors.map((item, i) => (
                          <Typography
                            key={i}
                            sx={{
                              fontSize: '0.875rem',
                              color: 'text.secondary',
                              mb: 1.5,
                              lineHeight: 1.5,
                            }}
                          >
                            {item}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Fade>
            )}
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default InsightSidebar;