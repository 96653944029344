// StickyFilters.js
import React from 'react';
import { 
  Box,
  Container,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  UnfoldLess,
  UnfoldMore,
  FilterList,
} from '@mui/icons-material';
import SharedFilters from './sharedFilters';

const StickyFilters = ({
  schools,
  selectedSchool,
  governanceFilter,
  selectedGrades,
  selectedDriveTime,
  schoolTypeFilter,
  availableSchoolTypes,
  onSchoolChange,
  onGovernanceChange,
  onGradeChange,
  onDriveTimeChange,
  onSchoolTypeChange,
  onHasPreKChange,
  expandedSections,
  onExpandAll,
  onCollapseAll,
  showSchoolType = true,
}) => {
  const isAllExpanded = Object.values(expandedSections).every(value => value === true);

  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'grey.50',
        backdropFilter: 'blur(8px)',
        zIndex: 1200,
        borderBottom: '1px solid',
        borderColor: 'grey.200',
        py: 1.5,
        mb: 3,
        width: '100%',
        height: 'auto',  // Add this
        minHeight: 'fit-content',  // Add this
        boxShadow: '0 2px 4px rgba(0,0,0,0.04)',
      }}
    >
      <Container 
        maxWidth={false}
        sx={{
          px: { xs: 2, sm: 3 },
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          maxWidth: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Tooltip title="Filters">
          <FilterList 
            color="primary" 
            sx={{ 
              opacity: 0.8,
              '&:hover': { opacity: 1 }
            }} 
          />
        </Tooltip>
        
        <Box sx={{ flex: 1 }}>
          <SharedFilters
            schools={schools}
            selectedSchool={selectedSchool}
            governanceFilter={governanceFilter}
            selectedGrades={selectedGrades}
            selectedDriveTime={selectedDriveTime}
            schoolTypeFilter={schoolTypeFilter}
            availableSchoolTypes={availableSchoolTypes}
            onSchoolChange={onSchoolChange}
            onGovernanceChange={onGovernanceChange}
            onGradeChange={onGradeChange}
            onDriveTimeChange={onDriveTimeChange}
            onSchoolTypeChange={onSchoolTypeChange}
            onHasPreKChange={onHasPreKChange}
            showSchoolType={showSchoolType}
            compact={true}
            sticky={true}
            singleRow={true}
          />
        </Box>

        <Tooltip title={isAllExpanded ? "Collapse all sections" : "Expand all sections"}>
          <IconButton 
            onClick={isAllExpanded ? onCollapseAll : onExpandAll}
            size="small"
            sx={{ 
              color: 'primary.main',
              opacity: 0.8,
              '&:hover': {
                backgroundColor: 'primary.light',
                color: 'primary.dark',
                opacity: 1
              }
            }}
          >
            {isAllExpanded ? <UnfoldLess /> : <UnfoldMore />}
          </IconButton>
        </Tooltip>
      </Container>
    </Box>
  );
};

export default StickyFilters;