import React, { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  Alert,
  Snackbar,
  CircularProgress,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import PhoneIcon from '@mui/icons-material/Phone';
import NavigationBar from './NavigationBar';
import SiteFooter from './SiteFooter';
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#FFFFFF',
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.1)',
    },
    '&:hover fieldset': {
      borderColor: '#4F46E5',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#4F46E5',
    },
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#4F46E5',
  },
  '& .MuiInputLabel-root': {
    fontSize: '0.875rem',
  },
  '& .MuiOutlinedInput-input': {
    padding: '12px 14px',
    fontSize: '0.875rem',
  },
});

const SubmitButton = styled(Button)({
  backgroundColor: '#4F46E5',
  color: '#FFFFFF',
  fontSize: '14px',
  fontWeight: 600,
  padding: '10px 24px',
  borderRadius: '20px',
  textTransform: 'none',
  width: '100%',
  boxShadow: '0px 2px 4px rgba(79, 70, 229, 0.2)',
  '&:hover': {
    backgroundColor: '#4338CA',
    boxShadow: '0px 4px 6px rgba(79, 70, 229, 0.3)',
  },
});

const ContactInfoCard = styled(Card)({
  height: '100%',
  backgroundColor: '#FFFFFF',
  border: '1px solid rgba(0, 0, 0, 0.05)',
  boxShadow: 'none',
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
  },
});

const ContactPage = () => {
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      organization: '',
      title: '',
      message: ''
    });
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
      open: false,
      message: '',
      severity: 'success'
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
  
      try {
  
        // Clear form on success
        setFormData({
          name: '',
          email: '',
          organization: '',
          title: '',
          message: ''
        });
  
        setSnackbar({
          open: true,
          message: 'Thank you for your message. We\'ll be in touch soon!',
          severity: 'success'
        });
  
      } catch (error) {
        console.error('Form submission error:', error);
        setSnackbar({
          open: true,
          message: 'There was an error sending your message. Please try again or contact us directly.',
          severity: 'error'
        });
      } finally {
        setLoading(false);
      }
    };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const contactInfo = [
    {
      icon: <EmailIcon sx={{ fontSize: 28, color: '#4F46E5' }} />,
      title: "Email",
      content: "info@educationdatacollective.com"
    },
    {
      icon: <HomeIcon sx={{ fontSize: 28, color: '#4F46E5' }} />,
      title: "Address",
      content: "PO Box 7062\nDenver, CO 80207"
    },
    {
      icon: <PhoneIcon sx={{ fontSize: 28, color: '#4F46E5' }} />,
      title: "Phone",
      content: "720-446-8905"
    }
  ];

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', bgcolor: 'grey.50' }}>
      <NavigationBar />

      <Container maxWidth="lg" sx={{ py: 8, flex: 1 }}>
        <Grid container spacing={4}>
          {/* Contact Form Section */}
          <Grid item xs={12} md={6}>
            <Typography variant="h5" component="h1" gutterBottom sx={{ fontWeight: 700, mb: 3 }}>
              Have Questions? Let's Connect
            </Typography>
            <Box 
              component="form" 
              onSubmit={handleSubmit} 
              sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                gap: 2,
                maxWidth: '450px'
              }}
            >
              <StyledTextField
                required
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                variant="outlined"
                size="small"
              />
              <StyledTextField
                required
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                variant="outlined"
                size="small"
              />
              <StyledTextField
                required
                fullWidth
                label="Organization"
                name="organization"
                value={formData.organization}
                onChange={handleChange}
                variant="outlined"
                size="small"
              />
              <StyledTextField
                required
                fullWidth
                label="Title/Position"
                name="title"
                value={formData.title}
                onChange={handleChange}
                variant="outlined"
                size="small"
              />
              <StyledTextField
                required
                fullWidth
                label="Message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                multiline
                rows={4}
                variant="outlined"
                size="small"
              />
              <Box sx={{ mt: 2 }}>
                <SubmitButton 
                  type="submit" 
                  variant="contained"
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
                </SubmitButton>
              </Box>
            </Box>
          </Grid>

          {/* Contact Information Section */}
          <Grid item xs={12} md={6}>
            <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 700, mb: 3 }}>
              How To Reach Us
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {contactInfo.map((info, index) => (
                <ContactInfoCard key={index}>
                  <CardContent sx={{ display: 'flex', alignItems: 'flex-start', gap: 2, p: 2 }}>
                    <Box sx={{ pt: 0.5 }}>
                      {info.icon}
                    </Box>
                    <Box>
                      <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 0.5 }}>
                        {info.title}
                      </Typography>
                      <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                        {info.content}
                      </Typography>
                    </Box>
                  </CardContent>
                </ContactInfoCard>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <SiteFooter />
    </Box>
  );
};

export default ContactPage;