import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
} from '@mui/material';
import { RACE_CATEGORIES } from '../../../../utils/schoolUtils';
import { getTrendColor } from '../../../../utils/schoolUtils';
import useSchoolStore from '../../../../stores/schoolStore';

const DemographicsValidation = ({ school }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  
  const schoolsEnrollmentData = useSchoolStore(state => state.schoolsEnrollmentData);
  const nearbySchools = useSchoolStore(state => state.nearbySchools);

  // Helper function to get grades excluding Pre-K
  const getGradesExcludingPreK = (enrollmentData) => {
    return Object.entries(enrollmentData || {})
      .filter(([grade, count]) => grade !== 'Pre-Kindergarten' && count > 0)
      .map(([grade]) => grade)
      .join(', ');
  };
  
  // Helper function to check grade overlap
  const hasGradeOverlap = (grades1, grades2) => {
    if (!grades1 || !grades2) return false;
    try {
      const set1 = new Set(grades1.split(', '));
      const set2 = new Set(grades2.split(', '));
      return [...set1].some(grade => set2.has(grade));
    } catch (error) {
      console.error('Error checking grade overlap:', error);
      return false;
    }
  };

  // Helper function to aggregate race data across grades, excluding Pre-K
  const aggregateRaceData = (raceDataByGrade, enrollmentByGrade) => {
    if (!raceDataByGrade || !enrollmentByGrade) return {};
    
    const totals = {};
    let total = 0;

    Object.entries(raceDataByGrade).forEach(([grade, gradeData]) => {
      // Skip Pre-K data
      if (grade === 'Pre-Kindergarten' || !enrollmentByGrade[grade]) return;

      Object.entries(gradeData).forEach(([category, count]) => {
        totals[category] = (totals[category] || 0) + (count || 0);
        total += (count || 0);
      });
    });

    // Calculate percentages
    const result = {
      counts: totals,
      percentages: {},
      total
    };

    Object.entries(totals).forEach(([category, count]) => {
      result.percentages[category] = total > 0 ? (count / total * 100).toFixed(0) : '0.0';
    });

    return result;
  };

  // Calculate 5-year changes in enrollment for each race/ethnicity
  const calculate5YearChanges = (current, comparison) => {
    const changes = {};
    Object.keys(current.counts || {}).forEach(category => {
      const currentCount = current.counts[category] || 0;
      const comparisonCount = comparison?.counts?.[category] || 0;
      changes[category] = comparisonCount > 0 ? 
        (((currentCount - comparisonCount) / comparisonCount) * 100).toFixed(0) :
        '0.0';
    });
    return changes;
  };

  useEffect(() => {
    const processData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Get selected school data
        const selectedSchoolData = schoolsEnrollmentData.find(d => d.ncessch === school.ncessch);
        if (!selectedSchoolData) throw new Error('Selected school data not found');

        // Get current grades for selected school, excluding Pre-K
        const currentGrades = getGradesExcludingPreK(selectedSchoolData.enrollment_by_grade?.current);

        // Process selected school demographics
        const selectedSchoolMetrics = {
          current: aggregateRaceData(
            selectedSchoolData.race_data?.current,
            selectedSchoolData.enrollment_by_grade?.current
          ),
          comparison: aggregateRaceData(
            selectedSchoolData.race_data?.comparison,
            selectedSchoolData.enrollment_by_grade?.comparison
          )
        };

        // Get nearby schools data
        const nearbySchoolsData = (nearbySchools?.current?.['10'] || [])
          .map(s => schoolsEnrollmentData.find(d => d.ncessch === s.ncessch))
          .filter(s => s && s.ncessch !== school.ncessch)
          .filter(s => {
            const grades = getGradesExcludingPreK(s.enrollment_by_grade?.current);
            return hasGradeOverlap(grades, currentGrades);
          });

        // Calculate area metrics (including selected school)
        const areaMetrics = {
          current: nearbySchoolsData.reduce((acc, school) => {
            const schoolMetrics = aggregateRaceData(
              school.race_data?.current,
              school.enrollment_by_grade?.current
            );
            Object.entries(schoolMetrics.counts).forEach(([category, count]) => {
              acc.counts[category] = (acc.counts[category] || 0) + count;
            });
            acc.total += schoolMetrics.total;
            return acc;
          }, {
            counts: { ...selectedSchoolMetrics.current.counts },
            total: selectedSchoolMetrics.current.total,
            percentages: {}
          }),
          comparison: nearbySchoolsData.reduce((acc, school) => {
            const schoolMetrics = aggregateRaceData(
              school.race_data?.comparison,
              school.enrollment_by_grade?.comparison
            );
            Object.entries(schoolMetrics.counts).forEach(([category, count]) => {
              acc.counts[category] = (acc.counts[category] || 0) + count;
            });
            acc.total += schoolMetrics.total;
            return acc;
          }, {
            counts: { ...selectedSchoolMetrics.comparison.counts },
            total: selectedSchoolMetrics.comparison.total,
            percentages: {}
          })
        };

        // Calculate percentages for area metrics
        Object.entries(areaMetrics.current.counts).forEach(([category, count]) => {
          areaMetrics.current.percentages[category] = areaMetrics.current.total > 0 ? 
            (count / areaMetrics.current.total * 100).toFixed(0) : '0.0';
        });
        Object.entries(areaMetrics.comparison.counts).forEach(([category, count]) => {
          areaMetrics.comparison.percentages[category] = areaMetrics.comparison.total > 0 ? 
            (count / areaMetrics.comparison.total * 100).toFixed(0) : '0.0';
        });

        // Calculate nearby schools metrics (excluding selected school)
        const nearbyMetrics = {
          current: nearbySchoolsData.reduce((acc, school) => {
            const schoolMetrics = aggregateRaceData(
              school.race_data?.current,
              school.enrollment_by_grade?.current
            );
            Object.entries(schoolMetrics.counts).forEach(([category, count]) => {
              acc.counts[category] = (acc.counts[category] || 0) + count;
            });
            acc.total += schoolMetrics.total;
            return acc;
          }, { counts: {}, total: 0, percentages: {} }),
          comparison: nearbySchoolsData.reduce((acc, school) => {
            const schoolMetrics = aggregateRaceData(
              school.race_data?.comparison,
              school.enrollment_by_grade?.comparison
            );
            Object.entries(schoolMetrics.counts).forEach(([category, count]) => {
              acc.counts[category] = (acc.counts[category] || 0) + count;
            });
            acc.total += schoolMetrics.total;
            return acc;
          }, { counts: {}, total: 0, percentages: {} })
        };

        // Calculate percentages for nearby metrics
        Object.entries(nearbyMetrics.current.counts).forEach(([category, count]) => {
          nearbyMetrics.current.percentages[category] = nearbyMetrics.current.total > 0 ? 
            (count / nearbyMetrics.current.total * 100).toFixed(0) : '0.0';
        });
        Object.entries(nearbyMetrics.comparison.counts).forEach(([category, count]) => {
          nearbyMetrics.comparison.percentages[category] = nearbyMetrics.comparison.total > 0 ? 
            (count / nearbyMetrics.comparison.total * 100).toFixed(0) : '0.0';
        });

        setData({
          selectedSchool: selectedSchoolMetrics,
          selectedSchoolChanges: calculate5YearChanges(selectedSchoolMetrics.current, selectedSchoolMetrics.comparison),
          areaMetrics: areaMetrics.current,
          areaChanges: calculate5YearChanges(areaMetrics.current, areaMetrics.comparison),
          nearbyMetrics: nearbyMetrics.current,
          nearbyChanges: calculate5YearChanges(nearbyMetrics.current, nearbyMetrics.comparison),
          nearbySchoolsCount: nearbySchoolsData.length
        });

      } catch (err) {
        console.error('Error processing demographic data:', err);
        setError(err.message || 'Failed to process demographic data');
      } finally {
        setLoading(false);
      }
    };

    if (school?.ncessch && schoolsEnrollmentData?.length > 0 && nearbySchools?.current) {
      processData();
    }
  }, [school, schoolsEnrollmentData, nearbySchools]);

  const renderSummarySection = (title, metrics, changes = null, schoolCount = null) => {
    if (!metrics) return null;

    return (
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom color="primary">
            {title}
            {schoolCount !== null && (
              <Typography variant="subtitle2" component="span" color="text.secondary" sx={{ ml: 2 }}>
                ({schoolCount} schools)
              </Typography>
            )}
          </Typography>
          
          <TableContainer component={Paper} variant="outlined">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Race/Ethnicity</TableCell>
                  <TableCell align="right">Count</TableCell>
                  <TableCell align="right">Percentage</TableCell>
                  {changes && <TableCell align="right">5-Year Change</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(RACE_CATEGORIES).map(([key, label]) => (
                  <TableRow key={key}>
                    <TableCell>{label}</TableCell>
                    <TableCell align="right">{(metrics.counts[key] || 0).toLocaleString()}</TableCell>
                    <TableCell align="right">{metrics.percentages[key] || '0.0'}%</TableCell>
                    {changes && (
                      <TableCell align="right">
                        <Typography
                          component="span"
                          sx={{
                            color: getTrendColor(parseFloat(changes[key])),
                            fontWeight: Math.abs(parseFloat(changes[key])) >= 5 ? 'bold' : 'normal'
                          }}
                        >
                          {changes[key] > 0 ? '+' : ''}{changes[key]}%
                        </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                <TableRow sx={{ bgcolor: 'grey.100' }}>
                  <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {metrics.total.toLocaleString()}
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>100%</TableCell>
                  {changes && <TableCell />}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    );
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (!data) {
    return <Alert severity="info">No demographic data available</Alert>;
  }

  return (
    <Box>
      {/* Selected School Demographics */}
      {renderSummarySection(
        'Selected School Demographics',
        data.selectedSchool.current,
        data.selectedSchoolChanges
      )}

      {/* Area Demographics (Including Selected School) */}
      {renderSummarySection(
        'Area Demographics (Including Selected School)',
        data.areaMetrics,
        data.areaChanges,
        data.nearbySchoolsCount + 1
      )}

      {/* Nearby Schools Demographics (Excluding Selected School) */}
      {renderSummarySection(
        'Nearby Schools Demographics (Excluding Selected School)',
        data.nearbyMetrics,
        data.nearbyChanges,
        data.nearbySchoolsCount
      )}
    </Box>
  );
};

export default DemographicsValidation;