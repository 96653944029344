import React from 'react';
import { 
  Box,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  UnfoldLess,
  UnfoldMore,
  FilterList,
  TrendingDown,
  TrendingFlat,
  TrendingUp
} from '@mui/icons-material';
import { formatSchoolName } from '../../utils/nameFormatter';

const ProjectionsFilters = ({
  schools,
  selectedSchool,
  timeframe,
  view,
  selectedGrades,
  availableGrades,
  selectedScenarios = ['low', 'median', 'high'], // Default all scenarios selected
  onSchoolChange,
  onTimeframeChange,
  onViewChange,
  onGradeChange,
  onScenariosChange,
  expandedSections,
  onExpandAll,
  onCollapseAll,
  warningElement
}) => {
  const isAllExpanded = Object.values(expandedSections).every(value => value === true);

  // Handle scenario toggle
  const handleScenarioChange = (event, newScenarios) => {
    // Prevent deselecting all scenarios
    if (newScenarios.length === 0) return;
    onScenariosChange?.(newScenarios);
  };

  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'grey.50',
        backdropFilter: 'blur(8px)',
        zIndex: 1200,
        borderBottom: '1px solid',
        borderColor: 'grey.200',
        py: 1.5,
        mb: 3,
        width: '100%',
        height: 'auto',
        minHeight: 'fit-content',
        boxShadow: '0 2px 4px rgba(0,0,0,0.04)',
      }}
    >
      <Container 
        maxWidth={false}
        sx={{
          px: { xs: 2, sm: 3 },
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          maxWidth: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 2,
          flexWrap: 'wrap'
        }}>
          <Tooltip title="Filters">
            <FilterList 
              color="primary" 
              sx={{ 
                opacity: 0.8,
                '&:hover': { opacity: 1 }
              }} 
            />
          </Tooltip>

          <FormControl sx={{ flexGrow: 1, minWidth: 200 }}>
            <InputLabel size="small">Select a School</InputLabel>
            <Select
              value={selectedSchool ? selectedSchool.ncessch : ''}
              label="Select a School"
              onChange={onSchoolChange}
              size="small"
            >
              {schools.map((school) => (
                <MenuItem key={school.ncessch} value={school.ncessch}>
                  {formatSchoolName(school.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <ToggleButtonGroup
            value={timeframe}
            exclusive
            onChange={onTimeframeChange}
            size="small"
          >
            <ToggleButton value="1-year">1-Year</ToggleButton>
            <ToggleButton value="3-year">3-Year</ToggleButton>
            <ToggleButton value="5-year">5-Year</ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={onViewChange}
            size="small"
          >
            <ToggleButton value="school-level">School-Level</ToggleButton>
            <ToggleButton value="grade-level">Grade-Level</ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup
            value={selectedScenarios}
            onChange={handleScenarioChange}
            size="small"
            aria-label="projection scenarios"
          >
            <ToggleButton value="low" aria-label="low scenario">
              <Tooltip title="Low Scenario">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <TrendingDown />
                  Low
                </Box>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="median" aria-label="median scenario">
              <Tooltip title="Medium Scenario">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <TrendingFlat />
                  Medium
                </Box>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="high" aria-label="high scenario">
              <Tooltip title="High Scenario">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <TrendingUp />
                  High
                </Box>
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>

          {view === 'grade-level' && (
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="grade-select-label" size="small">Grade(s)</InputLabel>
              <Select
                labelId="grade-select-label"
                multiple
                value={selectedGrades}
                label="Grade(s)"
                onChange={onGradeChange}
                size="small"
              >
                {availableGrades
                  .filter(grade => grade !== 'total')
                  .map((grade) => (
                    <MenuItem key={grade} value={grade}>
                      {grade === 'Kindergarten' ? grade : `Grade ${grade}`}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}

          <Tooltip title={isAllExpanded ? "Collapse all sections" : "Expand all sections"}>
            <IconButton 
              onClick={isAllExpanded ? onCollapseAll : onExpandAll}
              size="small"
              sx={{ 
                color: 'primary.main',
                opacity: 0.8,
                '&:hover': {
                  backgroundColor: 'primary.light',
                  color: 'primary.dark',
                  opacity: 1
                }
              }}
            >
              {isAllExpanded ? <UnfoldLess /> : <UnfoldMore />}
            </IconButton>
          </Tooltip>
        </Box>

        {warningElement}
      </Container>
    </Box>
  );
};

export default ProjectionsFilters;