import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Switch,
  FormControlLabel,
  Alert,
  AlertTitle,
  Paper
} from '@mui/material';
import { usePrivacyConsent } from '../../hooks/usePrivacyConsent';

const ManagePreferences = () => {
  const {
    analyticsAllowed,
    applyConsent
  } = usePrivacyConsent();

  const handleAnalyticsToggle = async (event) => {
    await applyConsent({
      analyticsAllowed: event.target.checked,
      gpcEnforced: false
    });
  };

  return (
    <Box sx={{ p: 3, maxWidth: 800, mx: 'auto' }}>
      <Paper elevation={0} sx={{ mb: 3 }}>
        <Typography variant="h5" gutterBottom>
          Privacy Preferences
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
          Manage how we use cookies and tracking technologies to improve your experience
        </Typography>
      </Paper>

      <Card>
        <CardContent>
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Analytics Cookies
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Help us understand how you use our site to improve our service
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={analyticsAllowed}
                  onChange={handleAnalyticsToggle}
                  color="primary"
                />
              }
              label={analyticsAllowed ? "Enabled" : "Disabled"}
            />
          </Box>

          {navigator.globalPrivacyControl && (
            <Alert severity="info" sx={{ mt: 2 }}>
              <AlertTitle>Global Privacy Control Detected</AlertTitle>
              In accordance with your browser settings, we've automatically disabled non-essential cookies.
            </Alert>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default ManagePreferences;