import React from 'react';
import { 
  Box, 
  Typography,
  Link,
  List,
  ListItem,
  styled
} from '@mui/material';
import NavigationBar from './NavigationBar';
import SiteFooter from './SiteFooter';
import LegalDocLayout from './LegalDocLayout';

// Styled Components
const ContentSection = styled(Box)({
  marginBottom: '32px',
});

const StyledLink = styled(Link)({
  color: '#4F46E5',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const PageWrapper = styled(Box)(({ theme }) => ({
    backgroundColor: '#F8F9FA',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
  }));

function PrivacyNotice() {
  return (
    <PageWrapper>
      <NavigationBar />
      
      <LegalDocLayout>
        <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontWeight: 700 }}>
          EDUCATION DATA COLLECTIVE, LLC
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center" sx={{ fontWeight: 600 }}>
        PRIVACY NOTICE
        </Typography>
        <Typography variant="subtitle1" gutterBottom align="center">
        <Box component="span" sx={{ fontWeight: 'bold' }}>Last Updated</Box>: November 7, 2024
        </Typography>

        <ContentSection>
        <Typography paragraph>
            This Privacy Notice (the "Privacy Notice") describes the privacy and data protection practices of Education Data
            Collective, LLC ("EDC," "we," "us," or "our"). This Privacy Notice is intended to provide details regarding the information
            we collect and/or process through our website located at{' '}
            <StyledLink href="https://www.educationdatacollective.com/" target="_blank" rel="noopener">
            https://www.educationdatacollective.com/
            </StyledLink>
            {' '}or any successor website (the "Site"), and all other applications, products, services, features, tools, application programming interfaces
            and software that we own or control and which post or link to this Privacy Notice (collectively, the "Services"), or
            through other communication methods such as email or phone.
        </Typography>

        <Typography paragraph sx={{ fontWeight: 'bold' }}>
            PLEASE READ THIS PRIVACY NOTICE CAREFULLY TO UNDERSTAND HOW WE HANDLE YOUR PERSONAL INFORMATION. IF
            YOU DO NOT AGREE TO THIS PRIVACY NOTICE, PLEASE DO NOT ACCESS THE SITE OR OTHERWISE USE OUR SERVICES.
        </Typography>
        </ContentSection>

        {/* Section 1: Information We Collect */}
        <ContentSection>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
            1. Information We Collect
        </Typography>
        <Typography paragraph>
            The amount and type of information we collect about you will depend upon the Services you use and the information
            you choose to provide. Please note that we need certain types of information so that you can access our Services or so
            we can provide the Services you request. If you do not provide us with the required information or ask us to delete it,
            you may no longer be able to access or use our Services.
        </Typography>

        <Typography paragraph>
            All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any
            changes to such personal information. You may not provide us information that is obscene, defamatory, infringing,
            malicious, or that violates any law.
        </Typography>

        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600 }}>
            Information You Provide to Us
        </Typography>
        <Typography paragraph>
            It's probably no surprise that we collect information that you provide to us. For example, we collect information from you
            when:
        </Typography>
        <List>
            <ListItem>you request information from us or otherwise communicate with us;</ListItem>
            <ListItem>an account is created by you or on your behalf;</ListItem>
            <ListItem>you carry out transactions through our Site and Services;</ListItem>
            <ListItem>you request customer support or technical assistance with the Services;</ListItem>
            <ListItem>you complete any forms, respond to a survey that we might ask you to complete for research purposes.</ListItem>
        </List>
        </ContentSection>

        {/* Section 2: Cookies and Similar Technologies */}
        <ContentSection>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
            2. Cookies and Similar Technologies
        </Typography>
        <Typography paragraph>
            Like many organizations, we collect information about how you use our Site by setting and accessing cookies on your
            device. A cookie is a small piece of information sent by our Site that is saved on your storage drive by your computer's
            browser.
        </Typography>
        </ContentSection>

        
        {/* Section 3: How We Use Your Personal Information */}
        <ContentSection>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
            3. How We Use Your Personal Information
        </Typography>
        <Typography paragraph>
            We, or our service providers working on our behalf, may use your personal information for various purposes depending
            on the types of information we have collected from you in order to:
        </Typography>
        <List>
            <ListItem>
            <Typography>
                Provide you with the Services. We may also use such information you provide in aggregated or deidentified
                form, to refine and improve the Services.
            </Typography>
            </ListItem>
            <ListItem>
            <Typography>
                Fulfill any other purpose for which you provide it.
            </Typography>
            </ListItem>
            <ListItem>
            <Typography>
                Process transactions.
            </Typography>
            </ListItem>
            <ListItem>
            <Typography>
                Give you notices about your account and renewals.
            </Typography>
            </ListItem>
            <ListItem>
            <Typography>
                Notify you when updates to the Services are available, and of enhancements or changes to any products or
                services we offer.
            </Typography>
            </ListItem>
            <ListItem>
            <Typography>
                Respond to your requests for information and provide you with more effective and efficient customer
                service.
            </Typography>
            </ListItem>
            <ListItem>
            <Typography>
                Market, promote, and conduct advertising for the Services. For example, we use your information to send
                you marketing communications via email or other contact methods where permitted by applicable law.
            </Typography>
            </ListItem>
            <ListItem>
            <Typography>
                Maintain, secure, and improve current and future Services. For example, our Services may collect your
                information through forms that you submit and may collect information automatically about your use of the
                Services to inform us about Services performance, areas of improvement, and Services updates and changes
                we may decide to evaluate.
            </Typography>
            </ListItem>
            <ListItem>
            <Typography>
                Comply with any procedures, laws, and regulations which apply to us or our processing.
            </Typography>
            </ListItem>
            <ListItem>
            <Typography>
                Establish, exercise, or defend our legal rights.
            </Typography>
            </ListItem>
            <ListItem>
            <Typography>
                Conduct outreach, recruiting and hiring activities for opportunities with us.
            </Typography>
            </ListItem>
            <ListItem>
            <Typography>
                Other legitimate purposes as required or permitted by applicable law.
            </Typography>
            </ListItem>
        </List>
        </ContentSection>

        {/* Section 4: Aggregate/Deidentified Data */}
        <ContentSection>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
            4. Aggregate/Deidentified Data
        </Typography>
        <Typography paragraph>
            We may aggregate and/or deidentify any information collected through the Services so that the information can no
            longer be linked to you or your device. We may use the aggregated and/or deidentified information for any purpose,
            including without limitation for research purposes and to promote the Services, and may also share such data with any
            third parties, including advertisers, promotional partners, and sponsors.
        </Typography>
        </ContentSection>

        {/* Section 5: How We Share and Disclose Your Personal Information */}
        <ContentSection>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
            5. How We Share and Disclose Your Personal Information
        </Typography>
        <Typography paragraph>
            We may share your personal information in the following ways:
        </Typography>
        <List>
            <ListItem>
            <Typography>
                <Box component="span" sx={{ fontWeight: 600 }}>Third-Party Service Providers:</Box> We may provide access to or share your information with select third parties
                who perform services on our behalf as a service provider. They provide a variety of services to us, including
                data storage and/or processing, website and app hosting and operation, surveys, security, fraud prevention,
                billing, payment processing, marketing and advertising, and legal services.
            </Typography>
            </ListItem>
            <ListItem>
            <Typography>
                <Box component="span" sx={{ fontWeight: 600 }}>Protection of EDC and Others:</Box> We may disclose the information we collect and maintain about you if
                required to do so by law or in a good faith belief that such access, retention or disclosure is reasonably
                necessary to: (a) comply with legal process; (b) enforce this Privacy Notice; (c) respond to claims that any content
                violates the rights of third parties; (d) respond to your requests for customer service; and/or (e) protect the rights,
                property or personal safety of EDC, its agents and affiliates, its users and/or the public.
            </Typography>
            </ListItem>
            <ListItem>
            <Typography>
                <Box component="span" sx={{ fontWeight: 600 }}>Your Employer and Users:</Box> We may share your information with your employer, your employer's other users
                of the Services and the EDC customer paying for your use of the Services.
            </Typography>
            </ListItem>
            <ListItem>
            <Typography>
                <Box component="span" sx={{ fontWeight: 600 }}>Consent:</Box> We may share your information when we have your consent.
            </Typography>
            </ListItem>
            <ListItem>
            <Typography>
                <Box component="span" sx={{ fontWeight: 600 }}>For the Purpose for Which You Provide It:</Box> We may share information you provide for the purpose for which
                you provide it.
            </Typography>
            </ListItem>
            <ListItem>
            <Typography>
                <Box component="span" sx={{ fontWeight: 600 }}>Intracompany:</Box> We may share your information with any affiliates and subsidiaries to operate our business.
            </Typography>
            </ListItem>
            <ListItem>
            <Typography>
                <Box component="span" sx={{ fontWeight: 600 }}>Enforcement:</Box> To enforce our rights arising from any contracts entered into between you and us, including
                the Terms of Service and for billing and collection.
            </Typography>
            </ListItem>
        </List>
        </ContentSection>

        {/* Section 6: Retention of Your Personal Information */}
        <ContentSection>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
            6. Retention of Your Personal Information
        </Typography>
        <Typography paragraph>
            We may retain your personal information both during and after the time you use our Services, but we keep your personal
            information for no longer than needed pursuant to a legitimate business purpose. For example, we may retain contact
            information after you register an account with us to maintain our internal financial or transactional records, to
            communicate with you about our relationship, or to deliver marketing communications to you in accordance with
            applicable law.
        </Typography>
        <Typography paragraph>
            The length of time for which we retain information depends on the purposes for which we collected and used it,
            requirements of applicable laws, the amount, nature, and sensitivity of the information, the potential risk of harm from
            unauthorized use or disclosure of the information, the resolution of any pending or threatened disputes, and
            enforcement of our agreements.
        </Typography>
        <Typography paragraph>
            When we no longer require the personal information, we will either delete or deidentify it, or if this is not possible,
            securely store it in accordance with this Privacy Notice and cease use of the personal information until deletion is
            possible. If we deidentify your personal information (so that it can no longer be associated with you and thus is no longer
            considered personal information under relevant laws), we may retain this information for longer periods.
        </Typography>
        </ContentSection>

        {/* Section 7: How We Protect Your Personal Information */}
        <ContentSection>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
            7. How We Protect Your Personal Information
        </Typography>
        <Typography paragraph>
            We use physical, administrative, and technical security measures to protect the confidentiality, integrity, and availability
            of your information. The safety and security of your information also depends on you. Where we have given you (or
            where you have chosen) a password for access to certain parts of our Services, you are responsible for keeping this
            password confidential. Do not share your password with anyone.
        </Typography>
        <Typography paragraph>
            Unfortunately, the transmission of information via the internet is not completely secure. Although we use measures to
            protect your personal information, we cannot guarantee the security of your personal information transmitted to our
            Services.
        </Typography>
        </ContentSection>

        {/* Section 8: Rights and Choices */}
        <ContentSection>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
            8. Rights and Choices
        </Typography>
        
        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600, mt: 2 }}>
            Right to Know What Personal Information is Being Collected (Right to Access)
        </Typography>
        <Typography paragraph>
            You have the right to request the following information:
        </Typography>
        <List>
            <ListItem>
            <Typography>Categories of personal information collected about you.</Typography>
            </ListItem>
            <ListItem>
            <Typography>Categories of sources from which the personal information is collected.</Typography>
            </ListItem>
            <ListItem>
            <Typography>The business or commercial purpose for collecting, selling, or sharing your personal information.</Typography>
            </ListItem>
            <ListItem>
            <Typography>Categories of third parties to whom we disclose your personal information.</Typography>
            </ListItem>
            <ListItem>
            <Typography>The specific pieces of personal information we have collected about you.</Typography>
            </ListItem>
        </List>

        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600, mt: 2 }}>
            Right to Delete Personal Information
        </Typography>
        <Typography paragraph>
            You have the right to request that we delete any personal information about you that we have collected from you.
        </Typography>

        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600, mt: 2 }}>
            Right to Correct Inaccurate Personal Information
        </Typography>
        <Typography paragraph>
            You have the right to request that we correct your inaccurate personal information if we maintain inaccurate personal
            information about you.
        </Typography>

        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600, mt: 2 }}>
            Right to Know What Personal Information is Sold or Shared and to Whom
        </Typography>
        <Typography paragraph>
            You have the right, if we sell or share your information, to request that we disclose to you the following:
        </Typography>
        <List>
            <ListItem>
            <Typography>The categories of personal information that we collected from you.</Typography>
            </ListItem>
            <ListItem>
            <Typography>
                The categories of personal information that we sold or shared about you and the categories of personal
                information for each category of third parties to whom the personal information was sold or shared.
            </Typography>
            </ListItem>
            <ListItem>
            <Typography>
                The categories of personal information that we disclosed about you for a business purpose and the
                categories of persons to whom it was disclosed for a business purpose.
            </Typography>
            </ListItem>
        </List>

        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600, mt: 2 }}>
            Right to Opt Out of Sale or Sharing of Personal Information
        </Typography>
        <Typography paragraph>
            You have the right to direct us, if we sell or share your personal information to third parties not to sell or share your 
            personal information. To exercise your opt-out right, please contact us at{' '}
            <StyledLink href="mailto:support@educationdatacollective.com">
            support@educationdatacollective.com
            </StyledLink>.
        </Typography>

        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600, mt: 2 }}>
            Right to Limit Use and Disclosure of Sensitive Personal Information
        </Typography>
        <Typography paragraph>
            You have the right to direct us to limit the use of your sensitive personal information to the use which is necessary to perform the Services.
        </Typography>

        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600, mt: 2 }}>
            Right of No Retaliation Following Opt Out or Exercise of Other Rights
        </Typography>
        <Typography paragraph>
            We may not discriminate against you if you exercise any of your rights as described above.
        </Typography>

        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600, mt: 2 }}>
            Right to Data Portability
        </Typography>
        <Typography paragraph>
            You have the right to obtain your personal information in a portable and, to the extent technically feasible, readily usable format that allows you to transmit the data to another entity without hindrance.
        </Typography>
        </ContentSection>

        {/* Section 9: Requests */}
        <ContentSection>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
            9. Requests
        </Typography>
        <Typography paragraph>
            If you wish to exercise any of the rights described above, please submit a request. In your request, please specify which
            right you are seeking to exercise and the scope of the request. We may require specific information from you to help us
            verify your identity and process your request. If we are unable to verify your identity, we may deny your request. You can
            designate an authorized agent to submit requests on your behalf. However, we will require written proof of the agent's
            permission to do so and verify your identity directly. Requests should be directed to{' '}
            <StyledLink href="mailto:support@educationdatacollective.com">
            support@educationdatacollective.com
            </StyledLink>.
        </Typography>
        <Typography paragraph>
            When you submit a request, do not send us, directly or indirectly, any sensitive or special categories of personal
            information (e.g., social security numbers or other national or state identifiers, health information, biometric data, and
            so on).
        </Typography>
        <Typography paragraph>
            We will evaluate and respond to your request to the extent required by law (or in our discretion if not required by law) in
            the time required by law and as permitted by our contracts, confidentiality obligations, and applicable laws and
            regulations. We may not be able to provide all of the information requested or fulfill your request due to certain
            exceptions enumerated under applicable law. In such a case, we will inform you of the reasons we cannot fulfill all or part
            of your request.
        </Typography>
        </ContentSection>

        {/* Section 10: OPT-OUT Preference Signal */}
        <ContentSection>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
            10. OPT-OUT Preference Signal
        </Typography>
        <Typography paragraph>
            If your browser supports an opt-out preference signal, you may be able to opt out of the sharing or sale of your personal
            information by some websites by properly configuring the opt-out preference signal. If you subsequently delete cookies,
            change your browser settings, or use a different browser, you will need to turn the opt-out signal on again. Our Services
            may not recognize or react in response to such signals. In the event our Services become subject to legal requirements to
            recognize opt-out signals, we will assess and provide an appropriate response to such signals.
        </Typography>
        </ContentSection>

        {/* Section 11: Children's Privacy */}
        <ContentSection>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
            11. Children's Privacy
        </Typography>
        <Typography paragraph>
            The Services are intended for general audiences and not for children under the age of 18. We do not knowingly collect,
            share, process, or sell personal information of children under the age of 18 without parental consent. If we become
            aware that we have inadvertently collected or received personal information from children under the age of 18 without
            valid parental consent, we will take reasonable steps to delete that information from our systems as soon as reasonably
            practicable.
        </Typography>
        </ContentSection>

        {/* Section 12: Third-Party Sites and Applications */}
        <ContentSection>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
            12. Third-Party Sites and Applications
        </Typography>
        <Typography paragraph>
            Our Services may provide links to or make available integrations with third-party website or applications ("Third-Party
            Site"). Such Third-Party Sites are outside our control and not covered by this Privacy Notice. We are not liable for any
            information, content, products, services, software, or other materials available on or through Third-Party Sites. The use
            of a Third-Party Site or any information or other content found on a Third-Party Site is subject to and governed by the
            terms and conditions of such Third-Party Site. We encourage you to review the privacy notices posted on such
            Third-Party Site.
        </Typography>
        </ContentSection>

        {/* Section 13: Business Transfers */}
        <ContentSection>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
            13. Business Transfers
        </Typography>
        <Typography paragraph>
            As we continue to develop our business, we may buy, merge, or partner with other entities. In such transactions user
            information may be among the transferred assets. If a portion or all of our assets are sold or transferred to a third party,
            user information (including information processed in accordance with this Privacy Notice) may be one of the transferred
            business assets. If such transfer is subject to additional mandatory restrictions under applicable laws, we will comply with
            such restrictions. Any third party to which we transfer or sell our assets will have the right to continue to use your
            information in the manner set out in this Privacy Notice.
        </Typography>
        </ContentSection>

        {/* Section 14: Changes to Our Privacy Notice */}
        <ContentSection>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
            14. Changes to Our Privacy Notice
        </Typography>
        <Typography paragraph>
            We reserve the right to amend this Privacy Notice at any time to reflect changes in the law, our data collection and use
            practices, our Services, advances in technology, and for other purposes. We will make the revised Privacy Notice
            accessible through the Services and encourage you to review the Privacy Notice periodically. Any changes will be
            effective immediately upon the posting of the revised Privacy Notice. The "last updated" date included at the top of this
            Privacy Notice will indicate when it was last updated. If we make a material change to the Privacy Notice, you will be
            provided with appropriate notice by email or in accordance with other legal requirements. Your continued use of the
            Services following the notice of such changes indicates that you have read, understood, and agreed to be bound by the
            latest version of the new Privacy Notice. This Privacy Notice is not intended to, nor does it, create any contractual or legal
            right in or on behalf of any party, including you.
        </Typography>
        </ContentSection>

        
        {/* Section 15: Contact Information */}
        <ContentSection>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
            15. Contact Information
        </Typography>
        <Typography paragraph>
            Please feel free to contact us if you have any questions about this Privacy Notice or our associated information practices.
            You may contact us as follows:
        </Typography>
        <Typography component="div">
            Education Data Collective, LLC<br />
            P.O. Box 7062, Denver, CO 80207<br />
            Email: <StyledLink href="mailto:support@educationdatacollective.com">
            support@educationdatacollective.com
            </StyledLink>
            </Typography>
        </ContentSection>
      </LegalDocLayout>
    <SiteFooter />
    </PageWrapper>
);
}

export default PrivacyNotice;