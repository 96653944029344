import React, { useEffect, useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';

// Register the required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const ChartWrapper = ({ 
  type = 'line', 
  data, 
  options, 
  plugins = [], 
  height = 400 
}) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    // If there's an existing chart, destroy it first
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      
      // Register any additional plugins passed as props
      plugins.forEach(plugin => {
        if (!ChartJS.registry.plugins.get(plugin.id)) {
          ChartJS.register(plugin);
        }
      });

      // Create new chart
      chartInstance.current = new ChartJS(ctx, {
        type,
        data,
        options: {
          ...options,
          responsive: true,
          maintainAspectRatio: false,
        },
        plugins
      });
    }

    // Cleanup function
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data, options, type, plugins]);

  return (
    <div style={{ width: '100%', height }}>
      <canvas ref={chartRef} />
    </div>
  );
};

export default ChartWrapper;