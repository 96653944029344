import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
  useTheme,
  useMediaQuery
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
}));

const StyledToolbar = styled(Toolbar)({
  justifyContent: 'space-between',
  padding: '0px 24px',
  minHeight: 'unset',
  height: 'auto',
});

const LogoWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  padding: '0px 0',
  '& img': {
    height: '100px',
    width: 'auto',
    display: 'block',
  }
});

const NavButton = styled(Button)({
  color: '#111827',
  fontSize: '14px',
  fontWeight: 600,
  textTransform: 'none',
  padding: '8px 16px',
  '&:hover': {
    backgroundColor: 'rgba(79, 70, 229, 0.04)',
  },
});

const ClientLoginButton = styled(Button)({
  color: '#4F46E5',
  fontSize: '14px',
  fontWeight: 600,
  textTransform: 'none',
  padding: '8px 16px',
  '&:hover': {
    backgroundColor: 'rgba(79, 70, 229, 0.04)',
  },
});

const RequestDemoButton = styled(Button)({
  backgroundColor: '#4F46E5',
  color: '#FFFFFF',
  fontSize: '14px',
  fontWeight: 600,
  padding: '8px 24px',
  borderRadius: '24px',
  textTransform: 'none',
  boxShadow: '0px 2px 4px rgba(79, 70, 229, 0.2)',
  '&:hover': {
    backgroundColor: '#4338CA',
    boxShadow: '0px 4px 6px rgba(79, 70, 229, 0.3)',
  },
});

const NavigationBar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navItems = [
    { label: 'Explore Our Tools', href: '/product' },
    { label: 'Meet The Collective', href: '/about' },
    { label: "Let's Connect", href: '/contact' },
  ];

  return (
    <StyledAppBar position="sticky">
      <Container maxWidth="xl">
        <StyledToolbar disableGutters>
        <LogoWrapper
            component="a"
            href="/"
            sx={{ 
              textDecoration: 'none',
              cursor: 'pointer'
            }}
          >
            <img
              src="/images/edc_logo_horizontal.png"
              alt="EDC Logo"
            />
          </LogoWrapper>

          {isMobile ? (
            <>
              <IconButton
                size="large"
                aria-label="navigation menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                sx={{ color: '#111827' }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
              >
                {navItems.map((item) => (
                  <MenuItem 
                    key={item.label} 
                    onClick={handleCloseNavMenu}
                    component="a"
                    href={item.href}
                  >
                    <Typography textAlign="center">{item.label}</Typography>
                  </MenuItem>
                ))}
                <MenuItem 
                  onClick={handleCloseNavMenu}
                  component="a"
                  href="/login"
                >
                  <Typography textAlign="center">Client Login</Typography>
                </MenuItem>
                <MenuItem 
                  onClick={handleCloseNavMenu}
                  component="a"
                  href="/contact"
                >
                  <Typography textAlign="center">Request Demo</Typography>
                </MenuItem>
              </Menu>
            </>
          ) : (
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              {navItems.map((item) => (
                <NavButton
                  key={item.label}
                  href={item.href}
                >
                  {item.label}
                </NavButton>
              ))}
              <ClientLoginButton
                href="/login"
              >
                Client Login
              </ClientLoginButton>
              <RequestDemoButton
                href="/contact"
                variant="contained"
                disableElevation={false}
              >
                Request Demo
              </RequestDemoButton>
            </Box>
          )}
        </StyledToolbar>
      </Container>
    </StyledAppBar>
  );
};

export default NavigationBar;