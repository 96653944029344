// components/shared/FeedbackDialog.js
import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Alert,
} from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";

const FEEDBACK_TYPES = {
  bug: "Report a bug",
  suggestion: "Make a suggestion",
  other: "Other feedback",
};

const FeedbackDialog = ({ 
  open, 
  onClose, 
  toolName,
  sectionId,
  sectionTitle,
  onSubmit,
}) => {
  const { currentUser } = useAuth();
  const [feedbackType, setFeedbackType] = useState("");
  const [feedbackText, setFeedbackText] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!feedbackType || !feedbackText.trim()) return;

    setSubmitting(true);
    setError(null);

    try {
      // Create feedback object with only available user data
      const feedbackData = {
        type: feedbackType,
        text: feedbackText.trim(),
        tool: toolName,
        section: sectionId,
        sectionTitle,
        userId: currentUser?.uid || "anonymous",
        userEmail: currentUser?.email || "anonymous",
        // Add any other user data you know will be available
      };

      await onSubmit(feedbackData);

      // Reset form
      setFeedbackType("");
      setFeedbackText("");
      onClose();
    } catch (err) {
      setError("Failed to submit feedback. Please try again.");
      console.error("Feedback submission error:", err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Provide Feedback</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            Your feedback helps us improve {toolName} - {sectionTitle}
          </Typography>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Feedback Type</InputLabel>
            <Select
              value={feedbackType}
              onChange={(e) => setFeedbackType(e.target.value)}
              label="Feedback Type"
              required
            >
              {Object.entries(FEEDBACK_TYPES).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            multiline
            rows={4}
            label="Your Feedback"
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button 
            type="submit" 
            variant="contained"
            disabled={submitting || !feedbackType || !feedbackText.trim()}
          >
            {submitting ? "Submitting..." : "Submit Feedback"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FeedbackDialog;