// src/components/external_site/PrivacyConsentManager.js
import React from 'react';
import { 
  Box,
  Button,
  Typography,
  IconButton,
  Snackbar,
  Link as MuiLink,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { usePrivacyConsent } from '../../hooks/usePrivacyConsent';

const PrivacyConsentManager = () => {
  const {
    showBanner,
    snackbarMessage,
    setSnackbarMessage,
    applyConsent,
    setShowBanner
  } = usePrivacyConsent();

  const handleChoice = async (granted) => {
    console.log('Consent choice made:', granted);
    await applyConsent({
      analyticsAllowed: granted,
      gpcEnforced: false
    });
  };

  const handleTemporaryDismiss = async () => {
    await applyConsent({
      analyticsAllowed: false,
      gpcEnforced: false
    }, true); // Pass true for isTemporary
    
    setShowBanner(false);
    setSnackbarMessage('You can update your cookie preferences at any time through the Privacy Policy.');
  };

  const handleSnackbarClose = () => {
    setSnackbarMessage('');
  };

  if (!showBanner) {
    return (
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton size="small" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    );
  }

  return (
    <>
      {/* Semi-transparent overlay */}
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bgcolor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 1199,
        }}
      />

      {/* Cookie Banner */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1200,
          bgcolor: 'background.paper',
          borderTop: '1px solid',
          borderColor: 'divider',
          boxShadow: '0px -4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Box
          sx={{
            maxWidth: '100%',
            mx: 'auto',
            p: { xs: 2, sm: 3 },
            display: 'flex',
            alignItems: 'flex-start',
            gap: 3,
          }}
        >
          {/* Content Section */}
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" sx={{ mb: 1, color: 'primary.main' }}>
              We Care About Your Privacy
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              We use essential cookies to make our site work. With your permission, 
              we'd also like to use analytics cookies to understand how you use our site. 
              We respect your privacy choices, including Global Privacy Control (GPC) signals.
            </Typography>
            <Typography variant="body2">
              For more information about how we use cookies and how to manage your preferences, 
              please read our{' '}
              <MuiLink href="/privacy-policy" color="primary">
                Privacy Policy
              </MuiLink>.
            </Typography>
          </Box>

          {/* Buttons Section */}
          <Box 
            sx={{ 
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              minWidth: '160px',
              alignSelf: 'center',
            }}
          >
            <Button
              variant="contained"
              onClick={() => handleChoice(true)}
              color="primary"
              fullWidth
            >
              Accept All
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleChoice(false)}
              sx={{ 
                color: 'text.primary',
                borderColor: 'divider',
                '&:hover': {
                  borderColor: 'text.primary',
                  bgcolor: 'action.hover'
                }
              }}
              fullWidth
            >
              Essential Only
            </Button>
            <Button
              variant="text"
              onClick={handleTemporaryDismiss}
              sx={{ 
                color: 'text.secondary',
                '&:hover': {
                  bgcolor: 'action.hover'
                }
              }}
              fullWidth
            >
              Ask Me Later
            </Button>
          </Box>
        </Box>
      </Box>

      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton size="small" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
};

export default PrivacyConsentManager;