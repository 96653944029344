import React, { createContext, useContext, useState, useCallback, useMemo, useRef, useEffect } from 'react';

const InsightContext = createContext(null);

export function useInsight() {
  const context = useContext(InsightContext);
  if (!context) {
    throw new Error('useInsight must be used within an InsightProvider');
  }
  return context;
}

export function InsightProvider({ children }) {
  // Add render counter
  const renderCount = useRef(0);
  useEffect(() => {
    renderCount.current += 1;
    console.log('InsightProvider render count:', renderCount.current);
  });

  const [currentTool, setCurrentTool] = useState(null);
  const [currentSection, setCurrentSection] = useState(null);

  // Mapping of tools and their sections to insight content - moved outside component
  const insightContent = useMemo(() => ({
    'geographic-explorer': {
      default: {
        purpose: "The Geographic Explorer helps leaders understand the community in which they are operating, including the nearby student population, enrollment trends, and competition.  This tool sets the foundation for deeper competitive analysis and impacts to future enrollment projections.",
        action: <>Begin by exploring <b>Your Community</b> headlines to understand the nearby student population, including demographics.  Then view <b>Enrollment Trends</b> to see how this impacting schools differently, including shifts over time.  Finally, use the <b>Nearby Schools Comparison</b> section to see specific data points and mapping visuals for area peers.</>
      },
      communityHeadlines: {
        explanations: [
          <><b>Population trends</b> estimate number of children living in the drive time and does not consider if the child attends public school or not.</>,
          <><b>Demographics</b> identifies nearby student and adult population.</>,
          <><b>Economic indicators</b> focus on family income and housing stability.</>,
          <><b>Source</b>: Esri and 2020 U.S. Census</>
        ],
        actions: [
          <>View recent <b>student population changes</b> and 2029 projections, primarily due to housing, migration, and aging.</>,
          <>Compare your <b>campus's enrollment change</b> to the area. Is your enrollment more stable?</>,
          <>Look at the nearby <b>racial, ethnic, and economic profile</b> to understand the nearby population, which are explored more deeply in the Enrollment Trends section.</>
        ],
        lookFors: [
          <><b>Size and change in the number of children</b> living nearby.</>,
          <>If <b>drive times are changed</b>, how do trends vary closer to your campus (5 minutes) versus further away (10 or 15 minutes)?</>
        ]
      },
      enrollmentHeadlines: {
        explanations: [
          <><b>Enrollment</b> is state-reported NCES and differs from average daily attendance or other points in time.</>,
          <>Figures are based on where <b>students attend school</b> for schools located in the drive time radius, meaning some students may not live in the radius.</>,
          <><b>Source</b>: NCES Common Core of Data</>
        ],
        actions: [
          <>Understand <b>overall enrollment shifts</b> and compare your school to the broader area.</>,
          <>Compare <b>demographics</b> to identify enrollment gaps by group and use the toggle to see 5-year trends by group.</>
        ],
        lookFors: [
          <>How is your school maintaining enrollment <b>vis-a-vis the area</b>?</>,
          <>How do your school's <b>demographics compare</b> with nearby schools? For those groups where you serve a <b>higher percentage of students</b>, what is their growth?</>
        ]
      },
      comparisonHeadlines: {
        explanations: [
          <><b>Enrollment</b> is state-reported NCES and differs from average daily attendance or other points in time.</>,
          <>Figures are based on where <b>students attend school</b> for schools located in the drive time radius, meaning some students may not live in the radius.</>,
          <><b>Source</b>: NCES Common Core of Data</>
        ],
        actions: [
          <>View your campus's <b>enrollment change rank</b>. <b>School Changes</b> identifies opening or closing schools since 2019.</>,
          <>Dig into <b>school-specific trends</b> for the closest schools, primary competition, or bucking area trends.</>,
          <>Use the Growth Comparison "<b>Expand View</b>" or the <b>map</b> to see your school's relative size and change versus peers.</>
        ],
        lookFors: [
          <>How does our enrollment change <b>compare to nearby peers</b>?</>,
          <>Which <b>specific schools</b> are maintaining or increasing enrollment? Do their student demographics vary from yours or other factors leading to strength?</>
        ]
      }
    },
    'competitive-explorer': {
      default: {
        purpose: "Building on the Geographic Explorer, the Competitive Explorer allows leaders to go deeper in understanding their school's ability to compete in a changing area. In many areas, a key driver of enrollment changes is the connection between your student’s demographics and the changes in the area’s demographics.",
        action: 
          <>Begin by understanding <b>Your Community</b> and the connection between the area's changing population and your school's market share, which is the percentage of students of those ages attending your school.<br></br><br></br>Then view <b>Enrollment & Demographic Trends</b> to see how market share has changed for demographic groups while <b>Nearby Schools Comparison</b> dives into specific schools to enable detailed analysis.</>
      },
      communityHeadlines: {
        explanations: [
          <><b>Market share</b> is calculated by dividing your school's 2023-24 enrollment by Esri's 2024 estimated nearby grade-specific population in the drivetime.</>,
          <><b>Area population change</b> (x-axis) is out of your control, but reflects the changing number of area children.</>,
          <><b>Market share change</b> (y-axis) is how the percentage of children living in the area that attend your school has changed over the last 4 years.</>
        ],
        actions: [
          <>Understand this <b>summary view</b> of area changes and school trends, then explore underlying dynamics in the sections below.</>,
          <>If you have not already, use the Geographic Explorer to better understand <b>population changes</b> in your area.</>
        ],
        lookFors: [
          <>What is the <b>future outlook</b> when linking area dynamics with our school's competitiveness? The quadrants highlight areas of strength, mixed considerations, or challenges.</>,
        ]
      },
      enrollmentTrends: {
        explanations: [
          <>Four dynamics are spotlighted to connect your school to the area:</>,
          <><b>Percentage of area enrollment</b> for race/ethnic groups</>,
          <><b>Change in area enrollment</b> since 2020 by group</>,
          <><b>Your school's market share</b> by group</>,
          <><b>Change your school's market share</b> by group since 2020</>
        ],
        actions: [
          <>Read the <b>headlines</b> to understand the area's operating environment.</>,
          <>Understand the <b>area's largest student groups</b> and how those groups have changed since 2019.</>,
          <>View your <b>school's market share</b> by student group and change since 2019.</>
        ],
        lookFors: [
          <>What is <b>area growth</b> for our largest student groups?</>,
          <>How has <b>our share of students changed</b> in those largest groups?</>,
          <>What other student groups in the area <b>could we recruit</b>? Have we tried and had difficulty? Or does this present a <b>new opportunity to diversify</b>?</>
        ]
      },
      nearby: {
        explanations: [
          <><b>Market share</b> is calculated by dividing your school's enrollment by total NCES enrollment for schools located in the selected drivetime.</>,
          <><b>Change in market share</b> is the difference between your 2023-24 market share and 2019-20 share.</>
        ],
        actions: [
          <>View the <b>rank of your market share trend</b> versus nearby peers and how that rank has changed since 2019.</>,
          <>Dig deeper into <b>nearby schools</b> using the data table and map.</>
        ],
        lookFors: [
          <>Which nearby schools have <b>increased their market share</b>? What is driving that strength?</>,
          <>Are there <b>surprising competitors</b> that you have not focused on previously?</>
        ]
      }
    },
    'projections-explorer': {
      default: {
        purpose: "The Projections Explorer calculates three forward-looking enrollment scenarios using the school's historical grade-level enrollment.  These are intended to be a starting point using historical entry grade and matriculation data for leaders to use as reference points in setting or evaluating their network targets. Please click here to view the enrollment projections scenarios calculation methodology.",
        action: [
          <>Follow these steps to analyze and plan your school's future enrollment:<br/>
          <ol>
            <li><b>Enter latest enrollment data</b>: analyze the most current information</li>
            <li><b>Review projection scenarios</b>: explore starter enrollment projection scenarios that have been calculated using your school's historical data</li>
            <li><b>Assess risk of meeting targets</b>: compare your network's enrollment targets against projection scenarios to identify potential opportunities and challenges</li>
            <li><b>Adjust scenarios based on strategies</b>: model how improving recruitment, retention, or changes to the area's population or competition could impact enrollment outcomes</li>
            <li><b>Take action</b>: access additional tools and resources to support next steps related to short and long-term planning</li>
          </ol></>
        ]
      },
      risk: {
        explanations: [
          "Risk assessment compares your targets to the projection scenarios and provides feedback based on how it aligns with the high-midpoint-low scenarios."
        ],
        actions: [
          <>Look at longer-term historical enrollment and the <b>midpoint projection scenarios</b>.</>,
          <>Upon entering your <b>enrollment targets</b> by grade and/or year, read the feedback on relative risk.  </>,
          <>Reference the <b>Strategy Impact Analysis</b> section to adjust projection scenarios based on school strategies or area changes.</>
        ],
        lookFors: [
          <>What are <b>overall enrollment trends</b>, which span upwards of a decade between historical and projected years?</>,
          <>Are there <b>specific areas of risk</b>, such as particular grades or broader risk across multiple years, which may suggest difficulty in meeting targets.</>
        ]
      },
      impact: {
        explanations: [
          'Four impacts are available to "dial up or down", impact future projection scenarios and relate to student recruiting and retention, area population, and competition.',
        ],
        actions: [
          <><b>Adjust one or more dials</b> to see the projections impacts and resulting risk assessment.</>,
          <><b>Collaborate with colleagues</b> on these dynamics to build alignment on network targets.</>
        ],
        lookFors: [
          <>Conducting a <b>"what would you have to believe?"</b> exercise builds understanding of what needs to happen to meet targets.  Engaging internal stakeholders on higher targets can build understanding of the operating environment and help manage risk.</>
        ]
      }
    }
  }), []); // Empty dependency array since this content is static

  // Memoized callbacks
  const setToolAndSection = useCallback((tool, section) => {
    // If we're switching tools (tool changed and section is null), 
    // we want to show default content
    if (tool && !section && tool !== currentTool) {
      setCurrentTool(tool);
      setCurrentSection(null);
      return;
    }
    
    // Normal case - either setting both or clearing both
    setCurrentTool(prev => prev === tool ? prev : tool);
    setCurrentSection(prev => prev === section ? prev : section);
  }, [currentTool]);

  const getCurrentContent = useCallback(() => {
    if (!currentTool || !currentSection) return null;
    return insightContent[currentTool]?.[currentSection] || null;
  }, [currentTool, currentSection, insightContent]);

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(() => ({
    currentTool,
    currentSection,
    setToolAndSection,
    getCurrentContent,
    insightContent
  }), [
    currentTool,
    currentSection,
    setToolAndSection,
    getCurrentContent,
    insightContent
  ]);

  return (
    <InsightContext.Provider value={contextValue}>
      {children}
    </InsightContext.Provider>
  );
}