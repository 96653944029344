import React from 'react';
import { Box, Container, Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const StyledFooter = styled(Box)({
  backgroundColor: '#FFFFFF',
  borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  padding: '12px 0',
});

const FooterContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '24px',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
    gap: '24px',
  },
});

const LogoAndNavWrapper = styled(Box)({
  display: 'flex',
  gap: '48px',
  alignItems: 'center',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
  },
});

const LogoWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  '& img': {
    height: '150px',
    width: 'auto',
  },
});

const NavLinks = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '24px',
  alignItems: 'center',
  '@media (max-width: 768px)': {
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '16px',
  },
});

// Matching the NavButton style from NavigationBar but with smaller font
const FooterLink = styled(Link)({
  color: '#111827',
  fontSize: '14px', // Slightly smaller than navbar
  fontWeight: 500, // Same as navbar
  textTransform: 'none',
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: 'rgba(79, 70, 229, 0.04)',
    textDecoration: 'none',
  },
});

const ContactInfo = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  justifyContent: 'center',
  height: '150px',
});

const ContactText = styled(Typography)({
  color: '#111827',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: 1.5,
});

function SiteFooter() {
  return (
    <StyledFooter component="footer">
      <FooterContainer maxWidth="xl">
        <LogoAndNavWrapper>
          <LogoWrapper>
            <img 
              src="/images/edc_logo.png" 
              alt="Education Data Collective" 
            />
          </LogoWrapper>

          <NavLinks>
            <FooterLink component={RouterLink} to="/tools">
              Explore Our Tools
            </FooterLink>
            <FooterLink component={RouterLink} to="/about">
              Meet The Collective
            </FooterLink>
            <FooterLink component={RouterLink} to="/contact">
              Let's Connect
            </FooterLink>
            <FooterLink component={RouterLink} to="/terms-of-service">
              Legal
            </FooterLink>
          </NavLinks>
        </LogoAndNavWrapper>

        <ContactInfo>
          <ContactText>
            PO Box 7062
            <br />
            Denver, CO 80207
          </ContactText>
          <ContactText>
            <Link 
              href="mailto:info@educationdatacollective.com"
              sx={{ 
                color: 'inherit',
                textDecoration: 'none',
                fontWeight: 500,
                '&:hover': {
                  textDecoration: 'underline',
                }
              }}
            >
              info@educationdatacollective.com
            </Link>
          </ContactText>
        </ContactInfo>
      </FooterContainer>
    </StyledFooter>
  );
}

export default SiteFooter;