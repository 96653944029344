import { useEffect } from 'react';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useAuth } from '../../contexts/AuthContext';

const SESSION_TIMEOUT = 30 * 60 * 1000; // 30 minutes in milliseconds

const SessionTracker = () => {
  const { currentUser } = useAuth();
  const analytics = getAnalytics();

  useEffect(() => {
    if (!currentUser) return;

    // Log initial page view and session start
    logEvent(analytics, 'page_view');
    logEvent(analytics, 'session_start');

    let lastActivity = Date.now();
    let sessionTimeout;

    const resetSession = () => {
      const now = Date.now();
      if (now - lastActivity > SESSION_TIMEOUT) {
        // If inactive for too long, start a new session
        logEvent(analytics, 'session_start');
      }
      lastActivity = now;
      
      // Clear existing timeout and set a new one
      if (sessionTimeout) clearTimeout(sessionTimeout);
      sessionTimeout = setTimeout(() => {
        logEvent(analytics, 'session_end');
      }, SESSION_TIMEOUT);
    };

    // Track user activity
    const activityEvents = ['mousedown', 'keydown', 'scroll', 'touchstart'];
    activityEvents.forEach(event => {
      window.addEventListener(event, resetSession);
    });

    // Clean up
    return () => {
      if (sessionTimeout) clearTimeout(sessionTimeout);
      activityEvents.forEach(event => {
        window.removeEventListener(event, resetSession);
      });
      logEvent(analytics, 'session_end');
    };
  }, [currentUser, analytics]);

  return null; // This component doesn't render anything
};

export default SessionTracker;