// src/stores/consentStore.js
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { getAnalytics, setConsent } from 'firebase/analytics';
import { getApp } from 'firebase/app';

class ConsentStore {

  // Lazy initialization of Firebase services
  initializeServices() {
    if (!this.db || !this.analytics) {
      try {
        const app = getApp();
        this.db = getFirestore(app);
        this.analytics = getAnalytics(app);
      } catch (error) {
        console.error('Error initializing Firebase services:', error);
      }
    }
  }

  async initializeConsent(userId) {
    // Initialize services when needed
    this.initializeServices();
    
    // First check local storage for existing consent
    const localConsent = this.getLocalConsent();
    
    if (!userId) {
      // No user logged in - use only local storage
      return localConsent;
    }

    try {
      // User is logged in - check Firebase
      const userDoc = await getDoc(doc(this.db, 'users', userId));
      const firebaseConsent = userDoc.data()?.consent;

      // If we have both, compare timestamps
      if (localConsent && firebaseConsent) {
        const mostRecentConsent = localConsent.timestamp > firebaseConsent.timestamp
          ? localConsent
          : firebaseConsent;

        // If local is more recent, update Firebase
        if (localConsent.timestamp > firebaseConsent.timestamp) {
          await this.updateConsent(userId, localConsent);
        } else {
          // If Firebase is more recent, update local
          this.setLocalConsent(firebaseConsent);
        }

        return mostRecentConsent;
      }
      
      // If we only have one, use it
      if (firebaseConsent) {
        this.setLocalConsent(firebaseConsent);
        return firebaseConsent;
      }
      if (localConsent) {
        await this.updateConsent(userId, localConsent);
        return localConsent;
      }
    } catch (error) {
      console.error('Firebase consent error:', error);
      // On Firebase error, fallback to local storage
      return localConsent;
    }

    return null;
  }

  async updateConsent(userId, consentData, isTemporary = false) {
    // Initialize services when needed
    this.initializeServices();

    const timestamp = Date.now();
    const consentRecord = {
      ...consentData,
      timestamp,
      version: '1.0',
      expiresAt: isTemporary ? 
        timestamp + (24 * 60 * 60 * 1000) : // 24 hours for temporary
        timestamp + (365 * 24 * 60 * 60 * 1000), // 1 year for explicit choice
      isTemporary: isTemporary,
      analyticsAllowed: isTemporary ? false : consentData.analyticsAllowed // Force analytics off for temporary state
    };

    // Always update local storage first
    this.setLocalConsent(consentRecord);

    // Update Firebase Analytics if available
    if (this.analytics) {
      setConsent(this.analytics, {
        analytics_storage: isTemporary ? 'denied' : (consentRecord.analyticsAllowed ? 'granted' : 'denied'),
        ad_storage: 'denied',
      });
    }

    // Try to update Firebase if user is logged in and Firestore is available
    if (userId && this.db) {
      try {
        const userRef = doc(this.db, 'users', userId);
        await setDoc(userRef, { 
          consent: consentRecord,
          lastConsentPrompt: timestamp // Track when we last asked
        }, { merge: true });
      } catch (error) {
        console.error('Error updating consent in Firebase:', error);
      }
    }

    return consentRecord;
  }

  getLocalConsent() {
    try {
      const consentStr = localStorage.getItem('analyticsConsent');
      if (!consentStr) return null;
      
      const consent = JSON.parse(consentStr);
      
      // Check if consent has expired
      if (consent.expiresAt && consent.expiresAt < Date.now()) {
        this.clearLocalConsent();
        return null;
      }
      
      return consent;
    } catch (error) {
      console.error('Error reading local consent:', error);
      return null;
    }
  }

  setLocalConsent(consentData) {
    try {
      localStorage.setItem('analyticsConsent', JSON.stringify(consentData));
      localStorage.setItem('hasExplicitConsent', (!consentData.isTemporary).toString());
    } catch (error) {
      console.error('Error setting local consent:', error);
    }
  }

  clearLocalConsent() {
    localStorage.removeItem('analyticsConsent');
    localStorage.removeItem('hasExplicitConsent');
  }

  shouldShowBanner() {
    const localConsent = this.getLocalConsent();
    if (!localConsent) return true;
    
    // Show banner if consent is temporary and expired
    if (localConsent.isTemporary && localConsent.expiresAt < Date.now()) {
      this.clearLocalConsent();
      return true;
    }
    
    return false;
  }
}

const consentStore = new ConsentStore();
export default consentStore;