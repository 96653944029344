import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Container, 
  Box, 
  TextField, 
  Button, 
  Typography, 
  Paper,
  CircularProgress,
  CssBaseline
} from '@mui/material';
import { styled, keyframes } from "@mui/material/styles";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from '../../firebase';
import customLogger from '../../utils/customLogger';

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const PageWrapper = styled(Box)({
  minHeight: '100vh',
  width: '100vw',
  backgroundColor: '#F9FAFB',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
});

const LogoWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '-50px',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '100px',
  height: '100px',
  '& .logo-content': {
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    borderRadius: '50%',
    padding: '4px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    animation: `${fadeInUp} 0.8s ease-out`,
  },
  '& .logo': {
    width: '100%',
    height: '100%',
    backgroundImage: 'url("/images/edc_logo_circle.png")',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  }
}));

const StyledContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
});

const StyledResetContainer = styled(Paper)({
  backgroundColor: '#ffffff',
  border: '1px solid rgba(0, 0, 0, 0.05)',
  borderRadius: '8px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.05)',
  maxWidth: '600px',
  width: '100%',
  padding: '40px',
  paddingTop: '80px',
  textAlign: 'center',
  position: 'relative',
  marginTop: '60px',
});

const InputLabel = styled(Typography)({
  textAlign: 'left',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '14px',
  fontWeight: 500,
  color: '#111827',
  marginBottom: '6px',
});

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    '& fieldset': {
      borderColor: '#E5E7EB',
    },
    '&:hover fieldset': {
      borderColor: '#E5E7EB',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6366F1',
    },
  },
  '& .MuiInputBase-input': {
    padding: '14px 16px',
    fontSize: '14px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&::placeholder': {
      color: '#9CA3AF',
    }
  },
});

const StyledButton = styled(Button)({
  backgroundColor: '#4F46E5',
  color: '#ffffff',
  fontSize: '16px',
  fontWeight: 600,
  padding: '14px',
  borderRadius: '32px',
  textTransform: 'none',
  boxShadow: '0px 4px 6px rgba(79, 70, 229, 0.3)',
  marginTop: '12px',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  '&:hover': {
    backgroundColor: '#4338CA',
    boxShadow: '0px 4px 8px rgba(79, 70, 229, 0.4)',
  },
});

function ResetPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    setIsLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('If an account exists for this email, a password reset link has been sent. Please check your inbox and spam folder.');
      customLogger('Password reset email request processed', 'info');
    } catch (error) {
      customLogger(`Password reset failed: ${error.code} - ${error.message}`, 'error');
      setError('An error occurred. Please try again later.');
    }
    setIsLoading(false);
  };

  return (
    <PageWrapper>
      <CssBaseline />
      <StyledContainer maxWidth="md">
        <StyledResetContainer elevation={0}>
          <LogoWrapper>
            <div className="logo-content">
              <div className="logo" aria-label="EDC Logo" />
            </div>
          </LogoWrapper>

          <Typography
            component="h1"
            variant="h4"
            sx={{
              fontSize: '28px',
              fontWeight: 600,
              color: '#111827',
              marginBottom: '32px',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            }}
          >
            Reset Password
          </Typography>

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: '100%' }}>
            <Box>
              <InputLabel>Email</InputLabel>
              <StyledTextField
                required
                fullWidth
                id="email"
                name="email"
                placeholder="Email"
                type="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
              />
            </Box>

            {error && (
              <Typography 
                color="error" 
                align="center" 
                sx={{ mt: 2 }}
              >
                {error}
              </Typography>
            )}

            {message && (
              <Typography 
                sx={{ 
                  color: '#059669',
                  mt: 2,
                  textAlign: 'center'
                }}
              >
                {message}
              </Typography>
            )}

            <StyledButton
              type="submit"
              fullWidth
              variant="contained"
              disabled={isLoading}
              sx={{ mt: 3 }}
            >
              {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Send Reset Email'}
            </StyledButton>

            <Box sx={{ mt: 3, textAlign: 'center' }}>
              <Link 
                to="/login" 
                style={{ 
                  textDecoration: 'none',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: '#4F46E5',
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  Back to Login
                </Typography>
              </Link>
            </Box>
          </Box>
        </StyledResetContainer>
      </StyledContainer>
    </PageWrapper>
  );
}

export default ResetPassword;