const API_URL = process.env.REACT_APP_BACKEND_URL;

const getToken = async () => {
  let attempts = 0;
  while (attempts < 3) {
    const token = localStorage.getItem('access_token');
    if (token) return token;
    await new Promise(resolve => setTimeout(resolve, 500));
    attempts++;
  }
  throw new Error('No authentication token available');
};

export async function apiFetch(endpoint, options = {}) {
  const url = `${API_URL}${endpoint}`;
  console.log('Making request to:', url);
  console.log('With options:', options);
  
  try {
    const token = await getToken();
    console.log('Got token:', token.substring(0, 10) + '...');
    
    options.headers = {
      ...options.headers,
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    let response = await fetch(url, options);
    
    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      console.error('Response error:', {
        status: response.status,
        statusText: response.statusText,
        data: errorData
      });
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error('API fetch error:', error);
    throw error;
  }
}
