import React from 'react';
import { 
  Box, 
  Container, 
  List,
  ListItem,
  styled
} from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '48px',
  padding: '48px 0',
  backgroundColor: '#F8F9FA',
  minHeight: 'calc(100vh - 100px)', // Adjust based on your navbar height
}));

const SideNav = styled(Box)(({ theme }) => ({
  width: '240px',
  flexShrink: 0,
}));

const MainContent = styled(Box)(({ theme }) => ({
  flex: 1,
}));

const NavLink = styled(RouterLink)(({ theme, active }) => ({
  textDecoration: 'none',
  color: '#111827',
  fontSize: '16px',
  fontWeight: active ? 700 : 600,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px 16px',
  borderRadius: '4px',
  backgroundColor: active ? '#FFFFFF' : 'transparent',
  boxShadow: active ? '0px 1px 3px rgba(0, 0, 0, 0.1)' : 'none',
  width: '100%',
  transition: theme.transitions.create(['background-color', 'box-shadow'], {
    duration: theme.transitions.duration.short,
  }),
  '&:hover': {
    backgroundColor: active ? '#FFFFFF' : 'rgba(255, 255, 255, 0.7)',
  },
}));

const StyledArrow = styled(ArrowForwardIosIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '14px',
}));

const Paper = styled(Box)(({ theme }) => ({
  padding: '48px',
  backgroundColor: '#FFFFFF',
  border: '1px solid rgba(0, 0, 0, 0.05)',
  borderRadius: '8px',
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
}));

const LegalDocLayout = ({ children }) => {
  const location = useLocation();

  const navItems = [
    { label: 'Privacy Notice', path: '/privacy-notice' },
    { label: 'Terms of Service', path: '/terms-of-service' }
  ];

  return (
    <Box sx={{ backgroundColor: '#F8F9FA' }}>
      <Container maxWidth="xl">
        <ContentWrapper>
          <SideNav>
            <List disablePadding>
              {navItems.map((item) => {
                const isActive = location.pathname === item.path;
                return (
                  <ListItem key={item.label} disablePadding sx={{ mb: 1 }}>
                    <NavLink 
                      to={item.path}
                      active={isActive ? 1 : 0}
                    >
                      {item.label}
                      {isActive && <StyledArrow />}
                    </NavLink>
                  </ListItem>
                );
              })}
            </List>
          </SideNav>

          <MainContent>
            <Paper>
              {children}
            </Paper>
          </MainContent>
        </ContentWrapper>
      </Container>
    </Box>
  );
};

export default LegalDocLayout;