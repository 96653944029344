import { apiFetch } from '../utils/api';

export const adminService = {
  listUsers: async () => {
    try {
      const response = await apiFetch('/admin/users');
      if (!response) {
        throw new Error('No response from server');
      }
      return response;
    } catch (error) {
      console.error('List users error:', error);
      throw new Error(`Failed to fetch users: ${error.message}`);
    }
  },

  createUser: async (userData) => {
    try {
      const response = await apiFetch('/admin/users', {
        method: 'POST',
        body: JSON.stringify(userData)
      });
      return response;
    } catch (error) {
      console.error('Create user error:', error);
      throw new Error(`Failed to create user: ${error.message}`);
    }
  },

  updateUser: async (uid, userData) => {
    try {
      const response = await apiFetch(`/admin/users/${uid}`, {
        method: 'PUT',
        body: JSON.stringify(userData)
      });
      return response;
    } catch (error) {
      console.error('Update user error:', error);
      throw new Error(`Failed to update user: ${error.message}`);
    }
  },

  bulkCreateUsers: async (usersData) => {
    try {
      const response = await apiFetch('/admin/users/bulk', {
        method: 'POST',
        body: JSON.stringify({ users: usersData })
      });
      return response;
    } catch (error) {
      console.error('Bulk create users error:', error);
      throw new Error(`Failed to bulk create users: ${error.message}`);
    }
  },

  suspendUser: async (uid) => {
    return apiFetch(`/admin/users/${uid}/suspend`, {
      method: 'POST'
    });
  },

  reactivateUser: async (uid) => {
    return apiFetch(`/admin/users/${uid}/reactivate`, {
      method: 'POST'
    });
  },

  deleteUser: async (uid, confirmationEmail) => {
    return apiFetch(`/admin/users/${uid}/delete`, {
      method: 'DELETE',
      body: JSON.stringify({ confirmationEmail })
    });
  },

  sendUserInvite: async (uid) => {
    try {
      const response = await apiFetch(`/admin/users/${uid}/invite`, {
        method: 'POST',
        // Add a longer timeout if needed
        timeout: 30000 // 30 seconds
      });
      return response;
    } catch (error) {
      if (error.status === 401) {
        // Handle token refresh here
        // You might want to implement a retry mechanism
        console.error('Authentication error:', error);
        throw new Error('Session expired. Please try again.');
      }
      console.error('Send invite error:', error);
      throw new Error(`Failed to send invite: ${error.message}`);
    }
  },

  testEmail: async () => {
      try {
          const response = await apiFetch('/admin/test-email', {
              method: 'POST'
          });
          return response;
      } catch (error) {
          console.error('Test email error:', error);
          throw new Error(`Failed to send test email: ${error.message}`);
      }
  },

  submitContact: async (formData) => {
    try {
      const response = await apiFetch('/admin/contact', {
        method: 'POST',
        body: JSON.stringify(formData)
      });
      return response;
    } catch (error) {
      console.error('Contact form submission error:', error);
      throw new Error(`Failed to submit contact form: ${error.message}`);
    }
  },
};