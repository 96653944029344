import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  Paper
} from '@mui/material';
import { formatGradeList, hasGradeOverlap } from '../../../../utils/schoolUtils';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

const MarketShareAnalysis = ({ 
  selectedSchool, 
  schoolsEnrollmentData,
  sortedSchools
}) => {
  // Helper to calculate total enrollment for overlapping grades
  const calculateOverlappingEnrollment = (enrollmentByGrade, selectedGrades) => {
    return Object.entries(enrollmentByGrade || {})
      .filter(([grade]) => {
        if (grade === 'Pre-Kindergarten') return false;
        return hasGradeOverlap(formatGradeList([grade]), selectedGrades);
      })
      .reduce((sum, [_, count]) => sum + (Number(count) || 0), 0);
  };

  // Calculate market share for a specific timepoint
  const calculateMarketShare = (timepoint) => {
    // Get selected school's enrollment data
    const selectedSchoolData = schoolsEnrollmentData.find(
      d => d.ncessch === selectedSchool.ncessch
    );

    // Get grades for the timepoint
    const gradesForTimepoint = timepoint === 'current' ? 
      selectedSchool.currentGrades : 
      selectedSchool.comparisonGrades;

    // Calculate selected school enrollment
    const selectedEnrollment = calculateOverlappingEnrollment(
      selectedSchoolData?.enrollment_by_grade?.[timepoint],
      gradesForTimepoint
    );

    // Get the appropriate set of schools based on timepoint
    let relevantSchools;
    if (timepoint === 'current') {
      // For current period, use schools open in 2023-24
      relevantSchools = sortedSchools;
          } else {
      // For comparison period, only include schools that:
      // 1. Were open in 2019-20
      // 2. Had enrollment in grades that overlap with selected school's comparison grades
      // 3. Are within the same geographic area as the current schools
      const currentSchoolIds = new Set(sortedSchools.map(s => s.ncessch));
      relevantSchools = schoolsEnrollmentData.filter(school => {
        // Only consider schools that are in our current geographic area
        if (!currentSchoolIds.has(school.ncessch)) return false;

        const enrollmentData = school.enrollment_by_grade?.comparison;
        if (!enrollmentData) return false;
        
        // Get only grades that had non-zero enrollment in comparison period
        const gradesWithEnrollment = Object.entries(enrollmentData)
          .filter(([_, count]) => count > 0)
          .map(([grade]) => grade);

        // Skip schools with no enrollment
        if (gradesWithEnrollment.length === 0) return false;
        
        // Check grade overlap
        const hasOverlap = hasGradeOverlap(
          formatGradeList(gradesWithEnrollment),
          gradesForTimepoint
        );

        console.log('School comparison check:', {
          name: school.name,
          ncessch: school.ncessch,
          gradesWithEnrollment: formatGradeList(gradesWithEnrollment),
          comparisonGrades: gradesForTimepoint,
          hasOverlap,
          enrollmentByGrade: enrollmentData
        });

        return hasOverlap; // Return the result of the overlap check
      });
    }

    // Calculate total area enrollment using the appropriate set of schools
    const totalAreaEnrollment = relevantSchools.reduce((sum, school) => {
      const schoolData = timepoint === 'current' ? 
        schoolsEnrollmentData.find(d => d.ncessch === school.ncessch) : 
        school; // For comparison, we already have the full school data

      return sum + calculateOverlappingEnrollment(
        schoolData?.enrollment_by_grade?.[timepoint],
        gradesForTimepoint
      );
    }, 0);

    return {
      schoolEnrollment: selectedEnrollment,
      areaEnrollment: totalAreaEnrollment,
      marketShare: totalAreaEnrollment > 0 ? 
        (selectedEnrollment / totalAreaEnrollment) * 100 : 0,
      grades: gradesForTimepoint,
      schoolCount: relevantSchools.length
    };
  };

  // Calculate current and past market shares
  const currentMarketShare = calculateMarketShare('current');
  const pastMarketShare = calculateMarketShare('comparison');
  
  // Calculate market share change
  const marketShareChange = currentMarketShare.marketShare - pastMarketShare.marketShare;

  const getChangeColor = (change) => {
    if (Math.abs(change) < 1) return 'text.secondary';
    return change > 0 ? 'success.main' : 'error.main';
  };

  const getChangeIcon = (change) => {
    if (Math.abs(change) < 1) return <CompareArrowsIcon />;
    return change > 0 ? <TrendingUpIcon /> : <TrendingDownIcon />;
  };

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Market Share Analysis
        </Typography>
        
        <Grid container spacing={3}>
          {/* Current Market Share */}
          <Grid item xs={12} md={4}>
            <Paper variant="outlined" sx={{ p: 2, height: '100%' }}>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Current Market Share (2023-24)
              </Typography>
              <Typography variant="h4" component="div">
                {currentMarketShare.marketShare.toFixed(1)}%
              </Typography>
              <Typography variant="caption" color="text.secondary" display="block">
                {currentMarketShare.schoolEnrollment.toLocaleString()} / {currentMarketShare.areaEnrollment.toLocaleString()} students
              </Typography>
              <Typography variant="caption" color="text.secondary" display="block">
                Grades: {currentMarketShare.grades}
              </Typography>
              <Typography variant="caption" color="text.secondary" display="block">
                Based on {currentMarketShare.schoolCount} schools
              </Typography>
            </Paper>
          </Grid>

          {/* Past Market Share */}
          <Grid item xs={12} md={4}>
            <Paper variant="outlined" sx={{ p: 2, height: '100%' }}>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Past Market Share (2019-20)
              </Typography>
              <Typography variant="h4" component="div">
                {pastMarketShare.marketShare.toFixed(1)}%
              </Typography>
              <Typography variant="caption" color="text.secondary" display="block">
                {pastMarketShare.schoolEnrollment.toLocaleString()} / {pastMarketShare.areaEnrollment.toLocaleString()} students
              </Typography>
              <Typography variant="caption" color="text.secondary" display="block">
                Grades: {pastMarketShare.grades}
              </Typography>
              <Typography variant="caption" color="text.secondary" display="block">
                Based on {pastMarketShare.schoolCount} schools
              </Typography>
            </Paper>
          </Grid>

          {/* Market Share Change */}
          <Grid item xs={12} md={4}>
            <Paper variant="outlined" sx={{ p: 2, height: '100%' }}>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Market Share Change
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="h4" component="div" color={getChangeColor(marketShareChange)}>
                  {marketShareChange > 0 ? '+' : ''}{marketShareChange.toFixed(1)}%
                </Typography>
                <Box sx={{ color: getChangeColor(marketShareChange) }}>
                  {getChangeIcon(marketShareChange)}
                </Box>
              </Box>
              <Typography variant="caption" color="text.secondary">
                {Math.abs(marketShareChange) < 1 ? 'Relatively Stable' :
                 marketShareChange > 0 ? 'Increased Market Share' : 'Decreased Market Share'}
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        {/* Key Insights */}
        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle2" gutterBottom>
            Key Insights
          </Typography>
          <List dense>
            <ListItem>
              <ListItemText 
                primary={
                  <Typography variant="body2">
                    {selectedSchool.name} currently serves {((currentMarketShare.marketShare).toFixed(1))}% 
                    of all students in the area
                    {Math.abs(marketShareChange) >= 1 && 
                      `, ${marketShareChange > 0 ? 'up' : 'down'} from ${pastMarketShare.marketShare.toFixed(1)}% in 2019-20`}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary={
                  <Typography variant="body2">
                    The comparison includes {currentMarketShare.schoolCount} current schools and {pastMarketShare.schoolCount} schools from 2019-20
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary={
                  <Typography variant="body2">
                    Total area enrollment has {
                      currentMarketShare.areaEnrollment > pastMarketShare.areaEnrollment ? 
                      `grown by ${(((currentMarketShare.areaEnrollment - pastMarketShare.areaEnrollment) / pastMarketShare.areaEnrollment) * 100).toFixed(1)}%` :
                      `declined by ${(((pastMarketShare.areaEnrollment - currentMarketShare.areaEnrollment) / pastMarketShare.areaEnrollment) * 100).toFixed(1)}%`
                    } since 2019-20
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Box>
      </CardContent>
    </Card>
  );
};

export default MarketShareAnalysis;