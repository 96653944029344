// components/shared/AccordionSection.js
import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  IconButton,
  Tooltip
} from '@mui/material';
import { ExpandMore, Feedback } from '@mui/icons-material';
import FeedbackDialog from './FeedbackDialog';
import { feedbackService } from '../../services/feedbackService';
import { useLocation } from 'react-router-dom';

const AccordionSection = ({
  title,
  icon: Icon,
  expanded,
  onChange,
  children,
  summary,
  defaultExpanded = false,
  headerClassName,
  detailsClassName,
  id
}) => {
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const location = useLocation();
  
  // Extract tool name from pathname
  const toolName = location.pathname.split('/')[1];

  const handleFeedbackSubmit = async (feedbackData) => {
    await feedbackService.submitFeedback(feedbackData);
  };

  return (
    <>
      <Accordion 
        expanded={expanded}
        onChange={onChange}
        defaultExpanded={defaultExpanded}
        sx={{ mt: 2 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls={`${title.toLowerCase()}-content`}
          id={`${title.toLowerCase()}-header`}
          className={headerClassName}
          sx={{
            '& .MuiAccordionSummary-content': {
              flexDirection: 'row',
              alignItems: 'center',
              gap: 2,
              margin: summary ? '12px 0' : undefined,
            }
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            flexGrow: 1
          }}>
            {Icon && <Icon sx={{ mr: 1, color: 'primary.main' }} />}
            <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
              {title}
            </Typography>
          </Box>
          {!expanded && summary && (
            <Typography 
              variant="body2" 
              sx={{ 
                color: 'text.secondary',
                fontSize: '0.875rem'
              }}
            >
              {summary}
            </Typography>
          )}
          {expanded && (
            <Tooltip title="Provide Feedback">
              <IconButton 
                onClick={(e) => {
                  e.stopPropagation();
                  setFeedbackOpen(true);
                }}
                size="small"
                sx={{ ml: 'auto' }}
              >
                <Feedback fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </AccordionSummary>
        <AccordionDetails className={detailsClassName}>
          {children}
        </AccordionDetails>
      </Accordion>

      <FeedbackDialog
        open={feedbackOpen}
        onClose={() => setFeedbackOpen(false)}
        toolName={toolName}
        sectionId={id}
        sectionTitle={title}
        onSubmit={handleFeedbackSubmit}
      />
    </>
  );
};

export default AccordionSection;