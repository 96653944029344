import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { 
  Box, 
  Grid, 
  Typography, 
  Card, 
  CardContent,
  CardActionArea,
  alpha,
  Stack,
  Divider,
  Button,
  Link,
  Chip
} from '@mui/material';

import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';

// Import visualization components
import ProjectXProjectionsChart from '../projections_explorer/dashboard/ProjectXProjectionsChart';
import CompXCommunityPlot from '../competitive_explorer/community/CompXCommunityPlot';
import UnifiedSchoolMap from '../shared_components/unifiedSchoolMap';
import GeoXNearbyMapPopup from '../geographic_explorer/nearby/GeoXNearbyMapPopup';
import GeoXEnrollmentCompositionChanges from '../geographic_explorer/enrollment/GeoXEnrollmentCompositionChanges';
import GeoXCommunityTrends from '../geographic_explorer/community/GeoXCommunityTrends';
import GeoXCommunityDemographics from '../geographic_explorer/community/GeoXCommunityDemographics';
import {
  TrendingUp,
  TrendingDown,
  TrendingFlat,
  CompareArrows,
  AutoGraph,
  Launch as LaunchIcon
} from '@mui/icons-material';

// Shared Components
import PageLayout from '../shared_components/pageLayout';
import LoadingErrorWrapper from '../shared_components/loadingErrorWrapper';
import InitialSchoolSelector from '../shared_components/initialSchoolSelector';
import withIndexedDBErrorHandler from '../shared_components/withIndexedDBErrorHandler';

// Display Components
import SchoolSummaryFilters from './SchoolSummaryFilters';
import MarketShareAnalysis from './display_components/MarketShareAnalysis';
import DemographicAnalysis from './display_components/DemographicAnalysis';
import ProjectionsAnalysis from './display_components/ProjectionsAnalysis';
import PopulationAnalysis from './display_components/PopulationAnalysis';
import ComparisonAnalysis from './display_components/ComparisonAnalysis';
import SimplePopulationTable from './display_components/SimplePopulationTable';

// Store
import useSchoolStore from '../../stores/schoolStore';

// Utils
import { 
  filterAgeData, 
  calculateTotalChildren, 
  CHART_COLORS,
  filterSchools,
  calculateEnrollmentTrends,
  getSchoolGrades,
  calculateEnrollment,
  hasGradeOverlap
} from '../../utils/schoolUtils';
import { shouldHaveHistoricalData } from '../../utils/marketShareUtils';

const HeadlineBox = ({ title, trend = 'neutral', isSelected, onClick, metrics }) => {
  const getTrendIcon = () => {
    switch (trend) {
      case 'up':
        return <TrendingUp color="success" />;
      case 'down':
        return <TrendingDown color="error" />;
      default:
        return <TrendingFlat color="action" />;
    }
  };

  const getTrendColor = () => {
    switch (trend) {
      case 'up':
        return CHART_COLORS.GROWING;
      case 'down':
        return CHART_COLORS.DECLINING;
      default:
        return CHART_COLORS.NEUTRAL;
    }
  };

  return (
    <Card 
      elevation={isSelected ? 2 : 0}
      sx={{
        border: '1px solid',
        borderColor: isSelected ? 'primary.main' : 'divider',
        bgcolor: isSelected ? 'grey.50' : 'background.paper',
        transition: 'all 0.2s ease-in-out',
        width: '100%',
        '&:hover': {
          borderColor: isSelected ? 'primary.main' : 'primary.light',
          bgcolor: isSelected ? alpha('#fff', 0.9) : alpha('#fff', 0.7),
          transform: 'translateX(4px)'
        }
      }}
    >
      <CardActionArea onClick={onClick}>
        <CardContent sx={{ pb: '8px !important' }}>
          <Stack spacing={1}>
            {/* Header with icon and title */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {getTrendIcon()}
              <Typography variant="h6" sx={{ 
                fontSize: '1.1rem', 
                fontWeight: 600,
                color: isSelected ? 'primary.main' : 'text.primary',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>
                {title}
              </Typography>
            </Box>

            <Divider />

            {/* Metrics */}
            {metrics && (
              <Box sx={{ 
                display: 'flex', 
                gap: 3,
                justifyContent: 'space-between',
                px: 1
              }}>
                {metrics.map((metric, index) => (
                  <Box key={index}>
                    <Typography variant="caption" color="text.secondary">
                      {metric.label}
                    </Typography>
                    <Typography 
                      variant="subtitle2" 
                      sx={{ 
                        fontWeight: 600,
                        color: getTrendColor()
                      }}
                    >
                      {metric.value}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const SchoolSummary = () => {

  const getOrdinalSuffix = (number) => {
    const j = number % 10;
    const k = number % 100;
    if (j === 1 && k !== 11) return 'st';
    if (j === 2 && k !== 12) return 'nd';
    if (j === 3 && k !== 13) return 'rd';
    return 'th';
  };

  // State declarations
  const [selectedDriveTime, setSelectedDriveTime] = useState(10);
  const [selectedHeadline, setSelectedHeadline] = useState(0);
  const [focusedSchool, setFocusedSchool] = useState(null);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [governanceFilter, setGovernanceFilter] = useState('All');
  const [schoolTypeFilter, setSchoolTypeFilter] = useState('All');

  // Get data from school store
  const {
    schools,
    selectedSchool,
    esriData,
    error,
    schoolsLoading,
    contentLoading,
    nearbySchools,
    schoolsEnrollmentData,
    enrollmentProjections
  } = useSchoolStore();

  const location = useLocation();
  useEffect(() => {
    if (location.state?.selectedSchool) {
      useSchoolStore.getState().selectSchool(location.state.selectedSchool);
    }
  }, [location.state]);

  // Filter schools for comparison (from CompetitiveExplorer)
  const filteredSchools = useMemo(() => {
    if (!selectedSchool || !nearbySchools) return [];
    
    const schoolsForSelectedDriveTime = nearbySchools?.current?.[selectedDriveTime] || [];
    
    const allSchools = [
      { 
        ...selectedSchool,
        drive_time: 0 
      }, 
      ...schoolsForSelectedDriveTime.map(school => ({
        ...school,
        drive_time: selectedDriveTime
      }))
    ];
  
    return filterSchools(
      allSchools,
      {
        selectedGrades,
        governanceFilter,
        schoolTypeFilter
      },
      schoolsEnrollmentData
    );
  }, [
    selectedSchool,
    nearbySchools,
    selectedDriveTime,
    selectedGrades,
    governanceFilter,
    schoolTypeFilter,
    schoolsEnrollmentData
  ]);

  // Calculate enrollment trends (from GeoXNearbyHeadlines)
  const selectedSchoolTrends = useMemo(() => {
    if (!selectedSchool || !schoolsEnrollmentData) return null;
    
    const selectedSchoolData = schoolsEnrollmentData.find(s => s.ncessch === selectedSchool.ncessch);
    return calculateEnrollmentTrends({
      selectedSchool,
      selectedSchoolData,
      filteredSchools,
      schoolsEnrollmentData,
      selectedGrades,
      governanceFilter
    });
  }, [selectedSchool, schoolsEnrollmentData, filteredSchools, selectedGrades, governanceFilter]);

  // School data for nearby comparisons
  const schoolData = useMemo(() => {
    if (!selectedSchool || !nearbySchools) {
      return [];
    }
    
    const driveTime = selectedDriveTime;
    const schoolsForSelectedDriveTime = nearbySchools?.current?.[driveTime] || [];
    
    // Filter out schools with no grade overlap and missing data
    const validSchools = schoolsForSelectedDriveTime
      .filter(school => {
        // First check it's not the selected school
        if (school.ncessch === selectedSchool.ncessch) return false;
        
        // Then check grade overlap
        const hasOverlap = hasGradeOverlap(school.grade_range, selectedGrades.join(', '));
        if (!hasOverlap) return false;
        
        // Then check for required data
        const schoolData = schoolsEnrollmentData.find(d => d.ncessch === school.ncessch);
        return schoolData && schoolData.enrollment_by_grade?.current;
      });

    return [
      { 
        ...selectedSchool,
        drive_time: 0,
        isMainSchool: true,
        isFocused: false,
        currentEnrollment: calculateEnrollment(
          schoolsEnrollmentData.find(d => d.ncessch === selectedSchool.ncessch)
            ?.enrollment_by_grade?.current || {},
          selectedGrades
        ),
        previousEnrollment: calculateEnrollment(
          schoolsEnrollmentData.find(d => d.ncessch === selectedSchool.ncessch)
            ?.enrollment_by_grade?.comparison || {},
          selectedGrades
        )
      }, 
      ...validSchools.map(school => {
        const schoolEnrollmentData = schoolsEnrollmentData.find(d => d.ncessch === school.ncessch);
        return {
          ...school,
          drive_time: driveTime,
          isMainSchool: false,
          isFocused: focusedSchool?.ncessch === school.ncessch,
          currentEnrollment: calculateEnrollment(
            schoolEnrollmentData?.enrollment_by_grade?.current || {},
            selectedGrades
          ),
          previousEnrollment: calculateEnrollment(
            schoolEnrollmentData?.enrollment_by_grade?.comparison || {},
            selectedGrades
          )
        };
      })
    ];
  }, [selectedSchool, nearbySchools, selectedDriveTime, focusedSchool, schoolsEnrollmentData, selectedGrades]);

  // Effect to set initial grades based on selected school
  useEffect(() => {
    if (selectedSchool?.grade_range) {
      const schoolGrades = getSchoolGrades(selectedSchool.grade_range);
      setSelectedGrades(schoolGrades);
    }
  }, [selectedSchool]);

  // Calculate community data similar to GeoXCommunityHeadlines
  const {
    populationTotals,
    currentVsPastChange,
  } = useMemo(() => {
    const communityData = esriData?.drive_times?.[selectedDriveTime];
    
    if (!communityData) {
      return {
        communityData: null,
        populationTotals: { past: 0, current: 0, future: 0 },
        currentVsPastChange: 0,
      };
    }
  
    // Pass selectedGrades here instead of empty array
    const filteredAgeData = filterAgeData(communityData.ages['4_17'], selectedGrades);
    
    const totals = {
      past: calculateTotalChildren(filteredAgeData['2020']),
      current: calculateTotalChildren(filteredAgeData.current),
      future: calculateTotalChildren(filteredAgeData.future)
    };
  
    const change = ((totals.current - totals.past) / totals.past) * 100;
  
    return {
      communityData,
      populationTotals: totals,
      currentVsPastChange: change,
    };
  }, [esriData, selectedDriveTime, selectedGrades]);

  const totalChange = useMemo(() => {
    return ((populationTotals.future - populationTotals.past) / populationTotals.past) * 100;
  }, [populationTotals]);

  // Market share calculation based on CompXCommunityHeadlines approach
  const marketShareData = useMemo(() => {
    if (!selectedSchool || !schoolsEnrollmentData || !esriData) return null;
    
    const selectedSchoolData = schoolsEnrollmentData.find(d => d.ncessch === selectedSchool.ncessch);
    if (!selectedSchoolData) return null;
  
    // Get community data for the selected drive time
    const communityData = esriData?.drive_times?.[selectedDriveTime];
    if (!communityData) return null;
  
    // Filter age data based on selected grades
    const filteredAgeData = filterAgeData(communityData.ages['4_17'], selectedGrades);
    
    // Calculate population totals
    const populationTotals = {
      past: calculateTotalChildren(filteredAgeData['2020']),
      current: calculateTotalChildren(filteredAgeData.current)
    };
  
    // Get enrollments for selected school
    const selectedEnrollmentCurrent = calculateEnrollment(
      selectedSchoolData?.enrollment_by_grade?.current || {},
      selectedGrades
    );
  
    const selectedEnrollmentPast = calculateEnrollment(
      selectedSchoolData?.enrollment_by_grade?.comparison || {},
      selectedGrades
    );
  
    // Calculate market shares using population
    const current = populationTotals.current > 0 ? 
      (selectedEnrollmentCurrent / populationTotals.current) * 100 : 0;
      
    const past = populationTotals.past > 0 ? 
      (selectedEnrollmentPast / populationTotals.past) * 100 : 0;
  
    // Calculate population change
    const populationChange = populationTotals.current > 0 && populationTotals.past > 0 ?
      ((populationTotals.current - populationTotals.past) / populationTotals.past) * 100 : 0;
  
    return {
      current,
      past,
      change: current - past,
      populationChange,
      metadata: {
        schoolCategory: selectedSchoolData.category,
        isNewOrNewer: !shouldHaveHistoricalData(selectedSchoolData.category),
        populationTotals
      }
    };
  }, [selectedSchool, schoolsEnrollmentData, esriData, selectedGrades, selectedDriveTime]);  

  // Get current tool based on selected headline
  const getCurrentTool = (index) => {
    switch (index) {
      case 0:
      case 3:
      case 4:
        return {
          path: '/geographic-explorer',
          name: 'Geographic Explorer',
          icon: <MapsHomeWorkIcon />
        };
      case 1:
        return {
          path: '/projections-explorer',
          name: 'Projections Explorer',
          icon: <AutoGraph />
        };
      case 2:
        return {
          path: '/competitive-explorer',
          name: 'Competitive Explorer',
          icon: <CompareArrows />
        };
      default:
        return null;
    }
  };

  // Headlines data
  const headlines = useMemo(() => {
     
    // Get the latest actual year and total
    const actualYears = Object.keys(enrollmentProjections?.actual_enrollment || {}).sort();
    const latestActualYear = actualYears[actualYears.length - 1];
    const latestActualTotal = latestActualYear ? 
      Object.values(enrollmentProjections?.actual_enrollment[latestActualYear] || {})
        .reduce((sum, val) => sum + (val || 0), 0) : 
      null;

    // Get the farthest projection year and total
    const projectionYears = Object.keys(enrollmentProjections?.projections?.median || {}).sort();
    const lastProjectionYear = projectionYears[projectionYears.length - 1];
    const lastProjectionTotal = lastProjectionYear ?
      Object.values(enrollmentProjections?.projections?.median[lastProjectionYear] || {})
        .reduce((sum, val) => sum + (val || 0), 0) :
      null;
  
    return [
      {
        title: 'Population Trends',
        content: `${Math.abs(populationTotals.current).toLocaleString()} school-age children live within a ${selectedDriveTime}-minute drive, which is ${currentVsPastChange >= 0 ? 'up' : 'down'} ${Math.abs(currentVsPastChange).toFixed(0)}% since 2020.`,
        trend: Math.abs(currentVsPastChange) < 5 ? 'neutral' : currentVsPastChange > 0 ? 'up' : 'down',
        metrics: [
          {
            label: 'Current Population',
            value: populationTotals.current.toLocaleString()
          },
          {
            label: '2020-24 Change',
            value: `${currentVsPastChange >= 0 ? '+' : ''}${currentVsPastChange.toFixed(1)}%`
          }
        ]
      },
      {
        title: 'Projected Enrollment',
        content: 'View enrollment projections and growth scenarios for your school.',
        trend: lastProjectionTotal && latestActualTotal ? 
               (lastProjectionTotal > latestActualTotal ? 'up' : 'down') : 'neutral',
        metrics: [
          {
            label: `${latestActualYear} Enrollment`,
            value: latestActualTotal?.toLocaleString() || 'N/A'
          },
          {
            label: `${lastProjectionYear} Projection`,
            value: lastProjectionTotal?.toLocaleString() || 'N/A'
          }
        ]
      },
      {
        title: 'Market Share Analysis',
        content: `This school enrolls ${selectedSchoolTrends?.selectedSchool?.newEnrollment?.toLocaleString() || 'N/A'} students, representing ${
          Math.round(marketShareData?.current) || 'N/A'
        }% of area children, down from ${Math.round(marketShareData?.past)}% in 2020 (-${Math.round(Math.abs(marketShareData?.change))}% change).`,
        trend: marketShareData?.change > 0 ? 'up' : 
               marketShareData?.change < 0 ? 'down' : 'neutral',
        metrics: [
          {
            label: 'Current Share',
            value: `${marketShareData?.current ? Math.round(marketShareData.current) : 'N/A'}%`
          },
          {
            label: 'Share Change',
            value: marketShareData?.change ? 
              `${marketShareData.change > 0 ? '+' : '-'}${Math.round(Math.abs(marketShareData.change))}%` :
              'N/A'
          }
        ]
      },
      // Inside your headlines useMemo, update the Area School Comparison headline:
      {
        title: 'Area School Comparison',
        content: selectedSchool ? 
          `${selectedSchool.name}'s enrollment change of ${Math.round(selectedSchoolTrends?.selectedSchool?.percentChange || 0)}% ranks ${(() => {
            // Calculate rank inline
            const sortedSchools = [...schoolData].sort((a, b) => {
              const changeA = a.currentEnrollment && a.previousEnrollment ? 
                ((a.currentEnrollment - a.previousEnrollment) / a.previousEnrollment) * 100 : -Infinity;
              const changeB = b.currentEnrollment && b.previousEnrollment ? 
                ((b.currentEnrollment - b.previousEnrollment) / b.previousEnrollment) * 100 : -Infinity;
              return changeB - changeA;
            });
            const rank = sortedSchools.findIndex(s => s.ncessch === selectedSchool.ncessch) + 1;
            return `${rank}${getOrdinalSuffix(rank)}`;
          })()}} out of ${schoolData.length} nearby schools between 2019-20 and 2023-24.` :
          'School comparison data not available.',
        trend: selectedSchoolTrends?.selectedSchool?.percentChange > 0 ? 'up' : 
              selectedSchoolTrends?.selectedSchool?.percentChange < 0 ? 'down' : 'neutral',
        metrics: [
          {
            label: 'Enrollment Change',
            value: selectedSchoolTrends?.selectedSchool?.percentChange ? 
              `${selectedSchoolTrends.selectedSchool.percentChange > 0 ? '+' : ''}${Math.round(selectedSchoolTrends.selectedSchool.percentChange)}%` :
              'N/A'
          },
          {
            label: 'Area Rank',
            value: selectedSchool && schoolData.length > 0 ? 
              (() => {
                const sortedSchools = [...schoolData].sort((a, b) => {
                  const changeA = a.currentEnrollment && a.previousEnrollment ? 
                    ((a.currentEnrollment - a.previousEnrollment) / a.previousEnrollment) * 100 : -Infinity;
                  const changeB = b.currentEnrollment && b.previousEnrollment ? 
                    ((b.currentEnrollment - b.previousEnrollment) / b.previousEnrollment) * 100 : -Infinity;
                  return changeB - changeA;
                });
                const rank = sortedSchools.findIndex(s => s.ncessch === selectedSchool.ncessch) + 1;
                return `${rank} of ${schoolData.length}`;
              })() :
              'N/A'
          }
        ]
      },
      {
        title: 'Demographic Composition',
        content: selectedSchoolTrends?.largestGroup ? 
          `The selected grades at this school serve a ${Math.round(Math.abs(selectedSchoolTrends.demographicDifference || 0))}% ${selectedSchoolTrends.demographicDifference >= 0 ? 'higher' : 'lower'} percentage of ${selectedSchoolTrends.largestGroup} students compared to nearby schools.` :
          `Demographic comparison data is not available.`,
        trend: selectedSchoolTrends?.largestGroupChange > 0 ? 'up' : 
               selectedSchoolTrends?.largestGroupChange < 0 ? 'down' : 'neutral',
        metrics: [
          {
            label: 'Largest Group',
            value: selectedSchoolTrends?.largestGroup || 'N/A'
          },
          {
            label: '5-Year Change',
            value: selectedSchoolTrends?.largestGroupChange ? 
              `${selectedSchoolTrends.largestGroupChange > 0 ? '+' : ''}${Math.round(selectedSchoolTrends.largestGroupChange)}%` :
              'N/A'
          }
        ]
      }
    ];
  }, [
    currentVsPastChange,
    selectedDriveTime,
    enrollmentProjections,
    marketShareData,
    selectedSchoolTrends,
    selectedSchool,
    schoolData,
    populationTotals
  ]);

  const handleSchoolChange = (event) => {
    const schoolId = event.target.value;
    const school = schools.find(s => s.ncessch === schoolId);
    if (school) {
      useSchoolStore.getState().selectSchool(school);
    }
  };

  const handleDriveTimeChange = (event) => {
    setSelectedDriveTime(Number(event.target.value));
  };

  const handleGovernanceFilterChange = (event) => {
    setGovernanceFilter(event.target.value);
  };
  
  const handleSchoolTypeFilterChange = (event) => {
    setSchoolTypeFilter(event.target.value);
  };

  return (
    <PageLayout
      title="School Summary"
      description={selectedSchool
        ? "A comprehensive overview of your school's performance and context"
        : "Get a quick snapshot of key metrics for any school. Start by selecting a school."
      }
    >
      <LoadingErrorWrapper
        loading={schoolsLoading}
        error={error}
        initialLoading={true}
      >
        {!selectedSchool ? (
          <InitialSchoolSelector
            schools={schools}
            selectedSchool={selectedSchool}
            onSchoolChange={handleSchoolChange}
          />
        ) : (
          <LoadingErrorWrapper
            loading={contentLoading}
            error={error}
            initialLoading={false}
          >
            <SchoolSummaryFilters
              schools={schools}
              selectedSchool={selectedSchool}
              selectedDriveTime={selectedDriveTime}
              governanceFilter={governanceFilter}
              schoolTypeFilter={schoolTypeFilter}
              onSchoolChange={handleSchoolChange}
              onDriveTimeChange={handleDriveTimeChange}
              onGovernanceFilterChange={handleGovernanceFilterChange}
              onSchoolTypeFilterChange={handleSchoolTypeFilterChange}
            />
  
            <Box sx={{ mt: 4 }}>
              <Grid 
                container 
                spacing={0}
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', lg: 'row' },
                  width: '100%',
                  margin: 0,
                  flexWrap: 'nowrap',
                  minWidth: 0
                }}
              >
                {/* Headlines Column */}
                <Grid 
                  item 
                  xs={12} 
                  lg="auto" 
                  sx={{
                    width: { lg: '360px' }, 
                    flexShrink: 0,
                    pr: { lg: 2 },  // Reduced from 3 to 2
                    minWidth: { lg: '360px' }
                  }}
                >
                  <Box sx={{ 
                    '& > :not(:last-child)': {
                      mb: 2
                    }
                  }}>
                    {headlines.map((headline, index) => (
                      <HeadlineBox
                        key={index}
                        title={headline.title}
                        trend={headline.trend}
                        metrics={headline.metrics}
                        isSelected={selectedHeadline === index}
                        onClick={() => setSelectedHeadline(index)}
                      />
                    ))}
                  </Box>
                </Grid>
  
                {/* Visualizations Column */}
                <Grid 
                  item 
                  xs={12}
                  lg  
                  sx={{
                    flexGrow: 1,
                    width: '100%',
                    mt: { xs: 3, lg: 1 },
                    minWidth: 0, 
                    overflow: 'visible',
                  }}
                >
                  <Box sx={{ width: '100%' }}>
                    {/* Header with content and tool button */}
                    <Box sx={{ 
                      mb: 2, 
                      display: (selectedHeadline === 0 || selectedHeadline === 1 || selectedHeadline === 2 || selectedHeadline === 3 || selectedHeadline === 4) ? 'none' : 'flex',
                      flexDirection: { xs: 'column', sm: 'row' },
                      justifyContent: 'space-between',
                      alignItems: { xs: 'flex-start', sm: 'center' },
                      gap: 2,
                      width: '100%'
                    }}>
                      <Typography 
                        variant="body1" 
                        sx={{ 
                          maxWidth: { sm: '60%' },
                          color: 'text.secondary'
                        }}
                      >
                        {headlines[selectedHeadline]?.content}
                      </Typography>
                      {getCurrentTool(selectedHeadline) && (
                        <Button
                          variant="contained"
                          size="small"
                          href={getCurrentTool(selectedHeadline).path}
                          startIcon={getCurrentTool(selectedHeadline).icon}
                          endIcon={<LaunchIcon />}
                          sx={{
                            whiteSpace: 'nowrap',
                            alignSelf: { xs: 'flex-start', sm: 'center' }
                          }}
                        >
                          View in {getCurrentTool(selectedHeadline).name}
                        </Button>
                      )}
                    </Box>
  
                    {/* Visualizations based on selected headline */}
                    <Grid 
                      container 
                      spacing={3}
                      sx={{
                        minHeight: { xs: 'auto', md: '600px' },
                        width: '100%',
                        mx: 0
                      }}
                    >
                      {selectedHeadline === 0 && (
                        <LoadingErrorWrapper
                          loading={contentLoading}
                          error={error}
                        >
                          <Box sx={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            width: '100%',
                            pt: 2
                          }}>
                            <PopulationAnalysis
                              selectedSchool={selectedSchool}
                              populationTotals={populationTotals}
                              currentVsPastChange={currentVsPastChange}
                              selectedDriveTime={selectedDriveTime}
                              selectedGrades={selectedGrades}
                            />
                            
                            <Box sx={{ 
                              border: '1px solid',
                              borderColor: 'divider',
                              borderRadius: 1,
                              p: 0,
                              width: '100%',
                              minWidth: 0,
                              overflow: 'hidden'
                            }}>
                              <GeoXCommunityTrends
                                populationTotals={populationTotals}
                                totalChange={totalChange}
                                selectedDriveTime={selectedDriveTime}
                                cardProps={{
                                  elevation: 0,
                                  sx: { 
                                    height: '100%',
                                    boxShadow: 'none'
                                  }
                                }}
                              />
                            </Box>
  
                            {/* Source Citation */}
                            <Box sx={{ 
                              mt: 2,
                              display: 'flex',
                              justifyContent: 'center'
                            }}>
                              <Typography variant="caption" color="text.secondary">
                                Source: {' '}
                                <Link 
                                  href="https://doc.arcgis.com/en/esri-demographics/latest/reference/methodologies.htm" 
                                  target="_blank" 
                                  rel="noopener noreferrer"
                                >
                                  Esri
                                </Link>
                              </Typography>
                            </Box>
                          </Box>
                        </LoadingErrorWrapper>
                      )}
  
                      {selectedHeadline === 1 && (
                        <LoadingErrorWrapper
                          loading={contentLoading}
                          error={error}
                        >
                          <Box sx={{ 
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            pt: 2
                          }}>
                            <ProjectionsAnalysis
                              selectedSchool={selectedSchool}
                              enrollmentData={enrollmentProjections}
                            />
                            
                            <Box sx={{ 
                              border: '1px solid',
                              borderColor: 'divider',
                              borderRadius: 1,
                              p: 2,
                              mt: -1,
                              width: '100%',
                              minWidth: 0,
                              overflow: 'hidden'
                            }}>
                              {/* Header with title and data source */}
                              <Box sx={{ mb: 3 }}>
                                <Box sx={{ 
                                  display: 'flex', 
                                  alignItems: 'center',
                                  gap: 1,
                                  mb: 1
                                }}>
                                  <AutoGraphIcon sx={{ color: 'primary.main' }} />
                                  <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
                                    Enrollment Trends & Projections
                                  </Typography>
                                </Box>
                                <Chip
                                  icon={<InfoOutlinedIcon sx={{ fontSize: 16 }} />}
                                  label={`Data source: ${enrollmentProjections?.based_on_user_data ? 
                                    "Historical and user-inputted data" : 
                                    "Historical fall count day enrollment only"}`}
                                  color="primary"
                                  variant="outlined"
                                  size="small"
                                />
                              </Box>
  
                              {/* Chart and Table side by side */}
                              <Box sx={{ 
                                display: 'flex', 
                                flexDirection: { xs: 'column', lg: 'row' },
                                gap: 3,
                                width: '100%',
                                minWidth: 0
                              }}>
                                <Box sx={{ 
                                  flex: { xs: '1 1 100%', lg: '1 1 70%' },
                                  minWidth: 0
                                }}>
                                  <ProjectXProjectionsChart
                                    school={selectedSchool}
                                    enrollmentData={enrollmentProjections}
                                    selectedGrades={selectedGrades}
                                    timeframe="5-year"
                                    view="school-level"
                                    targets={{}}
                                    selectedScenarios={['low', 'median', 'high']}
                                  />
                                </Box>
                              </Box>
                            </Box>
  
                            {/* Source Citation */}
                            <Box sx={{ 
                              mt: 2,
                              display: 'flex',
                              justifyContent: 'center'
                            }}>
                              <Typography variant="caption" color="text.secondary">
                                Source: {' '}
                                <Link href="https://nces.ed.gov/ccd/" target="_blank" rel="noopener noreferrer">
                                  NCES 2019-20 through 2023-24
                                </Link>
                              </Typography>
                            </Box>
                          </Box>
                        </LoadingErrorWrapper>
                      )}
  
                      {selectedHeadline === 2 && (
                      <LoadingErrorWrapper
                        loading={contentLoading}
                        error={error}
                      >
                        <Box sx={{ 
                          display: 'flex', 
                          flexDirection: 'column', 
                          width: '100%',
                          pt: 2
                        }}>
                          <MarketShareAnalysis
                            selectedSchool={selectedSchool}
                            selectedSchoolTrends={selectedSchoolTrends}
                            marketShareData={marketShareData}
                          />
                          
                          {/* Removed border for CompXCommunityPlot since it has its own */}
                          <Box sx={{ 
                            p: 0,
                            width: '100%',
                            minWidth: 0,
                            overflow: 'hidden'
                          }}>
                            {selectedSchool ? (
                              <CompXCommunityPlot
                                selectedSchool={selectedSchool}
                                marketShareData={marketShareData}
                                currentVsPastChange={marketShareData?.populationChange}
                                schoolCategory={selectedSchoolTrends?.category}
                                timeframeData={selectedSchoolTrends?.selectedSchool?.timeframe}
                              />
                            ) : (
                              <Typography>No school selected</Typography>
                            )}
                          </Box>

                          {/* Source Citation */}
                          <Box sx={{ 
                            mt: 2,
                            display: 'flex',
                            justifyContent: 'center'
                          }}>
                            <Typography variant="caption" color="text.secondary">
                              Sources: {' '}
                              <Link href="https://www.esri.com/en-us/home" target="_blank" rel="noopener noreferrer">
                                Esri
                              </Link>
                              {' and '}
                              <Link href="https://nces.ed.gov/ccd/" target="_blank" rel="noopener noreferrer">
                                NCES 2019-20 through 2023-24
                              </Link>
                            </Typography>
                          </Box>
                        </Box>
                      </LoadingErrorWrapper>
                    )}
  
                    {selectedHeadline === 3 && (
                    <LoadingErrorWrapper
                      loading={contentLoading}
                      error={error}
                    >
                      <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        width: '100%',
                        pt: 2
                      }}>
                        <ComparisonAnalysis
                          selectedSchool={selectedSchool}
                          selectedSchoolTrends={selectedSchoolTrends}
                          schoolData={schoolData}
                        />
                        
                        <Box sx={{ 
                          display: 'flex',
                          flexDirection: { xs: 'column', lg: 'row' },
                          gap: 3,
                          width: '100%',
                          minWidth: 0
                        }}>
                          <Box sx={{ 
                            flex: { xs: '1 1 100%', lg: '1 1 70%' },
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: 1,
                            p: 2
                          }}>
                            {/* Header with title and info */}
                            <Box sx={{ mb: 3 }}>
                              <Box sx={{ 
                                display: 'flex', 
                                alignItems: 'center',
                                gap: 1,
                                mb: 1
                              }}>
                                <MapsHomeWorkIcon sx={{ color: 'primary.main' }} />
                                <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
                                  School Locations
                                </Typography>
                              </Box>
                              <Typography
                                variant="body2"
                                sx={{
                                  bgcolor: 'rgba(25, 118, 210, 0.1)',
                                  color: 'primary.main',
                                  p: 1,
                                  borderRadius: 1,
                                  fontWeight: 500
                                }}
                              >
                                Click on map markers to view detailed school information
                              </Typography>
                            </Box>

                            {/* Map */}
                            <Box sx={{ 
                              height: 400,
                              position: 'relative',
                              overflow: 'hidden'
                            }}>
                              <UnifiedSchoolMap
                                mode="geographic"
                                selectedSchool={selectedSchool}
                                filteredSchools={schoolData}
                                esriData={esriData}
                                visibleDriveTimes={[selectedDriveTime]}
                                schoolsEnrollmentData={schoolsEnrollmentData}
                                governanceFilter={governanceFilter}
                                selectedGrades={selectedGrades}
                                schoolTypeFilter={schoolTypeFilter}
                                PopupComponent={GeoXNearbyMapPopup}
                                focusedSchool={focusedSchool}
                                onSchoolSelect={(ncessch) => setFocusedSchool(
                                  ncessch ? schoolData.find(s => s.ncessch === ncessch) : null
                                )}
                                initialZoom={11}
                              />
                            </Box>
                          </Box>

                          <Box sx={{ 
                            flex: { xs: '1 1 100%', lg: '1 1 50%' },
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: 1,
                            p: 2,
                            height: 550, // Match the map container height
                            overflow: 'hidden' // Prevent container from growing
                          }}>
                            <SimplePopulationTable 
                              schools={schoolData}
                              selectedSchoolTrends={selectedSchoolTrends}
                              focusedSchool={focusedSchool}
                              onSchoolSelect={(ncessch) => setFocusedSchool(
                                ncessch ? schoolData.find(s => s.ncessch === ncessch) : null
                              )}
                            />
                          </Box>
                        </Box>

                        {/* Source Citation */}
                        <Box sx={{ 
                          mt: 2,
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                          <Typography variant="caption" color="text.secondary">
                            Source: {' '}
                            <Link href="https://nces.ed.gov/ccd/" target="_blank" rel="noopener noreferrer">
                              NCES 2019-20 through 2023-24
                            </Link>
                          </Typography>
                        </Box>
                      </Box>
                    </LoadingErrorWrapper>
                  )}
  
                      {selectedHeadline === 4 && (
                      <LoadingErrorWrapper
                        loading={contentLoading}
                        error={error}
                      >
                        <Box sx={{ 
                          display: 'flex', 
                          flexDirection: 'column', 
                          width: '100%',
                          pt: 2
                        }}>
                          <DemographicAnalysis
                            selectedSchool={selectedSchool}
                            selectedSchoolTrends={selectedSchoolTrends}
                          />
                          
                          <Box sx={{ 
                            display: 'grid', 
                            gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' }, 
                            gap: 3,
                            mt: -2
                          }}>
                            <Box sx={{ 
                              border: '1px solid',
                              borderColor: 'divider',
                              borderRadius: 1,
                              p: 0,
                              mt: 2
                            }}>
                              <GeoXCommunityDemographics
                                esriData={esriData}
                                selectedDriveTime={selectedDriveTime}
                                cardProps={{
                                  elevation: 0,
                                  sx: { 
                                    height: '100%',
                                    boxShadow: 'none',
                                    border: 'none',
                                    '& .MuiBox-root': {  // Add this to target chart container
                                      px: 0  // Reduce padding to match the right chart
                                    }
                                  }
                                }}
                              />
                            </Box>
                            <Box sx={{ p: 2 }}>  {/* No border here since GeoXEnrollmentCompositionChanges has its own */}
                              <GeoXEnrollmentCompositionChanges
                                selectedSchool={selectedSchool}
                                schoolsEnrollmentData={schoolsEnrollmentData}
                                filteredSchools={filteredSchools}
                                selectedGrades={selectedGrades}
                                governanceFilter={governanceFilter}
                                timeframe={selectedSchoolTrends?.selectedSchool?.timeframe}
                              />
                            </Box>
                          </Box>

                          {/* Source Citation */}
                          <Box sx={{ 
                            mt: 2,
                            display: 'flex',
                            justifyContent: 'center'
                          }}>
                            <Typography variant="caption" color="text.secondary">
                              Sources: {' '}
                              <Link href="https://www.esri.com/en-us/home" target="_blank" rel="noopener noreferrer">
                                Esri
                              </Link>
                              {' and '}
                              <Link href="https://nces.ed.gov/ccd/" target="_blank" rel="noopener noreferrer">
                                NCES 2019-20 through 2023-24
                              </Link>
                            </Typography>
                          </Box>
                        </Box>
                      </LoadingErrorWrapper>
                    )}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </LoadingErrorWrapper>
        )}
      </LoadingErrorWrapper>
    </PageLayout>
  );
};

const SchoolSummaryWithErrorHandler = withIndexedDBErrorHandler(SchoolSummary);
export default SchoolSummaryWithErrorHandler;