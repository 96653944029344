import React from 'react';
import { Box, Paper } from '@mui/material';

const SplitLayout = ({ 
  topContent,
  leftContent, 
  rightContent,
  leftWidth = ' 50%',
  rightWidth = '50%'
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      {/* Filters - sticky at top */}
      {topContent && (
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 2,
            backgroundColor: 'background.default',
          }}
        >
          {topContent}
        </Box>
      )}

      {/* Content container */}
      <Box sx={{ 
        display: 'flex',
        gap: 3,
      }}>
        {/* Left content - sticky */}
        <Box
          sx={{
            width: leftWidth,
            position: 'sticky',
            top: topContent ? '84px' : 0, // Account for filter height
            height: 'fit-content'
          }}
        >
          <Paper 
            elevation={1}
            sx={{ 
              p: 3
            }}
          >
            {leftContent}
          </Paper>
        </Box>

        {/* Right content - scrollable */}
        <Box
          sx={{
            width: rightWidth,
            mt: '0px',
            '& > div > .MuiAccordion-root:first-of-type': {
              mt: 0
            },
            '& .MuiAccordion-root': {
              '&:first-of-type': {
                mt: 0
              }
            }
          }}
        >
          {rightContent}
        </Box>
      </Box>
    </Box>
  );
};

export default SplitLayout;