// components/GeographicExplorer.js
import React, { useState, useRef, useEffect, useMemo, Suspense } from 'react';
import { 
  Skeleton,
} from '@mui/material';
import { 
  GroupsOutlined,
  CompareArrowsOutlined,
  PeopleOutlined,
  DonutSmallOutlined,
  AutoGraphOutlined,
  DescriptionOutlined,
  Explore
} from '@mui/icons-material';

// Shared Components
import PageLayout from '../shared_components/pageLayout';
import LoadingErrorWrapper from '../shared_components/loadingErrorWrapper';
import AccordionSection from '../shared_components/accordionSection';
import AccordionContainer from '../shared_components/accordionContainer';
import StickyFilters from '../shared_components/stickyFilters';
import InitialSchoolSelector from '../shared_components/initialSchoolSelector';
import NowWhatSection from '../shared_components/templates/nowWhatTemplate';
import AccordionContentWrapper from '../shared_components/accordionContentWrapper';

// Custom Hook
import { useAccordionState } from '../../hooks/useAccordionState';

// Utils
import { filterSchools, getAvailableSchoolTypes } from '../../utils/schoolUtils';
import { formatSchoolName } from '../../utils/nameFormatter';

// Stores
import useSchoolStore from '../../stores/schoolStore';

// Contexts
import { useInsight } from '../../contexts/InsightContext';

// Components
import GeoXNearbyMapPopup from './nearby/GeoXNearbyMapPopup';

// Lazy load components
const GeoXCommunityHeadlines = React.lazy(() => import('./community/GeoXCommunityHeadlines'));
const GeoXEnrollmentHeadlines = React.lazy(() => import('./enrollment/GeoXEnrollmentHeadlines'));
const GeoXNearbyHeadlines = React.lazy(() => import('./nearby/GeoXNearbyHeadlines'));


function GeographicExplorer() {
  // Use the school store hook
  const {
    schools,
    selectedSchool,
    esriData,
    nearbySchools,
    schoolsEnrollmentData,
    error,
    schoolsLoading,
    contentLoading
  } = useSchoolStore();

  // Context declarations
  const { setToolAndSection } = useInsight();
  const initialSchoolLoadRef = useRef(false);

  // State declarations
  const [governanceFilter, setGovernanceFilter] = useState('All');
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [selectedDriveTime, setSelectedDriveTime] = useState(10);
  const [schoolTypeFilter, setSchoolTypeFilter] = useState('All');
  const [availableSchoolTypes, setAvailableSchoolTypes] = useState(['All']);

  // Handle URL parameters after schools are loaded
  useEffect(() => {
    if (schools.length > 0 && !selectedSchool && !initialSchoolLoadRef.current) {
      const params = new URLSearchParams(window.location.search);
      const schoolId = params.get('school');
      
      if (schoolId) {
        const school = schools.find(s => s.ncessch === schoolId);
        if (school) {
          useSchoolStore.getState().selectSchool({
            ...school,
            name: formatSchoolName(school.name)
          });
        }
        initialSchoolLoadRef.current = true;
      }
    }
  }, [schools, selectedSchool]);

  // Initialize schools on mount
  useEffect(() => {
    if (schools.length === 0) {
      useSchoolStore.getState().fetchSchools();
    }
  }, [schools.length]);

  // Use accordion state hook
  const {
    expandedSections,
    handleExpandedChange: baseHandleExpandedChange,
    handleExpandAll,
    handleCollapseAll,
  } = useAccordionState({
    communityHeadlines: false,
    enrollmentHeadlines: false,
    comparisonHeadlines: false,
    nowWhat: false,
  });

  // Actions for the Now What section
  const actions = [
    {
      icon: DonutSmallOutlined,
      title: 'Competitive Explorer',
      description: 'Analyze market share trends and projections for schools in your commuity',
      path: '/marketshare-analyzer'
    },
    {
      icon: AutoGraphOutlined,
      title: 'Projections Explorer',
      description: 'Explore historical and projected enrollment data for the next five years',
      path: '/projections-explorer'
    },
    {
      icon: DescriptionOutlined,
      title: 'Data Reports',
      description: 'Download detailed reports, including Esri demographic data for selected schools',
      path: '/data-reports'
    }
  ];
  
  // Update tool and section in context
  useEffect(() => {
    // On mount, set the tool with null section to show default content
    setToolAndSection('geographic-explorer', null); 
    
    // On unmount, only clear if we're actually unmounting (not just switching tools)
    return () => {
      // Get all tool components that might be mounted
      const geoExplorerExists = document.querySelector('[data-tool="geographic-explorer"]');
      const compExplorerExists = document.querySelector('[data-tool="competitive-explorer"]');
      
      // Only clear if no tool components are present
      if (!geoExplorerExists && !compExplorerExists) {
        setToolAndSection(null, null);
      }
    };
  }, [setToolAndSection]);
  
  // Update available school types
  useEffect(() => {
    if (schoolsEnrollmentData.length > 0) {
      const types = getAvailableSchoolTypes(schoolsEnrollmentData);
      setAvailableSchoolTypes(types);
    }
  }, [schoolsEnrollmentData]);

  useEffect(() => {
    // If all sections are collapsed, only clear the section, not the tool
    const hasExpandedSection = Object.values(expandedSections).some(isExpanded => isExpanded);
    if (!hasExpandedSection) {
      setToolAndSection('geographic-explorer', null);  // Keep the tool, just clear the section
    }
  }, [expandedSections, setToolAndSection]);

  // Filter schools based on criteria
  const filteredSchools = useMemo(() => {
    if (!selectedSchool || !nearbySchools) {
      return [];
    }
    
    // Get all schools for the selected drive time
    const schoolsForSelectedDriveTime = nearbySchools?.current?.[selectedDriveTime] || [];
    
    // Create array with selected school and nearby schools
    const allSchools = [
      { 
        ...selectedSchool, 
        drive_time: 0 
      }, 
      ...schoolsForSelectedDriveTime.map(school => ({
        ...school,
        drive_time: selectedDriveTime
      }))
    ];
  
    return filterSchools(
      allSchools,
      {
        selectedGrades,
        governanceFilter,
        schoolTypeFilter
      },
      schoolsEnrollmentData
    );
  }, [
    selectedSchool,
    nearbySchools,
    selectedDriveTime,
    selectedGrades,
    governanceFilter,
    schoolTypeFilter,
    schoolsEnrollmentData
  ]);

  const handleExpandedChange = (sectionId) => (event, isExpanded) => {
    baseHandleExpandedChange(sectionId)(event, isExpanded);
    if (isExpanded) {
      setToolAndSection('geographic-explorer', sectionId);
    } else if (!Object.values(expandedSections).some(expanded => expanded)) {
      setToolAndSection(null, null);
    }
  };
  
  const handleSchoolChange = (event) => {
    const schoolId = event.target.value;
    const school = schools.find(s => s.ncessch === schoolId);
    if (school) {
      useSchoolStore.getState().selectSchool({
        ...school,
        name: formatSchoolName(school.name)
      });
    }
  };

  const handleGradeChange = (event) => {
    const value = event.target.value;
    setSelectedGrades(typeof value === 'string' ? value.split(',') : value);
  };

  const handleGovernanceChange = (event) => {
    setGovernanceFilter(event.target.value);
  };

  const handleDriveTimeChange = (event) => {
    setSelectedDriveTime(Number(event.target.value));
  };

  const handleSchoolTypeChange = (event) => {
    setSchoolTypeFilter(event.target.value);
  };

  return (
    <PageLayout
      data-tool="geographic-explorer"
      title="Geographic Explorer"
      description={selectedSchool
        ? "Refine your analysis using the Governance, Grades, and Drive Time filters."
        : "Use this tool to explore the geographic context of schools in your area. Start by selecting a school."
      }
    >
      <LoadingErrorWrapper
        loading={schoolsLoading}
        error={error}
        initialLoading={true}
      >
        {!selectedSchool ? (
          <InitialSchoolSelector
            schools={schools}
            selectedSchool={selectedSchool}
            onSchoolChange={handleSchoolChange}
          />
        ) : (
          <LoadingErrorWrapper
            loading={contentLoading}
            error={error}
            initialLoading={false}
          >
            <StickyFilters
              schools={schools}
              selectedSchool={selectedSchool}
              governanceFilter={governanceFilter}
              selectedGrades={selectedGrades}
              selectedDriveTime={selectedDriveTime}
              schoolTypeFilter={schoolTypeFilter}
              availableSchoolTypes={availableSchoolTypes}
              onSchoolChange={handleSchoolChange}
              onGovernanceChange={handleGovernanceChange}
              onGradeChange={handleGradeChange}
              onDriveTimeChange={handleDriveTimeChange}
              onSchoolTypeChange={handleSchoolTypeChange}
              expandedSections={expandedSections}
              onExpandAll={handleExpandAll}
              onCollapseAll={handleCollapseAll}
              showSchoolType={true}
            />
  
            <AccordionContainer 
              expandedSections={expandedSections}
              onSectionChange={handleExpandedChange}
            >
              <AccordionSection
                id="communityHeadlines"
                title="Your Community"
                summary={`Demographic trends of the community within ${selectedDriveTime} minutes of ${selectedSchool?.name || 'the selected school'}`}
                icon={PeopleOutlined}
              >
                <AccordionContentWrapper 
                  sectionId="communityHeadlines"
                  isExpanded={expandedSections.communityHeadlines}
                >
                  <Suspense fallback={<Skeleton variant="rectangular" height={600} />}>
                    <GeoXCommunityHeadlines
                      selectedSchool={selectedSchool}
                      esriData={esriData}
                      selectedDriveTime={selectedDriveTime}
                      schoolsEnrollmentData={schoolsEnrollmentData}
                      filteredSchools={filteredSchools}
                      selectedGrades={selectedGrades}
                      governanceFilter={governanceFilter} 
                    />
                  </Suspense>
                </AccordionContentWrapper>
              </AccordionSection>
  
              <AccordionSection
                id="enrollmentHeadlines"
                title="Enrollment Trends"
                summary={`Historical enrollment and demographic trends for ${selectedSchool?.name || 'the selected school'}`}
                icon={GroupsOutlined}
              >
                <AccordionContentWrapper 
                  sectionId="enrollmentHeadlines"
                  isExpanded={expandedSections.enrollmentHeadlines}
                >
                  <Suspense fallback={<Skeleton variant="rectangular" height={200} />}>
                    <GeoXEnrollmentHeadlines
                      selectedSchool={selectedSchool}
                      filteredSchools={filteredSchools}
                      schoolsEnrollmentData={schoolsEnrollmentData}
                      selectedGrades={selectedGrades}
                      governanceFilter={governanceFilter} 
                      nearbySchools={nearbySchools} 
                      visibleDriveTimes={[selectedDriveTime]}
                      selectedDriveTime={selectedDriveTime}
                    />
                  </Suspense>
                </AccordionContentWrapper>
              </AccordionSection>
  
              <AccordionSection
                id="comparisonHeadlines"
                title="Nearby Schools Comparison"
                summary={`Interactive analysis of ${selectedSchool?.name || 'the selected school'} and nearby schools`}
                icon={CompareArrowsOutlined}
              >
                <AccordionContentWrapper 
                  sectionId="comparisonHeadlines"
                  isExpanded={expandedSections.comparisonHeadlines}
                >
                  <Suspense fallback={<Skeleton variant="rectangular" height={600} />}>
                    <GeoXNearbyHeadlines
                      selectedSchool={selectedSchool}
                      filteredSchools={filteredSchools}
                      esriData={esriData}
                      visibleDriveTimes={[selectedDriveTime]}
                      schoolsEnrollmentData={schoolsEnrollmentData}
                      governanceFilter={governanceFilter}
                      selectedGrades={selectedGrades}
                      schoolTypeFilter={schoolTypeFilter}
                      PopupComponent={GeoXNearbyMapPopup}
                      nearbySchools={nearbySchools}
                    />
                  </Suspense>
                </AccordionContentWrapper>
              </AccordionSection>
  
              <AccordionSection
                id="nowWhat"
                title="Now What?"
                summary="Continue your analysis with these related tools"
                icon={Explore}
              >
                <AccordionContentWrapper 
                  sectionId="nowWhat"
                  isExpanded={expandedSections.nowWhat}
                >
                  <Suspense fallback={<Skeleton variant="rectangular" height={600} />}>
                    <NowWhatSection 
                      sectionIcon={Explore}
                      actions={actions}
                    />
                  </Suspense>
                </AccordionContentWrapper>
              </AccordionSection>
  
            </AccordionContainer>
          </LoadingErrorWrapper>
        )}
      </LoadingErrorWrapper>
    </PageLayout>
  );
}

export default GeographicExplorer;