// marketShareUtils.js
import { SCHOOL_CATEGORIES } from './schoolCategorization';
import { 
  calculateEnrollment,
  filterAgeData,
  calculateTotalChildren,
  getSchoolTimeframe,
  RACE_CATEGORIES
} from './schoolUtils';

/**
 * Core types of market share calculations
 */
export const MARKET_SHARE_TYPES = {
  ENROLLMENT: 'enrollment', // Share of total enrollment across schools
  POPULATION: 'population', // Share of total school-age population
  DEMOGRAPHIC: 'demographic' // Share by racial/ethnic group
};

/**
 * Check if a school should have historical comparison data
 * @param {string} category - The school's category
 * @returns {boolean}
 */
export const shouldHaveHistoricalData = (category) => {
    return ![
      SCHOOL_CATEGORIES.NEW,
      SCHOOL_CATEGORIES.NEWER_INCREASING,
      SCHOOL_CATEGORIES.NEWER_DECREASING,
      SCHOOL_CATEGORIES.NEWER_STABLE
    ].includes(category);
  };

/**
 * Calculate population-based market share
 * @param {Object} params Configuration object
 * @param {Object} params.schoolData School enrollment data
 * @param {Array} params.selectedGrades Array of selected grade levels
 * @param {Object} params.esriData ESRI demographic data
 * @param {number} params.selectedDriveTime Drive time in minutes
 * @param {string} [params.timepoint='current'] Time period to calculate for
 * @returns {number} Market share percentage
 */
const calculatePopulationShare = ({
  schoolData,
  selectedGrades,
  esriData,
  selectedDriveTime,
  timepoint = 'current'
}) => {
  // Early returns check
  if (!schoolData?.enrollment_by_grade || !esriData?.drive_times?.[selectedDriveTime]?.ages['4_17']) {
    console.log('Missing required data:', {
      hasEnrollmentData: !!schoolData?.enrollment_by_grade,
      hasAgeData: !!esriData?.drive_times?.[selectedDriveTime]?.ages['4_17']
    });
    return 0;
  }

  // Get filtered age data for selected grades
  const ageData = filterAgeData(
    esriData.drive_times[selectedDriveTime].ages['4_17'],
    selectedGrades
  );

  // Get population data for right year 
  const yearData = timepoint === 'current' ? ageData.current : ageData['2020'];
  
  // Calculate population total
  const totalPopulation = calculateTotalChildren(yearData);

  // Calculate school enrollment
  const schoolEnrollment = calculateEnrollment(
    schoolData.enrollment_by_grade[timepoint] || {},
    selectedGrades
  );

  console.log('calculatePopulationShare:', {
    timepoint,
    schoolEnrollment,
    totalPopulation,
    share: ((schoolEnrollment / totalPopulation) * 100).toFixed(2)
  });

  return totalPopulation > 0 ? (schoolEnrollment / totalPopulation) * 100 : 0;
};

/**
 * Calculate enrollment-based market share
 * @param {Object} params Configuration object
 * @param {Object} params.school School data
 * @param {Object} params.schoolData School enrollment data
 * @param {Object} params.nearbySchools Nearby schools data
 * @param {Array} params.schoolsEnrollmentData All schools' enrollment data
 * @param {Array} params.selectedGrades Selected grade levels
 * @param {number} params.selectedDriveTime Drive time in minutes
 * @param {string} [params.governanceFilter='All'] Governance filter
 * @param {string} [params.timepoint='current'] Time period to calculate for
 * @returns {number} Market share percentage
 */
const calculateEnrollmentShare = ({
  school,
  schoolData,
  nearbySchools,
  schoolsEnrollmentData,
  selectedGrades,
  selectedDriveTime,
  governanceFilter = 'All',
  timepoint = 'current'
}) => {
  // For new/newer schools requesting historical data, return 0
  if (timepoint === 'comparison' && !shouldHaveHistoricalData(schoolData?.category)) {
    return 0;
  }

  // Get appropriate universe of schools
  const relevantSchools = nearbySchools?.[timepoint]?.[selectedDriveTime] || [];
  
  // Filter by governance if needed
  const filteredSchools = governanceFilter === 'All' 
    ? relevantSchools
    : relevantSchools.filter(s => {
        const isCharter = s.charter === 'Yes';
        return (governanceFilter === 'Charter' && isCharter) || 
               (governanceFilter === 'District' && !isCharter);
      });

  // Calculate school's enrollment
  const schoolEnrollment = calculateEnrollment(
    schoolData?.enrollment_by_grade?.[timepoint] || {},
    selectedGrades
  );

  // Calculate total area enrollment
  const areaEnrollment = filteredSchools.reduce((total, nearbySchool) => {
    const nearbyData = schoolsEnrollmentData.find(d => d.ncessch === nearbySchool.ncessch);
    return total + calculateEnrollment(
      nearbyData?.enrollment_by_grade?.[timepoint] || {},
      selectedGrades
    );
  }, 0);

  // Include school's enrollment in total if it matches governance filter
  const shouldIncludeSchool = governanceFilter === 'All' ||
    (governanceFilter === 'Charter' && school.charter === 'Yes') ||
    (governanceFilter === 'District' && school.charter !== 'Yes');

  const totalEnrollment = shouldIncludeSchool ? 
    areaEnrollment + schoolEnrollment : 
    areaEnrollment;

  return totalEnrollment > 0 ? (schoolEnrollment / totalEnrollment) * 100 : 0;
};

/**
 * Calculate comprehensive market share statistics
 * @param {Object} params Configuration object
 * @param {string} [params.type=MARKET_SHARE_TYPES.ENROLLMENT] Type of market share calculation
 * @param {Object} params.school School data
 * @param {Object} params.schoolData School enrollment data
 * @returns {Object} Market share statistics
 */
export const calculateMarketShareStats = ({
  type = MARKET_SHARE_TYPES.ENROLLMENT,
  school,
  schoolData,
  selectedGrades,
  esriData,
  selectedDriveTime,
  ...params
}) => {
  // Choose appropriate calculation method
  const calculateShare = type === MARKET_SHARE_TYPES.POPULATION ?
    calculatePopulationShare :
    calculateEnrollmentShare;

  // Calculate current share
  const currentShare = calculateShare({
    school,
    schoolData,
    selectedGrades,    // Add these missing parameters
    esriData,
    selectedDriveTime,
    ...params,
    timepoint: 'current'
  });

  // Calculate past share
  const pastShare = shouldHaveHistoricalData(schoolData?.category) ?
    calculateShare({
      school,
      schoolData,
      selectedGrades,    // Add these missing parameters
      esriData,
      selectedDriveTime,
      ...params,
      timepoint: 'comparison'
    }) : 0;

  // Debug logging shows we have the right population data
  const debug = {
    schoolCurrentEnrollment: calculateEnrollment(
      schoolData?.enrollment_by_grade?.current || {},
      selectedGrades
    ),
    schoolPastEnrollment: calculateEnrollment(
      schoolData?.enrollment_by_grade?.comparison || {},
      selectedGrades
    ),
    filteredAgeData: filterAgeData(
      esriData?.drive_times?.[selectedDriveTime]?.ages['4_17'],
      selectedGrades
    )
  };

  console.log('calculateMarketShareStats debug:', {
    currentShare,
    pastShare, 
    enrollments: debug.schoolCurrentEnrollment,
    populationData: debug.filteredAgeData,
    params: {
      selectedGrades,
      selectedDriveTime,
      schoolCategory: schoolData?.category
    }
  });

  // Return stats
  return {
    current: currentShare,
    past: pastShare,
    change: shouldHaveHistoricalData(schoolData?.category) ? currentShare - pastShare : null,
    hasValidHistory: shouldHaveHistoricalData(schoolData?.category),
    timeframe: getSchoolTimeframe(schoolData),
    metadata: {
      schoolCategory: schoolData?.category,
      isNewOrNewer: !shouldHaveHistoricalData(schoolData?.category)
    }
  };
};

/**
 * Calculate demographic market share statistics
 * @param {Object} params Configuration object
 * @param {Object} params.school School data
 * @param {Object} params.schoolData School enrollment data
 * @param {Object} params.nearbySchools Nearby schools data
 * @param {Array} params.schoolsEnrollmentData All schools' enrollment data
 * @param {Array} params.selectedGrades Selected grade levels
 * @param {number} params.selectedDriveTime Drive time in minutes
 * @param {string} [params.timepoint='current'] Time period to calculate for
 * @returns {Array} Array of demographic share statistics
 */
export const calculateDemographicShares = ({
  school,
  schoolData,
  nearbySchools,
  schoolsEnrollmentData,
  selectedGrades,
  selectedDriveTime,
  timepoint = 'current'
}) => {
  // For new/newer schools requesting historical data, return empty or zero-filled data
  if (timepoint === 'comparison' && !shouldHaveHistoricalData(schoolData?.category)) {
    return Object.entries(RACE_CATEGORIES).map(([field, label]) => ({
      category: label,
      field,
      share: 0,
      schoolCount: 0,
      areaCount: 0,
      totalCount: 0,
      hasHistory: false
    }));
  }

  // Get appropriate universe of schools
  const relevantSchools = nearbySchools?.[timepoint]?.[selectedDriveTime] || [];

  return Object.entries(RACE_CATEGORIES).map(([field, label]) => {
    // Calculate school's demographic count
    const schoolCount = selectedGrades.reduce((total, grade) => {
      return total + (schoolData?.race_data?.[timepoint]?.[grade]?.[field] || 0);
    }, 0);

    // Calculate area demographic count (excluding selected school)
    const areaCount = relevantSchools
      .filter(s => s.ncessch !== school.ncessch)
      .reduce((total, nearbySchool) => {
        const nearbyData = schoolsEnrollmentData.find(d => d.ncessch === nearbySchool.ncessch);
        return total + selectedGrades.reduce((gradeTotal, grade) => {
          return gradeTotal + (nearbyData?.race_data?.[timepoint]?.[grade]?.[field] || 0);
        }, 0);
      }, 0);

    const totalCount = schoolCount + areaCount;
    const share = totalCount > 0 ? (schoolCount / totalCount) * 100 : 0;

    return {
      category: label,
      field,
      share,
      schoolCount,
      areaCount,
      totalCount,
      hasHistory: shouldHaveHistoricalData(schoolData?.category)
    };
  });
};

/**
 * Calculate comprehensive demographic market share statistics including trends
 * @param {Object} params Parameters for demographic calculations
 * @returns {Array} Array of demographic statistics with historical comparison
 */
export const calculateDemographicMarketShares = (params) => {
  const currentShares = calculateDemographicShares({
    ...params,
    timepoint: 'current'
  });

  const pastShares = calculateDemographicShares({
    ...params,
    timepoint: 'comparison'
  });

  return currentShares.map(current => {
    const past = pastShares.find(p => p.field === current.field);
    return {
      ...current,
      pastShare: past?.share || 0,
      pastSchoolCount: past?.schoolCount || 0,
      pastAreaCount: past?.areaCount || 0,
      pastTotalCount: past?.totalCount || 0,
      shareChange: past ? current.share - past.share : 0,
      hasHistory: Boolean(past?.totalCount && current.hasHistory)
    };
  });
};