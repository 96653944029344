// utils/schoolCategorization.js

export const SCHOOL_CATEGORIES = {
    // Selected School Categories
    CONTINUOUS_STABLE: 'CONTINUOUS_STABLE',
    CONTINUOUS_INCREASING: 'CONTINUOUS_INCREASING',
    CONTINUOUS_DECREASING: 'CONTINUOUS_DECREASING',
    NEW: 'NEW',
    NEWER_INCREASING: 'NEWER_INCREASING',
    NEWER_DECREASING: 'NEWER_DECREASING',
    NEWER_STABLE: 'NEWER_STABLE',
    
    // Nearby School Categories
    CLOSED: 'CLOSED',
    NEW_OR_NEWER: 'NEW_OR_NEWER',
    UNKNOWN: 'UNKNOWN'
  };
  
  export const countGradesWithEnrollment = (enrollmentData) => {
    return Object.values(enrollmentData || {}).filter(enrollment => enrollment > 0).length;
  };
  
  export const hasDataInYear = (enrollmentData) => {
    return Object.values(enrollmentData || {}).some(enrollment => enrollment > 0);
  };
  
  export const categorizeSelectedSchool = (schoolData) => {
    const { enrollment_by_grade } = schoolData;
    
    // Check if school has 2019-20 data
    const has2019Data = hasDataInYear(enrollment_by_grade.comparison);
    const has2024Data = hasDataInYear(enrollment_by_grade.current);
    
    if (has2019Data) {
      const grades2019 = countGradesWithEnrollment(enrollment_by_grade.comparison);
      const grades2024 = countGradesWithEnrollment(enrollment_by_grade.current);
      
      if (grades2024 > grades2019) {
        return SCHOOL_CATEGORIES.CONTINUOUS_INCREASING;
      } else if (grades2024 < grades2019) {
        return SCHOOL_CATEGORIES.CONTINUOUS_DECREASING;
      }
      return SCHOOL_CATEGORIES.CONTINUOUS_STABLE;
    }
    
    // Check intermediate years (2020-21 to 2022-23)
    const intermediateYears = Object.keys(enrollment_by_grade)
      .filter(year => !isNaN(parseInt(year)) && parseInt(year) >= 2020 && parseInt(year) <= 2022)
      .sort();
      
    if (intermediateYears.length > 0) {
      const firstYear = intermediateYears[0];
      const initialGrades = countGradesWithEnrollment(enrollment_by_grade[firstYear]);
      const currentGrades = countGradesWithEnrollment(enrollment_by_grade.current);
      
      if (currentGrades > initialGrades) {
        return SCHOOL_CATEGORIES.NEWER_INCREASING;
      } else if (currentGrades < initialGrades) {
        return SCHOOL_CATEGORIES.NEWER_DECREASING;
      }
      return SCHOOL_CATEGORIES.NEWER_STABLE;
    }
    
    // If only has current year data
    if (has2024Data) {
      return SCHOOL_CATEGORIES.NEW;
    }
    
    return SCHOOL_CATEGORIES.UNKNOWN;
  };
  
  export const categorizeNearbySchool = (schoolData) => {
    const { enrollment_by_grade } = schoolData;
    
    const has2019Data = hasDataInYear(enrollment_by_grade.comparison);
    const has2024Data = hasDataInYear(enrollment_by_grade.current);
    
    // Handle closed schools
    if (has2019Data && !has2024Data) {
      return SCHOOL_CATEGORIES.CLOSED;
    }
    
    // Handle new/newer schools
    if (!has2019Data && has2024Data) {
      return SCHOOL_CATEGORIES.NEW_OR_NEWER;
    }
    
    // Handle continuous schools
    if (has2019Data && has2024Data) {
      const grades2019 = countGradesWithEnrollment(enrollment_by_grade.comparison);
      const grades2024 = countGradesWithEnrollment(enrollment_by_grade.current);
      
      if (grades2024 > grades2019) {
        return SCHOOL_CATEGORIES.CONTINUOUS_INCREASING;
      } else if (grades2024 < grades2019) {
        return SCHOOL_CATEGORIES.CONTINUOUS_DECREASING;
      }
      return SCHOOL_CATEGORIES.CONTINUOUS_STABLE;
    }
    
    return SCHOOL_CATEGORIES.UNKNOWN;
  };
  
  export const categorizeSchools = (schoolsData, selectedSchoolId) => {
    return schoolsData.map(schoolData => {
      const category = schoolData.ncessch === selectedSchoolId
        ? categorizeSelectedSchool(schoolData)
        : categorizeNearbySchool(schoolData);
  
      return {
        ...schoolData,
        category
      };
    });
  };