import React from 'react';
import { Box, Typography, Paper, Button } from '@mui/material';
import { MapsHomeWork, Launch } from '@mui/icons-material';

const ComparisonAnalysis = ({ 
  selectedSchool,
  selectedSchoolTrends,
  schoolData
}) => {
  if (!selectedSchool || !selectedSchoolTrends || !schoolData) {
    return null;
  }

  // Calculate rank based on enrollment changes
  const getRank = () => {
    const sortedSchools = [...schoolData].sort((a, b) => {
      const changeA = a.currentEnrollment && a.previousEnrollment ? 
        ((a.currentEnrollment - a.previousEnrollment) / a.previousEnrollment) * 100 : -Infinity;
      const changeB = b.currentEnrollment && b.previousEnrollment ? 
        ((b.currentEnrollment - b.previousEnrollment) / b.previousEnrollment) * 100 : -Infinity;
      return changeB - changeA;
    });
    return sortedSchools.findIndex(s => s.ncessch === selectedSchool.ncessch) + 1;
  };

  // Get ordinal suffix
  const getOrdinalSuffix = (number) => {
    const j = number % 10;
    const k = number % 100;
    if (j === 1 && k !== 11) return 'st';
    if (j === 2 && k !== 12) return 'nd';
    if (j === 3 && k !== 13) return 'rd';
    return 'th';
  };

  // Create URL with school parameter
  const explorerUrl = `/geographic-explorer?school=${selectedSchool.ncessch}`;

  // Round enrollment change to nearest integer
  const enrollmentChange = Math.round(selectedSchoolTrends?.selectedSchool?.percentChange || 0);
  
  // Calculate rank
  const rank = getRank();

  return (
    <Box sx={{ width: '100%' }}>
      <Paper 
        elevation={0}
        sx={{
          mb: 2,
          py: 2,
          px: 3,
          backgroundColor: 'grey.50',
          color: 'text.primary',
          borderRadius: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2,
          position: 'relative',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            width: 6,
            backgroundColor: 'primary.main',
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8
          }
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography variant="h8" sx={{ fontWeight: 200 }}>
            <strong>{selectedSchool.name}</strong>'s enrollment change of <strong>{enrollmentChange}%</strong> ranks <strong>{rank}{getOrdinalSuffix(rank)}</strong> out of {schoolData.length} nearby schools between 2019-20 and 2023-24.
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          startIcon={<MapsHomeWork />}
          endIcon={<Launch />}
          href={explorerUrl}
          sx={{ 
            backgroundColor: 'primary.dark',
            color: 'white',
            '&:hover': {
              backgroundColor: 'primary.main'
            }
          }}
        >
          OPEN IN GEOGRAPHIC EXPLORER
        </Button>
      </Paper>
    </Box>
  );
};

export default ComparisonAnalysis;